import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
    createRemoteRunnerAsync,
    ICreateRemoteRunner,
    selectRemoteRunners,
} from "./RemoteRunnerSlice";
import { RemoteRunnerEdit } from "./RemoteRunnerEdit";
import { ButtonWithImplicitLicenseCheck, showMaxRemoteRunnerLimitExceededLicenseMessage } from "../misc/Util";

import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import { addOrActivateRemoteRunnerConfigurationWidget } from "../main_lumino_widget/MainLuminoWidgetSlice";
import { selectValidLicenses } from "../license/LicenseSlice";
import { licenseFeatureUnlimitedCountMarker } from "../misc/Constants";

export const RemoteRunnerCreate = () => {

    const remoteRunners = useAppSelector(selectRemoteRunners)
    const validLicenses = useAppSelector(selectValidLicenses)
    const maxAllowedRemoteRunners: number = Math.max(...validLicenses.map((validLicense) => validLicense.getFeatureValue("MaxRemoteRunners") ?? 0))
    const hasUnlimitedRemoteRunnersLicense: boolean = validLicenses.some((validLicense) => validLicense.getFeatureValue("MaxRemoteRunners") === licenseFeatureUnlimitedCountMarker)

    const [name, setName] = useState('')

    const { t } = useTranslation()

    const dispatch = useAppDispatch()

    const hasAllNeededData = () => {
        return name.length > 0
    }

    const createRemoteRunner = () => {
        if (!hasAllNeededData()) {
            return
        }

        if (!hasUnlimitedRemoteRunnersLicense && (remoteRunners.length + 1) > maxAllowedRemoteRunners) {
            showMaxRemoteRunnerLimitExceededLicenseMessage(maxAllowedRemoteRunners)
            return
        }

        const newRemoteRunner: ICreateRemoteRunner = {
            name: name,
        }
        dispatch(createRemoteRunnerAsync(newRemoteRunner)).then((res) => {
            if (res.meta.requestStatus === "fulfilled") {
                dispatch(addOrActivateRemoteRunnerConfigurationWidget(res.payload))
            }
        })
    }

    return (
        <Container disableGutters sx={{ overflow: "hidden", overflowY: "auto", marginLeft: -1 }}>
            <RemoteRunnerEdit {...{
                stateName: [name, setName],
                createView: true
            }}/>

            <ButtonWithImplicitLicenseCheck
                text={t("Create")}
                sx={{ margin: 1 }}
                id="create-remote-runner-button"
                variant="contained"
                onClickCallback={createRemoteRunner}
                disabledCondition={!hasAllNeededData()}
            />
        </Container>
    )
}