import { dcrfClient } from '../../app/api'
import { logger } from '../../app/logging';
import { IHardwareInterface } from "./HardwareInterfacesSlice";

/*
export const fetchAllHwInterfacesViaREST = () => {
    logger.debug('fetchAllInterfacesViaREST')
    return restClient.get('/hw_interfaces/')
}
*/

export const fetchAllHwInterfacesViaDCRF = () => {
    logger.debug('fetchAllInterfacesViaDCRF')
    return dcrfClient.list('hwinterface')
}

export const patchHwInterfaceViaDCRF = (id: number, data: any) => {
    logger.debug(`patchHwInterfaceViaDCRF with id: ${id} - data: ${data}`)
    return dcrfClient.patch("hwinterface", id, data)
}

export const subscribeToHwInterfaceChangesViaDCRF = (callback: (hwInterface: IHardwareInterface, action: string) => void) => {
    logger.debug("subscribeToInterfaceChangesViaDCRF")
    const subscription = dcrfClient.subscribe('hwinterface',
        {},
        (hwInterface, action) => {
            callback(hwInterface as IHardwareInterface, action)
        },
        {
            includeCreateEvents: true,
            requestId: 'subscribeToHwInterfaceChangesViaDCRF',
            subscribeAction: 'subscribe_to_all_changes',
            unsubscribeAction: 'unsubscribe_from_all_changes',
        }
    )
    // a subscription can be canceled by calling "cancel" on the returned subscription object
    return subscription
}