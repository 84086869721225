import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Container from "@mui/material/Container";
import { ILicense, TLicenseFeatures, selectLicense, selectLicenses, deleteLicenseAsync, LicenseWrapper, selectValidLicenses } from "./LicenseSlice";
import { addOrActivateLicensesWidget } from "../main_lumino_widget/MainLuminoWidgetSlice";
import { ButtonWithDialog, useObserveAndDeleteWidget } from "../misc/Util";
import { Alert, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { useTranslation } from 'react-i18next'
import { licenseFeatureUnlimitedCountMarker } from "../misc/Constants";
import { selectSystemData } from "../system_data/SystemDataSlice";

export const LicenseConfig = (props: any) => {

    const license: ILicense | undefined = useAppSelector(selectLicense(props.licenseId))
    const licenses: ILicense[] = useAppSelector(selectLicenses)
    const validLicenses: LicenseWrapper[] = useAppSelector(selectValidLicenses)
    const systemData = useAppSelector(selectSystemData)
    
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' } as const

    const { t } = useTranslation()

    const dispatch = useAppDispatch()
    useObserveAndDeleteWidget("LICENSECONFIG", licenses)

    const getDate = (unix_timestamp: number | undefined) => {
        if (typeof unix_timestamp !== "undefined") {
            const date = new Date(unix_timestamp * 1000).toLocaleDateString(undefined, options)
            return date
        } else {
            return t("undefined")
        }
    }

    const getExpiryDate = (issuedAt: number | undefined, validUntil: number | undefined) => {
        if ((validUntil ? validUntil : 0) === 0) {
            return t("Infinite")
        } else {
            if (typeof validUntil !== "undefined") {
                const validUntilDate = getDate(validUntil)
                const dateToday = new Date().toLocaleDateString(undefined, options)
                // Show date and time if expiry date is today,
                // otherwise only date
                if (validUntilDate === dateToday) {
                    return new Date(validUntil * 1000).toLocaleTimeString(undefined, options)
                } else {
                    return validUntilDate
                }
            } else {
                return t("undefined")
            }
        }
    }

    const getFeatureValueRepresentation = (value: string | number) => {
        if (value === licenseFeatureUnlimitedCountMarker || value === `${licenseFeatureUnlimitedCountMarker}`) {
            return t("Unlimited")
        }
        return value
    }

    const getFeaturesAsRows = (features: TLicenseFeatures) => {
        const rows: any = []

        Object.keys(features ?? []).sort().forEach((key: string) => {
            rows.push(<TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                key={key}
            >
                <TableCell component="th" scope="row">
                    {key}
                </TableCell>
                <TableCell align="right">{getFeatureValueRepresentation(String((features as any)[key]))}</TableCell>
            </TableRow>
            )
        })
        return rows
    }
    const getFeaturesAsTable = (features: TLicenseFeatures) => {
        return (
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 300 }} aria-label="Features Table">
                    <TableHead>
                        <TableRow>
                            <TableCell><b>{t("Feature")}</b></TableCell>
                            <TableCell align="right"><b>{t("Value")}</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getFeaturesAsRows(features ?? {})}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

    const deleteLicense = () => {
        if (license === undefined) {
            return
        }
        dispatch(deleteLicenseAsync(license.id))
    }

    return (
        <Container id="license-container" sx={{ overflow: "hidden", overflowY: "auto", height: "90vh" }}>
            <TextField
                sx={{ margin: 1 }}
                id="license-expiry-date"
                label={t("Expiry date")}
                value={getExpiryDate(license?.license_config.IssuedAt, license?.license_config.ValidUntil) ?? t("undefined")}
                disabled
            />
            <TextField
                sx={{ margin: 1 }}
                id="license-issued-date"
                label={t("Issued at")}
                value={getDate(license?.license_config.IssuedAt)}
                disabled
            />
            <br />
            <TextField
                sx={{ margin: 1 }}
                id="license-serial"
                label={t("Serial")}
                value={license?.license_config.Serial ?? t("undefined")}
                disabled
            />
            <TextField
                sx={{ margin: 1 }}
                id="license-email"
                label={t("Email")}
                value={license?.license_config.Email ?? t("undefined")}
                disabled
            />

            {systemData.hardware_id === license?.license_config.HWID ? <br /> :
                <div>
                    <br />
                    <Alert
                        sx={{ margin: 1 }}
                        severity="warning"
                        id="license-hw-id-invalid-alert"
                        action={
                            <Button
                                color="inherit"
                                size="small"
                                onClick={() => dispatch(addOrActivateLicensesWidget())}
                            >
                                {t("Licenses View")}
                            </Button>
                        }
                    >
                        {t("License not valid for this machine")}
                    </Alert>
                    <br />
                </div>
            }
            {validLicenses.find(validLicense => validLicense.license.license_config.Serial === license?.license_config.Serial) !== undefined ? <br /> :
                <div>
                    <br />
                        <Alert
                            sx={{ margin: 1 }}
                            severity="warning"
                            id="license-invalid-alert"
                            action={
                                <Button 
                                    color="inherit"
                                    size="small"
                                    onClick={() => dispatch(addOrActivateLicensesWidget())}
                                    >
                                  {t("Licenses View")}
                                </Button>
                              }
                            >
                            {t("License expired")}
                        </Alert>
                    <br />
                </div>
            }
            {validLicenses.find(validLicense => validLicense.license.license_config.Serial === license?.license_config.Serial)?.license.disabled ?
                <div>
                    <br />
                        <Alert 
                            sx={{ margin: 1 }}
                            severity="error"
                            id="license-disabled-alert"
                            action={
                                <Button 
                                    color="inherit"
                                    size="small"
                                    onClick={() => dispatch(addOrActivateLicensesWidget())}
                                    >
                                  {t("Licenses View")}
                                </Button>
                              }
                            >
                            {t("License has been disabled. Please contact license@dissec.to if you have any questions regarding the license.")}
                        </Alert>
                    <br />
                </div>
                : <></>
            }
            <br />
            <div>
                {getFeaturesAsTable(license?.license_config.Features ?? {})}
                <ButtonWithDialog
                    id={"license-delete-button"}
                    title={t("Do you really want to delete this license?")}
                    message={t("Deleting a license is permanent and can not be undone. Of course you can always add it again. Are you sure?")}
                    buttonColor="error"
                    proceedButtonColor="error"
                    cancelButtonColor="primary"
                    proceedCallback={deleteLicense}
                >
                    {t("Delete")}
                </ButtonWithDialog>
            </div>
        </Container>
    )
}