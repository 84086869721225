import { createAction, createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, store } from "../../app/store";
import {
    createRemoteJobTemplateViaDCRF, deleteRemoteJobTemplateViaDCRF,
    fetchAllRemoteJobTemplatesViaDCRF,
    patchRemoteJobTemplateViaDCRF,
    subscribeToRemoteJobTemplateChangesViaDCRF,
} from "./RemoteJobTemplateAPI";
import {
    showUserMessage,
} from '../user_message/UserMessageSlice';
import { send } from "@giantmachines/redux-websocket";
import { logger } from "../../app/logging";

export const WEBSOCKET_REMOTE_TEMPLATE_EDITOR_REDUX_ACTION_PREFIX = "REMOTE_TEMPLATE_EDITOR"

export type TRemoteJobTemplateJobType = "GENERIC" | "TERMINAL" | "SCANRUN" | "INTERNAL" | "TESTCASE"

export interface IRemoteJobTemplate {
    id: number,
    name: string,
    git_remote_url?: string,
    git_last_operation_error_text?: string,
    archive_template_file: string,
    job_type: TRemoteJobTemplateJobType
    created_at: string,
    last_updated: string,
    outdated_local_repo: boolean,
    local_repository_commit_id_on_last_update: string
}

export interface IRemoteJobTemplateFile {
    children?: IRemoteJobTemplateFile[],
    name: string,
    id?: string | number
    _id?: number
}

export interface IRemoteJobTemplateFileTree {
    remoteJobTemplateId: number,
    files: IRemoteJobTemplateFile
}

export interface IRemoteJobTemplateFileContent {
    remoteJobTemplateId: number
    filePath: string
    fileContent: string | null
}

export interface IRequestRemoteJobTemplateFileContent {
    remoteJobTemplateId: number
    filePath: string
}

type LastRequestStatus = 'success' | 'busy' | 'failed'

export interface IRemoteJobTemplates {
    RemoteJobTemplates: IRemoteJobTemplate[]
    RemoteJobTemplateFileTrees: IRemoteJobTemplateFileTree[]
    RemoteJobTemplateFileContents: IRemoteJobTemplateFileContent[]
    lastRequestStatus: LastRequestStatus
    subscribedToUpdates: boolean
}

export const cloneTemplateArchiveFromRemoteGITRepositoryAsync = createAsyncThunk(
    'remoteJobTemplate/cloneTemplateArchiveFromRemoteGITRepositoryAsync',
    async (remoteJobTemplateId: number, { dispatch, rejectWithValue }) => {
        try {
            dispatch(send({
                stream: 'trigger_git_clone',
                payload: {
                    remoteJobTemplateId: remoteJobTemplateId,
                }
            }, WEBSOCKET_REMOTE_TEMPLATE_EDITOR_REDUX_ACTION_PREFIX))
        } catch (rejectedValue) {
            // @ts-ignore
            const error_message = JSON.stringify(rejectedValue.errors)
            dispatch(showUserMessage({ title: 'cloneTemplateArchiveFromRemoteGITRepositoryAsync failed', message: error_message }))
            return rejectWithValue(rejectedValue)
        }
    }
)

export const pullTemplateArchiveFromRemoteGITRepositoryAsync = createAsyncThunk(
    'remoteJobTemplate/pullTemplateArchiveFromRemoteGITRepositoryAsync',
    async (remoteJobTemplateId: number, { dispatch, rejectWithValue }) => {
        try {
            dispatch(send({
                stream: 'trigger_git_pull',
                payload: {
                    remoteJobTemplateId: remoteJobTemplateId,
                }
            }, WEBSOCKET_REMOTE_TEMPLATE_EDITOR_REDUX_ACTION_PREFIX))
        } catch (rejectedValue) {
            // @ts-ignore
            const error_message = JSON.stringify(rejectedValue.errors)
            dispatch(showUserMessage({ title: 'pullTemplateArchiveFromRemoteGITRepositoryAsync failed', message: error_message }))
            return rejectWithValue(rejectedValue)
        }
    }
)

export const pushTemplateArchiveFromRemoteGITRepositoryAsync = createAsyncThunk(
    'remoteJobTemplate/pushTemplateArchiveFromRemoteGITRepositoryAsync',
    async (remoteJobTemplateId: number, { dispatch, rejectWithValue }) => {
        try {
            dispatch(send({
                stream: 'trigger_git_push',
                payload: {
                    remoteJobTemplateId: remoteJobTemplateId,
                }
            }, WEBSOCKET_REMOTE_TEMPLATE_EDITOR_REDUX_ACTION_PREFIX))
        } catch (rejectedValue) {
            // @ts-ignore
            const error_message = JSON.stringify(rejectedValue.errors)
            dispatch(showUserMessage({ title: 'pushTemplateArchiveFromRemoteGITRepositoryAsync failed', message: error_message }))
            return rejectWithValue(rejectedValue)
        }
    }
)

export const requestFileContentAsync = createAsyncThunk(
    'remoteJobTemplate/requestFileContentAsync',
    async (remoteJobTemplateFileContent: IRequestRemoteJobTemplateFileContent, { dispatch, rejectWithValue }) => {
        try {
            dispatch(send({
                stream: 'request_file_content',
                payload: {
                    remoteJobTemplateId: remoteJobTemplateFileContent.remoteJobTemplateId,
                    filePath: remoteJobTemplateFileContent.filePath
                }
            }, WEBSOCKET_REMOTE_TEMPLATE_EDITOR_REDUX_ACTION_PREFIX))
        } catch (rejectedValue) {
            // @ts-ignore
            const error_message = JSON.stringify(rejectedValue.errors)
            dispatch(showUserMessage({ title: 'requestFileContentAsync failed', message: error_message }))
            return rejectWithValue(rejectedValue)
        }
    }
)

export const requestFileTreeAsync = createAsyncThunk(
    'remoteJobTemplate/requestFileTreeAsync',
    async (remoteJobTemplateId: number, { dispatch, rejectWithValue }) => {
        try {
            dispatch(send({
                stream: 'request_file_tree',
                payload: {
                    remoteJobTemplateId: remoteJobTemplateId
                }
            }, WEBSOCKET_REMOTE_TEMPLATE_EDITOR_REDUX_ACTION_PREFIX))
        } catch (rejectedValue) {
            // @ts-ignore
            const error_message = JSON.stringify(rejectedValue.errors)
            dispatch(showUserMessage({ title: 'requestFileTreeAsync failed', message: error_message }))
            return rejectWithValue(rejectedValue)
        }
    }
)

export const createTemplateCopyAsync = createAsyncThunk(
    'remoteJobTemplate/createTemplateCopyAsync',
    async (remoteJobTemplateId: number, { dispatch, rejectWithValue }) => {
        try {
            dispatch(send({
                stream: 'create_template_copy',
                payload: {
                    remoteJobTemplateId: remoteJobTemplateId
                }
            }, WEBSOCKET_REMOTE_TEMPLATE_EDITOR_REDUX_ACTION_PREFIX))
        } catch (rejectedValue) {
            // @ts-ignore
            const error_message = JSON.stringify(rejectedValue.errors)
            dispatch(showUserMessage({ title: 'createTemplateCopyAsync failed', message: error_message }))
            return rejectWithValue(rejectedValue)
        }
    }
)

export interface IUpdateTemplate {
    remoteJobTemplateId: number,
    updatedFileTree: IRemoteJobTemplateFile,
    updatedFiles: IRequestRemoteJobTemplateFileContent[]
}

export const updateTemplateAsync = createAsyncThunk(
    'remoteJobTemplate/updateTemplateAsync',
    async (updatedTemplate: IUpdateTemplate, { dispatch, rejectWithValue }) => {
        try {
            dispatch(send({
                stream: 'update_template',
                payload: {
                    remoteJobTemplateId: updatedTemplate.remoteJobTemplateId,
                    updatedFileTree: updatedTemplate.updatedFileTree,
                    updatedFiles: updatedTemplate.updatedFiles
                }
            }, WEBSOCKET_REMOTE_TEMPLATE_EDITOR_REDUX_ACTION_PREFIX))
        } catch (rejectedValue) {
            // @ts-ignore
            const error_message = JSON.stringify(rejectedValue.errors)
            dispatch(showUserMessage({ title: 'updateTemplateAsync failed', message: error_message }))
            return rejectWithValue(rejectedValue)
        }
    }
)

export const fetchAllRemoteJobTemplatesAsync = createAsyncThunk(
    'remoteJobTemplate/fetchAllRemoteJobTemplatesAsync',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            return await fetchAllRemoteJobTemplatesViaDCRF()
        } catch (rejectedValue) {
            // @ts-ignore
            const error_message = JSON.stringify(rejectedValue.errors)
            dispatch(showUserMessage({ title: 'fetchAllRemoteJobTemplatesAsync failed', message: error_message }))
            return rejectWithValue(rejectedValue)
        }
    }
)

export interface IUpdateRemoteJobTemplate {
    id: number
    data: {
        name?: string,
        git_remote_url?: string
    }
}

export const updateRemoteJobTemplateAsync = createAsyncThunk(
    'remoteJobTemplate/updateRemoteJobTemplateAsync',
    async (updatedEndpoint: IUpdateRemoteJobTemplate, { dispatch, rejectWithValue }) => {
        try {
            return await patchRemoteJobTemplateViaDCRF(updatedEndpoint.id, updatedEndpoint.data)
        } catch (rejectedValue) {
            // @ts-ignore
            const error_message = JSON.stringify(rejectedValue.errors)
            dispatch(showUserMessage({ title: 'updateRemoteJobTemplateAsync failed', message: error_message }))
            return rejectWithValue(rejectedValue)
        }
    }
)

export interface ICreateRemoteJobTemplate {
    name: string,
    git_remote_url?: string,
    archive_template_file?: string,
    job_type?: TRemoteJobTemplateJobType
}

export const createRemoteJobTemplateAsync = createAsyncThunk(
    'remoteJobTemplate/createRemoteJobTemplateAsync',
    async (newRemoteJobTemplate: ICreateRemoteJobTemplate, { dispatch, rejectWithValue }) => {
        try {
            return await createRemoteJobTemplateViaDCRF(newRemoteJobTemplate)
        } catch (rejectedValue) {
            // @ts-ignore
            const error_message = JSON.stringify(rejectedValue.errors)
            dispatch(showUserMessage({ title: 'createRemoteJobTemplateAsync failed', message: error_message }))
            return rejectWithValue(rejectedValue)
        }
    }
)

export const deleteRemoteJobTemplateAsync = createAsyncThunk(
    'remoteJobTemplate/deleteRemoteJobTemplateAsync',
    async (id: number, { dispatch, rejectWithValue }) => {
        try {
            return await deleteRemoteJobTemplateViaDCRF(id)
        } catch (rejectedValue) {
            // @ts-ignore
            const error_message = JSON.stringify(rejectedValue.errors)
            dispatch(showUserMessage({ title: 'deleteRemoteJobTemplateAsync failed', message: error_message }))
            return rejectWithValue(rejectedValue)
        }
    }
)

const getUpdatedRemoteJobTemplates = (currentRemoteJobTemplates: IRemoteJobTemplate[], updatedRemoteJobTemplate: IRemoteJobTemplate) => {
    return currentRemoteJobTemplates.map((existingRemoteJobTemplate: IRemoteJobTemplate) => {
        if (existingRemoteJobTemplate.id === updatedRemoteJobTemplate.id) {
            return updatedRemoteJobTemplate
        } else {
            return existingRemoteJobTemplate
        }
    })
}

const initialState: IRemoteJobTemplates = {
    RemoteJobTemplates: [],
    RemoteJobTemplateFileTrees: [],
    RemoteJobTemplateFileContents: [],
    subscribedToUpdates: false,
    lastRequestStatus: 'success',
}

interface IRawWebsocketMessage  {
    message: string,
    id: number,
    origin: string,
}

const websocketOnMessageAction = createAction<IRawWebsocketMessage>(`${WEBSOCKET_REMOTE_TEMPLATE_EDITOR_REDUX_ACTION_PREFIX}::MESSAGE`)

export const RemoteJobTemplatesSlice = createSlice({
    name: 'RemoteJobTemplates',
    initialState,
    reducers: {
        addLocalRemoteJobTemplate: (state, action: PayloadAction<IRemoteJobTemplate>) => {
            if (!state.RemoteJobTemplates.some(RemoteJobTemplate => RemoteJobTemplate.id === action.payload.id)) {
                state.RemoteJobTemplates.push(action.payload)
            }
        },
        updateLocalRemoteJobTemplate: (state, action: PayloadAction<IRemoteJobTemplate>) => {
            state.RemoteJobTemplates = getUpdatedRemoteJobTemplates(state.RemoteJobTemplates, action.payload)
        },
        deleteLocalRemoteJobTemplate: (state, action: PayloadAction<IRemoteJobTemplate>) => {
            state.RemoteJobTemplates = state.RemoteJobTemplates.filter((endpoint) => endpoint.id !== action.payload.id)
        },
        subscribeToRemoteJobTemplateChanges: (state) => {
            if (state.subscribedToUpdates) {
                return
            }
            const callback = (RemoteJobTemplate: IRemoteJobTemplate, action: string) => {
                logger.debug(`remote runner subscription callback (action: ${action})`)
                switch(action) {
                    case 'create':
                        store.dispatch(addLocalRemoteJobTemplate(RemoteJobTemplate))
                        break
                    case 'update':
                        store.dispatch(updateLocalRemoteJobTemplate(RemoteJobTemplate))
                        break
                    case 'delete':
                        // NOTE: We get the action.payload with a non null value here. That means,
                        // that this callback and the deleteLocalRemoteJob are responsible for
                        // keeping the state up to date when a remote job gets deleted.
                        // deleteRemoteJobAsync.fulfilled does only get a null value
                        store.dispatch(deleteLocalRemoteJobTemplate(RemoteJobTemplate))
                        break
                    default:
                        logger.debug('FIXME: unexpected action')
                }
            }
            subscribeToRemoteJobTemplateChangesViaDCRF(callback)
            state.subscribedToUpdates = true
        },
        deleteRemoteJobTemplateFileTree: (state, action: PayloadAction<any>) => {
            state.RemoteJobTemplateFileTrees = state.RemoteJobTemplateFileTrees.filter((endpoint) => endpoint.remoteJobTemplateId !== action.payload.id)
        },
        deleteRemoteJobTemplateFileContents: (state, action: PayloadAction<any>) => {
            state.RemoteJobTemplateFileContents = state.RemoteJobTemplateFileContents.filter((endpoint) => endpoint.remoteJobTemplateId !== action.payload.id)
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllRemoteJobTemplatesAsync.pending, (state) => {
                state.lastRequestStatus = 'busy'
            })
            .addCase(fetchAllRemoteJobTemplatesAsync.fulfilled, (state, action: PayloadAction<IRemoteJobTemplate[]>) => {
                state.lastRequestStatus = 'success'
                state.RemoteJobTemplates = action.payload
            })
            .addCase(fetchAllRemoteJobTemplatesAsync.rejected, (state) => {
                state.lastRequestStatus = 'failed'
            })
            .addCase(createRemoteJobTemplateAsync.pending, (state) => {
                state.lastRequestStatus = 'busy'
            })
            .addCase(createRemoteJobTemplateAsync.fulfilled, (state, action: PayloadAction<IRemoteJobTemplate>) => {
                state.lastRequestStatus = 'success'
                if (!state.RemoteJobTemplates.some(endpoint => endpoint.id === action.payload.id)) {
                    state.RemoteJobTemplates.push(action.payload)
                }
            })
            .addCase(createRemoteJobTemplateAsync.rejected, (state) => {
                state.lastRequestStatus = 'failed'
            })
            .addCase(updateRemoteJobTemplateAsync.pending, (state) => {
                state.lastRequestStatus = 'busy'
            })
            .addCase(updateRemoteJobTemplateAsync.fulfilled, (state, action: PayloadAction<IRemoteJobTemplate>) => {
                state.lastRequestStatus = 'success'
                state.RemoteJobTemplates = getUpdatedRemoteJobTemplates(state.RemoteJobTemplates, action.payload)
            })
            .addCase(updateRemoteJobTemplateAsync.rejected, (state) => {
                state.lastRequestStatus = 'failed'
            })
            .addCase(deleteRemoteJobTemplateAsync.pending, (state) => {
                state.lastRequestStatus = 'busy'
            })
            .addCase(deleteRemoteJobTemplateAsync.fulfilled, (state, action: PayloadAction<IRemoteJobTemplate>) => {
                state.lastRequestStatus = 'success'
                // NOTE: action.payload.id is not available here (null value; update, fetch and create get a non null value) 
                // so just leave the state as it is (the subscription will trigger the deletion).
                // This means we are dependent on a functioning subscription callback
            })
            .addCase(deleteRemoteJobTemplateAsync.rejected, (state) => {
                state.lastRequestStatus = 'failed'
            })
            .addCase(websocketOnMessageAction, (state, action: PayloadAction<IRawWebsocketMessage>) => {
                const websocketMessage = JSON.parse(action.payload.message)

                // TODO: use a type (if possible) for the stream names
                switch(websocketMessage.stream) {
                    case 'request_file_tree':
                        const newRemoteJobTemplateFileTree: IRemoteJobTemplateFileTree = {
                            remoteJobTemplateId: websocketMessage.id,
                            files: websocketMessage.payload
                        }
                        state.RemoteJobTemplateFileTrees = state.RemoteJobTemplateFileTrees.filter(remoteJobTemplateFileTree => remoteJobTemplateFileTree.remoteJobTemplateId !== newRemoteJobTemplateFileTree.remoteJobTemplateId)
                        state.RemoteJobTemplateFileTrees.push(newRemoteJobTemplateFileTree)
                        break
                    case 'request_file_content':
                        const newRemoteJobTemplateFileContent: IRemoteJobTemplateFileContent = {
                            remoteJobTemplateId: websocketMessage.remoteJobTemplateId,
                            filePath: websocketMessage.filePath,
                            fileContent: websocketMessage.payload
                        }
                        state.RemoteJobTemplateFileContents = state.RemoteJobTemplateFileContents.filter(remoteJobFileContent => {
                            return !(remoteJobFileContent.remoteJobTemplateId === websocketMessage.remoteJobTemplateId && remoteJobFileContent.filePath === websocketMessage.filePath)
                        })
                        state.RemoteJobTemplateFileContents.push(newRemoteJobTemplateFileContent)
                        break
                    case 'trigger_git_clone':
                        logger.debug(websocketMessage.payload)
                        break
                    case 'trigger_git_push':
                        logger.debug(websocketMessage.payload)
                        break
                    case 'trigger_git_pull':
                        logger.debug(websocketMessage.payload)
                        break
                    case 'create_template_copy':
                        logger.debug(websocketMessage.payload)
                        break
                    default:
                        break
                }
        })
    }
})

export const {
    addLocalRemoteJobTemplate,
    updateLocalRemoteJobTemplate,
    deleteLocalRemoteJobTemplate,
    subscribeToRemoteJobTemplateChanges,
    deleteRemoteJobTemplateFileTree,
    deleteRemoteJobTemplateFileContents } = RemoteJobTemplatesSlice.actions

export const selectRemoteJobTemplatesByType = (jobTypes: TRemoteJobTemplateJobType[]) => (state: RootState) =>
    state.remoteJobTemplates.RemoteJobTemplates.filter(remoteJobTemplate => jobTypes.some((jobType: TRemoteJobTemplateJobType) => remoteJobTemplate.job_type === jobType))

export const selectGenericRemoteJobTemplates = (state: RootState) => selectRemoteJobTemplatesByType(["GENERIC"])(state)
export const selectTestcaseRemoteJobTemplates = (state: RootState) => selectRemoteJobTemplatesByType(["TESTCASE"])(state)

export const selectRemoteJobTemplates = (state: RootState) => state.remoteJobTemplates.RemoteJobTemplates
export const selectRemoteJobTemplate = (id: number) => (state: RootState) => {
    return state.remoteJobTemplates.RemoteJobTemplates.filter( RemoteJobTemplate => RemoteJobTemplate.id === id).pop()
}

export const selectRemoteJobTemplateQueryHWInterfaces = () => (state: RootState) => {
    return state.remoteJobTemplates.RemoteJobTemplates.filter( remoteJobTemplate => remoteJobTemplate.name === "QueryHWInterfaces" && remoteJobTemplate.job_type === "INTERNAL").pop()
}

export const selectRemoteJobTemplateFileTrees = (state: RootState) => state.remoteJobTemplates.RemoteJobTemplateFileTrees
export const selectRemoteJobTemplateFileTree = (id: number) => (state: RootState) => {
    return state.remoteJobTemplates.RemoteJobTemplateFileTrees.filter( RemoteJobTemplateFileTree => RemoteJobTemplateFileTree.remoteJobTemplateId === id).pop()?.files
}

export const selectRemoteJobTemplateFileContents = (state: RootState) => state.remoteJobTemplates.RemoteJobTemplateFileContents
export const selectRemoteJobTemplateFileContent = (id: number, filePath: string) => (state: RootState) => {
    return state.remoteJobTemplates.RemoteJobTemplateFileContents.filter( RemoteJobTemplateFileContent => {
        return RemoteJobTemplateFileContent.remoteJobTemplateId === id && RemoteJobTemplateFileContent.filePath === filePath
    }).pop()
}

export const selectLastRemoteJobTemplateRequestStatus = (state: RootState) => state.remoteJobTemplates.lastRequestStatus
export const selectRemoteJobTemplateIsSubscribed = (state: RootState) => state.remoteJobTemplates.subscribedToUpdates

export default RemoteJobTemplatesSlice.reducer
