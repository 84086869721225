import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../app/hooks";
import { selectUDSScanRunReports } from "../uds_scan_run/UDSScanRunsSlice";
import { useEffect, useMemo, useState } from "react";
import { Container, IconButton, Paper, TextField, Typography, styled } from "@mui/material";
import { authenticatedFetch, makeRGBAColorList } from "../misc/Util";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { httpBackendURL } from "../../app/api";
import { DataGrid, GridEventListener, GridRowSelectionModel } from '@mui/x-data-grid';


const DataInspectorTestCaseComponent = (props: {
    testCaseName: string,
    testCaseResults: any[]
}) => {
    const [showContentTable, setShowContentTable] = useState(false)
    const [filterPattern, setFilterPattern] = useState('')
    const [rdbiDetail, setRdbiDetail] = useState('')
    const [rdbiDocumentation, setRdbiDocumentation] = useState('')
    const [fastaCtxList, setFastaCtxList] = useState([])
    const { t } = useTranslation()

    const checkIfObjectHasKeyword = (obj: any, keyword: string): boolean => {
        const checkIfObjectHasKeywordRecursivly = (obj: any, keyword: string): boolean | undefined => {
            for (var k in obj) {
                if (`${obj[k]}`.includes(keyword) || k.includes(keyword)) {
                    return true
                }
                if (typeof obj[k] == "object" && obj[k] !== null) {
                    const res = checkIfObjectHasKeywordRecursivly(obj[k], keyword)
                    if (res) {
                        return res
                    }
                }
            }
            return
        }

        return keyword === '' ? true : checkIfObjectHasKeywordRecursivly(obj, keyword) ?? false
    }

    const filteredPackages = useMemo(() => {
        return props.testCaseResults.filter(result => checkIfObjectHasKeyword(result, filterPattern)).sort((a, b) => (a.dtc_bytes > b.dtc_bytes) ? 1 : ((b.dtc_bytes > a.dtc_bytes) ? -1 : 0))
    }, [props.testCaseResults, filterPattern])

    const packageIds = useMemo(() => {
        switch (props.testCaseName) {
            case ("dtc_shadow_memory"):
                return Array.from(new Set(filteredPackages.flatMap(result => result.resp_id))).sort()
            case ("rdbi"):
                return Array.from(new Set(filteredPackages.flatMap(result => result.resp_id))).sort()
            case ("dtcs"):
                return Array.from(new Set(filteredPackages.flatMap(result => result.pkt_id))).sort()
            case ("fasta_events"):
                return Array.from(new Set(filteredPackages.flatMap(result => result.id))).sort()
            case ("fasta_sys_ctx"):
                return Array.from(new Set(filteredPackages.flatMap(result => result.event_id))).sort()
            default:
                return []
        }
    }, [props.testCaseResults, filterPattern])

    // remove ' from representation for easier filtering
    const replaceQuotesInplace = (obj: any): void => {
        for (const key in obj) {
            if (typeof obj[key] == 'object') {
                replaceQuotesInplace(obj[key]);
            } else if (typeof obj[key] == 'string') {
                const value = obj[key];
                if (value.charAt(0) === "'" && value.charAt(value.length - 1) === "'") {
                    obj[key] = value.slice(1, -1);
                }
            }
        }
    }

    useEffect(() => {
        replaceQuotesInplace(props.testCaseResults)
    }, [])

    const prepareRdbiFields = (filteredPackages: any[]) => {
        filteredPackages.forEach(filteredPackage => {
            Object.keys(filteredPackage.fields).forEach((field: string | number) => {
                filteredPackage[field] = filteredPackage.fields[field]
            })
        })
    }

    const prepareDtcReprFor = (packages: any[], dtcReprKey: string = 'dtc_repr') => {
        // We try to parse the system, the numeric and the addional value codes.
        // The codes should be combined
        const regex = new RegExp('<DTC\\s+system=(.*?)\\s.*numeric_value_code=(.*?)\\sadditional_information_code=(.*?)\\s.*|>')
        packages.forEach(filteredPackage => {
            const match = filteredPackage[dtcReprKey].match(regex)
            filteredPackage[dtcReprKey] = match.length === 4 ? `${match[1]} Code=${match[2]} ${match[3]}` : filteredPackage[dtcReprKey]
        })
    }

    const prepareSnapshotReprFor = (packages: any[], dtcReprKey: string = 'snapshot_repr') => {
        // remove useless extra info. Only show identifier, value and unit
        const regex = new RegExp('<.*?\\s+(.*)\\s|>')
        packages.forEach(filteredPackage => {
            const match = filteredPackage[dtcReprKey].match(regex)
            filteredPackage[dtcReprKey] = match.length === 2 ? `${match[1]}` : filteredPackage[dtcReprKey]
        })
    }

    const prepareRdbiDtcReprFor = (packages: any[], dtcReprKey: string = 'dtc_repr') => {
        // We try to parse the system, the numeric and the addional value codes.
        // The codes should be combined
        const regex = new RegExp('<.*?\\s(.*)\\s|>')
        packages.forEach(filteredPackage => {
            const match = filteredPackage[dtcReprKey].match(regex)
            filteredPackage[dtcReprKey] = match[1] ? `${match[1].trim()}` : filteredPackage[dtcReprKey]
        })
    }

    const prepareDtcSnapshotsFor = (packages: any[]) => {
        const regex = new RegExp('<.*\\s+identifier=(.*)value=(.*)\\s|>')
        packages.forEach(filteredPackage => {
            const match = filteredPackage['snapshot_data_repr'].match(regex)
            filteredPackage['snapshot_data_repr'] = match ? `${match[1].trim()} = ${match[2].trim()}` : filteredPackage['snapshot_data_repr']
        })
    }
    const logTypeColors: string[] = makeRGBAColorList(0.5, 0, 1, filteredPackages.length)

    const getBackgroundColor = (pkt_id: string, id_list: string[]) => {
        const index = id_list.findIndex(id => id === pkt_id)
        return logTypeColors[index]
    }

    const StyledDataGrid = styled(DataGrid)(({ theme }) => {
        let styleObj: any = {}
        packageIds.forEach(respId => {
            styleObj[`& .app-theme--${respId}`] = { backgroundColor: getBackgroundColor(respId, packageIds) }
        })
        return styleObj
    })

    const showTestCaseContentButton = (
        <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setShowContentTable(!showContentTable)}
        >
            {showContentTable ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
        </IconButton>
    )

    const getReadableUDSEnumeratorNameFrom = (testcaseName: string): string => {
        switch (testcaseName) {
            case 'rdbi':
                return 'Read Data By Identifier'
            case 'dtc_shadow_memory':
                return 'Diagnostic Trouble Codes Shadow Memory'
            case 'fasta_events':
                return 'FASTA Events'
            case 'fasta_sys_ctx':
                return 'FASTA System Context'
            case 'dtcs':
                return 'Diagnostic Trouble Codes'
            default:
                return testcaseName
        }
    }

    const TestCaseName = (props: { testCaseName: string }) => (
        <span>
            {getReadableUDSEnumeratorNameFrom(props.testCaseName)}
        </span>
    )

    const getDataGridDependingOnTestcase = useMemo(() => {
        switch (props.testCaseName) {
            case ("dtc_shadow_memory"):
                prepareDtcReprFor(filteredPackages)
                prepareSnapshotReprFor(filteredPackages)
                return (
                    <Paper sx={{ height: 800, width: '100%', mb: 2 }}>
                        <StyledDataGrid
                            rows={filteredPackages}
                            columns={[
                                { field: 'id', headerName: 'ID' },
                                { field: 'resp_id', headerName: 'Response ID' },
                                { field: 'dtc_repr', headerName: 'DTC Representation', width: 200 },
                                { field: 'dtc_bytes', headerName: 'DTC Bytes', width: 100 },
                                { field: 'dtc_desc', headerName: 'DTC Description', width: 400 },
                                { field: 'identifier', headerName: 'Snapshot Identifier' },
                                { field: 'description', headerName: 'Description', width: 200 },
                                // { field: 'snapshot_data_byte', headerName: 'Data Byte' },
                                { field: 'snapshot_data_int', headerName: 'Data Int' },
                                { field: 'snapshot_repr', headerName: "Snapshot Representation", width: 600 }
                            ]}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 100,
                                    },
                                },
                                columns: {
                                    columnVisibilityModel: {
                                        // Hide columns status and traderName, the other columns will remain visible
                                        id: false,
                                        resp_id: false,
                                        snapshot_data_int: false
                                    },
                                },
                            }}
                            pageSizeOptions={[25, 50, 100]}
                            disableRowSelectionOnClick
                            getRowClassName={(params) => `app-theme--${params.row.resp_id}`}
                        />
                    </Paper>
                )
            case ("rdbi"):
                prepareRdbiFields(filteredPackages)
                prepareRdbiDtcReprFor(filteredPackages, 'data_repr')

                const handleRdbiRowClick: GridEventListener<'rowClick'> = (
                    params, // GridRowParams
                    event, // MuiEvent<React.MouseEvent<HTMLElement>>
                    details, // GridCallbackDetails
                ) => {
                    setRdbiDetail(`${params.row.data_show}`)
                    setRdbiDocumentation(`${params.row.doc}`)
                }

                return (
                    <Paper sx={{ height: 800, width: '50%', mb: 2 }}>
                        <StyledDataGrid
                            rows={filteredPackages}
                            columns={[
                                { field: 'id', headerName: 'ID' },
                                { field: 'resp_id', headerName: 'Response ID' },
                                { field: 'identifier', headerName: 'UDS Identifier' },
                                { field: 'description', headerName: 'Description', width: 200 },
                                { field: 'doc', headerName: 'Documentation', width: 200 },
                                { field: 'data_show', headerName: 'Data Show', width: 400 },
                                { field: 'data_repr', headerName: 'Data Representation', minWidth: 200, flex: 1 },
                                // { field: 'fields', headerName: 'Fields', width: 400 }   
                            ]}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 100,
                                    },
                                },
                                columns: {
                                    columnVisibilityModel: {
                                        id: false,
                                        resp_id: false,
                                        data_show: false
                                    },
                                },
                            }}
                            pageSizeOptions={[25, 50, 100]}
                            onRowClick={handleRdbiRowClick}
                            getRowClassName={(params) => `app-theme--${params.row.resp_id}`}
                        />
                    </Paper>
                )
            case ("dtcs"):
                prepareDtcReprFor(filteredPackages)
                prepareDtcSnapshotsFor(filteredPackages)

                return (
                    <Paper sx={{ height: 800, width: '100%', mb: 2 }}>
                        <StyledDataGrid
                            rows={filteredPackages}
                            columns={[
                                { field: 'id', headerName: 'ID' },
                                { field: 'pkt_id', headerName: 'Paket ID' },
                                { field: 'dtc_bytes', headerName: 'DTC Bytes' },
                                { field: 'dtc_repr', headerName: 'DTC Representation', width: 200 },
                                { field: 'dtc_desc', headerName: 'DTC Description', width: 300 },
                                { field: 'status_mask', headerName: 'Status Mask', width: 200 },
                                { field: 'snapshot_description', headerName: 'Snapshot Description', width: 200 },
                                { field: 'snapshot_identifier', headerName: 'Snapshot Identifier', width: 200 },
                                { field: 'snapshot_data_repr', headerName: 'Snapshot Data Representation', width: 200 },
                                // { field: 'snapshot_data_show', headerName: 'Snapshot Data Show', width: 200 },
                                { field: 'snapshot_data_int', headerName: 'Snapshot Data Int', width: 200 },
                                { field: 'snapshot_data_byte', headerName: 'Snapshot Data Byte', width: 200 },
                                { field: 'dtc_extended_data_type', headerName: 'DTC Extended Data Type', width: 200 },
                                { field: 'dtc_extended_record', headerName: 'DTC Extended Record', width: 200 },
                                { field: 'severity_record', headerName: 'Severity Record', width: 200 },
                            ]}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 100,
                                    },
                                },
                                columns: {
                                    columnVisibilityModel: {
                                        id: false,
                                        pkt_id: false,
                                        dtc_bytes: false,
                                        resp_id: false,
                                        snapshot_data_int: false,
                                        snapshot_data_byte: false,
                                        status_mask: false
                                    },
                                },
                            }}
                            pageSizeOptions={[25, 50, 100]}
                            disableRowSelectionOnClick
                            getRowClassName={(params) => `app-theme--${params.row.pkt_id}`}
                        />
                    </Paper>
                )
            case ("fasta_events"):
                prepareDtcReprFor(filteredPackages)
                // prepareDtcSnapshotsFor(packages)
                const handleFastaRowClick: GridEventListener<'rowClick'> = (
                    params, // GridRowParams
                    event, // MuiEvent<React.MouseEvent<HTMLElement>>
                    details, // GridCallbackDetails
                ) => {
                    setFastaCtxList(filteredPackages.filter(pkt => pkt.id === params.row.id).pop().ctx_list ?? [])
                }
                return (
                    <Paper sx={{ height: 800, width: '50%', mb: 2 }}>
                        <StyledDataGrid
                            rows={filteredPackages}
                            columns={[
                                { field: 'id', headerName: 'ID' },
                                { field: 'dtc_repr', headerName: 'DTC Representation', width: 200 },
                                // { field: 'timestamp', headerName: 'Timestamp' },
                                { field: 'timestring', headerName: 'Timestring', width: 200 },
                                // { field: 'dtc', headerName: 'DTC' },
                                { field: 'dtc_desc', headerName: 'DTC Description', width: 300 },
                            ]}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 100,
                                    },
                                },
                                columns: {
                                    columnVisibilityModel: {
                                        id: false,
                                        pkt_id: false,
                                        dtc_bytes: false,
                                        resp_id: false,
                                        snapshot_data_int: false,
                                        snapshot_data_byte: false,
                                        status_mask: false,
                                        dtc_desc: false,
                                    },
                                },
                            }}
                            pageSizeOptions={[25, 50, 100]}
                            onRowClick={handleFastaRowClick}
                            getRowClassName={(params) => `app-theme--${params.row.id}`}
                        />
                    </Paper>
                )
            case ("fasta_sys_ctx"):
                prepareDtcReprFor(filteredPackages, 'event_dtc_repr')
                // prepareDtcSnapshotsFor(packages)

                return (
                    <Paper sx={{ height: 800, width: '100%', mb: 2 }}>
                        <StyledDataGrid
                            rows={filteredPackages}
                            columns={[
                                { field: 'id', headerName: 'ID' },
                                { field: 'event_id', headerName: 'Event ID' },
                                { field: 'event_dtc_repr', headerName: 'Event DTC Representation', width: 200 },
                                { field: 'timestamp_0', headerName: 'Timestamp' },
                                { field: 'event_timestring', headerName: 'Event Timestring', width: 200},
                                // { field: 'year', headerName: ''},
                                // { field: 'month', headerName: ''},
                                // { field: 'day', headerName: ''},
                                // { field: 'hours', headerName: ''},
                                // { field: 'minutes', headerName: ''},
                                // { field: 'seconds', headerName: ''},
                                // { field: 'ecu_addr', headerName: ''},
                                // { field: 'ecu_name', headerName: ''},
                                { field: 'dtc_desc', headerName: 'DTC Description', width: 200 },
                                { field: 'wakeup_time', headerName: 'Wakeup Time', width: 200 },
                                { field: 'kl_r_on_time', headerName: 'Klemme R On Time', width: 200 },
                                { field: 'kl_15_on_time', headerName: 'Klemme 15 on Time', width: 200 },
                                { field: 'klemme_bei_fehler', headerName: 'Klemme bei Fehler', width: 200 },
                                { field: 'klemme_vor_fehler', headerName: 'Klemme vor Fehler', width: 200 },
                                { field: 'timestamp_klemmenwechsel', headerName: 'Timestamp Klemmenwechsel', width: 200 },
                                { field: 'op_status', headerName: 'OP Status', width: 200 },
                                { field: 'timestamp_op_wechsel', headerName: 'Timestamp OP Wechsel', width: 200 },
                                { field: 'op_status_vor_fehler', headerName: 'OP Status vor Fehler', width: 200 },
                                { field: 'Bordspannung_min', headerName: 'Bordspannung min', width: 200 },
                                { field: 'Bordspannung_max', headerName: 'Bordspannung max', width: 200 },
                                { field: 'Temperatur_aussen', headerName: 'Temperatur aussen', width: 200 },
                                { field: 'Temperatur_Motor', headerName: 'Temperatur Motor', width: 200 },
                                { field: 'const_00', headerName: 'Const 00', width: 200 },
                                { field: 'km_stand', headerName: 'KM Stand', width: 200 },
                                { field: 'Geschwindigkeit_min', headerName: 'Geschwindigkeit min', width: 200 },
                                { field: 'Geschwindigkeit_max', headerName: 'Geschwindigkeit max', width: 200 },
                                { field: 'Drehzahl_min', headerName: 'Drehzahl min', width: 200 },
                                { field: 'Drehzahl_max', headerName: 'Drehzahl max', width: 200 },
                                { field: 'Spannung_min', headerName: 'Spannung min', width: 200 },
                                { field: 'Spannung_max', headerName: 'Spannung max', width: 200 },
                                { field: 'Basis_Teilnetze', headerName: 'Basis Teilnetze', width: 200 },
                                { field: 'funktionale_Teilnetze', headerName: 'Funktionale Teilnetze', width: 200 },
                            ]}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 100,
                                    },
                                },
                                columns: {
                                    columnVisibilityModel: {
                                        id: false,
                                        event_id: false,
                                        dtc_desc: false,
                                    },
                                },
                                sorting: {
                                    sortModel: [{ field: 'timestamp_0', sort: 'asc' }],
                                  },
                            }}
                            pageSizeOptions={[25, 50, 100]}
                            getRowClassName={(params) => `app-theme--${params.row.event_id}`}
                        />
                    </Paper>
                )
            default:
                break
        }
    }, [filteredPackages, props.testCaseName])

    const getSideInformationDependingOnTestcase = useMemo(() => {
        switch (props.testCaseName) {
            case ("rdbi"):
                return (
                    <Container sx={{ maxHeight: "800px", overflowY: "auto" }}>
                        <Typography variant="h5">{"Packet Documentation"}</Typography>
                        <TextField multiline sx={{ width: "100%" }} value={rdbiDocumentation} />
                        <Typography variant="h5">{"Packet Details"}</Typography>
                        <TextField multiline sx={{ width: "100%" }} value={rdbiDetail} />
                    </Container>
                )
            case ("fasta_events"):
                return (
                    <Paper sx={{ height: 800, width: '50%', mb: 2 }}>
                        <StyledDataGrid
                            rows={fastaCtxList}
                            columns={[
                                { field: 'id', headerName: 'ID' },
                                { field: 'event_id', headerName: 'Event ID' },
                                { field: 'timestamp_0', headerName: 'Timestamp' },
                                // { field: 'year', headerName: ''},
                                // { field: 'month', headerName: ''},
                                // { field: 'day', headerName: ''},
                                // { field: 'hours', headerName: ''},
                                // { field: 'minutes', headerName: ''},
                                // { field: 'seconds', headerName: ''},
                                // { field: 'ecu_addr', headerName: ''},
                                // { field: 'ecu_name', headerName: ''},
                                { field: 'dtc_desc', headerName: 'DTC Description', width: 200 },
                                { field: 'wakeup_time', headerName: 'Wakeup Time', width: 200 },
                                { field: 'kl_r_on_time', headerName: 'Klemme R On Time', width: 200 },
                                { field: 'kl_15_on_time', headerName: 'Klemme 15 on Time', width: 200 },
                                { field: 'klemme_bei_fehler', headerName: 'Klemme bei Fehler', width: 200 },
                                { field: 'klemme_vor_fehler', headerName: 'Klemme vor Fehler', width: 200 },
                                { field: 'timestamp_klemmenwechsel', headerName: 'Timestamp Klemmenwechsel', width: 200 },
                                { field: 'op_status', headerName: 'OP Status', width: 200 },
                                { field: 'timestamp_op_wechsel', headerName: 'Timestamp OP Wechsel', width: 200 },
                                { field: 'op_status_vor_fehler', headerName: 'OP Status vor Fehler', width: 200 },
                                { field: 'Bordspannung_min', headerName: 'Bordspannung min', width: 200 },
                                { field: 'Bordspannung_max', headerName: 'Bordspannung max', width: 200 },
                                { field: 'Temperatur_aussen', headerName: 'Temperatur aussen', width: 200 },
                                { field: 'Temperatur_Motor', headerName: 'Temperatur Motor', width: 200 },
                                { field: 'const_00', headerName: 'Const 00', width: 200 },
                                { field: 'km_stand', headerName: 'KM Stand', width: 200 },
                                { field: 'Geschwindigkeit_min', headerName: 'Geschwindigkeit min', width: 200 },
                                { field: 'Geschwindigkeit_max', headerName: 'Geschwindigkeit max', width: 200 },
                                { field: 'Drehzahl_min', headerName: 'Drehzahl min', width: 200 },
                                { field: 'Drehzahl_max', headerName: 'Drehzahl max', width: 200 },
                                { field: 'Spannung_min', headerName: 'Spannung min', width: 200 },
                                { field: 'Spannung_max', headerName: 'Spannung max', width: 200 },
                                { field: 'Basis_Teilnetze', headerName: 'Basis Teilnetze', width: 200 },
                                { field: 'funktionale_Teilnetze', headerName: 'Funktionale Teilnetze', width: 200 },
                            ]}
                            initialState={{
                                // pagination: {
                                //     paginationModel: {
                                //         pageSize: 100,
                                //     },
                                // },
                                columns: {
                                    columnVisibilityModel: {
                                        id: false,
                                        event_id: false,
                                        dtc_desc: false,
                                    },
                                },
                            }}
                            // pageSizeOptions={[25, 50, 100]}
                            disableRowSelectionOnClick
                            getRowClassName={(params) => `app-theme--${params.row.id}`}
                        />
                    </Paper>
                )
            default:
                return <></>
        }
    }, [rdbiDetail, fastaCtxList])

    return (
        <div>
            <h1>{showTestCaseContentButton} <TestCaseName testCaseName={props.testCaseName} /></h1>
            {
                showContentTable ? (
                    <Container maxWidth={false} style={{ display: 'flex', flexDirection: 'row', width: "100%" }} >
                        {getDataGridDependingOnTestcase}
                        {getSideInformationDependingOnTestcase}
                    </Container>
                ) : <></>
            }
        </div>
    )
}

export const DataInspector = (props: any) => {

    const udsScanReports = useAppSelector(selectUDSScanRunReports)
    const scanReport = udsScanReports.filter(scanRunFinding => scanRunFinding.id === props.scanReportId)[0]
    const [localReport, setLocalReport] = useState<any>(null)

    const { t } = useTranslation()


    useEffect(() => {
        let headers = new Headers()

        authenticatedFetch(`${httpBackendURL}/api/uds_scan_run_findings/${scanReport.id}/get_data_inspector_data/`, "GET", headers)
            .then((response) => response.json())
            .then(data => setLocalReport(data))

    }, [])

    const getTestcases = () => {
        return Object.keys(localReport).map(testcaseName => {
            return (
                <DataInspectorTestCaseComponent testCaseName={testcaseName} testCaseResults={localReport[testcaseName]} key={`${scanReport.id}-${testcaseName}`} />
            )
        })

    }


    return (
        <Container maxWidth={false} disableGutters sx={{ overflow: "hidden", overflowY: "auto", width: "100%", height: "100%" }}>
            {localReport === null ? t("Downloading ...") :
                getTestcases()
            }
        </Container>)
}