import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, store } from "../../app/store";
import {
    createRemoteRunnerViaDCRF, deleteRemoteRunnerViaDCRF,
    fetchAllRemoteRunnerViaDCRF as fetchAllRemoteRunnersViaDCRF,
    patchRemoteRunnerViaDCRF,
    subscribeToRemoteRunnerChangesViaDCRF,
} from "./RemoteRunnerAPI";
import {
    showUserMessage,
} from '../user_message/UserMessageSlice';
import { t } from "i18next";
import { logger } from "../../app/logging";

type LastRequestStatus = 'success' | 'busy' | 'failed'

type TRemoteRunnerSystemDataType = 'hw_interface_descriptions'

export interface IRemoteRunnerSystemData {
    id: number
    remote_runner: number
    runner_data: string
    runner_data_type: TRemoteRunnerSystemDataType
    created_at: string
    last_updated: string
}

export interface IRemoteRunner {
    id: number
    name: string
    token: string
    last_seen_ip: string
    created_at: string
    last_seen: string
    system_data: IRemoteRunnerSystemData[]
}

export interface IRemoteRunners {
    remoteRunners: IRemoteRunner[]
    lastRequestStatus: LastRequestStatus
    subscribedToUpdates: boolean
}

export const fetchAllRemoteRunnersAsync = createAsyncThunk(
    'isotpEndpoint/fetchAllRemoteRunnersAsync',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            return await fetchAllRemoteRunnersViaDCRF()
        } catch (rejectedValue) {
            // @ts-ignore
            const error_message = JSON.stringify(rejectedValue.errors)
            dispatch(showUserMessage({ title: 'fetchAllRemoteRunnersAsync failed', message: error_message }))
            return rejectWithValue(rejectedValue)
        }
    }
)

export interface IUpdateRemoteRunner {
    id: number
    data: {
        name?: string
    }
}

export const updateRemoteRunnerAsync = createAsyncThunk(
    'isotpEndpoint/updateRemoteRunnerAsync',
    async (updatedEndpoint: IUpdateRemoteRunner, { dispatch, rejectWithValue }) => {
        try {
            return await patchRemoteRunnerViaDCRF(updatedEndpoint.id, updatedEndpoint.data)
        } catch (rejectedValue) {
            // @ts-ignore
            const error_message = JSON.stringify(rejectedValue.errors)
            switch(true) {
                case error_message.includes("remote runner with this name already exists"):
                    dispatch(showUserMessage({ title: t('Name already taken'), message: t('The supplied name is already taken. Please try again.') }))
                    break
                default:
                    dispatch(showUserMessage({ title: 'updateRemoteRunnerAsync failed', message: error_message }))
            }
            return rejectWithValue(rejectedValue)
        }
    }
)

export interface ICreateRemoteRunner {
    name: string
}

export const createRemoteRunnerAsync = createAsyncThunk(
    'isotpEndpoint/createRemoteRunnerAsync',
    async (newRemoteRunner: ICreateRemoteRunner, { dispatch, rejectWithValue }) => {
        try {
            return await createRemoteRunnerViaDCRF(newRemoteRunner)
        } catch (rejectedValue) {
            // @ts-ignore
            const error_message = JSON.stringify(rejectedValue.errors)
            switch(true) {
                case error_message.includes("remote runner with this name already exists"):
                    dispatch(showUserMessage({ title: t('Name already taken'), message: t('The supplied name is already taken. Please try again.') }))
                    break
                default:
                    dispatch(showUserMessage({ title: 'createRemoteRunnerAsync failed', message: error_message }))
            }
            return rejectWithValue(rejectedValue)
        }
    }
)

export const deleteRemoteRunnerAsync = createAsyncThunk(
    'isotpEndpoint/deleteRemoteRunnerAsync',
    async (id: number, { dispatch, rejectWithValue }) => {
        try {
            return await deleteRemoteRunnerViaDCRF(id)
        } catch (rejectedValue) {
            // @ts-ignore
            const error_message = JSON.stringify(rejectedValue.errors)
            dispatch(showUserMessage({ title: 'deleteRemoteRunnerAsync failed', message: error_message }))
            return rejectWithValue(rejectedValue)
        }
    }
)

const getUpdatedRemoteRunners = (currentRemoteRunners: IRemoteRunner[], updatedRemoteRunner: IRemoteRunner) => {
    return currentRemoteRunners.map((existingRemoteRunner: IRemoteRunner) => {
        if (existingRemoteRunner.id === updatedRemoteRunner.id) {
            return updatedRemoteRunner
        } else {
            return existingRemoteRunner
        }
    })
}

const initialState: IRemoteRunners = {
    remoteRunners: [],
    subscribedToUpdates: false,
    lastRequestStatus: 'success',
}

export const remoteRunnersSlice = createSlice({
    name: 'remoteRunners',
    initialState,
    reducers: {
        addLocalRemoteRunner: (state, action: PayloadAction<IRemoteRunner>) => {
            if (!state.remoteRunners.some(remoterunner => remoterunner.id === action.payload.id)) {
                state.remoteRunners.push(action.payload)
            }
        },
        updateLocalRemoteRunner: (state, action: PayloadAction<IRemoteRunner>) => {
            state.remoteRunners = getUpdatedRemoteRunners(state.remoteRunners, action.payload)
        },
        deleteLocalRemoteRunner: (state, action: PayloadAction<IRemoteRunner>) => {
            state.remoteRunners = state.remoteRunners.filter((endpoint) => endpoint.id !== action.payload.id)
        },
        subscribeToRemoteRunnerChanges: (state) => {
            if (state.subscribedToUpdates) {
                return
            }
            const callback = (remoterunner: IRemoteRunner, action: string) => {
                logger.debug(`remote runner subscription callback (action: ${action})`)
                switch(action) {
                    case 'create':
                        store.dispatch(addLocalRemoteRunner(remoterunner))
                        break
                    case 'update':
                        store.dispatch(updateLocalRemoteRunner(remoterunner))
                        break
                    case 'delete':
                        // NOTE: We get the action.payload with a non null value here. That means,
                        // that this callback and the deleteLocalRemoteJob are responsible for
                        // keeping the state up to date when a remote job gets deleted.
                        // deleteRemoteJobAsync.fulfilled does only get a null value
                        store.dispatch(deleteLocalRemoteRunner(remoterunner))
                        break
                    default:
                        logger.debug('FIXME: unexpected action')
                }
            }
            subscribeToRemoteRunnerChangesViaDCRF(callback)
            state.subscribedToUpdates = true
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllRemoteRunnersAsync.pending, (state) => {
                state.lastRequestStatus = 'busy'
            })
            .addCase(fetchAllRemoteRunnersAsync.fulfilled, (state, action: PayloadAction<IRemoteRunner[]>) => {
                state.lastRequestStatus = 'success'
                state.remoteRunners = action.payload
            })
            .addCase(fetchAllRemoteRunnersAsync.rejected, (state) => {
                state.lastRequestStatus = 'failed'
            })
            .addCase(createRemoteRunnerAsync.pending, (state) => {
                state.lastRequestStatus = 'busy'
            })
            .addCase(createRemoteRunnerAsync.fulfilled, (state, action: PayloadAction<IRemoteRunner>) => {
                state.lastRequestStatus = 'success'
                if (!state.remoteRunners.some(endpoint => endpoint.id === action.payload.id)) {
                    state.remoteRunners.push(action.payload)
                }
            })
            .addCase(createRemoteRunnerAsync.rejected, (state) => {
                state.lastRequestStatus = 'failed'
            })
            .addCase(updateRemoteRunnerAsync.pending, (state) => {
                state.lastRequestStatus = 'busy'
            })
            .addCase(updateRemoteRunnerAsync.fulfilled, (state, action: PayloadAction<IRemoteRunner>) => {
                state.lastRequestStatus = 'success'
                state.remoteRunners = getUpdatedRemoteRunners(state.remoteRunners, action.payload)
            })
            .addCase(updateRemoteRunnerAsync.rejected, (state) => {
                state.lastRequestStatus = 'failed'
            })
            .addCase(deleteRemoteRunnerAsync.pending, (state) => {
                state.lastRequestStatus = 'busy'
            })
            .addCase(deleteRemoteRunnerAsync.fulfilled, (state, action: PayloadAction<IRemoteRunner>) => {
                state.lastRequestStatus = 'success'
                // NOTE: action.payload.id is not available here (null value; update, fetch and create get a non null value) 
                // so just leave the state as it is (the subscription will trigger the deletion).
                // This means we are dependent on a functioning subscription callback
            })
            .addCase(deleteRemoteRunnerAsync.rejected, (state) => {
                state.lastRequestStatus = 'failed'
            })
    }
})

export const {
    addLocalRemoteRunner,
    updateLocalRemoteRunner,
    deleteLocalRemoteRunner,
    subscribeToRemoteRunnerChanges } = remoteRunnersSlice.actions

export const selectRemoteRunners = (state: RootState) => state.remoteRunners.remoteRunners
export const selectRemoteRunner = (id: number) => (state: RootState) => {
    return state.remoteRunners.remoteRunners.filter( remoteRunner => remoteRunner.id === id).pop()
}
export const selectActiveRemoteRunners = (state: RootState) => {
    return state.remoteRunners.remoteRunners.filter((remoteRunner) => {
        const now: any = new Date()
        const lastSeen: any = new Date(remoteRunner.last_seen)
        const fiveMinutes = 5 * 60 * 1000
        return (now - lastSeen) < fiveMinutes
    }) 
}
export const selectLastRemoteRunnerRequestStatus = (state: RootState) => state.remoteRunners.lastRequestStatus
export const selectRemoteRunnerIsSubscribed = (state: RootState) => state.remoteRunners.subscribedToUpdates

export default remoteRunnersSlice.reducer
