import { useState } from "react"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Alert from "@mui/material/Alert"
import { authManager } from "../../app/auth"
import Paper from "@mui/material/Paper"
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import { ConditionalFragment } from "../misc/Util"
import { useTranslation } from "react-i18next"
import { Typography } from "@mui/material"


export const LoginPage = (props: any) => {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const [lastError, setLastError] = useState<string | undefined>(undefined)

    const { t } = useTranslation()

    const doLogin = () => {
        const loginFailedErrorStr = t("last login failed")
        authManager?.login(email, password).then(success => {
            if (!success) {
                setLastError(loginFailedErrorStr)
                return
            }
        })
    }

    return (
        <div style={{ width: "100%", height: "100vh", backgroundColor: "#d3d3d3" }}>
            <Stack height="100vh" direction="column" alignItems="center" justifyContent="space-between">
                <Typography fontSize="23px" variant="body3" padding="30px">
                    {process.env.REACT_APP_PERSONALITY_NAME}
                </Typography>
                <Paper elevation={10} component={Stack} direction="column" alignItems="center" justifyContent="center" sx={{ padding: "15px", width: 350 }}>
                    <Typography variant="h6" paddingBottom="10px">
                        {t("Sign In")}
                    </Typography>
                    <TextField
                        sx={{ margin: 1 }}
                        fullWidth
                        id="login-email"
                        label={"EMail"}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onKeyUp={(ev) => {
                            if (ev.key === 'Enter') {
                                ev.preventDefault()
                                doLogin()
                            }
                        }}
                    />
                    <TextField
                        sx={{ margin: 1 }}
                        fullWidth
                        id="login-password"
                        label={"Password"}
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyUp={(ev) => {
                            if (ev.key === 'Enter') {
                                ev.preventDefault()
                                doLogin()
                            }
                        }}
                    />
                    <ConditionalFragment condition={lastError !== undefined}>
                        <Alert
                            sx={{ margin: 1 }}
                            severity={'error'}
                        >
                            {lastError}
                        </Alert>
                    </ConditionalFragment>
                    <Button
                        sx={{ margin: 1 }}
                        color="primary"
                        fullWidth
                        id="login-button"
                        variant="contained"
                        onClick={doLogin}
                    >
                        {t("Login")}
                    </Button>
                </Paper>
                <Box
                    sx={{
                        width: "100px",
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                        padding: "30px"
                    }}
                    component="img"
                    src={process.env.PUBLIC_URL + "/dissecto718x366.png"}
                    // src={process.env.PUBLIC_URL + "/dissecto718x366_dark.png"}
                />
            </Stack>
        </div>
    )
}