import { dcrfClient } from "../../app/api";
import { logger } from "../../app/logging";
import {
    IRemoteJobTemplate,
    ICreateRemoteJobTemplate
} from "./RemoteJobTemplateSlice";

const wsStream = 'remotejobtemplate'

export const fetchAllRemoteJobTemplatesViaDCRF = () => {
    logger.debug('fetchAllRemoteJobTemplatesViaDCRF')
    return dcrfClient.list(wsStream)
}

export const patchRemoteJobTemplateViaDCRF = (id: number, data: any) => {
    logger.debug(`patchRemoteJobTemplateViaDCRF with id: ${id} - data: ${data}`)
    return dcrfClient.patch(wsStream, id, data)
}

export const createRemoteJobTemplateViaDCRF = (newRemoteJobTemplate: ICreateRemoteJobTemplate) => {
    logger.debug(`createRemoteJobTemplateViaDCRF ${newRemoteJobTemplate.name}`)
    return dcrfClient.create(wsStream, newRemoteJobTemplate)
}

export const deleteRemoteJobTemplateViaDCRF = (id: number) => {
    logger.debug(`deleteRemoteJobTemplateViaDCRF with id: ${id}`)
    return dcrfClient.delete(wsStream, id)
}

export const subscribeToRemoteJobTemplateChangesViaDCRF = (callback: (scanRun: IRemoteJobTemplate, action: string) => void) => {
    logger.debug("subscribeToRemoteJobTemplateChangesViaDCRF")
    const subscription = dcrfClient.subscribe(wsStream,
        {},
        (scanner, action) => {
            callback(scanner as IRemoteJobTemplate, action)
        },
        {
            includeCreateEvents: true,
            requestId: 'subscribeToRemoteJobTemplateChangesViaDCRF',
            subscribeAction: 'subscribe_to_all_changes',
            unsubscribeAction: 'unsubscribe_from_all_changes',
        }
    )
    // a subscription can be canceled by calling "cancel" on the returned subscription object
    return subscription
}
