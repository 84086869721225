import Button from "@mui/material/Button"
import Chip from "@mui/material/Chip"
import Container from "@mui/material/Container"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"
import { useTranslation } from "react-i18next"
import { httpBackendURL, jobArtifactsPath } from "../../app/api"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { RemoteJobLiveData } from "../live_data/LiveData"
import { IStringMapping, utcToLocalDateTimeRepresentation, useObserveAndDeleteWidget, authenticatedFetch, ConditionalFragment } from "../misc/Util"
import { selectRemoteJobs } from "../remote_jobs/RemoteJobSlice"
import { selectRemoteJobTemplates } from "../remote_job_template/RemoteJobTemplateSlice"
import { showUserMessage } from "../user_message/UserMessageSlice"
import { IRemoteJobArtifact, selectRemoteJobArtifacts } from "./RemoteJobArtifactSlice"

// Note: RemoteJobResult combines live data of a remote job and the remote job artifacts
// -> that is the reason why it is called RemoteJobResult instead of RemoteJobArtifact

export const RemoteJobResult = (props: any) => {
    const remoteJobId = props.remoteJob.id
    const remoteJobs = useAppSelector(selectRemoteJobs)
    const remoteJobArtifacts = useAppSelector(selectRemoteJobArtifacts)
    const remoteJobTemplates = useAppSelector(selectRemoteJobTemplates)
    const remoteJob = remoteJobs.filter(remoteJob => remoteJob.id === remoteJobId).pop()
    
    useObserveAndDeleteWidget("GENERICREMOTEJOBRESULT", remoteJobs)
    
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const handleArtifactDownload = (filePath: string) => {
        fetch(`${httpBackendURL}${filePath}`).then(response => {
            if(response.status !== 200){
                dispatch(showUserMessage({ title: t("Artifact Download failed"), message: t("The requested artifact does not seem to exist") }))
                return
            }
            response.blob().then(blob => {
                const fileURL = window.URL.createObjectURL(blob)
                let alink = document.createElement('a')
                alink.href = fileURL
                alink.download = `${filePath.replace(`/${jobArtifactsPath}/`, "")}`
                alink.click()
            })
        })
    }

    const handleAllArtifactsDownloadForJob = () => {
        let headers = new Headers()
        headers.append('Content-Type', 'application/x-zip-compressed')
        headers.append('Accept', 'application/x-zip-compressed')
        
        authenticatedFetch(`${httpBackendURL}/api/remote_job_artifacts/${remoteJobId}/download_zipped_artifacts_of_job/`, "GET", headers).then(response => {
            if(response.status !== 200){
                dispatch(showUserMessage({ title: t("Artifacts Download failed"), message: t("The requested artifacts do not seem to exist") }))
                return
            }
            response.blob().then(blob => {
                const fileURL = window.URL.createObjectURL(blob)
                let alink = document.createElement('a')
                alink.href = fileURL
                alink.download = `remote_job_${remoteJobId}_artifacts.zip`
                alink.click()
            })
        })
    }

    const getReadableArtifactType = (artifactType: string) => {
        const artifactToReadableStringMapping: IStringMapping = {
            "can-log": "CAN Log",
            "scan_run-log": "Scan Run Log",
            "isotp-scan_run": "ISOTP Scan Run",
            "uds-scan_run": "UDS Scan Run",
            "uds-log": "UDS Log",
            "scanner-pickle": "Scanner Pickle",
            "state-graph-pickle": "State Graph Pickle"
        }
        
        const readableArtifactType = artifactToReadableStringMapping[artifactType]

        return readableArtifactType ? readableArtifactType : artifactType
    }
    
    const getRemoteJobArtifactsAsRows = (remoteJobArtifacts: IRemoteJobArtifact[]) => {
        const rows:any = []

        remoteJobArtifacts.filter((remoteJobArtifact) => remoteJobArtifact.remote_job === remoteJobId).forEach((remoteJobArtifact: IRemoteJobArtifact) => {
            rows.push( 
            <TableRow
                 sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                 key={remoteJobArtifact.id}
             >
                <TableCell component="th" scope="row">
                    {remoteJobArtifact.artifact_file.split("/job_artifacts/")[1]}
                </TableCell>
                <TableCell align="right">{utcToLocalDateTimeRepresentation(remoteJobArtifact.created_at)}</TableCell> 
                <TableCell align="right">{getReadableArtifactType(remoteJobArtifact.artifact_type)}</TableCell>
                <TableCell align="right">
                    <Button
                        sx={{ margin: 1 }}
                        variant="contained"
                        color="primary"
                        onClick={() => handleArtifactDownload(remoteJobArtifact.artifact_file)}
                    >
                        {t("Download")}
                    </Button>
                </TableCell>
             </TableRow>
            )
        })
        return rows
    }

    const getRemoteJobArtifactsAsTable = (remoteJobArtifacts: IRemoteJobArtifact[]) => {
        return (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 300}} aria-label="Generic Job Artifacts Table">
                <TableHead>
                  <TableRow>
                    <TableCell><b>{t("Path")}</b></TableCell>
                    <TableCell align="right"><b>{t("Created at")}</b></TableCell>
                    <TableCell align="right"><b>{t("Artifact type")}</b></TableCell>
                    <TableCell align="right">
                        <Button
                            sx={{ margin: 1 }}
                            variant="contained"
                            color="primary"
                            onClick={() => handleAllArtifactsDownloadForJob()}
                        >
                            {t("Download all")}
                        </Button>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getRemoteJobArtifactsAsRows(remoteJobArtifacts)}
                </TableBody>
              </Table>
            </TableContainer>
          )
    }

    return (
        <Container sx={{ overflow: "hidden", overflowY: "auto", height: "90vh", marginLeft: -1 }}>
            <Typography variant="h6">{t("Generic job for probe ") + props.remoteRunner.name}</Typography>
                <Chip sx={{margin: 1}} color="primary" label={`${t("Generic job")} ID: ${remoteJob?.id}`}/>
                <Chip color="primary" sx={{}} label={`Template: ${remoteJobTemplates.filter(template => template.id === remoteJob?.job_template).pop()?.name}`}/>
                <ConditionalFragment condition={remoteJob !== undefined}>
                    <Container sx={{marginLeft: -3}}>
                        <RemoteJobLiveData remoteJob={remoteJob!}/>
                    </Container>
                </ConditionalFragment>
            <Typography sx={{marginTop: 2}} variant="h6">{t("Generic job artifacts")}</Typography>
            <Container sx={{marginLeft: -3}}>
                {getRemoteJobArtifactsAsTable(remoteJobArtifacts)}
            </Container>
        </Container>
    )
}