import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, store } from '../../app/store';
import { showUserMessage } from '../user_message/UserMessageSlice';
import { createLicenseViaDCRF, deleteLicenseViaDCRF, patchLicenseViaDCRF, subscribeToLicenseChangesViaDCRF, fetchAllLicensesViaDCRF } from './LicenseAPI'
import i18n from 'i18next'
import { logger } from '../../app/logging';

type LastRequestStatus = 'success' | 'busy' | 'failed'

export enum LicenseType {
    FREE = "Free",
    PERSONAL = "Personal",
    ENTERPRISE = "Enterprise",
    SITE = "Site"
}

export enum PaidLicenseType {
    PERSONAL = "Personal",
    ENTERPRISE = "Enterprise",
    SITE = "Site"
}

export type TLicenseFeature = "FreeLicense" | "MaxUDSScanTime" | "MaxAnalyzerResults" | "MaxRemoteRunners"

export interface ILicenseFeaturesToExplanationMapping {
    [featureName: string]: {
        [localisation: string]: string
    }
}

export class LicenseWrapper {
    license: ILicense
    constructor(license: ILicense) {
        this.license = license
    }

    isValid() {
        if (this.license.license_config.ValidUntil === 0) {
            return true
        }
        return Math.floor(Date.now() / 1000) <= this.license.license_config.ValidUntil
    }

    getFeatureValue(key: TLicenseFeature): any {
        return this.license.license_config.Features[key]
    }
}

export interface ILicense {
    id: number
    license_config: ILicenseConfiguration
    disabled: boolean
}

export type TLicenseFeatures = {
    [featureName in TLicenseFeature]?: any
}

export interface ILicenseInfo {
    [licenseName: string]: {
        features: TLicenseFeatures
        price: number | string
    }
}

export interface ILicenseConfiguration {
    Version: number
    IssuedAt: number
    ValidUntil: number
    Serial: number
    HWID: string
    Email: string
    Features: TLicenseFeatures
    IsValid: boolean
}

export interface ILicenses {
    licenses: ILicense[]
    lastRequestStatus: LastRequestStatus
    subscribedToUpdates: boolean
}

const getUpdatedLicenses = (currentLicenses: ILicense[], updatedLicense: ILicense) => {
    return currentLicenses.map((existingLicense: ILicense) => {
        if (existingLicense.id === updatedLicense.id) {
            return updatedLicense
        } else {
            return existingLicense
        }
    })
}

const initialState: ILicenses = {
    licenses: [],
    subscribedToUpdates: false,
    lastRequestStatus: 'success',
}

export interface ICreateLicense {
    license_text: string
}

export interface IUpdateLicense {
    id: number
    data: {
        license_text?: string
    }
}

export const createLicenseAsync = createAsyncThunk(
    'license/createLicenseAsync',
    async (newLicense: ICreateLicense, {dispatch, rejectWithValue}) => {
        logger.debug("createLicenseAsync")
        try {
            const response = await createLicenseViaDCRF(newLicense)
            // logger.debug(response)
            return response
        } catch (rejectedValue) {
            logger.debug("createLicenseAsync Error occurred")
            // @ts-ignore
            const error_message = JSON.stringify(rejectedValue.errors)
            if (error_message.includes("License does not seem to be correct")) {
                // TODO: we need something that is better than comparing the error string with some pattern (maybe add error codes)
                dispatch(showUserMessage({ title: i18n.t("Failed to add license"), message: i18n.t("The license seems to be invalid.") }))
            } else {
                dispatch(showUserMessage({ title: "createLicenseAsync failed", message: error_message }))
            }
            return rejectWithValue(rejectedValue)
        }
    }
)

export const fetchAllLicensesAsync = createAsyncThunk(
    'license/fetchAllLicensesAsync',
    async () => {
        const response = await fetchAllLicensesViaDCRF()
        return response
    }
)

export const updateLicenseAsync = createAsyncThunk(
    'license/updateLicenseAsync',
    async (updatedLicense: IUpdateLicense) => {
        const response = await patchLicenseViaDCRF(updatedLicense.id, updatedLicense.data)
        return response
    }
)

export const deleteLicenseAsync = createAsyncThunk(
    'license/deleteLicenseAsync',
    async (id: number) => {
        const response = await deleteLicenseViaDCRF(id)
        return response
    }
)

export const licensesSlice = createSlice({
    name: 'licenses',
    initialState,
    reducers: {
        addLicense: (state, action: PayloadAction<ILicense>) => {
            if (!state.licenses.some(license => license.id === action.payload.id)) {
                state.licenses.push(action.payload)
            }
        },
        updateLicense: (state, action: PayloadAction<ILicense>) => {
            state.licenses = getUpdatedLicenses(state.licenses, action.payload)
        },
        deleteLicense: (state, action: PayloadAction<ILicense>) => {
            state.licenses = state.licenses.filter((license) => license.id !== action.payload.id)
        },
        subscribeToLicenseChanges: (state) => {
            if (state.subscribedToUpdates) {
                return
            }
            const callback = (license: ILicense, action: string) => {
                logger.debug(`license subscription callback (action: ${action})`)
                switch(action) {
                    case 'create':
                        store.dispatch(addLicense(license))
                        break
                    case 'update':
                        store.dispatch(updateLicense(license))
                        break
                    case 'delete':
                        // NOTE: We get the action.payload with a non null value here. That means,
                        // that this callback and the deleteLocalRemoteJob are responsible for
                        // keeping the state up to date when a remote job gets deleted.
                        // deleteRemoteJobAsync.fulfilled does only get a null value
                        store.dispatch(deleteLicense(license))
                        break
                    default:
                        logger.debug('FIXME: unexpected action')
                }
            }
            subscribeToLicenseChangesViaDCRF(callback)
            state.subscribedToUpdates = true
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllLicensesAsync.pending, (state) => {
                state.lastRequestStatus = 'busy'
            })
            .addCase(fetchAllLicensesAsync.fulfilled, (state, action: PayloadAction<ILicense[]>) => {
                state.lastRequestStatus = 'success'
                state.licenses = action.payload
            })
            .addCase(fetchAllLicensesAsync.rejected, (state) => {
                state.lastRequestStatus = 'failed'
            })
            .addCase(createLicenseAsync.pending, (state) => {
                state.lastRequestStatus = 'busy'
            })
            .addCase(createLicenseAsync.fulfilled, (state, action: PayloadAction<ILicense>) => {
                state.lastRequestStatus = 'success'
                if (!state.licenses.some(license => license.id === action.payload.id)) {
                    state.licenses.push(action.payload)
                }
            })
            .addCase(createLicenseAsync.rejected, (state) => {
                state.lastRequestStatus = 'failed'
            })
            .addCase(updateLicenseAsync.pending, (state) => {
                state.lastRequestStatus = 'busy'
            })
            .addCase(updateLicenseAsync.fulfilled, (state, action: PayloadAction<ILicense>) => {
                state.lastRequestStatus = 'success'
                state.licenses = getUpdatedLicenses(state.licenses, action.payload)
            })
            .addCase(updateLicenseAsync.rejected, (state) => {
                state.lastRequestStatus = 'failed'
            })
            .addCase(deleteLicenseAsync.pending, (state) => {
                state.lastRequestStatus = 'busy'
            })
            .addCase(deleteLicenseAsync.fulfilled, (state, action: PayloadAction<ILicense>) => {
                state.lastRequestStatus = 'success'
                // NOTE: action.payload.id is not available here (null value; update, fetch and create get a non null value) 
                // so just leave the state as it is (the subscription will trigger the deletion).
                // This means we are dependent on a functioning subscription callback
            })
            .addCase(deleteLicenseAsync.rejected, (state) => {
                state.lastRequestStatus = 'failed'
            })
    }
})

export const {
    addLicense,
    updateLicense,
    deleteLicense,
    subscribeToLicenseChanges
} = licensesSlice.actions

export const selectLicense = (id: number) => (state: RootState) => {
    return state.licenses.licenses.filter((license: { id: number }) => license.id === id).pop()
}
export const selectLicenses = (state: RootState) => state.licenses.licenses
export const selectValidLicenses = (state: RootState) => {
    return state.licenses.licenses.map((license) => {
        return new LicenseWrapper(license)
    }).filter((license) => {
        return license.isValid()
    })
}
export default licensesSlice.reducer
