import { dcrfClient } from "../../app/api";
import { logger } from "../../app/logging";
import {
    IRemoteRunner,
    ICreateRemoteRunner
} from "./RemoteRunnerSlice";

const wsStream = 'remoterunner'

export const fetchAllRemoteRunnerViaDCRF = () => {
    logger.debug('fetchAllRemoteRunnerViaDCRF')
    return dcrfClient.list(wsStream)
}

export const patchRemoteRunnerViaDCRF = (id: number, data: any) => {
    logger.debug(`patchRemoteRunnerViaDCRF with id: ${id} - data: ${data}`)
    return dcrfClient.patch(wsStream, id, data)
}

export const createRemoteRunnerViaDCRF = (newRemoteRunner: ICreateRemoteRunner) => {
    logger.debug(`createRemoteRunnerViaDCRF ${newRemoteRunner.name}`)
    return dcrfClient.create(wsStream, newRemoteRunner)
}

export const deleteRemoteRunnerViaDCRF = (id: number) => {
    logger.debug(`deleteRemoteRunnerViaDCRF with id: ${id}`)
    return dcrfClient.delete(wsStream, id)
}

export const subscribeToRemoteRunnerChangesViaDCRF = (callback: (scanRun: IRemoteRunner, action: string) => void) => {
    logger.debug("subscribeToRemoteRunnerChangesViaDCRF")
    const subscription = dcrfClient.subscribe(wsStream,
        {},
        (scanner, action) => {
            callback(scanner as IRemoteRunner, action)
        },
        {
            includeCreateEvents: true,
            requestId: 'subscribeToRemoteRunnerChangesViaDCRF',
            subscribeAction: 'subscribe_to_all_changes',
            unsubscribeAction: 'unsubscribe_from_all_changes',
        }
    )
    // a subscription can be canceled by calling "cancel" on the returned subscription object
    return subscription
}