import { dcrfClient } from '../../app/api'
import { logger } from '../../app/logging';
import { ICreateLicense, ILicense } from "./LicenseSlice";

const wsStream = 'license'

export const fetchAllLicensesViaDCRF = () => {
    logger.debug('fetchAllLicensesViaDCRF')
    return dcrfClient.list(wsStream)
}

export const createLicenseViaDCRF = (newLicense: ICreateLicense) => {
    logger.debug(`createLicenseViaDCRF ${newLicense.license_text}`)
    return dcrfClient.create(wsStream, newLicense)
}

export const patchLicenseViaDCRF = (id: number, data: any) => {
    logger.debug(`updateLicenseViaDCRF with id ${id} - data: ${data}}`)
    return dcrfClient.patch(wsStream, id, data)
}

export const deleteLicenseViaDCRF = (id: number) => {
    logger.debug(`deleteLicenseViaDCRF ${id}`)
    return dcrfClient.delete(wsStream, id)
}

export const subscribeToLicenseChangesViaDCRF = (callback: (license: ILicense, action: string) => void) => {
    logger.debug("subscribeToLicenseChangesViaDCRF")
    const subscription = dcrfClient.subscribe(wsStream,
        {},
        (scanner, action) => {
            callback(scanner as ILicense, action)
        },
        {
            includeCreateEvents: true,
            requestId: 'subscribeToLicenseChangesViaDCRF',
            subscribeAction: 'subscribe_to_all_changes',
            unsubscribeAction: 'unsubscribe_from_all_changes',
        }
    )
    // a subscription can be canceled by calling "cancel" on the returned subscription object
    return subscription
}