import { dcrfClient } from "../../app/api";
import { logger } from "../../app/logging";
import {
    IRemoteJobArtifact
} from "./RemoteJobArtifactSlice";

const wsStream = 'remotejobartifact'

export const fetchAllRemoteJobArtifactsViaDCRF = () => {
    logger.debug('fetchAllRemoteJobArtifactsViaDCRF')
    return dcrfClient.list(wsStream)
}

export const subscribeToRemoteJobArtifactChangesViaDCRF = (callback: (scanRun: IRemoteJobArtifact, action: string) => void) => {
    logger.debug("subscribeToISOTPEndpointChangesViaDCRF")
    const subscription = dcrfClient.subscribe(wsStream,
        {},
        (scanner, action) => {
            callback(scanner as IRemoteJobArtifact, action)
        },
        {
            includeCreateEvents: true,
            requestId: 'subscribeToRemoteJobArtifactChangesViaDCRF',
            subscribeAction: 'subscribe_to_all_changes',
            unsubscribeAction: 'unsubscribe_from_all_changes',
        }
    )
    // a subscription can be canceled by calling "cancel" on the returned subscription object
    return subscription
}
