import React, { useEffect, useRef, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
    selectGlobalLiveDataLines,
    selectISOTPEndpointScanRunLiveDataLines,
    selectGenericRemoteJobLiveDataLines,
    selectUDSScanRunLiveDataLines
} from "./LiveDataSlice"
import { IUDSScanRun } from "../uds_scan_run/UDSScanRunsSlice"
import { IISOTPEndpointScanRun } from "../isotp_endpoint_scan_run/ISOTPEndpointScanRunsSlice"
import { FixedSizeList, ListChildComponentProps } from "react-window"
import AutoSizer from "react-virtualized-auto-sizer"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import Container from "@mui/material/Container"
import { IRemoteJob } from "../remote_jobs/RemoteJobSlice"
import { Box, Button, Card, Checkbox, Toolbar } from "@mui/material"
import { addOrActivateRawLiveData } from "../main_lumino_widget/MainLuminoWidgetSlice"
import { useTranslation } from "react-i18next"

export const LiveData = (props: { lines: string[], rawLiveDataWidgetTitle?: string }) => {

    const fixedSizeListRef = useRef<FixedSizeList>(null)
    const [availableLogLevels, setAvailableLogLevels] = useState<string[]>([])
    const [enabledLogLevels, setEnabledLogLevels] = useState<string[]>([])
    const [shownLines, setShownLines] = useState<string[]>([])

    const [latestSeenLogline, setLatestSeenLogline] = useState('')

    const { t } = useTranslation()

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (fixedSizeListRef.current === null) {
            return
        }
        const lastLogline = shownLines.at(-1) ?? ''
        if (latestSeenLogline !== lastLogline) {
            fixedSizeListRef.current.scrollToItem(shownLines.length - 1)
            setLatestSeenLogline(lastLogline)
        }
    }, [shownLines, latestSeenLogline])

    useEffect(() => {
        setShownLines(props.lines.filter(line => {
            const logLevel = line.split(" - ")[0]
            setAvailableLogLevels(availableLevels => availableLevels.indexOf(logLevel) === -1 ? availableLevels.concat(logLevel) : availableLevels)
            return !enabledLogLevels.includes(logLevel)
        }))
    }, [props.lines, enabledLogLevels])

    const renderRow = (rowProps: ListChildComponentProps) => {
        const { index, style } = rowProps
        const [logLevel, message] = shownLines[index].split(" - ")
        const isError = logLevel.includes("ERROR")

        return (
            <ListItem dense={true} style={style} key={index} id={`list-item-row-${index}`} component="div" disablePadding>
                {isError ?
                    <ListItemText sx={{ color: "red", whiteSpace: "nowrap" }} primary={message} />
                    :
                    <ListItemText sx={{ whiteSpace: "nowrap" }} primary={message} />
                }
            </ListItem>
        )
    }

    const handleChangeCheckBox = (logLevel: string, checked: boolean) => {
        if (checked) {
            setEnabledLogLevels((enabledLogLevels) => enabledLogLevels.filter(t => t !== logLevel))
        } else {
            setEnabledLogLevels((enabledLogLevels) => enabledLogLevels.concat(logLevel))
        }
    }

    const viewRawLiveData = () => {
        dispatch(addOrActivateRawLiveData(props.lines, props.rawLiveDataWidgetTitle!))
    }

    return (
        <Box sx={{ width: "100%" }}>
            <Toolbar
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                    height: "10%",
                    justifyContent: "end"
                }}
            >
                <>
                    {availableLogLevels.sort().map((logLevel) => {
                        return (
                            <React.Fragment key={`log-level-${logLevel}`}>
                                <Checkbox
                                    checked={enabledLogLevels.includes(logLevel) === false}
                                    onChange={(e) => handleChangeCheckBox(logLevel, e.target.checked)}
                                />
                                <div>
                                    {logLevel}
                                </div>
                            </React.Fragment>
                        )
                    })}
                </>
                {props.rawLiveDataWidgetTitle &&
                    <Button
                        variant="contained"
                        onClick={viewRawLiveData}
                        sx={{ "marginLeft": 1 }}
                    >
                        {t("View Raw Log Data")}
                    </Button>
                }
            </Toolbar>
            <Card
                sx={{ width: '100%', height: '300px', border: 0.5, borderRadius: 1, resize: 'vertical' }}
            >
                <AutoSizer>
                    {({ height, width }) => (
                        <FixedSizeList
                            height={height}
                            width={width}
                            itemSize={20}
                            itemCount={shownLines.length}
                            ref={fixedSizeListRef}
                        >
                            {renderRow}
                        </FixedSizeList>
                    )}
                </AutoSizer>
            </Card>
        </Box>
    )
}

export const GlobalLiveData = () => {
    const lines = useAppSelector(selectGlobalLiveDataLines).map(line => {
        return line.split(" - ").splice(1).join(" - ")
    })

    return (
        <Container sx={{}}>
            <LiveData lines={lines} />
        </Container>
    )
}

export const UDSScanRunLiveData = (props: { scanRun: IUDSScanRun }) => {
    const lines = useAppSelector(selectUDSScanRunLiveDataLines(props.scanRun.id)).map(line => {
        return line.split(" - ").splice(1).join(" - ")
    })
    const rawLiveDataWidgetTitle = `UDS Scan Run ${props.scanRun.id}`
    
    return (
        <LiveData lines={lines} rawLiveDataWidgetTitle={rawLiveDataWidgetTitle}/>
    )
}

export const ISOTPEndpointScanRunLiveData = (props: { scanRun: IISOTPEndpointScanRun }) => {
    const lines = useAppSelector(selectISOTPEndpointScanRunLiveDataLines(props.scanRun.id)).map(line => {
        return line.split(" - ").splice(1).join(" - ")
    })
    const rawLiveDataWidgetTitle = `ISOTP Scan Run ${props.scanRun.id}`
    
    return (
        <LiveData lines={lines} rawLiveDataWidgetTitle={rawLiveDataWidgetTitle}/>
    )
}

export const RemoteJobLiveData = (props: { remoteJob: IRemoteJob }) => {
    const lines = useAppSelector(selectGenericRemoteJobLiveDataLines(props.remoteJob.id)).map(line => {
        return line.split(" - ").splice(1).join(" - ")
    })
    const rawLiveDataWidgetTitle = `Remote Job ${props.remoteJob.id}`

    return (
        <LiveData lines={lines} rawLiveDataWidgetTitle={rawLiveDataWidgetTitle}/>
    )
}

export const RawLiveData = (props: { lines: string[] }) => {
    return (
        <Container sx={{ overflow: "hidden", overflowY: "auto", marginTop: 0 }}>
            {props.lines.map(line => {
                return <div>{line.split(" - ").splice(1)}</div>
            })}
        </Container>
    )
}
