import { dcrfClient } from "../../app/api";
import { logger } from "../../app/logging";
import { ICreateUser, IUser } from "./UserSlice";

const wsStream = 'users'

export const fetchAllUsersViaDCRF = () => {
    logger.debug('fetchAllUsersViaDCRF')
    return dcrfClient.list(wsStream)
}

export const patchUserViaDCRF = (id: number, data: any) => {
    logger.debug(`patchUserViaDCRF with id: ${id} - data: ${data}`)
    return dcrfClient.patch(wsStream, id, data)
}

export const createUserViaDCRF = (newUser: ICreateUser) => {
    logger.debug(`createUserViaDCRF ${newUser}`)
    return dcrfClient.create(wsStream, newUser)
}

export const deleteUserViaDCRF = (id: number) => {
    logger.debug(`deleteUserViaDCRF with id: ${id}`)
    return dcrfClient.delete(wsStream, id)
}

export const subscribeToUserChangesViaDCRF = (callback: (User: IUser, action: string) => void) => {
    logger.debug("subscribeToUserChangesViaDCRF")
    const subscription = dcrfClient.subscribe(wsStream,
        {},
        (User, action) => {
            callback(User as IUser, action)
        },
        {
            includeCreateEvents: true,
            requestId: 'subscribeToUserChangesViaDCRF',
            subscribeAction: 'subscribe_to_all_changes',
            unsubscribeAction: 'unsubscribe_from_all_changes',
        }
    )
    // a subscription can be canceled by calling "cancel" on the returned subscription object
    return subscription
}
