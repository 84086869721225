import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, store } from '../../app/store';
import {
    fetchAllISOTPEndpointScanRunsViaDCRF,
    subscribeToISOTPEndpointScanRunChangesViaDCRF,
    patchISOTPEndpointScanRunViaDCRF,
    createISOTPEndpointScanRunViaDCRF,
    deleteISOTPEndpointScanRunViaDCRF,
} from './ISOTPEndpointScanRunsAPI';
import {
    showUserMessage,
} from '../user_message/UserMessageSlice';
import {IScanRunFindingLogFile} from '../uds_scan_run/UDSScanRunsSlice';
import { logger } from '../../app/logging';

export type ISOTPEndpointScanRunState = 'CREATED' | 'RUNNING' | 'FINISHED_SUCCESS' | 'FINISHED_ERROR'
export type ISOTPEndpointScanRunDesiredState = 'RUNNING' | 'PAUSED' | 'FINISHED'


export interface IISOTPEndpointScanRunFinding {
    id: number
    rx_id: number
    tx_id: number
    ext_address?: number
    rx_ext_address?: number
    padding: boolean
    created_at: string
    basecls: string
}

// FIXME: the config should be part of the top level object (no benefit to have a separate object for a 1-1 relation ... just adds unnecessary complexity)
export interface IISOTPEndpointScanRunConfig {
    name: string
    scan_range?: string
    extended_addressing?: boolean
    extended_scan_range?: string
    noise_listen_time?: number
    sniff_time?: number
    extended_can_id?: boolean
    verify_results?: boolean
    remote_scan_selected_channel?: string
}

export interface IISOTPEndpointScanRun {
    id: number
    hw_interface?: string
    remote_runner?: number
    remote_job?: number
    scan_run_findings: IISOTPEndpointScanRunFinding[]
    log_files: IScanRunFindingLogFile[]
    state: ISOTPEndpointScanRunState
    desired_state: ISOTPEndpointScanRunDesiredState
    error_description: string
    created_at: string
    finished_at: string
    // FIXME: the config should be part of the top level object (no benefit to have a separate object for a 1-1 relation ... just adds unnecessary complexity)
    config: IISOTPEndpointScanRunConfig
    scan_was_aborted: boolean
}

type LastRequestStatus = 'success' | 'busy' | 'failed'

export interface IISOTPEndpointScanRuns {
    scanRuns: IISOTPEndpointScanRun[]
    lastRequestStatus: LastRequestStatus
    subscribedToUpdates: boolean
}

export const fetchAllISOTPEndpointScanRunsAsync = createAsyncThunk(
    'isotpEndpointScanRun/fetchAllISOTPEndpointScanRunsAsync',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            return await fetchAllISOTPEndpointScanRunsViaDCRF()
        } catch (rejectedValue) {
            // @ts-ignore
            const error_message = JSON.stringify(rejectedValue.errors)
            dispatch(showUserMessage({ title: 'fetchAllISOTPEndpointScanRunsAsync failed', message: error_message }))
            return rejectWithValue(rejectedValue)
        }
    }
)

export interface IUpdateISOTPEndpointScanRun {
   id: number
   data: {
        hw_interface?: string | null
        remote_runner?: number | null
        state?: ISOTPEndpointScanRunState
        desired_state?: ISOTPEndpointScanRunDesiredState
        error_description?: string
        finished_at?: string | null
        // FIXME: the config should be part of the top level object (no benefit to have a separate object for a 1-1 relation ... just adds unnecessary complexity)
        config?: IISOTPEndpointScanRunConfig
   }
}

export const updateISOTPEndpointScanRunAsync = createAsyncThunk(
    'isotpEndpointScanRun/updateISOTPEndpointScanRunAsync',
    async (updatedScanRun: IUpdateISOTPEndpointScanRun, { dispatch, rejectWithValue }) => {
        try {
            return await patchISOTPEndpointScanRunViaDCRF(updatedScanRun.id, updatedScanRun.data)
        } catch (rejectedValue) {
            // @ts-ignore
            const error_message = JSON.stringify(rejectedValue.errors)
            dispatch(showUserMessage({ title: 'updateISOTPEndpointScanRunAsync failed', message: error_message }))
            return rejectWithValue(rejectedValue)
        }
    }
)

export interface ICreateISOTPEndpointScanRun {
    hw_interface?: string
    remote_runner?: number
    // FIXME: the config should be part of the top level object (no benefit to have a separate object for a 1-1 relation ... just adds unnecessary complexity)
    config: IISOTPEndpointScanRunConfig
}

export const createISOTPEndpointScanRunAsync = createAsyncThunk(
    'isotpEndpointScanRun/createISOTPEndpointScanRunAsync',
    async (newScanRun: ICreateISOTPEndpointScanRun, { dispatch, rejectWithValue }) => {
        try {
            return await createISOTPEndpointScanRunViaDCRF(newScanRun)
        } catch (rejectedValue) {
            // @ts-ignore
            const error_message = JSON.stringify(rejectedValue.errors)
            dispatch(showUserMessage({ title: 'createISOTPEndpointScanRunAsync failed', message: error_message }))
            return rejectWithValue(rejectedValue)
        }
    }
)

export const deleteISOTPEndpointScanRunAsync = createAsyncThunk(
    'isotpEndpointScanRun/deleteISOTPEndpointScanRunAsync',
    async (id: number, { dispatch, rejectWithValue }) => {
        try {
            return await deleteISOTPEndpointScanRunViaDCRF(id)
        } catch (rejectedValue) {
            // @ts-ignore
            const error_message = JSON.stringify(rejectedValue.errors)
            dispatch(showUserMessage({ title: 'deleteISOTPEndpointScanRunAsync failed', message: error_message }))
            return rejectWithValue(rejectedValue)
        }
    }
)

const getUpdatedISOTPEndpointScanRuns = (currentScanRuns: IISOTPEndpointScanRun[], updatedScanRun: IISOTPEndpointScanRun) => {
    return currentScanRuns.map((existingScanRun: IISOTPEndpointScanRun) => {
        if (existingScanRun.id === updatedScanRun.id) {
            return updatedScanRun
        } else {
            return existingScanRun
        }
    })
}

const initialState: IISOTPEndpointScanRuns = {
    scanRuns: [],
    subscribedToUpdates: false,
    lastRequestStatus: 'success',
}

export const isotpEndpointScanRunsSlice = createSlice({
    name: 'isotpEndpointScanRuns',
    initialState,
    reducers: {
        addLocalISOTPEndpointScanRun: (state: { scanRuns: IISOTPEndpointScanRun[] }, action: PayloadAction<IISOTPEndpointScanRun>) => {
            if (!state.scanRuns.some((scanRun: { id: number }) => scanRun.id === action.payload.id)) {
                state.scanRuns.push(action.payload)
            }
        },
        deleteLocalISOTPEndpointScanRun: (state: { scanRuns: any[] }, action: PayloadAction<IISOTPEndpointScanRun>) => {
            state.scanRuns = state.scanRuns.filter( (scanRun: { id: number }) => scanRun.id !== action.payload.id )
        },
        updateLocalISOTPEndpointScanRun: (state: { scanRuns: IISOTPEndpointScanRun[] }, action: PayloadAction<IISOTPEndpointScanRun>) => {
            state.scanRuns = getUpdatedISOTPEndpointScanRuns(state.scanRuns, action.payload)
        },
        subscribeToISOTPEndpointScanRunChanges: (state: { subscribedToUpdates: boolean }) => {
            if (state.subscribedToUpdates) {
                return
            }
            const callback = (scanRun: IISOTPEndpointScanRun | undefined, action: string) => {
                logger.debug(`isotp endpoint scan run subscription callback (action: ${action})`)
                switch(action) {
                    case 'create':
                        store.dispatch(addLocalISOTPEndpointScanRun(scanRun!))
                        break
                    case 'update':
                        store.dispatch(updateLocalISOTPEndpointScanRun(scanRun!))
                        break
                    case 'delete':
                        // NOTE: We get the action.payload with a non null value here. That means,
                        // that this callback and the deleteLocalRemoteJob are responsible for
                        // keeping the state up to date when a remote job gets deleted.
                        // deleteRemoteJobAsync.fulfilled does only get a null value
                        store.dispatch(deleteLocalISOTPEndpointScanRun(scanRun!))
                        break
                    case 'force-refetch':
                        store.dispatch(fetchAllISOTPEndpointScanRunsAsync())
                        break
                    default:
                        // TODO: handle all missing actions
                        logger.debug('FIXME')
                }
            }
            subscribeToISOTPEndpointScanRunChangesViaDCRF(callback)
            state.subscribedToUpdates = true
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllISOTPEndpointScanRunsAsync.pending, (state) => {
                state.lastRequestStatus = 'busy'
            })
            .addCase(fetchAllISOTPEndpointScanRunsAsync.fulfilled, (state, action: PayloadAction<IISOTPEndpointScanRun[]>) => {
                state.lastRequestStatus = 'success'
                state.scanRuns = action.payload
            })
            .addCase(fetchAllISOTPEndpointScanRunsAsync.rejected, (state, action) => {
                // @ts-ignore
                const error_message = JSON.stringify(action.payload.errors)
                logger.debug(`failed to fetch scan runs - ${error_message}`)
                state.lastRequestStatus = 'failed'
            })
            .addCase(createISOTPEndpointScanRunAsync.pending, (state) => {
                state.lastRequestStatus = 'busy'
            })
            .addCase(createISOTPEndpointScanRunAsync.fulfilled, (state, action: PayloadAction<IISOTPEndpointScanRun>) => {
                state.lastRequestStatus = 'success'
                if (!state.scanRuns.some(scanRun => scanRun.id === action.payload.id)) {
                    state.scanRuns.push(action.payload)
                }
            })
            .addCase(createISOTPEndpointScanRunAsync.rejected, (state, action) => {
                // @ts-ignore
                const error_message = JSON.stringify(action.payload.errors)
                logger.debug(`failed to create scan run - ${error_message}`)
                state.lastRequestStatus = 'failed'
            })
            .addCase(updateISOTPEndpointScanRunAsync.pending, (state) => {
                state.lastRequestStatus = 'busy'
            })
            .addCase(updateISOTPEndpointScanRunAsync.fulfilled, (state, action: PayloadAction<IISOTPEndpointScanRun>) => {
                state.lastRequestStatus = 'success'
                state.scanRuns = getUpdatedISOTPEndpointScanRuns(state.scanRuns, action.payload)
            })
            .addCase(updateISOTPEndpointScanRunAsync.rejected, (state, action) => {
                // @ts-ignore
                const error_message = JSON.stringify(action.payload.errors)
                logger.debug(`failed to update scan run - ${error_message}`)
                state.lastRequestStatus = 'failed'
            })
            .addCase(deleteISOTPEndpointScanRunAsync.pending, (state) => {
                state.lastRequestStatus = 'busy'
            })
            .addCase(deleteISOTPEndpointScanRunAsync.fulfilled, (state, action: PayloadAction<IISOTPEndpointScanRun>) => {
                state.lastRequestStatus = 'success'
                // NOTE: action.payload.id is not available here (null value; update, fetch and create get a non null value) 
                // so just leave the state as it is (the subscription will trigger the deletion).
                // This means we are dependent on a functioning subscription callback
            })
            .addCase(deleteISOTPEndpointScanRunAsync.rejected, (state) => {
                state.lastRequestStatus = 'failed'
            })
    },
})

export const { addLocalISOTPEndpointScanRun,
               deleteLocalISOTPEndpointScanRun,
               updateLocalISOTPEndpointScanRun,
               subscribeToISOTPEndpointScanRunChanges } = isotpEndpointScanRunsSlice.actions

export const selectISOTPEndpointScanRuns = (state: RootState) => state.isotpEndpointScanRuns.scanRuns
export const selectLastISOTPEndpointScanRunRequestStatus = (state: RootState) => state.isotpEndpointScanRuns.lastRequestStatus
export const selectISOTPEndpointScanRunIsSubscribed = (state: RootState) => state.isotpEndpointScanRuns.subscribedToUpdates

export default isotpEndpointScanRunsSlice.reducer
