import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useTranslation } from 'react-i18next'
import { Button, Checkbox, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from "@mui/material";
import { useState } from "react";
import { createUserAsync, deleteUserAsync, ICreateUser, IUpdateUser, IUser, selectCurrentUser, selectUsers, updateUserAsync } from "./UserSlice";
import { ButtonWithDialog, ConditionalFragment, compactMap, utcToLocalDateTimeRepresentation } from "../misc/Util";
import { IGroup, selectGroups } from "../group_management/GroupSlice";


export const UserManagementGroupEdit = (props: {user: IUser}) => {
    const [selectedGroups, setSelectedGroups] = useState<number[]>(props.user.groups ?? [])
    const groups = useAppSelector(selectGroups)

    const { t } = useTranslation()

    const dispatch = useAppDispatch()


    const getSelectedUsers = (userIds: number[]) => {
        return compactMap<number, IGroup>(userIds, ((userId) => {
            return groups.filter(group => {
                return group.id === userId ? true : false
            }).pop()
        }))
    }

    const getSelectedUsersAsStrings = (groupIds: number[]) => {
        return getSelectedUsers(groupIds).map(group => {
            return group.name
        })
    }

    const isUserSelected = (userId: number, selectedUsers: number[]) => {
        return selectedUsers.indexOf(userId) === -1 ? false : true
    }

    const getSelectedUsersAsIds = (userIds: number[]) => {
        return getSelectedUsers(userIds).map(user => {
            return user.id
        })
    }

    const handleChangeForSelectedUsersGroupChange = (event: SelectChangeEvent<typeof selectedGroups>) => {
        const {
            target: { value },
        } = event
        setSelectedGroups(
            // On autofill we get a stringified value.
            typeof value === 'string' ? getSelectedUsersAsIds(value.split(',').map(value => parseInt(value))) : value,
        )
    }

    const handleChangeGroupUsers = () => {
        const updatedUser: IUpdateUser = {
            id: props.user.id,
            data: {
                groups: selectedGroups
            }
        }
        dispatch(updateUserAsync(updatedUser))
    }

    const hasAllNeededDataGroupUsersChange = () => {
        return selectedGroups.length > 0
    }

    return (
        <Stack direction="column">
            <FormControl sx={{ marginTop: -1, marginRight: 0, width: 200 }}>
                <InputLabel id={`user-management-group-users-select-label-${props.user.id}`}>
                    {t("Groups")}
                </InputLabel>
                <Select
                    labelId={`user-management-group-users-select-label-${props.user.id}`}
                    id={`user-management-group-users-select-${props.user.id}`}
                    multiple
                    label={t("Groups")}
                    value={selectedGroups}
                    onChange={handleChangeForSelectedUsersGroupChange}
                    renderValue={(selected) => getSelectedUsersAsStrings(selected).join(', ')}
                >
                    {groups.map((user) => (
                        <MenuItem
                            key={`${user.name}-${props.user.id}`}
                            value={user.id}
                        >
                            {isUserSelected(user.id, selectedGroups) ?
                                <b>{user.name}</b>
                                :
                                user.name
                            }
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Button
                id={`user-management-group-users-change-button-${props.user.id}`}
                sx={{ marginTop: 1 }}
                color="primary"
                variant="contained"
                disabled={!hasAllNeededDataGroupUsersChange()}
                onClick={() => handleChangeGroupUsers()}
            >
                {t("Change Groups")}
            </Button>
        </Stack>
    )
}


export const UserManagement = (props: any) => {
    const currentUser = useAppSelector(selectCurrentUser)
    const users = useAppSelector(selectUsers)
    const groups = useAppSelector(selectGroups)

    const [addUserDialogOpen, setAddUserDialogOpen] = useState(false)
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [passwordConfirmation, setPasswordConfirmation] = useState("")
    const [newUserAdminRights, setNewUserAdminRights] = useState<boolean>(false)

    const [userDataChangeDialogOpen, setPasswordChangeDialogOpen] = useState(false)
    const [authenticatedUserPassword, setAuthenticatedUserPassword] = useState("")
    const [newName, setNewName] = useState("")
    const [newEMail, setNewEMail] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("")
    const [userIdPasswordChange, setUserIdPasswordChange] = useState<number | undefined>()
    const [selectedGroups, setSelectedGroups] = useState<number[]>([])

    const { t } = useTranslation()

    const dispatch = useAppDispatch()

    const getSelectedGroups = (groupIds: number[]) => {
        return compactMap<number, IGroup>(groupIds, ((groupId) => {
            return groups.filter(group => {
                return group.id === groupId ? true : false
            }).pop()
        }))
    }
    
    const getSelectedGroupsAsStrings = (groupIds: number[]) => {
        return getSelectedGroups(groupIds).map(group => {
            return group.name
        })
    }
    
    const getSelectedGroupsAsIds = (groupIds: number[]) => {
        return getSelectedGroups(groupIds).map(group => {
            return group.id
        })
    }

    const handleChange = (event: SelectChangeEvent<typeof selectedGroups>) => {
        const {
          target: { value },
        } = event
        setSelectedGroups(
            // On autofill we get a stringified value.
            typeof value === 'string' ? getSelectedGroupsAsIds(value.split(',').map(value => parseInt(value))) : value,
        )
    }

    const isGroupSelected = (groupId: number, selectedGroups: number[]) => {
        return selectedGroups.indexOf(groupId) === -1 ? false : true
    }

    const deleteUser = (user: IUser) => {
        dispatch(deleteUserAsync(user.id))
    }

    const changeUserData = () => {
        const updatedUser: IUpdateUser = {
            id: userIdPasswordChange!,
            data: {
                name: newName.length > 0 ? newName : undefined,
                email: newEMail.length > 0 ? newEMail : undefined,
                password: newPassword.length > 0 ? newPassword : undefined,
                authenticated_user_password: authenticatedUserPassword
            }
        }
        dispatch(updateUserAsync(updatedUser))
        handleUserDataChangeClose()
    }

    const handlePasswordChangeOpen = (user: IUser) => {
        setUserIdPasswordChange(user.id)
        setNewName(user.name)
        setNewEMail(user.email)
        setPasswordChangeDialogOpen(true)
    }

    const handleUserDataChangeClose = () => {
        setPasswordChangeDialogOpen(false)
        setAuthenticatedUserPassword("")
        setNewName("")
        setNewEMail("")
        setNewPassword("")
        setNewPasswordConfirmation("")
        setUserIdPasswordChange(undefined)
    }

    const handleAddUserOpen = () => {
        setAddUserDialogOpen(true)
    }

    const handleAddUserClose = () => {
        setAddUserDialogOpen(false)
        setName("")
        setPassword("")
        setEmail("")
        setPasswordConfirmation("")
        setNewUserAdminRights(false)
        setSelectedGroups([])
    }

    const addUser = () => {
        const newUser: ICreateUser = {
            email: email,
            name: name,
            company: currentUser!.company,
            password: password,
            is_staff: newUserAdminRights,
            groups: selectedGroups
        }
        dispatch(createUserAsync(newUser))
        handleAddUserClose()
    }

    const hasAllNeededDataAddUser = () => {
        return currentUser &&
            password !== "" &&
            passwordConfirmation !== "" &&
            password === passwordConfirmation &&
            name !== "" &&
            email !== "" &&
            selectGroups.length !== 0
    }

    const hasAllNeededDataForUserDataChange = () => {
        return userIdPasswordChange &&
               newName !== "" &&
               newEMail !== "" &&
               authenticatedUserPassword !== "" &&
               newPassword === newPasswordConfirmation
               // newPassword !== "" &&
               // newPasswordConfirmation !== "" &&
    }

    const getRelatedUsersAsRows = (users: IUser[]) => {
        const rows: any = []

        users.forEach((companyUser: IUser) => {
            rows.push(
                <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    key={companyUser.email}
                >
                    <TableCell component="th" scope="row">
                        {companyUser.name}
                    </TableCell>
                    <TableCell align="right">
                        {companyUser.email}
                    </TableCell>
                    <TableCell align="right">
                        {utcToLocalDateTimeRepresentation(companyUser.last_login)}
                    </TableCell>
                    <TableCell align="right">
                        <UserManagementGroupEdit user={companyUser}/>
                    </TableCell>
                    <TableCell align="right">
                        {utcToLocalDateTimeRepresentation(companyUser.creation_date)}
                    </TableCell>
                    <TableCell align="right">
                        <p>
                            <ConditionalFragment condition={!companyUser.is_staff || companyUser.id === currentUser?.id}>
                                <Button
                                    id="change-user-password-button"
                                    sx={{ marginRight: 1 }}
                                    color="primary"
                                    variant="contained"
                                    onClick={() => handlePasswordChangeOpen(companyUser)}
                                >
                                    {t("Change user data")}
                                </Button>
                            </ConditionalFragment>
                            <ConditionalFragment condition={!companyUser.is_staff}>
                                <ButtonWithDialog
                                    title={t("Do you really want to delete this user?")}
                                    message={t("Deleting a user is permanent and can not be undone. Of course you can always create a new one. Are you sure?")}
                                    buttonColor="error"
                                    proceedButtonColor="error"
                                    cancelButtonColor="primary"
                                    proceedCallback={() => deleteUser(companyUser)}
                                >
                                    {t("Delete")}
                                </ButtonWithDialog>
                            </ConditionalFragment>
                        </p>
                    </TableCell>
                </TableRow>
            )
        })
        return rows
    }

    const getRelatedUsersAsTable = (users: IUser[]) => {
        return (
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 300 }} aria-label="User Management Table">
                    <TableHead>
                        <TableRow>
                            <TableCell><b>{t("Name")}</b></TableCell>
                            <TableCell align="center"><b>{t("EMail")}</b></TableCell>
                            <TableCell align="center"><b>{t("Last login")}</b></TableCell>
                            <TableCell align="center"><b>{t("Groups")}</b></TableCell>
                            <TableCell align="center"><b>{t("Created at")}</b></TableCell>
                            <TableCell align="center">
                                <Button
                                    sx={{ margin: 1 }}
                                    variant="contained"
                                    onClick={handleAddUserOpen}
                                >
                                    {t("Add User")}
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getRelatedUsersAsRows(users)}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    return (
        <Container sx={{ marginLeft: -2, overflow: "hidden", overflowY: "auto", height: "90vh" }}>
            {getRelatedUsersAsTable(users)}
            <Dialog open={addUserDialogOpen} onClose={handleAddUserClose}>
                <DialogTitle>{t("Add User")}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="add-user-name"
                        label={t("Name")}
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        fullWidth
                    />
                    <TextField
                        margin="dense"
                        id="add-user-email"
                        label={t("EMail")}
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        fullWidth
                    />
                    <TextField
                        margin="dense"
                        id="add-user-password"
                        label={t("Password")}
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        fullWidth
                    />
                    <TextField
                        margin="dense"
                        id="name"
                        label={t("Password confirmation")}
                        type="password"
                        value={passwordConfirmation}
                        onChange={(e) => setPasswordConfirmation(e.target.value)}
                        fullWidth
                    />

                    <FormControl sx={{ marginTop: 1, marginRight: 1, width: 300 }}>
                        <InputLabel id="user-management-groups-select-label">
                            {t("Groups")}
                        </InputLabel>
                        <Select
                            labelId="user-management-groups-select-label"
                            id="user-management-groups-select"
                            multiple
                            label={t("Groups")}
                            value={selectedGroups}
                            onChange={handleChange}
                            renderValue={(selected) => getSelectedGroupsAsStrings(selected).join(', ')}
                        >
                            {groups.map((group) => (
                                <MenuItem
                                    key={group.name}
                                    value={group.id}
                                >
                                    {isGroupSelected(group.id, selectedGroups) ?
                                        <b>{group.name}</b>
                                        :
                                        group.name
                                    }
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControlLabel
                        sx={{ margin: 1, minWidth: 200, marginLeft: -1 }}
                        control={
                            <Checkbox
                                id="new-user-admin-rights"
                                checked={newUserAdminRights}
                                onChange={(event) => setNewUserAdminRights(event.target.checked)}
                            />
                        }
                        label={t("Admin rights")}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAddUserClose}>{t("Cancel")}</Button>
                    <Button onClick={addUser} disabled={!hasAllNeededDataAddUser()}>{t("Add User")}</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={userDataChangeDialogOpen} onClose={handleUserDataChangeClose}>
                <DialogTitle>{t("Change user data")}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="user-old-password"
                        label={t("Current staff user password")}
                        type="password"
                        value={authenticatedUserPassword}
                        onChange={(e) => setAuthenticatedUserPassword(e.target.value)}
                        fullWidth
                    />
                    <TextField
                        margin="dense"
                        id="user-new-name"
                        label={t("New User Name")}
                        type="text"
                        value={newName}
                        onChange={(e) => setNewName(e.target.value)}
                        fullWidth
                    />
                    <TextField
                        margin="dense"
                        id="user-new-email"
                        label={t("New User EMail address")}
                        type="text"
                        value={newEMail}
                        onChange={(e) => setNewEMail(e.target.value)}
                        fullWidth
                    />
                    <Tooltip title={t("Leave this field empty if you dont't want to change the password.")}>
                        <TextField
                            margin="dense"
                            id="user-new-password"
                            label={t("New User Password")}
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            fullWidth
                        />
                    </Tooltip>
                    <TextField
                        margin="dense"
                        id="name"
                        label={t("Password confirmation")}
                        type="password"
                        value={newPasswordConfirmation}
                        onChange={(e) => setNewPasswordConfirmation(e.target.value)}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUserDataChangeClose}>{t("Cancel")}</Button>
                    <Button onClick={changeUserData} disabled={!hasAllNeededDataForUserDataChange()}>{t("Change")}</Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}