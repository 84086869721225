import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, store } from "../../app/store";
import {
    createRemoteJobViaDCRF, deleteRemoteJobViaDCRF,
    fetchAllRemoteJobsViaDCRF,
    patchRemoteJobViaDCRF,
    subscribeToRemoteJobChangesViaDCRF,
} from "./RemoteJobAPI";
import {
    showUserMessage,
} from '../user_message/UserMessageSlice';
import { logger } from "../../app/logging";


export type TRemoteJobType = "GENERIC" | "TERMINAL" | "SCANRUN" | "INTERNAL" | "TESTCASE"
export type TRemoteJobState = 'CREATED_WAITING' | 'CREATED' | 'WAITING_FOR_ARCHIVE_DOWNLOAD' | 'RUNNING' | 'WAITING_FOR_ARTIFACT_UPLOAD' | 'FINISHED_SUCCESS' | 'FINISHED_ERROR'
export type TRemoteJobDesiredState = 'RUNNING' | 'FINISHED'


export interface IRemoteJob {
    id: number,
    runner: number,
    job_template: number,
    job_context?: number,
    state: TRemoteJobState
    desired_state: TRemoteJobDesiredState,
    error_description: string,
    created_at: string,
    finished_at: string
    tag?: string
}

type LastRequestStatus = 'success' | 'busy' | 'failed'

export interface IRemoteJobs {
    remoteJobs: IRemoteJob[]
    lastRequestStatus: LastRequestStatus
    subscribedToUpdates: boolean
}

export const fetchAllRemoteJobsAsync = createAsyncThunk(
    'RemoteJob/fetchAllRemoteJobsAsync',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            return await fetchAllRemoteJobsViaDCRF()
        } catch (rejectedValue) {
            // @ts-ignore
            const error_message = JSON.stringify(rejectedValue.errors)
            dispatch(showUserMessage({ title: 'fetchAllRemoteJobsAsync failed', message: error_message }))
            return rejectWithValue(rejectedValue)
        }
    }
)

export interface IUpdateRemoteJob {
    id: number
    data: {
        state?: TRemoteJobState,
        desired_state: TRemoteJobDesiredState,
        job_context?: number,
        error_description?: string,
        finished_at?: string | null,
    }
}

export const updateRemoteJobAsync = createAsyncThunk(
    'RemoteJob/updateRemoteJobAsync',
    async (updatedEndpoint: IUpdateRemoteJob, { dispatch, rejectWithValue }) => {
        try {
            return await patchRemoteJobViaDCRF(updatedEndpoint.id, updatedEndpoint.data)
        } catch (rejectedValue) {
            // @ts-ignore
            const error_message = JSON.stringify(rejectedValue.errors)
            dispatch(showUserMessage({ title: 'updateRemoteJobAsync failed', message: error_message }))
            return rejectWithValue(rejectedValue)
        }
    }
)

export interface ICreateRemoteJob {
    state?: TRemoteJobState,
    runner: number,
    job_template: number,
    job_context?: number,
    tag?: string
}

export const createRemoteJobAsync = createAsyncThunk(
    'RemoteJob/createRemoteJobAsync',
    async (newRemoteJob: ICreateRemoteJob, { dispatch, rejectWithValue }) => {
        try {
            return await createRemoteJobViaDCRF(newRemoteJob)
        } catch (rejectedValue) {
            // @ts-ignore
            const error_message = JSON.stringify(rejectedValue.errors)
            dispatch(showUserMessage({ title: 'createRemoteJobAsync failed', message: error_message }))
            return rejectWithValue(rejectedValue)
        }
    }
)

export const deleteRemoteJobAsync = createAsyncThunk(
    'RemoteJob/deleteRemoteJobAsync',
    async (id: number, { dispatch, rejectWithValue }) => {
        try {
            return await deleteRemoteJobViaDCRF(id)
        } catch (rejectedValue) {
            // @ts-ignore
            const error_message = JSON.stringify(rejectedValue.errors)
            dispatch(showUserMessage({ title: 'deleteRemoteJobAsync failed', message: error_message }))
            return rejectWithValue(rejectedValue)
        }
    }
)

const getUpdatedRemoteJobs = (currentRemoteJobs: IRemoteJob[], updatedRemoteJob: IRemoteJob) => {
    return currentRemoteJobs.map((existingRemoteJob: IRemoteJob) => {
        if (existingRemoteJob.id === updatedRemoteJob.id) {
            return updatedRemoteJob
        } else {
            return existingRemoteJob
        }
    })
}


const initialState: IRemoteJobs = {
    remoteJobs: [],
    subscribedToUpdates: false,
    lastRequestStatus: 'success',
}

export const remoteJobsSlice = createSlice({
    name: 'remoteJobs',
    initialState,
    reducers: {
        addLocalRemoteJob: (state, action: PayloadAction<IRemoteJob>) => {
            if (!state.remoteJobs.some(RemoteJob => RemoteJob.id === action.payload.id)) {
                state.remoteJobs.push(action.payload)
            }
        },
        updateLocalRemoteJob: (state, action: PayloadAction<IRemoteJob>) => {
            state.remoteJobs = getUpdatedRemoteJobs(state.remoteJobs, action.payload)
        },
        deleteLocalRemoteJob: (state, action: PayloadAction<IRemoteJob>) => {
            state.remoteJobs = state.remoteJobs.filter((endpoint) => endpoint.id !== action.payload.id)
        },
        subscribeToRemoteJobChanges: (state) => {
            if (state.subscribedToUpdates) {
                return
            }
            const callback = (RemoteJob: IRemoteJob, action: string) => {
                logger.debug(`remote job subscription callback (action: ${action})`)
                switch(action) {
                    case 'create':
                        store.dispatch(addLocalRemoteJob(RemoteJob))
                        break
                    case 'update':
                        store.dispatch(updateLocalRemoteJob(RemoteJob))
                        break
                    case 'delete':
                        // NOTE: We get the action.payload with a non null value here. That means,
                        // that this callback and the deleteLocalRemoteJob are responsible for
                        // keeping the state up to date when a remote job gets deleted.
                        // deleteRemoteJobAsync.fulfilled does only get a null value
                        store.dispatch(deleteLocalRemoteJob(RemoteJob))
                        break
                    default:
                        logger.debug('FIXME: unexpected action')
                }
            }
            subscribeToRemoteJobChangesViaDCRF(callback)
            state.subscribedToUpdates = true
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllRemoteJobsAsync.pending, (state) => {
                state.lastRequestStatus = 'busy'
            })
            .addCase(fetchAllRemoteJobsAsync.fulfilled, (state, action: PayloadAction<IRemoteJob[]>) => {
                state.lastRequestStatus = 'success'
                state.remoteJobs = action.payload
            })
            .addCase(fetchAllRemoteJobsAsync.rejected, (state) => {
                state.lastRequestStatus = 'failed'
            })
            .addCase(createRemoteJobAsync.pending, (state) => {
                state.lastRequestStatus = 'busy'
            })
            .addCase(createRemoteJobAsync.fulfilled, (state, action: PayloadAction<IRemoteJob>) => {
                state.lastRequestStatus = 'success'
                if (!state.remoteJobs.some(endpoint => endpoint.id === action.payload.id)) {
                    state.remoteJobs.push(action.payload)
                }
            })
            .addCase(createRemoteJobAsync.rejected, (state) => {
                state.lastRequestStatus = 'failed'
            })
            .addCase(updateRemoteJobAsync.pending, (state) => {
                state.lastRequestStatus = 'busy'
            })
            .addCase(updateRemoteJobAsync.fulfilled, (state, action: PayloadAction<IRemoteJob>) => {
                state.lastRequestStatus = 'success'
                state.remoteJobs = getUpdatedRemoteJobs(state.remoteJobs, action.payload)
            })
            .addCase(updateRemoteJobAsync.rejected, (state) => {
                state.lastRequestStatus = 'failed'
            })
            .addCase(deleteRemoteJobAsync.pending, (state) => {
                state.lastRequestStatus = 'busy'
            })
            .addCase(deleteRemoteJobAsync.fulfilled, (state, action: PayloadAction<IRemoteJob>) => {
                state.lastRequestStatus = 'success'
                // NOTE: action.payload.id is not available here (null value; update, fetch and create get a non null value) 
                // so just leave the state as it is (the subscription will trigger the deletion).
                // This means we are dependent on a functioning subscription callback
            })
            .addCase(deleteRemoteJobAsync.rejected, (state) => {
                state.lastRequestStatus = 'failed'
            })
    }
})

export const {
    addLocalRemoteJob,
    updateLocalRemoteJob,
    deleteLocalRemoteJob,
    subscribeToRemoteJobChanges } = remoteJobsSlice.actions

export const selectRemoteJobs = (state: RootState) => state.remoteJobs.remoteJobs
export const selectRemoteJob = (id: number) => (state: RootState) => {
    return state.remoteJobs.remoteJobs.filter( RemoteJob => RemoteJob.id === id).pop()
}

export const selectLastRemoteJobRequestStatus = (state: RootState) => state.remoteJobs.lastRequestStatus
export const selectRemoteJobIsSubscribed = (state: RootState) => state.remoteJobs.subscribedToUpdates

export default remoteJobsSlice.reducer
