import { dcrfClient } from "../../app/api";
import { logger } from "../../app/logging";
import {
    IRemoteJob,
    ICreateRemoteJob
} from "./RemoteJobSlice";

const wsStream = 'remotejob'

export const fetchAllRemoteJobsViaDCRF = () => {
    logger.debug('fetchAllRemoteJobsViaDCRF')
    return dcrfClient.list(wsStream)
}

export const patchRemoteJobViaDCRF = (id: number, data: any) => {
    logger.debug(`patchRemoteJobViaDCRF with id: ${id} - data: ${data}`)
    return dcrfClient.patch(wsStream, id, data)
}

export const createRemoteJobViaDCRF = (newRemoteJob: ICreateRemoteJob) => {
    logger.debug(`createRemoteJobViaDCRF ${newRemoteJob}`)
    return dcrfClient.create(wsStream, newRemoteJob)
}

export const deleteRemoteJobViaDCRF = (id: number) => {
    logger.debug(`deleteRemoteJobViaDCRF with id: ${id}`)
    return dcrfClient.delete(wsStream, id)
}

export const subscribeToRemoteJobChangesViaDCRF = (callback: (remoteJob: IRemoteJob, action: string) => void) => {
    logger.debug("subscribeToRemoteJobChangesViaDCRF")
    const subscription = dcrfClient.subscribe(wsStream,
        {},
        (remoteJob, action) => {
            callback(remoteJob as IRemoteJob, action)
        },
        {
            includeCreateEvents: true,
            subscribeAction: 'subscribe_to_all_changes',
            unsubscribeAction: 'unsubscribe_from_all_changes',
        }
    )
    // a subscription can be canceled by calling "cancel" on the returned subscription object
    return subscription
}
