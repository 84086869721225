import { configureStore, ThunkAction, Action, Middleware, combineReducers } from '@reduxjs/toolkit';
import mainLuminoWidgetReducer from "../features/main_lumino_widget/MainLuminoWidgetSlice";
import hardwareInterfacesReducer from "../features/hardware_interface/HardwareInterfacesSlice";
import userMessagesReducer from "../features/user_message/UserMessageSlice";
import backendEventsReducer from "../features/backend_event/BackendEventSlice";
import isotpEndpointScanRunReducer from "../features/isotp_endpoint_scan_run/ISOTPEndpointScanRunsSlice";
import isotpEndpointReducer from "../features/isotp_endpoint/ISOTPEndpointsSlice";
import udsScanRunReducer from "../features/uds_scan_run/UDSScanRunsSlice";
import licensesReducer from "../features/license/LicenseSlice";
import settingsReducer from "../features/settings/SettingsSlice"
import systemDataReducer from "../features/system_data/SystemDataSlice"
import targetECUsReducer from "../features/target_ecu/TargetECUSlice"
import remoteRunnersReducer from "../features/remote_runner/RemoteRunnerSlice"
import remoteJobTemplateReducer, { WEBSOCKET_REMOTE_TEMPLATE_EDITOR_REDUX_ACTION_PREFIX } from '../features/remote_job_template/RemoteJobTemplateSlice';
import remoteJobsReducer from "../features/remote_jobs/RemoteJobSlice"
import remoteJobContextsReducer from "../features/remote_jobs/RemoteJobContextSlice"
import remoteJobArtifactsReducer from "../features/remote_job_artifacts/RemoteJobArtifactSlice"
import userDataReducer from "../features/settings/UserDataSlice"
import usersReducer from "../features/user_management/UserSlice"
import groupsReducer from "../features/group_management/GroupSlice"
import { baseBackendURL } from './api';
import { logger } from './logging';

import liveDataReducer, { WEBSOCKET_LIVEDATA_REDUX_ACTION_PREFIX } from "../features/live_data/LiveDataSlice";
import reduxWebsocket, { connect } from '@giantmachines/redux-websocket';
import { fetchAndSubscribeAllData } from '../App';
import { authManager } from './auth';
import { groupsChangedUserMessageTitle } from '../features/misc/Constants';

const reduxWebsocketLivedataMiddleware = reduxWebsocket({ prefix: WEBSOCKET_LIVEDATA_REDUX_ACTION_PREFIX, reconnectOnClose: true })
const reduxWebsocketRemoteTemplateEditorMiddleware = reduxWebsocket({ prefix: WEBSOCKET_REMOTE_TEMPLATE_EDITOR_REDUX_ACTION_PREFIX, reconnectOnClose: true })

// For testing we want to block the live messages if necessary. Live messages slow down the app considerably
export const appEnvironmentMiddleware: Middleware<{}, RootState> = store => next => action => {
  if (store.getState().systemData.systemData.app_environment === "test" && action.type === `${WEBSOCKET_LIVEDATA_REDUX_ACTION_PREFIX}::MESSAGE`) {
    logger.debug(`Action with type ${WEBSOCKET_LIVEDATA_REDUX_ACTION_PREFIX}::MESSAGE was blocked because app environment variable is set to test`)
  } else {
    next(action)
  }
}

const logoutUserGroupChangeMiddleware: Middleware<{}, RootState> = store => next => action => {
  // Note: we need to check the UserMessage title. Otherwise if we check 
  // action.type === "users/updateLocalUser" or action.type === "User/updateUserAsync/fulfilled"
  // the UserMessage will trigger before the group update and the UserMessage will then be 
  // in the state "PRESENTED", but will not be shown because of the logout. And it will
  // also not be shown after the next login, because it is not in the "CREATED" state.

  // Groups changed for current user
  if (action.type === "userMessages/addLocalUserMessage" ) {
    if (action.payload.title === groupsChangedUserMessageTitle) {
      authManager?.logout()
    }
  }
  
  // Group which user is assigned to was deleted
  // if (action.type === "groups/deleteLocalGroup" ) {
  //   const currentUser = store.getState().users.users.filter(user => user.id === authManager?.getAuthenticatedUserId()).pop()
  //   if (currentUser?.groups.indexOf(action.payload.id) !== -1) {
  //     authManager?.logout()
  //   }
  // }

  const nextValue = next(action)
  return nextValue
}

// Use combineReducers for configureStore reducer to avoid circular import problems
// https://redux.js.org/usage/usage-with-typescript#type-checking-middleware
const rootReducer = combineReducers({ 
  mainLuminoWidget: mainLuminoWidgetReducer,
  hardwareInterfaces: hardwareInterfacesReducer,
  userMessages: userMessagesReducer,
  backendEvents: backendEventsReducer,
  udsScanRuns: udsScanRunReducer,
  isotpEndpointScanRuns: isotpEndpointScanRunReducer,
  isotpEndpoints: isotpEndpointReducer,
  liveData: liveDataReducer,
  licenses: licensesReducer,
  settings: settingsReducer,
  systemData: systemDataReducer,
  targetECUs: targetECUsReducer,
  remoteRunners: remoteRunnersReducer,
  remoteJobTemplates: remoteJobTemplateReducer,
  remoteJobs: remoteJobsReducer,
  remoteJobContexts: remoteJobContextsReducer,
  remoteJobArtifacts: remoteJobArtifactsReducer,
  userData: userDataReducer,
  users: usersReducer,
  groups: groupsReducer
 });

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware( { serializableCheck: { ignoredActionPaths: ['meta.timestamp', 'payload'] } } ).concat(reduxWebsocketLivedataMiddleware).concat(reduxWebsocketRemoteTemplateEditorMiddleware).concat(appEnvironmentMiddleware).concat(logoutUserGroupChangeMiddleware)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

// NOTE: the used websocket middleware will try to reconnect if the connection is lost
//       the default retry time is set to 2 seconds, see also https://github.com/giantmachines/redux-websocket (especially the Options interface)
store.dispatch(connect(`wss://${baseBackendURL}/ws/livedata/`, WEBSOCKET_LIVEDATA_REDUX_ACTION_PREFIX))
store.dispatch(connect(`wss://${baseBackendURL}/ws/remotejobtemplateeditor/`, WEBSOCKET_REMOTE_TEMPLATE_EDITOR_REDUX_ACTION_PREFIX))

// NOTE: this only runs once during app initialization
//       it gets executed here to avoid a race condition
//       where the store is not yet initialized but needed in fetchAndSubscribeAllData
// if (typeof window !== 'undefined') {
//   if (process.env.REACT_APP_PERSONALITY_NAME === 'HydraScope') {
//       fetchAndSubscribeAllData()
//   }
// }
