
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, store } from "../../app/store";
import {
    createTargetECUViaDCRF,
    deleteTargetECUViaDCRF,
    fetchAllTargetECUsViaDCRF,
    patchTargetECUViaDCRF,
    subscribeToTargetECUChangesViaDCRF,
} from "./TargetECUAPI";
import {
    showUserMessage,
} from '../user_message/UserMessageSlice';
import { logger } from "../../app/logging";


export interface ITargetECU {
    id: number,
    context?: number,
    remote_runner?: number,
    name: string,
    description: string,
    created_at: string,
}

type LastRequestStatus = 'success' | 'busy' | 'failed'

export interface ITargetECUs {
    targetECUs: ITargetECU[]
    lastRequestStatus: LastRequestStatus
    subscribedToUpdates: boolean
}

export const fetchAllTargetECUsAsync = createAsyncThunk(
    'TargetECU/fetchAllTargetECUsAsync',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            return await fetchAllTargetECUsViaDCRF()
        } catch (rejectedValue) {
            // @ts-ignore
            const error_message = JSON.stringify(rejectedValue.errors)
            dispatch(showUserMessage({ title: 'fetchAllTargetECUsAsync failed', message: error_message }))
            return rejectWithValue(rejectedValue)
        }
    }
)

export interface IUpdateTargetECU {
    id: number
    data: {
        context?: number,
        remote_runner?: number,
        name: string,
        description: string,
    }
}

export const updateTargetECUAsync = createAsyncThunk(
    'TargetECU/updateTargetECUAsync',
    async (updatedTargetECU: IUpdateTargetECU, { dispatch, rejectWithValue }) => {
        try {
            return await patchTargetECUViaDCRF(updatedTargetECU.id, updatedTargetECU.data)
        } catch (rejectedValue) {
            // @ts-ignore
            const error_message = JSON.stringify(rejectedValue.errors)
            dispatch(showUserMessage({ title: 'updateTargetECUAsync failed', message: error_message }))
            return rejectWithValue(rejectedValue)
        }
    }
)

export interface ICreateTargetECU {
    context?: number,
    remote_runner?: number,
    name: string,
    description: string,
}

export const createTargetECUAsync = createAsyncThunk(
    'TargetECU/createTargetECUAsync',
    async (newTargetECU: ICreateTargetECU, { dispatch, rejectWithValue }) => {
        try {
            return await createTargetECUViaDCRF(newTargetECU)
        } catch (rejectedValue) {
            // @ts-ignore
            const error_message = JSON.stringify(rejectedValue.errors)
            dispatch(showUserMessage({ title: 'createTargetECUAsync failed', message: error_message }))
            return rejectWithValue(rejectedValue)
        }
    }
)

export const deleteTargetECUAsync = createAsyncThunk(
    'TargetECU/deleteTargetECUAsync',
    async (id: number, { dispatch, rejectWithValue }) => {
        try {
            return await deleteTargetECUViaDCRF(id)
        } catch (rejectedValue) {
            // @ts-ignore
            const error_message = JSON.stringify(rejectedValue.errors)
            dispatch(showUserMessage({ title: 'deleteTargetECUAsync failed', message: error_message }))
            return rejectWithValue(rejectedValue)
        }
    }
)

const getUpdatedTargetECUs = (currentTargetECUs: ITargetECU[], updatedTargetECU: ITargetECU) => {
    return currentTargetECUs.map((existingTargetECU: ITargetECU) => {
        if (existingTargetECU.id === updatedTargetECU.id) {
            return updatedTargetECU
        } else {
            return existingTargetECU
        }
    })
}

const initialState: ITargetECUs = {
    targetECUs: [],
    subscribedToUpdates: false,
    lastRequestStatus: 'success',
}

export const targetECUsSlice = createSlice({
    name: 'targetECUs',
    initialState,
    reducers: {
        addLocalTargetECU: (state, action: PayloadAction<ITargetECU>) => {
            if (state.targetECUs.every(targetECU => targetECU.id !== action.payload.id)) {
                state.targetECUs.push(action.payload)
            }
        },
        updateLocalTargetECU: (state, action: PayloadAction<ITargetECU>) => {
            state.targetECUs = getUpdatedTargetECUs(state.targetECUs, action.payload)
        },
        deleteLocalTargetECU: (state, action: PayloadAction<ITargetECU>) => {
            state.targetECUs = state.targetECUs.filter((endpoint) => endpoint.id !== action.payload.id)
        },
        subscribeToTargetECUChanges: (state) => {
            if (state.subscribedToUpdates) {
                return
            }
            const callback = (TargetECU: ITargetECU, action: string) => {
                logger.debug(`target ecu subscription callback (action: ${action})`)
                switch(action) {
                    case 'create':
                        store.dispatch(addLocalTargetECU(TargetECU))
                        break
                    case 'update':
                        store.dispatch(updateLocalTargetECU(TargetECU))
                        break
                    case 'delete':
                        // NOTE: We get the action.payload with a non null value here. That means,
                        // that this callback and the deleteLocalTargetECU are responsible for
                        // keeping the state up to date when a remote job gets deleted.
                        // deleteTargetECUAsync.fulfilled does only get a null value
                        store.dispatch(deleteLocalTargetECU(TargetECU))
                        break
                    default:
                        logger.debug('FIXME: unexpected action')
                }
            }
            subscribeToTargetECUChangesViaDCRF(callback)
            state.subscribedToUpdates = true
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllTargetECUsAsync.pending, (state) => {
                state.lastRequestStatus = 'busy'
            })
            .addCase(fetchAllTargetECUsAsync.fulfilled, (state, action: PayloadAction<ITargetECU[]>) => {
                state.lastRequestStatus = 'success'
                state.targetECUs = action.payload
            })
            .addCase(fetchAllTargetECUsAsync.rejected, (state) => {
                state.lastRequestStatus = 'failed'
            })
            .addCase(createTargetECUAsync.pending, (state) => {
                state.lastRequestStatus = 'busy'
            })
            .addCase(createTargetECUAsync.fulfilled, (state, action: PayloadAction<ITargetECU>) => {
                state.lastRequestStatus = 'success'
                if (state.targetECUs.every(endpoint => endpoint.id !== action.payload.id)) {
                    state.targetECUs.push(action.payload)
                }
            })
            .addCase(createTargetECUAsync.rejected, (state) => {
                state.lastRequestStatus = 'failed'
            })
            .addCase(updateTargetECUAsync.pending, (state) => {
                state.lastRequestStatus = 'busy'
            })
            .addCase(updateTargetECUAsync.fulfilled, (state, action: PayloadAction<ITargetECU>) => {
                state.lastRequestStatus = 'success'
                state.targetECUs = getUpdatedTargetECUs(state.targetECUs, action.payload)
            })
            .addCase(updateTargetECUAsync.rejected, (state) => {
                state.lastRequestStatus = 'failed'
            })
            .addCase(deleteTargetECUAsync.pending, (state) => {
                state.lastRequestStatus = 'busy'
            })
            .addCase(deleteTargetECUAsync.fulfilled, (state, action: PayloadAction<ITargetECU>) => {
                state.lastRequestStatus = 'success'
                // NOTE: action.payload.id is not available here (null value; update, fetch and create get a non null value) 
                // so just leave the state as it is (the subscription will trigger the deletion).
                // This means we are dependent on a functioning subscription callback
            })
            .addCase(deleteTargetECUAsync.rejected, (state) => {
                state.lastRequestStatus = 'failed'
            })
    }
})

export const {
    addLocalTargetECU,
    updateLocalTargetECU,
    deleteLocalTargetECU,
    subscribeToTargetECUChanges } = targetECUsSlice.actions

export const selectTargetECUs = (state: RootState) => state.targetECUs.targetECUs
export const selectTargetECU = (id: number) => (state: RootState) =>
    state.targetECUs.targetECUs.filter(targetECU => targetECU.id === id).pop()

export const selectLastTargetECURequestStatus = (state: RootState) => state.targetECUs.lastRequestStatus
export const selectTargetECUIsSubscribed = (state: RootState) => state.targetECUs.subscribedToUpdates

export default targetECUsSlice.reducer
