import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, store } from "../../app/store";
import {
    createISOTPEndpointViaDCRF, deleteISOTPEndpointViaDCRF,
    fetchAllISOTPEndpointsViaDCRF,
    patchISOTPEndpointViaDCRF,
    subscribeToISOTPEndpointChangesViaDCRF,
} from "./ISOTPEndpointsAPI";
import {
    showUserMessage,
} from '../user_message/UserMessageSlice';
import { logger } from "../../app/logging";

export interface IISOTPEndpoint {
    id: number
    hw_interface: string
    name: string
    rx_id: number
    tx_id: number
    ext_address: number
    rx_ext_address: number
    padding: boolean
}

type LastRequestStatus = 'success' | 'busy' | 'failed'

export interface IISOTPEndpoints {
    endpoints: IISOTPEndpoint[]
    lastRequestStatus: LastRequestStatus
    subscribedToUpdates: boolean
}

export const fetchAllISOTPEndpointsAsync = createAsyncThunk(
    'isotpEndpoint/fetchAllISOTPEndpointsAsync',
    async () => {
        const response = await fetchAllISOTPEndpointsViaDCRF()
        return response
    }
)

export interface IUpdateISOTPEndpoint {
    id: number
    data: {
        name?: string
        hw_interface_id?: string
        rx_id?: number
        tx_id?: number
        ext_address?: number
        rx_ext_address?: number
        padding?: boolean
    }
}

export const updateISOTPEndpointAsync = createAsyncThunk(
    'isotpEndpoint/updateISOTPEndpointAsync',
    async (updatedEndpoint: IUpdateISOTPEndpoint, { dispatch, rejectWithValue }) => {
        try {
            return await patchISOTPEndpointViaDCRF(updatedEndpoint.id, updatedEndpoint.data)
        } catch (rejectedValue) {
            // @ts-ignore
            const error_message = JSON.stringify(rejectedValue.errors)
            dispatch(showUserMessage({ title: 'updateISOTPEndpointAsync failed', message: error_message }))
            return rejectWithValue(rejectedValue)
        }
    }
)

export interface ICreateISOTPEndpoint {
    name: string
    hw_interface?: string
    rx_id?: number
    tx_id?: number
    ext_address?: number
    rx_ext_address?: number
    padding?: boolean
}

export const createISOTPEndpointAsync = createAsyncThunk(
    'isotpEndpoint/createISOTPEndpointAsync',
    async (newEndpoint: ICreateISOTPEndpoint, { dispatch, rejectWithValue }) => {
        try {
            return await createISOTPEndpointViaDCRF(newEndpoint)
        } catch (rejectedValue) {
            // @ts-ignore
            const error_message = JSON.stringify(rejectedValue.errors)
            dispatch(showUserMessage({ title: 'createISOTPEndpointAsync failed', message: error_message }))
            return rejectWithValue(rejectedValue)
        }
    }
)

export const deleteISOTPEndpointAsync = createAsyncThunk(
    'isotpEndpoint/deleteISOTPEndpointAsync',
    async (id: number) => {
        const response = await deleteISOTPEndpointViaDCRF(id)
        return response
    }
)

const getUpdatedISOTPEndpoints = (currentEndpoints: IISOTPEndpoint[], updatedEndpoint: IISOTPEndpoint) => {
    return currentEndpoints.map((existingEndpoint: IISOTPEndpoint) => {
        if (existingEndpoint.id === updatedEndpoint.id) {
            return updatedEndpoint
        } else {
            return existingEndpoint
        }
    })
}

const initialState: IISOTPEndpoints = {
    endpoints: [],
    subscribedToUpdates: false,
    lastRequestStatus: 'success',
}

export const isotpEndpointsSlice = createSlice({
    name: 'isotpEndpoints',
    initialState,
    reducers: {
        addLocalISOTPEndpoint: (state, action: PayloadAction<IISOTPEndpoint>) => {
            if (!state.endpoints.some(endpoint => endpoint.id === action.payload.id)) {
                state.endpoints.push(action.payload)
            }
        },
        updateLocalISOTPEndpoint: (state, action: PayloadAction<IISOTPEndpoint>) => {
            state.endpoints = getUpdatedISOTPEndpoints(state.endpoints, action.payload)
        },
        deleteLocalISOTPEndpoint: (state, action: PayloadAction<IISOTPEndpoint>) => {
            state.endpoints = state.endpoints.filter( (endpoint) => endpoint.id !== action.payload.id)
        },
        subscribeToISOTPEndpointChanges: (state) => {
            if (state.subscribedToUpdates) {
                return
            }
            const callback = (endpoint: IISOTPEndpoint, action: string) => {
                logger.debug(`isotp endpoint subscription callback (action: ${action})`)
                switch(action) {
                    case 'create':
                        store.dispatch(addLocalISOTPEndpoint(endpoint))
                        break
                    case 'update':
                        store.dispatch(updateLocalISOTPEndpoint(endpoint))
                        break
                    case 'delete':
                        // NOTE: We get the action.payload with a non null value here. That means,
                        // that this callback and the deleteLocalRemoteJob are responsible for
                        // keeping the state up to date when a remote job gets deleted.
                        // deleteRemoteJobAsync.fulfilled does only get a null value
                        store.dispatch(deleteLocalISOTPEndpoint(endpoint))
                        break
                    default:
                        logger.debug('FIXME: unexpected action')
                }
            }
            subscribeToISOTPEndpointChangesViaDCRF(callback)
            state.subscribedToUpdates = true
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllISOTPEndpointsAsync.pending, (state) => {
                state.lastRequestStatus = 'busy'
            })
            .addCase(fetchAllISOTPEndpointsAsync.fulfilled, (state, action: PayloadAction<IISOTPEndpoint[]>) => {
                state.lastRequestStatus = 'success'
                state.endpoints = action.payload
            })
            .addCase(fetchAllISOTPEndpointsAsync.rejected, (state) => {
                state.lastRequestStatus = 'failed'
            })
            .addCase(createISOTPEndpointAsync.pending, (state) => {
                state.lastRequestStatus = 'busy'
            })
            .addCase(createISOTPEndpointAsync.fulfilled, (state, action: PayloadAction<IISOTPEndpoint>) => {
                state.lastRequestStatus = 'success'
                if (!state.endpoints.some(endpoint => endpoint.id === action.payload.id)) {
                    state.endpoints.push(action.payload)
                }
            })
            .addCase(createISOTPEndpointAsync.rejected, (state) => {
                state.lastRequestStatus = 'failed'
            })
            .addCase(updateISOTPEndpointAsync.pending, (state) => {
                state.lastRequestStatus = 'busy'
            })
            .addCase(updateISOTPEndpointAsync.fulfilled, (state, action: PayloadAction<IISOTPEndpoint>) => {
                state.lastRequestStatus = 'success'
                state.endpoints = getUpdatedISOTPEndpoints(state.endpoints, action.payload)
            })
            .addCase(updateISOTPEndpointAsync.rejected, (state) => {
                state.lastRequestStatus = 'failed'
            })
            .addCase(deleteISOTPEndpointAsync.pending, (state) => {
                state.lastRequestStatus = 'busy'
            })
            .addCase(deleteISOTPEndpointAsync.fulfilled, (state, action: PayloadAction<IISOTPEndpoint>) => {
                state.lastRequestStatus = 'success'
                // NOTE: action.payload.id is not available here (null value; update, fetch and create get a non null value) 
                // so just leave the state as it is (the subscription will trigger the deletion).
                // This means we are dependent on a functioning subscription callback
            })
            .addCase(deleteISOTPEndpointAsync.rejected, (state) => {
                state.lastRequestStatus = 'failed'
            })
    }
})

export const {
    addLocalISOTPEndpoint,
    updateLocalISOTPEndpoint,
    deleteLocalISOTPEndpoint,
    subscribeToISOTPEndpointChanges } = isotpEndpointsSlice.actions

export const selectISOTPEndpoints = (state: RootState) => state.isotpEndpoints.endpoints
export const selectISOTPEndpoint = (id: number) => (state: RootState) => {
    return state.isotpEndpoints.endpoints.filter( isotpEndpoint => isotpEndpoint.id === id).pop()
}
export const selectLastISOTPEndpointRequestStatus = (state: RootState) => state.isotpEndpoints.lastRequestStatus
export const selectISOTPEndpointIsSubscribed = (state: RootState) => state.isotpEndpoints.subscribedToUpdates

export default isotpEndpointsSlice.reducer
