import { useEffect, useMemo, useState } from "react";
import { useAppSelector, useAppDispatch } from '../../app/hooks';

import { useTranslation } from "react-i18next";
import {
    cloneTemplateArchiveFromRemoteGITRepositoryAsync,
    createRemoteJobTemplateAsync,
    createTemplateCopyAsync,
    deleteRemoteJobTemplateAsync,
    deleteRemoteJobTemplateFileContents,
    deleteRemoteJobTemplateFileTree,
    ICreateRemoteJobTemplate,
    IRemoteJobTemplate,
    IRemoteJobTemplateFile,
    IRemoteJobTemplateFileContent,
    IUpdateRemoteJobTemplate,
    // pullTemplateArchiveFromRemoteGITRepositoryAsync,
    // pushTemplateArchiveFromRemoteGITRepositoryAsync,
    requestFileContentAsync,
    requestFileTreeAsync,
    selectRemoteJobTemplate,
    selectRemoteJobTemplateFileContents,
    selectRemoteJobTemplateFileTree,
    selectRemoteJobTemplatesByType,
    updateRemoteJobTemplateAsync,
    updateTemplateAsync
} from "./RemoteJobTemplateSlice";
import Container from "@mui/material/Container";
import { Alert, Button, FormControl, Grid, IconButton, Input, InputLabel, MenuItem, Paper, Select, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Tabs, TextField, Tooltip, Typography } from "@mui/material";
import { addOrActivateHydraCoreDocuWidget, addOrActivateRemoteJobTemplatesWidget, addOrActivateTestCasesExecutionWidget } from "../main_lumino_widget/MainLuminoWidgetSlice";
import Editor from "@monaco-editor/react";
import { selectSettings } from "../settings/SettingsSlice";
import { Box } from '@mui/system';
import FolderTree from "react-folder-tree";
import { AiOutlineDelete, AiOutlineCheck, AiOutlineFile, AiOutlineEdit, AiOutlineClose } from 'react-icons/ai';
import { DeleteElementButton, utcToLocalDateTimeRepresentation, useObserveAndDeleteWidget, authenticatedFetch, TableToolbarWithFilterInput, getSortComparator, IconButtonWithImplicitLicenseCheck, isRemoteJobRunning, utcToLocalDateTime, getUserDataEntryFor, ConditionalFragment, useTargetECUSelectedRemoteRunnerExists, IconButtonWithDialog, isRunningAs } from "../misc/Util";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
// import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import 'react-folder-tree/dist/style.css';
import { showUserMessage } from "../user_message/UserMessageSlice";
import { httpBackendURL } from "../../app/api";
import { fileExtensionToLanguageMapping, remoteTestcasesMetadataAllTestcasesKey, userDataExpertModeEnabled } from "../misc/Constants";
import { logger } from "../../app/logging";
import { createRemoteJobAsync, ICreateRemoteJob, IRemoteJob, IUpdateRemoteJob, selectRemoteJobs, updateRemoteJobAsync } from "../remote_jobs/RemoteJobSlice";
import { THeadAlign, TSortOrder } from "../misc/GlobalTypes";
import SaveIcon from '@mui/icons-material/Save';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { selectTargetECUs } from "../target_ecu/TargetECUSlice";
import React from "react";
import { deleteGenericRemoteJobDataLines } from "../live_data/LiveDataSlice";
import { selectUserDataAsObject } from "../settings/UserDataSlice";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { fetchSystemDataAsync, IRemoteTestcase, selectSystemData } from "../system_data/SystemDataSlice";
import RefreshIcon from '@mui/icons-material/Refresh';
import ArticleIcon from '@mui/icons-material/Article';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import CachedIcon from '@mui/icons-material/Cached';

interface TableHeadCell {
    id: keyof IRemoteJobTemplate
    label: string
    align: THeadAlign
}

const tableHeadCells: readonly TableHeadCell[] = [
    {
        id: "name",
        // t("Name")
        label: "Name",
        align: "left"
    },
    {
        id: "created_at",
        // t("Created at")
        label: "Created at",
        align: "right"
    },
    {
        id: "last_updated",
        // t("Last updated")
        label: "Last updated",
        align: "right"
    },
]

const TableRowComponent = (props: any) => {

    const { t } = useTranslation()

    const dispatch = useAppDispatch()

    const [name, setName] = useState<string>(props.remoteJobTemplate.name)

    const hasEditedTemplateName = () => { return name !== props.remoteJobTemplate.name }

    const updateRemoteJobTemplateName = () => {
        const updatedRemoteJobTemplate: IUpdateRemoteJobTemplate = {
            id: props.remoteJobTemplate?.id ?? -1,
            data: {
                name: name,
            }
        }
        dispatch(updateRemoteJobTemplateAsync(updatedRemoteJobTemplate))
    }

    const triggerGITRemoteRepoClone = () => {
        dispatch(cloneTemplateArchiveFromRemoteGITRepositoryAsync(props.remoteJobTemplate.id))
    }

    return (
        <TableRow
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            key={props.remoteJobTemplate.id}
        >
            <TableCell component="th" scope="row">
                <Stack direction="row" style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}>
                    <TextField
                        // sx={{ margin: 1 }}
                        id={`remote-job-template-table-row-name-${props.remoteJobTemplate.id}`}
                        size="small"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <Tooltip title={t("Save the name change of the template.") as string}>
                        <span>
                            <IconButton
                                sx={{}}
                                id={`remote-job-template-table-row-save-button-${props.remoteJobTemplate.id}`}
                                disabled={!hasEditedTemplateName()}
                                onClick={() => updateRemoteJobTemplateName()}
                            >
                                <SaveIcon color={hasEditedTemplateName() ? "primary" : "disabled"} />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <ConditionalFragment condition={props.remoteJobTemplate.outdated_local_repo}>
                        <Tooltip title={t("This testcase is outdated. Clone it again to get the latest version.")}>
                            <ErrorOutlineIcon color="warning" sx={{ }} />
                        </Tooltip>
                    </ConditionalFragment>
                    <Tooltip title={t("Re-clone the testcase.")}>
                        <span>
                            <IconButtonWithDialog
                                title={t("Do you really want to re-clone this testcase?")}
                                message={t("All local content will be overwritten with the remote Git repository content.")}
                                proceedButtonColor="error"
                                cancelButtonColor="primary"
                                onClickCallback={triggerGITRemoteRepoClone}
                                icon={SystemUpdateAltIcon}
                                size="medium"
                                disabledCondition={false}
                            />
                        </span>
                    </Tooltip>
                </Stack>
            </TableCell>
            <TableCell align="right">{utcToLocalDateTimeRepresentation(props.remoteJobTemplate.created_at)}</TableCell>
            <TableCell align="right">{utcToLocalDateTimeRepresentation(props.remoteJobTemplate.last_updated)}</TableCell>
            <TableCell align="right">
                <Button
                    id="remote-job-template-copy-button"
                    sx={{ margin: 1 }}
                    variant="contained"
                    color="primary"
                    onClick={() => props.createTemplateCopy(props.remoteJobTemplate)}
                >
                    {t("Copy")}
                </Button>
                <Button
                    id="remote-job-template-edit-button"
                    sx={{ margin: 1 }}
                    variant="contained"
                    color="primary"
                    onClick={() => props.editRemoteJobTemplate(props.remoteJobTemplate)}
                >
                    {t("Edit")}
                </Button>
                <DeleteElementButton
                    id="remote-job-template-delete-button"
                    title={t("Do you really want to delete this template?")}
                    message={t("Deleting a template is permanent and can not be undone. Of course you can always create a new one. Are you sure?")}
                    deleteCallback={() => props.deleteRemoteJobTemplate(props.remoteJobTemplate)}
                />
            </TableCell>
        </TableRow>
    )
}

export const RemoteJobTemplate = (props: any) => {
    const remoteJobTemplateId = props.remoteJobTemplateId

    const remoteJobs = useAppSelector(selectRemoteJobs)
    const remoteJobTemplates = useAppSelector(selectRemoteJobTemplatesByType(['GENERIC', 'TESTCASE']))
    const remoteJobTemplate = useAppSelector(selectRemoteJobTemplate(remoteJobTemplateId))
    const remoteJobTemplateFileTree = useAppSelector(selectRemoteJobTemplateFileTree(remoteJobTemplateId))
    const remoteTargetECUs = useAppSelector(selectTargetECUs)
    const systemData = useAppSelector(selectSystemData)

    // const [selectedJobTypeForTemplateCreation, setSelectedJobTypeForTemplateCreation] = useState<TRemoteJobType>("TESTCASE")
    const [selectedRemoteTestcaseForCreation, setSelectedRemoteTestcaseForCreation] = useState<string>("")
    const selectedJobTypeForTemplateCreation = "TESTCASE"
    const existingRemoteJobTemplate: IRemoteJobTemplate | undefined = remoteJobTemplates.filter(remoteJobTemplate => remoteJobTemplate.id === remoteJobTemplateId).pop()
    const [initialLocalTreeState, setInitialLocalTreeState] = useState<IRemoteJobTemplateFile>()
    const [fileContent, setFileContent] = useState<string>('')
    const initialOpenedFile = remoteJobTemplate === undefined ? '' : remoteJobTemplate.job_type === "GENERIC" ? `/${remoteJobTemplate.name}/run.sh` : `/${remoteJobTemplate.name}/test_case.py`
    const [filePath, setFilePath] = useState<string>(initialOpenedFile)
    const [remoteJobTemplateName, setRemoteJobTemplateName] = useState<string>('')
    // const [remoteJobTemplateGitRepoURL, setRemoteJobTemplateGitRepoURL] = useState<string>('')
    const settings = useAppSelector(selectSettings)
    const remoteJobTemplateFileContents = useAppSelector(selectRemoteJobTemplateFileContents)
    const [localJobTemplateFileContents, setLocalJobTemplateFileContents] = useState<IRemoteJobTemplateFileContent[]>([])
    const [localTreeState, setLocalTreeState] = useState<any>()
    const [editedFileId, setEditedFileId] = useState()
    const [selectedEditorLanguage, setSelectedEditorLanguage] = useState<string>("automatic")
    const [order, setOrder] = useState<TSortOrder>("desc")
    const [orderBy, setOrderBy] = useState<keyof IRemoteJobTemplate>("created_at")
    const [nameFilterPattern, setNameFilterPattern] = useState("")
    const [rowsPerPage, setRowsPerPage] = useState(25)
    const [page, setPage] = useState(0)
    const supportedEditorLanguages = Object.values(fileExtensionToLanguageMapping)
    const userDataObject = useAppSelector(selectUserDataAsObject)
    const [selectedRemoteTargetECUId, setSelectedRemoteTargetECUId] = useState("")
    const [hasBeenModifiedInBackend, setHasBeenModifiedInBackend] = useState<boolean>(false)

    const selectedTargetECU = remoteTargetECUs.filter((remoteTargetECU) => remoteTargetECU.id === parseInt(selectedRemoteTargetECUId)).pop()
    const selectedTargetECURemoteRunnerExists = useTargetECUSelectedRemoteRunnerExists(selectedTargetECU)
    
    const notDeleteableFileNamesInsideFileTree = ["run.sh", "test_case.py"]


    const { t } = useTranslation()

    const dispatch = useAppDispatch()
    useObserveAndDeleteWidget("REMOTEJOBTEMPLATES", remoteJobTemplates)

    // const [expertMode, setExpertMode] = useState<boolean>(remoteJobTemplate?.job_type === "TESTCASE" ? false : true)
    const expertMode = useMemo(() => {
        // FIXME: default to "TESTCASE" if there is no template (then this is the "create template view")
        if ((remoteJobTemplate?.job_type ?? "TESTCASE") === "TESTCASE") {
            return getUserDataEntryFor(userDataExpertModeEnabled, userDataObject, false)
        } else {
            // always enable expert mode for non testcase views (copied from the old handling ...)
            return true
        }
    }, [userDataObject, remoteJobTemplate?.job_type])

    // useEffect(() => {
    //     // NOTE: the job type selection is only available in expert mode, so set the selected job type back to the only
    //     //       one available in normal mode
    //     if (!expertMode) {
    //         setSelectedJobTypeForTemplateCreation("TESTCASE")
    //     }
    // }, [expertMode])

    const remoteTargetECUSelectionOptions = remoteTargetECUs.map((remoteTargetECU) => {
        return (
            <MenuItem
                key={remoteTargetECU.id}
                value={remoteTargetECU.id}
            >
                {remoteTargetECU.name}
            </MenuItem>
        )
    })

    const getFilePaths = (currentPath: string, file: IRemoteJobTemplateFile) => {
        const paths: string[] = []
        const newPath = `${currentPath}/${file.name}`
        paths.push(newPath)
        for (let currentFile of (file.children ?? [])) {
            paths.push(...getFilePaths(newPath, currentFile))
        }
        return paths
    }

    useEffect(() => {
        if (!remoteJobTemplateId) {
            return
        }

        const getNewFilePath = (oldFilePath: string) => {
            return `/${remoteJobTemplate?.name}/`.concat((oldFilePath.split("/").slice(2).join('')))
        }

        const newFilePath = `/${remoteJobTemplate?.name}/`.concat((filePath.split("/").slice(2).join('')))
        setFilePath(newFilePath)
        setLocalJobTemplateFileContents(localJobTemplateFileContents => localJobTemplateFileContents.map(localJobFileContent => {
            const newLocalJobFileContent: IRemoteJobTemplateFileContent = {
                fileContent: localJobFileContent.fileContent,
                filePath: getNewFilePath(localJobFileContent.filePath),
                remoteJobTemplateId: localJobFileContent.remoteJobTemplateId
            }
            return newLocalJobFileContent
        }))
        updateRemoteJobTemplate()
        cleanAndReloadFileContentsAndFileTree()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [remoteJobTemplate?.name])

    useEffect(() => {
        if (remoteJobTemplateFileTree && !initialLocalTreeState) {
            setInitialLocalTreeState(remoteJobTemplateFileTree)
        }
        if (remoteJobTemplate) {
            requestFileContent(initialOpenedFile)
        }
        // The linter wants requestFileContent as dependency,
        // which doesn't make any sense I think 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [remoteJobTemplateFileTree, initialLocalTreeState, remoteJobTemplate])

    useEffect(() => {
        if (editedFileId && localTreeState) {
            const newFilePath = getFilePathByInternalTreeId('', editedFileId, localTreeState)
            const oldFilePath = filePath

            const oldFile = getFileByPath('', oldFilePath, localTreeState)

            if (newFilePath && !oldFile) {

                setLocalJobTemplateFileContents(localJobTemplateFileContents => localJobTemplateFileContents.map(localJobFileContent => {
                    if (localJobFileContent.filePath === oldFilePath) {
                        const newLocalJobFileContent: IRemoteJobTemplateFileContent = {
                            fileContent: localJobFileContent.fileContent,
                            filePath: newFilePath,
                            remoteJobTemplateId: localJobFileContent.remoteJobTemplateId
                        }
                        return newLocalJobFileContent
                    }
                    return localJobFileContent
                }))
                setFilePath(newFilePath)
            }
        }
        // The linter wants getFilePathByInternalTreeId as dependency,
        // which doesn't make any sense I think 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editedFileId, filePath, localTreeState])

    useEffect(() => {
        // Remove deleted localFileContent if it does not appear in the tree anymore
        const filePaths: string[] = []
        if (localTreeState) {
            filePaths.push(...getFilePaths('', localTreeState))
        }

        setLocalJobTemplateFileContents(localJobTemplateFileContents => localJobTemplateFileContents.filter((localJobTemplateFileContent) => {
            if (localJobTemplateFileContent.remoteJobTemplateId === remoteJobTemplateId) {
                if (filePaths.indexOf(localJobTemplateFileContent.filePath) !== -1) {
                    return true
                }
                return false
            }
            return true
        }))
        // Setting setLocalJobTemplateFileContents gets in an endless loop if localJobTemplateFileContents
        // is set as dep 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localTreeState, remoteJobTemplateId])

    useEffect(() => {
        const remoteJobTemplateFileContent = remoteJobTemplateFileContents.filter(remoteJobTemplateFileContent => {
            return remoteJobTemplateFileContent.filePath === filePath && remoteJobTemplateFileContent.remoteJobTemplateId === remoteJobTemplateId
        }).pop()
        const localJobTemplateFileContent = localJobTemplateFileContents.filter(localJobTemplateFileContent => {
            return localJobTemplateFileContent.filePath === filePath && localJobTemplateFileContent.remoteJobTemplateId === remoteJobTemplateId
        }).pop()
        // File has already been requested/edited
        if (localJobTemplateFileContent) {
            setFileContent(localJobTemplateFileContent?.fileContent!)
        }
        // File has been requested, local file contents has to be updated
        else if (remoteJobTemplateFileContent) {
            setFileContent(remoteJobTemplateFileContent?.fileContent!)
        } else {
            const remotePaths: string[] = []
            if (remoteJobTemplateFileTree) {
                remotePaths.push(...getFilePaths('', remoteJobTemplateFileTree))
            }

            // A new file was added
            if (remotePaths.indexOf(filePath) === -1) {
                // Do not change the file path or request file content for folders
                if (localTreeState) {
                    const clickedFile = getFileByPath("", filePath, localTreeState)
                    if (Array.isArray(clickedFile?.children)) {
                        return
                    }
                    if (filePath === '') {
                        return
                    }
                    const newFileContent: IRemoteJobTemplateFileContent = {
                        fileContent: '',
                        filePath: filePath,
                        remoteJobTemplateId: remoteJobTemplateId
                    }
                    setLocalJobTemplateFileContents(localJobTemplateFileContents => localJobTemplateFileContents.concat(newFileContent))
                    setFileContent('')
                }
            }
        }

        // The editorOnChange and this useEffect give the error
        // 'Maximum update depth exceeded.' if localJobTemplateFileContents
        // is part of the deps array
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filePath, remoteJobTemplateId, remoteJobTemplateFileContents])

    useEffect(() => {
        if (initialLocalTreeState) {
            if (!hasUnsavedChangesTemplateEditor()) {
                cleanAndReloadFileContentsAndFileTree()
            } else {
                setHasBeenModifiedInBackend(true)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [remoteJobTemplate?.local_repository_commit_id_on_last_update])

    const cleanAndReloadFileContentsAndFileTree = () => {
        setHasBeenModifiedInBackend(false)
        setLocalJobTemplateFileContents([])
        setLocalTreeState(undefined)
        dispatch(deleteRemoteJobTemplateFileTree({ id: remoteJobTemplateId }))
        dispatch(requestFileTreeAsync(remoteJobTemplateId))
        dispatch(deleteRemoteJobTemplateFileContents({ id: remoteJobTemplateId }))
        setInitialLocalTreeState(undefined)
    }

    const checkIfFolder = (filePath: string) => {
        if (localTreeState) {
            const clickedFile = getFileByPath("", filePath, localTreeState)
            if (Array.isArray(clickedFile?.children)) {
                return true
            }
        }
        if (filePath === `/${remoteJobTemplate?.name}`) {
            return true
        }
        return false
    }

    const onTreeStateChange = (newState: any, event: any) => {
        const remotePaths: string[] = []
        if (remoteJobTemplateFileTree) {
            remotePaths.push(...getFilePaths('', remoteJobTemplateFileTree))
        }
        const newStatePaths: string[] = []
        newStatePaths.push(...getFilePaths('', newState))

        const newFiles = newStatePaths.filter(path => remotePaths.indexOf(path) < 0)
        const newFileContents: IRemoteJobTemplateFileContent[] = []
        for (const newFile of newFiles) {
            const newFileContent: IRemoteJobTemplateFileContent = {
                fileContent: '',
                filePath: newFile,
                remoteJobTemplateId: remoteJobTemplateId
            }

            // do not add fileContent if folder, if file name was edited or if editor was reloaded
            if (!checkIfFolder(newFile) && !editedFileId && remotePaths.length !== 0) {
                if (localJobTemplateFileContents.filter((localFileContent) => localFileContent.filePath === newFile && localFileContent.remoteJobTemplateId === remoteJobTemplateId).length === 0) {
                    newFileContents.push(newFileContent)
                }
            }
        }
        setLocalJobTemplateFileContents(localJobTemplateFileContents => localJobTemplateFileContents.concat(newFileContents))
        setLocalTreeState(newState)
    }

    const filteredAndSortedRemoteJobTemplates = useMemo(() =>
        remoteJobTemplates.filter((remoteJobTemplate) => remoteJobTemplate.name.toLowerCase().includes(nameFilterPattern.toLowerCase())).sort(getSortComparator(order, orderBy)), [nameFilterPattern, remoteJobTemplates, order, orderBy])

    const handleChangePage = (_: unknown, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const setSortHandler = (key: keyof IRemoteJobTemplate) => (_: React.MouseEvent<unknown>) => {
        const isAsc = orderBy === key && order === "asc"
        setOrder(isAsc ? "desc" : "asc")
        setOrderBy(key)
    }

    const getRemoteJobTemplatesAsRows = (remoteJobTemplates: IRemoteJobTemplate[]) => {
        return remoteJobTemplates.map((remoteJobTemplate: IRemoteJobTemplate) => (
            <TableRowComponent key={remoteJobTemplate.id}
                               remoteJobTemplate={remoteJobTemplate}
                               createTemplateCopy={createTemplateCopy}
                               editRemoteJobTemplate={editRemoteJobTemplate}
                               deleteRemoteJobTemplate={deleteRemoteJobTemplate}/>
        ))
    }

    const getRemoteJobTemplatesAsTable = (remoteJobTemplates: IRemoteJobTemplate[]) => {
        return (
            <Paper sx={{ width: "100%", mb: 2 }}>
                <TableToolbarWithFilterInput
                    hasValidFilter={true}
                    toolbarTitle={t("Testcase Templates")}
                    filterTextBoxLabel={t("Filter for names")}
                    filterPattern={nameFilterPattern}
                    setFilterPattern={setNameFilterPattern}
                    getSuggestedFilterItems={(currentFilterString: string) =>
                        remoteJobTemplates.filter((remoteJobTemplate) => remoteJobTemplate.name.toLowerCase().includes(currentFilterString.toLowerCase())).map((remoteJobTemplate) => remoteJobTemplate.name)
                    }
                />
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 300 }} aria-label="Remote Job Templates Table">
                        <TableHead>
                            <TableRow>
                                {tableHeadCells.map((tableHeadCell) => (
                                    <TableCell
                                        key={`table-header-cell-remote-job-template-${tableHeadCell.id}`}
                                        align={tableHeadCell.align}
                                        sortDirection={orderBy === tableHeadCell.id ? order : false}
                                    >
                                        <TableSortLabel
                                            active={orderBy === tableHeadCell.id}
                                            direction={orderBy === tableHeadCell.id ? order : "asc"}
                                            onClick={setSortHandler(tableHeadCell.id)}
                                        >
                                            <b>
                                                {t(tableHeadCell.label) /* FIXME: this will generate a warning during the extraction of the translatable strings */}
                                            </b>
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                                <TableCell align="right"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {getRemoteJobTemplatesAsRows(remoteJobTemplates ?? [])}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        )
    }

    // const triggerGITRemoteRepoClone = () => {
    //     dispatch(cloneTemplateArchiveFromRemoteGITRepositoryAsync(remoteJobTemplateId)).then(() => {
    //         cleanAndReloadFileContentsAndFileTree()
    //         setFilePath('')
    //     })
    // }

    // const triggerGITRemotePull = () => {
    //     dispatch(pullTemplateArchiveFromRemoteGITRepositoryAsync(remoteJobTemplateId)).then(() => {
    //         reloadEditorContent()
    //     })
    // }

    // const triggerGITRemotePush = () => {
    //     dispatch(pushTemplateArchiveFromRemoteGITRepositoryAsync(remoteJobTemplateId))
    // }

    const selectedNodeHandling = (nodeClicked: any) => {
        const id = nodeClicked.nodeData._id

        // show the edit icons
        nodeClicked.defaultOnClick()

        if (localTreeState) {
            // Do not change the file path or request file content for folders
            const clickedFile = getFileByInternalTreeId(id, localTreeState)
            if (Array.isArray(clickedFile?.children)) {
                return
            }
            const nodeFilePath = getFilePathByInternalTreeId('', id, localTreeState)

            if (remoteJobTemplateFileTree) {
                const file = getFileByPath("", nodeFilePath!, remoteJobTemplateFileTree)
                if (file) {
                    setFilePath(nodeFilePath!)
                    requestFileContent(nodeFilePath!)
                } else {
                    const localPath = getFilePathByInternalTreeId("", id, localTreeState)
                    if (localPath) {
                        setFilePath(localPath)
                    }
                    const localFileContent = localJobTemplateFileContents.filter(localJobTemplateFileContent => localJobTemplateFileContent.filePath === filePath).pop()?.fileContent
                    if (localFileContent) {
                        setFileContent(localFileContent)
                    }
                }
            }
        }
    }

    const getFileByPath = (currentPath: string, path: string, file: IRemoteJobTemplateFile): IRemoteJobTemplateFile | undefined => {
        let result = undefined
        currentPath += `/${file.name}`
        if (currentPath === path) {
            return file
        }
        for (let currentFile of (file.children ?? [])) {
            result = getFileByPath(currentPath, path, currentFile)
            if (result) {
                break
            }
        }
        return result
    }

    const getFilePathByInternalTreeId = (currentPath: string, nodeId: number, file: IRemoteJobTemplateFile): string | undefined => {
        let result = undefined
        currentPath += `/${file.name}`
        if (file._id === nodeId) {
            if (!file.children) {
                return currentPath
            }
        } else {
            for (let currentFile of (file.children ?? [])) {
                result = getFilePathByInternalTreeId(currentPath, nodeId, currentFile)
                if (result) {
                    break
                }
            }
        }
        return result
    }

    const getFileByInternalTreeId = (nodeId: number, file: IRemoteJobTemplateFile): IRemoteJobTemplateFile | undefined => {
        let result = undefined
        if (file._id === nodeId) {
            return file
        }
        for (let currentFile of (file.children ?? [])) {
            result = getFileByInternalTreeId(nodeId, currentFile)
            if (result) {
                break
            }
        }
        return result
    }

    const requestFileContent = (path: string) => {
        dispatch(requestFileContentAsync({
            filePath: path,
            remoteJobTemplateId: remoteJobTemplateId
        }))
    }

    const editorOnChange = (newValue: string | undefined) => {
        if (newValue !== undefined) {
            const currentJobTemplateFileContent = localJobTemplateFileContents.filter(localJobTemplateFileContent => {
                return localJobTemplateFileContent.filePath === filePath && localJobTemplateFileContent.remoteJobTemplateId === remoteJobTemplateId
            }).pop()
            if (currentJobTemplateFileContent) {
                const newJobTemplateFileContent: IRemoteJobTemplateFileContent = {
                    fileContent: newValue,
                    filePath: currentJobTemplateFileContent.filePath,
                    remoteJobTemplateId: currentJobTemplateFileContent.remoteJobTemplateId
                }
                setLocalJobTemplateFileContents(localJobTemplateFileContents => localJobTemplateFileContents.map(oldJobTemplateFileContent => {
                    if (oldJobTemplateFileContent.remoteJobTemplateId === remoteJobTemplateId && oldJobTemplateFileContent.filePath === filePath) {
                        return newJobTemplateFileContent
                    } else {
                        return oldJobTemplateFileContent
                    }
                }))
            } else {
                const newJobTemplateFileContent: IRemoteJobTemplateFileContent = {
                    fileContent: newValue,
                    filePath: filePath,
                    remoteJobTemplateId: remoteJobTemplateId
                }
                setLocalJobTemplateFileContents(localJobTemplateFileContents => localJobTemplateFileContents.concat(newJobTemplateFileContent))
            }
        }
    }

    const checkIfFileIsDeletable = (fileName: string): boolean => {
        return notDeleteableFileNamesInsideFileTree.indexOf(fileName) !== -1
    }

    const DeleteIcon = (onClickedData: any) => {
        const id = onClickedData.nodeData._id
        const nodeIsRootOrInRootDir = onClickedData.nodeData.path.length <= 1 ? true : false
        const name = onClickedData.nodeData.name

        const handleClick = () => {
            if (localTreeState) {
                const deletedPath = getFilePathByInternalTreeId('', id, localTreeState)

                setFilePath('')
                setLocalJobTemplateFileContents(localJobTemplateFileContents => localJobTemplateFileContents.filter(fileContent => fileContent.filePath !== deletedPath))
 
                // FIXME: just calling updateRemoteJobTemplate here will prevent deleting the file from the local file tree (file gets not even removed locally)
                // save the local state (this will delete the file on the backend)
                setTimeout(() => updateRemoteJobTemplate())
            }

            onClickedData.onClick()
        }

        return nodeIsRootOrInRootDir && (checkIfFileIsDeletable(name) || name === remoteJobTemplate?.name) ? null : (<AiOutlineDelete onClick={handleClick} />)
    }

    const FileIcon = (onClickedData: any) => {
        const handleClick = () => onClickedData.onClick()
        if (localTreeState) {
            const selectedId = getFileByPath('', filePath, localTreeState)?._id
            if (selectedId === onClickedData.nodeData._id) {
                return <AiOutlineFile color="#ffcd00" onClick={handleClick} />
            }
        }
        return <AiOutlineFile onClick={handleClick} />
    }

    const OKIcon = (onClickedData: any) => {
        const handleClick = () => {
            setEditedFileId(onClickedData.nodeData._id)
            onClickedData.onClick()
        }

        return <AiOutlineCheck onClick={handleClick} />
    }

    const EditIcon = (onClickedData: any) => {
        const nodeIsRootOrInRootDir = onClickedData.nodeData.path.length <= 1 ? true : false
        const name = onClickedData.nodeData.name

        return nodeIsRootOrInRootDir && (checkIfFileIsDeletable(name) || name === remoteJobTemplate?.name) ? null : (<AiOutlineEdit onClick={onClickedData.onClick} />)
    }

    const CancelIcon = (onClickedData: any) => {
        const nodeIsInRootDir = onClickedData.nodeData.path.length === 1 ? true : false
        const name = onClickedData.nodeData.name

        return nodeIsInRootDir && checkIfFileIsDeletable(name) ? null : (<AiOutlineClose onClick={onClickedData.onClick} />)
    }

    const createRemoteJobTemplate = () => {
        // create a new remote job template
        const newRemoteJobTemplate: ICreateRemoteJobTemplate = {
            name: remoteJobTemplateName,
            git_remote_url: selectedRemoteTestcaseForCreation.length > 0 ? selectedRemoteTestcaseForCreation : undefined,
            job_type: selectedJobTypeForTemplateCreation
        }
        dispatch(createRemoteJobTemplateAsync(newRemoteJobTemplate)).then((resultPromise) => {
            // open the widget for the created scan run
            // TODO: get rid of the string compare
            if (resultPromise.type.includes("rejected")) {
                return
            }
            const createdRemoteJobTemplate: IRemoteJobTemplate = resultPromise.payload
            dispatch(requestFileTreeAsync(createdRemoteJobTemplate.id))
            dispatch(addOrActivateRemoteJobTemplatesWidget(createdRemoteJobTemplate))
        })
    }

    const editRemoteJobTemplate = (jobTemplate: IRemoteJobTemplate) => {
        dispatch(requestFileTreeAsync(jobTemplate.id))
        dispatch(addOrActivateRemoteJobTemplatesWidget(jobTemplate))
    }
    
    const createTemplateCopy = (jobTemplate: IRemoteJobTemplate) => {
        dispatch(createTemplateCopyAsync(jobTemplate.id))
    }

    const updateRemoteJobTemplate = () => {
        // NOTE: the backend will delete all files that are not in the "updatedFiles" list
        //       (this means that if a file is available in localJobTemplateFileContents with an unchanged content and this function gets called,
        //        it will delete the file)
        logger.debug(`Updating remoteJobTemplate with ID ${remoteJobTemplateId}`)
        setHasBeenModifiedInBackend(false)
        if (localTreeState) {
            setTimeout(() => {
                const updatedFiles = localJobTemplateFileContents.filter(jobTemplateFileContent => {
                    const remoteFileContent = remoteJobTemplateFileContents.filter(remoteJobTemplateFileContent => {
                        return remoteJobTemplateFileContent.remoteJobTemplateId === remoteJobTemplateId && remoteJobTemplateFileContent.filePath === jobTemplateFileContent.filePath
                    }).pop()
                    if (remoteFileContent) {
                        // Check if file content changed
                        return jobTemplateFileContent.remoteJobTemplateId === remoteJobTemplateId && remoteFileContent.fileContent !== jobTemplateFileContent.fileContent
                    } else {
                        return jobTemplateFileContent.remoteJobTemplateId === remoteJobTemplateId
                    }
                })

                // updatedFilesWithUpdatedRootDirName and localTreeStateWithUpdatedRootDirName are used
                // if editor has unsaved changes and template gets renamed -> all occurrences of the old
                // template name have to be replaced by the new template name
                const updatedFilesWithUpdatedRootDirName = updatedFiles.map(updatedFile => {
                    const newLocalJobFileContent: IRemoteJobTemplateFileContent = {
                        fileContent: updatedFile.fileContent,
                        filePath: `/${remoteJobTemplate?.name}/`.concat((updatedFile.filePath.split("/").slice(2).join("/"))),
                        remoteJobTemplateId: updatedFile.remoteJobTemplateId
                    }
                    return newLocalJobFileContent
                })

                const localTreeStateWithUpdatedRootDirName = {
                    checked: localTreeState.checked,
                    children: localTreeState.children,
                    isOpen: localTreeState.isOpen,
                    name: remoteJobTemplate!.name,
                    _id: localTreeState._id
                }

                dispatch(updateTemplateAsync({
                    remoteJobTemplateId: remoteJobTemplateId,
                    updatedFileTree: localTreeStateWithUpdatedRootDirName,
                    updatedFiles: updatedFilesWithUpdatedRootDirName
                }))
                cleanAndReloadFileContentsAndFileTree()
            })
        }
    }

    const deleteRemoteJobTemplate = (jobTemplate: IRemoteJobTemplate) => {
        dispatch(deleteRemoteJobTemplateAsync(jobTemplate.id))
    }

    const getLanguageByFileEnding = (): string => {
        const fileExt = filePath.split('.').pop()
        if (fileExt === filePath || !fileExt) {
            return "plaintext"
        }
        return fileExtensionToLanguageMapping[fileExt] ? fileExtensionToLanguageMapping[fileExt] : "plaintext"
    }

    const getSelectedLanguage = (): string | undefined => {
        if (selectedEditorLanguage.includes("automatic")) {
            return getLanguageByFileEnding()
        } else {
            return selectedEditorLanguage
        }
    }

    const editorSelectOptions = supportedEditorLanguages.map((supportedLanguage) => {
        return (
            <MenuItem
                key={supportedLanguage}
                value={supportedLanguage}
            >
                {supportedLanguage}
            </MenuItem>
        )
    })

    const executeTestCase = () => {
        // find existing jobs for this template and target ecu (context)
        const remoteRunner = selectedTargetECU?.remote_runner!
        const existingRemoteJobs = remoteJobs.filter((remoteJob) => remoteJob.job_template === remoteJobTemplateId &&
                                                                    remoteJob.job_context === selectedTargetECU!.context &&
                                                                    remoteJob.runner === remoteRunner)
        const existingAndRunningJob = existingRemoteJobs.filter((remoteJob) => isRemoteJobRunning(remoteJob)).at(0)
        if (existingAndRunningJob) {
            // TODO: the "user message view" should have the ability to interact with the user
            //       (ask if the test should be restarted in this case)
            dispatch(showUserMessage({
                title: t("Testcase already running"),
                message: t("This testcase is already running on the selected target ecu.") +
                           ` (Job ID: ${existingAndRunningJob.id})`
            }))
            dispatch(addOrActivateTestCasesExecutionWidget(selectedTargetECU))
            return
        }
        if (existingRemoteJobs.length > 0) {
            // assume that the one created last is the "active" one
            const activeRemoteJob = existingRemoteJobs.sort((a: IRemoteJob, b: IRemoteJob): number => (utcToLocalDateTime(a.created_at)?.getTime() ?? 0) - (utcToLocalDateTime(b.created_at)?.getTime() ?? 0)).at(-1)
            const updatedRemoteJob: IUpdateRemoteJob = {
                id: activeRemoteJob!.id,
                data: {
                    state: 'CREATED_WAITING',   // the backend will set the state to "should run" (it ensures that there is never more than one testcase executed at the same time)
                    desired_state: 'RUNNING',
                    error_description: '',
                    finished_at: null
                }
            }
            dispatch(deleteGenericRemoteJobDataLines(activeRemoteJob!.id))
            dispatch(updateRemoteJobAsync(updatedRemoteJob)).then(() => {
                dispatch(addOrActivateTestCasesExecutionWidget(selectedTargetECU))
            })
        } else {
            const remoteJob: ICreateRemoteJob = {
                state: 'CREATED_WAITING',   // the backend will set the state to "should run" (it ensures that there is never more than one testcase executed at the same time)
                runner: remoteRunner,
                job_template: remoteJobTemplateId,
                job_context: selectedTargetECU!.context,
            }
            dispatch(createRemoteJobAsync(remoteJob)).then(() => {
                dispatch(addOrActivateTestCasesExecutionWidget(selectedTargetECU))
            })
        }
    }
    
    const hasAllNeededDataForCreatingTemplate = () => {
        // FIXME: the url check can't handle "git@git.intern.dissec.to:the-product/hydravison-testcases/isotpscan.git"
        //        (git@...)
        return remoteJobTemplateName.length > 0
        // (remoteJobTemplateGitRepoURL.length > 0 ? isValidURL(remoteJobTemplateGitRepoURL) : true)
    }

    const hasAllNeededDataForExecutingTestCase = () => {
        return selectedRemoteTargetECUId !== "" &&
               selectedTargetECURemoteRunnerExists
    }

    const showConfigHelp = () => {
        dispatch(showUserMessage({
            title: t('config.json'),
            message: t("The config.json file is responsible for defining the artifacts. ") +
                t("That means you can specify the patterns of the files which should be picked up ") +
                t("after the completion of the job. These Files will then be saved in the database ") +
                t("where they are stored as job artifacts. You can download them at the Generic Jobs view.")
        }))
    }

    const showTestCaseHelp = () => {
        dispatch(showUserMessage({
            title: t('test_case.py'),
            message: t("The test_case.py file contains the logic of the test case which shall be executed. ") +
                t("The test_case method can have all possible values which are defined in the TargetECU configurations as call parameters. ") +
                t("The return value of the method will be appended to the TargetECU as a new entry.")
        }))
    }

    const showRunAsMainHelp = () => {
        dispatch(showUserMessage({
            title: t('run.sh'),
            message: t("The run.sh is the entrance point for all remote jobs. ") +
                t("Here you can specify the commands and their environment so the jobs can run successful.")
        }))
    }

    const showFileTreeHelp = () => {
        dispatch(showUserMessage({
            title: t('File Tree'),
            message: t("The file tree can be used to add, update and delete files for the testcase execution. ") +
                t("Certain files ({{notDeleteableFileNames}}) can not be deleted. ", {notDeleteableFileNames: notDeleteableFileNamesInsideFileTree.join(", ")}) +
                t("Empty folders will not be available in the GIT version history of this template and are also not created on a probe during testcase / job execution.")
        }))
    }

    const handleFilesUpload = (files: FileList | null) => {
        if (files === null) {
            return
        }

        // save the current state
        updateRemoteJobTemplate()

        let formData = new FormData();
        Array.from(files).forEach(file => formData.append('files', file))

        authenticatedFetch(`${httpBackendURL}/api/remote_job_templates/${remoteJobTemplateId}/upload_files_to_template/`, "POST", undefined, formData).then(response => {
            if (response.status !== 200) {
                dispatch(showUserMessage({ title: t("File Upload failed"), message: t("The file could not be uploaded due to an unexpected error.") }))
                return
            }
            dispatch(deleteRemoteJobTemplateFileTree({ id: remoteJobTemplateId }))
            dispatch(requestFileTreeAsync(remoteJobTemplateId))

            dispatch(deleteRemoteJobTemplateFileContents({ id: remoteJobTemplateId }))
            setInitialLocalTreeState(undefined)
        })
    }

    const hasUnsavedChangesTemplateEditor = () => {
        return localJobTemplateFileContents.some(jobTemplateFileContent => {
            const remoteFileContent = remoteJobTemplateFileContents.filter(remoteJobTemplateFileContent => {
                return remoteJobTemplateFileContent.remoteJobTemplateId === remoteJobTemplateId && remoteJobTemplateFileContent.filePath === jobTemplateFileContent.filePath
            }).pop()
            // Check if file content changed
            if (remoteFileContent) {
                return jobTemplateFileContent.remoteJobTemplateId === remoteJobTemplateId && remoteFileContent.fileContent !== jobTemplateFileContent.fileContent
            // Check if new file
            } else {
                return jobTemplateFileContent.remoteJobTemplateId === remoteJobTemplateId
            }
        })
    }

    const getRemoteTestcasesDictForTopLevelEntry = (topLevelEntry: string): IRemoteTestcase[] => {
        try {
            return systemData.remote_testcases_dict[topLevelEntry] ?? []
        } catch {
            return []
        }
    }

    const showHydraCoreDocu = () => {
        dispatch(addOrActivateHydraCoreDocuWidget())
    }

    const remoteTestcaseSelectionOptions = getRemoteTestcasesDictForTopLevelEntry(remoteTestcasesMetadataAllTestcasesKey).map( (remoteTestcase) => {
        return (
            <MenuItem
                key={remoteTestcase.name}
                value={remoteTestcase.url}
            >
                <Tooltip title={remoteTestcase.description.length > 0 ? remoteTestcase.description : remoteTestcase.name} placement="right" key={`${remoteTestcase.name}-tooltip`}>
                    <div>{remoteTestcase.name}</div>
                </Tooltip>
            </MenuItem>
        )
    })

    const setSelectedRemoteTestcaseForCreationAndTemplateName = (value: string) => {
        const remoteTestcases = getRemoteTestcasesDictForTopLevelEntry(remoteTestcasesMetadataAllTestcasesKey)
        setSelectedRemoteTestcaseForCreation(value)
        const remoteTestcaseNames = remoteTestcases.map(remoteTestcase => remoteTestcase.name)
        if (remoteJobTemplateName.length === 0 || remoteTestcaseNames.filter(name => name === remoteJobTemplateName).length !== 0){
            const testcaseName = remoteTestcases.filter(remoteTestCase => remoteTestCase.url === value).pop()
            if (testcaseName) {
                setRemoteJobTemplateName(testcaseName.name)
            }
        }
    }

    return (
        <Container disableGutters sx={{ overflow: "hidden", overflowY: "auto", height: "90vh", marginLeft: -1 }}>
            {existingRemoteJobTemplate === undefined ?
                // Overview
                <Container id="remote-job-templates-container">
                    <Typography variant="h6">{t("Create a new testcase template")}</Typography>
                    <Container sx={{ marginBottom: 3, marginLeft: -4 }}>
                        <TextField
                            sx={{ margin: 1 }}
                            id="create-remote-job-template-name"
                            label={t("Template Name")}
                            value={remoteJobTemplateName}
                            onChange={(e) => setRemoteJobTemplateName(e.target.value)}
                        />

                        <br />

                        {/* <TextField
                            sx={{ margin: 1, minWidth: 500 }}
                            id="create-remote-job-template-git-repo-url"
                            label={t("Git Repo URL")}
                            type="url"
                            // color={isValidURL(remoteJobTemplateGitRepoURL) ? "primary" : "error"}
                            value={remoteJobTemplateGitRepoURL}
                            onChange={(e) => setRemoteJobTemplateGitRepoURL(e.target.value)}
                        /> */}
                        <FormControl sx={{ margin: 1, minWidth: 200 }}>
                            <InputLabel id="remote-job-template-remote-testcase-select-label">
                                {t("Remote Testcase")}
                            </InputLabel>
                            <Select
                                labelId="remote-job-template-remote-testcase-select-label"
                                id="remote-job-template-remote-testcase-select"
                                label={t("Remote Testcase")}
                                value={selectedRemoteTestcaseForCreation}
                                onChange={(e) => setSelectedRemoteTestcaseForCreationAndTemplateName(e.target.value)}
                            >
                                    <MenuItem 
                                        key="Disabled"
                                        value=""
                                    >
                                        <Tooltip title={t("Basic testcase template is used without prewritten testcase")} placement="right">
                                            <div>{t("Disabled")}</div>
                                        </Tooltip>

                                    </MenuItem>
                                {remoteTestcaseSelectionOptions}
                            </Select>
                        </FormControl>
                        <FormControl sx={{ marginTop: 2, minWidth: 50 }}>
                            <IconButton
                                    color="primary"
                                    onClick={() => dispatch(fetchSystemDataAsync())}
                                >
                                    <Tooltip title={t("Click to refresh available remote testcases")}>
                                        <RefreshIcon/>
                                    </Tooltip>
                            </IconButton>
                        </FormControl>
                        {/* <br />

                        <ConditionalFragment condition={expertMode}>
                            <FormControl sx={{ margin: 1, minWidth: 210 }}>
                                <InputLabel id="remote-job-template-job-type-select-label">
                                    {t("Type")}
                                </InputLabel>
                                <Select
                                    labelId="remote-job-template-job-type-select-label"
                                    id="remote-job-template-job-type-select"
                                    label={t("Type")}
                                    value={selectedJobTypeForTemplateCreation}
                                    onChange={(e) => setSelectedJobTypeForTemplateCreation(e.target.value as TRemoteJobType)}
                                >
                                    <MenuItem value={"TESTCASE"}>Testcase</MenuItem>
                                    <MenuItem value={"GENERIC"}>Generic</MenuItem>
                                </Select>
                            </FormControl>
                        </ConditionalFragment> */}

                        <br />

                        <Button
                            id="create-remote-job-template-button"
                            variant="contained"
                            sx={{ margin: 1 }}
                            disabled={!hasAllNeededDataForCreatingTemplate()}
                            onClick={createRemoteJobTemplate}
                        >
                            {t('Create')}
                        </Button>
                    </Container>
                    <Typography variant="h6">{t("Edit an existing template")}</Typography>
                    {getRemoteJobTemplatesAsTable(filteredAndSortedRemoteJobTemplates.slice(page * rowsPerPage, (page + 1) * rowsPerPage))}
                    <TablePagination
                        rowsPerPageOptions={[25, 50]}
                        component="div"
                        count={filteredAndSortedRemoteJobTemplates.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Container>
                :
                // Monaco editor and tree view
                <Container
                    id="remote-job-template-container"
                    sx={{ marginTop: 2 }}
                >
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignContent: 'flex-start'
                    }}
                    >
                        <Box sx={{
                            display: expertMode? 'flex' : 'none',
                            flexDirection: 'column',
                            alignContent: 'flex-start',
                            width: expertMode ? "20%" : "0%"
                        }}
                        >
                            {/* Note: we use display: 'none' if expertMode, so the FolderTree is not shown.
                            We need the initial onTreeStateChange so localTreeState gets set with the initial
                            values. Otherwise we can't update the template in expertMode */}
                            {initialLocalTreeState ?
                                <FolderTree
                                    data={initialLocalTreeState}
                                    showCheckbox={false}
                                    onChange={onTreeStateChange}
                                    onNameClick={selectedNodeHandling}
                                    iconComponents={{ DeleteIcon, OKIcon, FileIcon, EditIcon, CancelIcon }}
                                />
                                :
                                <div>{t('Loading directory tree')}</div>
                            }
                            <ConditionalFragment condition={expertMode}>
                                <IconButton sx={{}} size="small" onClick={showFileTreeHelp}>
                                                <QuestionMarkIcon color="primary" />
                                        </IconButton>
                                </ConditionalFragment>
                            <ConditionalFragment condition={((remoteJobTemplate?.git_remote_url ?? '').length !== 0 && expertMode)}>
                                <Box>
                                    {/* <ButtonWithDialog
                                        title={t("Do you really want to re-clone this archive?")}
                                        message={t("All local content will be overwritten with the remote Git repository content.")}
                                        disabled={false}
                                        buttonColor="primary"
                                        proceedButtonColor="error"
                                        cancelButtonColor="primary"
                                        proceedCallback={triggerGITRemoteRepoClone}
                                    >
                                        {t("Clone from remote Git repo")}
                                    </ButtonWithDialog> */}
                                    {/* <ButtonWithDialog
                                        title={t("Do you really want to push your local archive changes to the remote repository?")}
                                        message={t("All local changes will be pushed to the remote repository (if possible).")}
                                        disabled={false}
                                        buttonColor="primary"
                                        proceedButtonColor="error"
                                        cancelButtonColor="primary"
                                        proceedCallback={triggerGITRemotePush}
                                    >
                                        {t("Push to remote Git repo")}
                                    </ButtonWithDialog>
                                    <ButtonWithDialog
                                        title={t("Do you really want to pull changes from the remote repository?")}
                                        message={t("The local content will be updated with changes from the remote repository (if possible).")}
                                        disabled={false}
                                        buttonColor="primary"
                                        proceedButtonColor="error"
                                        cancelButtonColor="primary"
                                        proceedCallback={triggerGITRemotePull}
                                    >
                                        {t("Pull from remote Git repo")}
                                    </ButtonWithDialog> */}
                                    {remoteJobTemplate && (remoteJobTemplate.git_last_operation_error_text ?? '').length === 0 ?
                                        <></>
                                        :
                                        <Alert
                                            sx={{ margin: 1 }}
                                            severity={'error'}
                                        >
                                            {remoteJobTemplate?.git_last_operation_error_text ?? ''}
                                        </Alert>
                                    }
                                </Box>
                            </ConditionalFragment>
                            <ConditionalFragment condition={expertMode}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    id="remote-job-template-upload-file"
                                    component="label"
                                    sx={{ margin: 1 }}
                                >
                                    {t("Upload File(s)")}
                                    <Input
                                        type="file"
                                        hidden
                                        style={{ display: 'none' }}
                                        inputProps={{ multiple: true }}
                                        onChange={(e) => handleFilesUpload((e.target as HTMLInputElement).files)}
                                    />
                                </Button>
                            </ConditionalFragment>
                        </Box>
                        {filePath === '' ?
                            <div>{t("Click on a file to start editing")}</div>
                            :
                            <Box sx={{ width: expertMode ? "80%" : "100%" }}
                            >
                                <Stack alignItems={"center"} direction={"row"}>
                                    {/* Leave this Grid item element, otherwise the text in the tab is displayed weird */}
                                    <Grid item>
                                        <Tabs
                                            value={filePath}
                                            textColor="inherit"
                                            indicatorColor="primary"
                                            sx={{ textTransform: "None" }}
                                            id="remote-job-template-file-paths-tabs"
                                        >
                                            <Tab value={filePath} label={filePath} sx={{ textTransform: "None" }} />
                                        </Tabs>
                                    </Grid>
                                    <ConditionalFragment condition={hasBeenModifiedInBackend}>
                                        <Tooltip title={t("This testcase has been modified in the background. You can either save to overwrite the background changes or reload the editor to get them.")}>
                                            <ErrorOutlineIcon color="warning" sx={{ }} />
                                        </Tooltip>
                                    </ConditionalFragment>
                                    <Tooltip title={t("Save changes")}>
                                        <span>
                                            <IconButton
                                                sx={{ }}
                                                id="remote-job-template-save-template"
                                                disabled={!hasUnsavedChangesTemplateEditor()}
                                                onClick={() => updateRemoteJobTemplate()}
                                            >
                                                <SaveIcon color={hasUnsavedChangesTemplateEditor() ? "primary" : "disabled"} />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                    <Tooltip title={t("Reload testcase")}>
                                        <IconButton
                                            sx={{ }}
                                            id="remote-job-template-reload-template"
                                            onClick={() => cleanAndReloadFileContentsAndFileTree()}
                                        >
                                            <CachedIcon color="primary" />
                                        </IconButton>
                                    </Tooltip>
                                    
                                    {filePath === `/${remoteJobTemplate?.name}/run.sh` ?
                                        <IconButton sx={{ }} size="small" onClick={showRunAsMainHelp}>
                                            <QuestionMarkIcon color="primary" />
                                        </IconButton>
                                        :
                                        <></>
                                    }
                                    {filePath === `/${remoteJobTemplate?.name}/config.json` ?
                                        <IconButton sx={{ }} size="small" onClick={showConfigHelp}>
                                            <QuestionMarkIcon color="primary" />
                                        </IconButton>
                                        :
                                        <></>
                                    }
                                    {filePath === `/${remoteJobTemplate?.name}/test_case.py` ?
                                        <IconButton sx={{ }} size="small" onClick={showTestCaseHelp}>
                                            <QuestionMarkIcon color="primary" />
                                        </IconButton>
                                        :
                                        <></>
                                    }
                                    <Grid container sx={{ marginBottom: 1 }} alignItems="center" direction="row-reverse">
                                        <Grid item>
                                            <FormControl sx={{ justifyItems: "flex-end", margin: 1, minWidth: 200, size: "small", justifyContent: "flex-end" }}>
                                                <InputLabel id="remote-job-template-language-select-label">
                                                    {t("Language Mode")}
                                                </InputLabel>
                                                <Select
                                                    labelId="remote-job-template-language-select-label"
                                                    id="remote-job-template-language-select"
                                                    value={selectedEditorLanguage}
                                                    label={t("Select language mode for editor or let the app automatically decided the language depending on the file extension.")}
                                                    onChange={(e) => setSelectedEditorLanguage(e.target.value)}
                                                    size="small"
                                                >
                                                    <MenuItem
                                                        value="automatic"
                                                        key="automatic"
                                                    >
                                                        {`${t("automatic")} (${getLanguageByFileEnding()})`}
                                                    </MenuItem>
                                                    {editorSelectOptions}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <ConditionalFragment condition={remoteJobTemplate?.job_type === "TESTCASE"}>
                                            <ConditionalFragment condition={isRunningAs("HydraVision")}>
                                                <Grid item>
                                                    <Tooltip title={t("HydraCore Documentation")}>
                                                        <IconButton onClick={showHydraCoreDocu} color="inherit">
                                                            <ArticleIcon/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            </ConditionalFragment>
                                            <Grid item>
                                                <IconButtonWithImplicitLicenseCheck
                                                    id="editor-testcase-job-remote-runners-start-button"
                                                    sx={{ marginRight: 1 }}
                                                    size="large"
                                                    color="primary"
                                                    icon={(selectedTargetECU === undefined || selectedTargetECURemoteRunnerExists) ? PlayCircleIcon : ErrorOutlineIcon}
                                                    tooltip={(selectedTargetECU === undefined || selectedTargetECURemoteRunnerExists) ? t("Execute testcase on selected TargetECU") : t("The Probe associated with this Target ECU is either gone or currently not available")}
                                                    onClickCallback={executeTestCase}
                                                    disabledCondition={!hasAllNeededDataForExecutingTestCase()}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <FormControl sx={{ width: 150 }}>
                                                    <InputLabel id="remote-job-template-target-ecu-select-label">
                                                        {t("Target ECU")}
                                                    </InputLabel>
                                                    <Select
                                                        labelId="remote-job-template-target-ecu-select-label"
                                                        id="remote-job-template-target-ecu-select"
                                                        value={selectedRemoteTargetECUId}
                                                        label={t("Target ECU")}
                                                        onChange={(e) => setSelectedRemoteTargetECUId(e.target.value)}
                                                    >
                                                        {remoteTargetECUSelectionOptions}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </ConditionalFragment>
                                    </Grid>
                                </Stack>
                                {fileContent === null ?
                                    <div>{t("The selected file is a binary file which can not be edited.")}</div>
                                    :
                                    <Editor
                                        height="80vh"
                                        width="100%"
                                        value={fileContent}
                                        theme={settings.isDarkTheme ? 'vs-dark' : 'vs-light'}
                                        onChange={editorOnChange}
                                        language={getSelectedLanguage()}
                                    />
                                }
                            </Box>
                        }
                    </Box>
                </Container>
            }
        </Container>
    )
}

