import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { XTerm } from 'xterm-for-react'
import { FitAddon } from 'xterm-addon-fit'
import AutoSizer from 'react-virtualized-auto-sizer'
import './terminal.css'
import { logger } from '../../app/logging'

export const Terminal = (props: { id: string }) => {
    
    const terminalId = props.id ?? "TEST"
    const [connectRetryCounter, setConnectRetryCounter] = useState(0)
    const wsRef = useRef<WebSocket | null>(null)
    const xtermRef = useRef<XTerm | null>(null)

    const fitAddon = useMemo(() => new FitAddon(), [])
    let timer: ReturnType<typeof setTimeout> | undefined = undefined

    type TPacketType = 'D' | 'C'    // data / control
    const makeWSPacket = useCallback(() => (type: TPacketType, data: any): any => JSON.stringify({ T: type, P: data }), [])

    useEffect(() => {
        logger.debug('trying to (re)connect')
        wsRef.current = new WebSocket(`ws://localhost:8000/ws/terminal/${terminalId}/`)
        const wsCurrent = wsRef.current
        wsCurrent.onmessage = (m) => {
            xtermRef.current?.terminal.write(m.data)
        }
        wsCurrent.onopen = () => {
            logger.debug("connected")
            xtermRef.current?.terminal.reset()
            wsCurrent.send(makeWSPacket()('D', '\n'))
            fitAddon.fit()
        }
        wsCurrent.onclose = () => {
            logger.debug("connection closed")
            setTimeout(() => {
                setConnectRetryCounter((v) => v + 1)
            }, 5000)
        }
        return () => {
            wsCurrent.close()
        };
    }, [fitAddon, makeWSPacket, terminalId, connectRetryCounter]);

    return (
        <AutoSizer
            onResize={(o) => { 
                // logger.debug(o)
                if (timer !== undefined) {
                    clearTimeout(timer)               
                }
                timer = setTimeout(() => {
                    logger.debug("fitting terminal")
                    // logger.debug(fitAddon.proposeDimensions())
                    fitAddon.fit()
                    timer = undefined
                }, 100)
            }}
        >
        {({height, width}) => (
            <div style={{width: width, height: height}}>
                <XTerm
                    ref={xtermRef}
                    className='xtermParent'
                    onData={(d) => {wsRef.current?.send(makeWSPacket()('D', d))}}
                    options={{ fontSize: 12 }}
                    addons={[fitAddon]}
                    onResize={(o) => {
                        logger.debug("terminal size changed")
                        const wsCurrent = wsRef.current
                        if (wsCurrent?.readyState === 1) {
                            wsCurrent.send(makeWSPacket()('C', {cols: o.cols, rows: o.rows}))
                        }
                    }}
                />
            </div>
        )}
        </AutoSizer>
    )
}