import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
    createUDSScanRunAsync,
    deleteUDSScanRunAsync,
    IUDSScanRun,
    IUDSScanRunConfig,
    selectUDSScanRuns,
    TUDSScanRunDesiredState,
    TUDSScanRunState,
    updateUDSScanRunAsync
} from './UDSScanRunsSlice';
import { selectISOTPEndpoints } from '../isotp_endpoint/ISOTPEndpointsSlice';
import { addOrActivateUDSScanRunWidget } from '../main_lumino_widget/MainLuminoWidgetSlice';
import { selectHardwareInterfaces } from '../hardware_interface/HardwareInterfacesSlice';
import { ButtonWithDialog, ButtonWithImplicitLicenseCheck, DeleteElementButton, hasNecessaryRunnerDataForScan, getAvailableChannels, showNeedsProfessionalVersionMessage, showRemoteScanFailurePopup, useObserveAndDeleteWidget, generateRemoteRunnerSystemData, getLastUpdatedDateTimeForHwInterfaceDescriptions } from '../misc/Util';

import TreeItem from '@mui/lab/TreeItem';
import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Alert, { AlertColor } from '@mui/material/Alert';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import { Autocomplete, Box } from '@mui/material';
import { UDSScanRunLiveData } from '../live_data/LiveData';
import { LicenseWrapper, selectValidLicenses } from '../license/LicenseSlice';
import { Trans, useTranslation } from 'react-i18next'
import { selectActiveRemoteRunners, selectRemoteRunners } from '../remote_runner/RemoteRunnerSlice';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import { deleteUDSScanRunLiveDataLines, selectUDSScanRunLiveDataLines} from '../live_data/LiveDataSlice';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import RefreshIcon from '@mui/icons-material/Refresh';
import Typography from '@mui/material/Typography';  // Note: Enable/Disable Remote Runner here for release/development
import { selectRemoteJobTemplateQueryHWInterfaces } from '../remote_job_template/RemoteJobTemplateSlice';

import './UDSScanRun.css';
import { licenseFeatureDefaultValueMaxUDSScanTime, licenseFeatureKeyMaxUDSScanTime, licenseFeatureUnlimitedCountMarker, scannerTimeoutLogLineNormalScanRun, scannerTimeoutLogLineSmartScanRun } from '../misc/Constants';
import { logger } from '../../app/logging';
import { showUserMessage } from '../user_message/UserMessageSlice';

export const getReadableUDSEnumeratorNameFrom = (udsEnumerator: TUDSEnumerator): string => {
    switch (udsEnumerator) {
        case "UDS_CCEnumerator":
            return "CommunicationControl (0x28) Enumeration"
        case "UDS_DSCEnumerator":
            return "DiagnosticSessionControl (0x10) Enumeration"
        case "UDS_EREnumerator":
            return "ECUReset (0x11) Enumeration"
        case "UDS_IOCBIEnumerator":
            return "InputOutputControlByIdentifier (0x2F) Enumeration"
        case "UDS_RCEnumerator":
            return "RoutineControl (0x31) Enumeration"
        case "UDS_RCSelectiveEnumerator":
            return "Smart RoutineControl (0x31) Enumeration"
        case "UDS_RCStartEnumerator":
            return "RoutineControl (0x31) Enumeration - RoutineControlType 'startRoutine' only"
        case "UDS_RDBIEnumerator":
            return "ReadDataByIdentifier (0x22) Enumeration"
        case "UDS_RDBISelectiveEnumerator":
            return "Smart ReadDataByIdentifier (0x22) Enumeration"
        case "UDS_RDEnumerator":
            return "RequestDownload (0x34) Enumeration"
        case "UDS_RMBAEnumerator":
            return "Smart ReadMemoryByAddress (0x23) Enumeration"
        case "UDS_RMBARandomEnumerator":
            return "Random ReadMemoryByAddress (0x23) Enumeration"
        case "UDS_RMBASequentialEnumerator":
            return "Sequential ReadMemoryByAddress (0x23) Enumeration"
        case "UDS_RDBPIEnumerator":
            return "ReadDataByPeriodicIdentifier (0x2A) Enumeration"
        case "UDS_SA_XOR_Enumerator":
            return "SecurityAccess (0x27) Enumeration - XOR-Key probing"
        case "UDS_SAEnumerator":
            return "SecurityAccess (0x27) Enumeration"
        case "UdsSecurityAccessServerEnumerator":
            return "SecurityAccess (0x27) Enumeration - Query server for keys"
        case "UDS_ServiceEnumerator":
            return "Available Services Enumeration"
        case "UDS_TDEnumerator":
            return "TransferData (0x36) Enumeration"
        case "UDS_TPEnumerator":
            return "TesterPresent (0x3E) Enumeration"
        case "UDS_WDBISelectiveEnumerator":
            return "Smart WriteDataByIdentifier (0x2E) Enumeration"
        default:
            return udsEnumerator
    }
}

export const UDSScanRuns = () => {

    const udsScanRuns = useAppSelector(selectUDSScanRuns)

    const { t } = useTranslation()

    const udsScanRunTreeItems = udsScanRuns.map((scanRun) => {
        const treeNodeId = `UDSSCANRUN::${scanRun.id}`
        return (
            <TreeItem
                key={scanRun.id}
                nodeId={treeNodeId}
                label={scanRun.config.name}
            />
        )
    })

    return (
        <TreeItem nodeId="UDSSCANRUN" label={t("Start an UDS Scan")}>
            {udsScanRunTreeItems}
        </TreeItem>
    )
}

//
// UDS Enumerator Configuration
//

const UDSBaseEnumerator = (props: {
    config: IUDS_EnumeratorConfig
    updateConfig: (config: IUDS_EnumeratorConfig) => void
}) => {

    /*
    timeout?: number
    retry_if_none_received?: boolean
    exit_if_no_answer_received?: boolean
    exit_if_service_not_supported?: boolean
    exit_scan_on_first_negative_response?: boolean
    retry_if_busy_returncode?: boolean
    scan_range?: string
    */
    const { t } = useTranslation()

    useEffect(() => {
        // "mirror" the config back on first run
        // (init the config with default values if necessary, not pretty but this way we only need to have the default values in this component)
        props.updateConfig({...props.config,
            scan_range: props.config.scan_range ?? '',
            timeout: props.config.timeout ?? 0.1,
            retry_if_none_received: props.config.retry_if_none_received ?? false,
            exit_if_no_answer_received: props.config.exit_if_no_answer_received ?? false,
            exit_if_service_not_supported: props.config.exit_if_service_not_supported ?? false,
            exit_scan_on_first_negative_response: props.config.exit_scan_on_first_negative_response ?? false,
            retry_if_busy_returncode: props.config.retry_if_busy_returncode ?? false
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [scanRange, setScanRange] = useState(props.config.scan_range ?? '')
    const [timeout, setTimeout] = useState((props.config.timeout ?? 0.1).toString())

    return (
        <div>
            <Tooltip title={t("List of sub-functions to be scanned") as string}>
                <TextField
                    sx={{ marginRight: 1, marginTop: 1, marginBottom: 1 }}
                    id="uds-enumerator-scan-range"
                    label={t("Scan Range")}
                    //helperText="Range of identifiers to scan"
                    value={scanRange}
                    onChange={(e) => {
                        setScanRange(e.target.value)
                        props.updateConfig( {...props.config, scan_range: e.target.value } )
                    }}
                />
            </Tooltip>

            <Tooltip title={t("Time to wait for a response after a request was sent") as string}>
                <TextField
                    sx={{ marginRight: 1, marginTop: 1, marginBottom: 1 }}
                    id="uds-enumerator-timeout"
                    label={t("Timeout")}
                    helperText={t("Seconds")}
                    value={timeout}
                    type="number"
                    onChange={(e) => {
                        setTimeout(e.target.value)
                        const floatValue = parseFloat(e.target.value)
                        if (!isNaN(floatValue)) {
                            props.updateConfig( {...props.config, timeout: floatValue} )
                        }
                    }}
                />
            </Tooltip>
        </div>
    )
}

// NOTE: same as the "UDSBaseEnumerator" but encapsulated so that its appearance matches the other "complex" config pages
const UDSEnumerator = (props: {
    config: IUDS_EnumeratorConfig
    updateConfig: (config: IUDS_EnumeratorConfig) => void
}) => {
    return (
        <Container
            sx={{ border: 0.5, borderRadius: 1, padding: 1.5 }}
        >
            <UDSBaseEnumerator
                config={ props.config as IUDS_EnumeratorConfig }
                updateConfig={ props.updateConfig as (config: IUDS_EnumeratorConfig) => void }
            />
        </Container>
    )
}


const UDSEmptyConfigEnumerator = (props: {
    config: IUDS_EnumeratorConfig
    updateConfig: (config: IUDS_EnumeratorConfig) => void
}) => {
    return (
        <div></div>
    )
}


const UDSDSCEnumerator = (props: {
    config: IUDS_DSCEnumeratorConfig
    updateConfig: (config: IUDS_DSCEnumeratorConfig) => void
}) => {

    /*
    delay_state_change?: number
    overwrite_timeout?: boolean
    */

    const { t } = useTranslation()

    useEffect(() => {
        // "mirror" the config back on first run
        // (init the config with default values if necessary, not pretty but this way we only need to have the default values in this component)
        props.updateConfig({...props.config,
            delay_state_change: props.config.delay_state_change ?? 3,
            overwrite_timeout: props.config.overwrite_timeout ?? true
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Container
            sx={{ border: 0.5, borderRadius: 1, padding: 1.5 }}
        >
            <UDSBaseEnumerator
                config={ props.config as IUDS_EnumeratorConfig }
                updateConfig={ props.updateConfig as (config: IUDS_EnumeratorConfig) => void }
            />

            <br/>

            <Tooltip title={t("Delay for a session change") as string}>
                <TextField
                    sx={{ marginRight: 1, marginTop: 1, marginBottom: 1 }}
                    id="uds-dscenumerator-delay-state-change"
                    label={t("Session change delay")}
                    helperText={t("Seconds")}
                    value={props.config.delay_state_change ?? 3}
                    type="number"
                    onChange={(e) => props.updateConfig( {...props.config, delay_state_change: parseInt(e.target.value)} )}
                />
            </Tooltip>
        </Container>
    )
}


const UDSRCEnumerator = (props: {
    config: IUDS_RCEnumeratorConfig
    updateConfig: (config: IUDS_RCEnumeratorConfig) => void
}) => {

    /*
    type_list: Optional[List[int]]
    */

    const { t } = useTranslation()

    useEffect(() => {
        // "mirror" the config back on first run
        // (init the config with default values if necessary, not pretty but this way we only need to have the default values in this component)
        props.updateConfig({...props.config,
            type_list: props.config.type_list ?? [1, 2, 3]
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const makeTypeListStringFrom = (typeList: number[]): string => {
        return typeList.map(e => e.toString()).join(',')
    }

    const makeTypeListFrom = (typeListString: string): number[] => {
        return typeListString.split(',').map(e => parseInt(e)).filter(e => typeof e === 'number')
    }

    const [typeList, setTypeList] = useState(makeTypeListStringFrom(props.config.type_list ?? [1, 2, 3]))

    return (
        <Container
            sx={{ border: 0.5, borderRadius: 1, padding: 1.5 }}
        >
            <UDSBaseEnumerator
                config={ props.config as IUDS_EnumeratorConfig }
                updateConfig={ props.updateConfig as (config: IUDS_EnumeratorConfig) => void }
            />

            <br/>

            <Tooltip title={t("Comma separated list of RoutineControlTypes") as string}>
                <TextField
                    sx={{ marginRight: 1, marginTop: 1, marginBottom: 1 }}
                    id="uds-rc-enumerator-probe-start"
                    label={t("RoutineControlTypes List")}
                    helperText={t("1 = startRoutine, 2 = stopRoutine, 3 = requestRoutineResults")}
                    value={typeList}
                    onChange={(e) => {
                        setTypeList(e.target.value)
                        props.updateConfig( {...props.config, type_list: makeTypeListFrom(e.target.value)} )
                    }}
                />
            </Tooltip>
        </Container>
    )
}

const udsEnumerators = [
    'UDS_ServiceEnumerator',
    'UDS_DSCEnumerator',
    'UDS_RDBIEnumerator',
    'UDS_RDBISelectiveEnumerator',
    'UDS_WDBISelectiveEnumerator',
    'UDS_SAEnumerator',
    'UDS_SA_XOR_Enumerator',
    'UdsSecurityAccessServerEnumerator',
    'UDS_RCEnumerator',
    'UDS_RCStartEnumerator',
    'UDS_RCSelectiveEnumerator',
    'UDS_RMBAEnumerator',
    'UDS_RMBARandomEnumerator',
    'UDS_RMBASequentialEnumerator',
    'UDS_TPEnumerator',
    'UDS_EREnumerator',
    'UDS_IOCBIEnumerator',
    'UDS_CCEnumerator',
    'UDS_RDEnumerator',
    'UDS_TDEnumerator',
    'UDS_RDBPIEnumerator'
] as const

export type TUDSEnumerator = typeof udsEnumerators[number]

interface IUDS_EnumeratorConfig {
    timeout?: number
    retry_if_none_received?: boolean
    exit_if_no_answer_received?: boolean
    exit_if_service_not_supported?: boolean
    exit_scan_on_first_negative_response?: boolean
    retry_if_busy_returncode?: boolean
    scan_range?: string
}
interface IUDS_DSCEnumeratorConfig extends IUDS_EnumeratorConfig {
    delay_state_change?: number
    overwrite_timeout?: boolean
}
interface IUDS_TPEnumeratorConfig extends IUDS_EnumeratorConfig {}
interface IUDS_EREnumeratorConfig extends IUDS_EnumeratorConfig {}
interface IUDS_CCEnumeratorConfig extends IUDS_EnumeratorConfig {}
interface IUDS_RDBPIEnumeratorConfig extends IUDS_EnumeratorConfig {}
interface IUDS_ServiceEnumeratorConfig extends IUDS_EnumeratorConfig {}
interface IUDS_RDBIEnumeratorConfig extends IUDS_EnumeratorConfig {}
interface IUDS_RDBISelectiveEnumeratorConfig extends IUDS_EnumeratorConfig {}
interface IUDS_WDBIEnumeratorConfig extends IUDS_EnumeratorConfig {}
interface IUDS_WDBISelectiveEnumeratorConfig extends IUDS_EnumeratorConfig {}
interface IUDS_SAEnumeratorConfig extends IUDS_EnumeratorConfig {}
interface IUDS_SA_XOR_EnumeratorConfig extends IUDS_EnumeratorConfig {}
interface IUDS_SecurityAccessServerEnumeratorConfig extends IUDS_EnumeratorConfig {}
interface IUDS_RCEnumeratorConfig extends IUDS_EnumeratorConfig {
    type_list?: number[]
}
interface IUDS_RCStartEnumeratorConfig extends IUDS_EnumeratorConfig {}
interface IUDS_RCSelectiveEnumeratorConfig extends IUDS_EnumeratorConfig {}
interface IUDS_IOCBIEnumeratorConfig extends IUDS_EnumeratorConfig {}
interface IUDS_RMBAEnumeratorConfig extends IUDS_EnumeratorConfig {}
interface IUDS_RMBARandomEnumeratorConfig extends IUDS_EnumeratorConfig {}
interface IUDS_RMBASequentialEnumeratorConfig extends IUDS_EnumeratorConfig {}
interface IUDS_RDEnumeratorConfig extends IUDS_EnumeratorConfig {}
interface IUDS_TDEnumeratorConfig extends IUDS_EnumeratorConfig {}

interface IUDS_EnumeratorsConfig {
    UDS_DSCEnumeratorConfig?: IUDS_DSCEnumeratorConfig
    UDS_TPEnumeratorConfig?: IUDS_TPEnumeratorConfig
    UDS_EREnumeratorConfig?: IUDS_EREnumeratorConfig
    UDS_CCEnumeratorConfig?: IUDS_CCEnumeratorConfig
    UDS_RDBPIEnumeratorConfig?: IUDS_RDBPIEnumeratorConfig
    UDS_ServiceEnumeratorConfig?: IUDS_ServiceEnumeratorConfig
    UDS_RDBIEnumeratorConfig?: IUDS_RDBIEnumeratorConfig
    UDS_RDBISelectiveEnumeratorConfig?: IUDS_RDBISelectiveEnumeratorConfig
    UDS_WDBIEnumeratorConfig?: IUDS_WDBIEnumeratorConfig
    UDS_WDBISelectiveEnumeratorConfig?: IUDS_WDBISelectiveEnumeratorConfig
    UDS_SAEnumeratorConfig?: IUDS_SAEnumeratorConfig
    UDS_SA_XOR_EnumeratorConfig?: IUDS_SA_XOR_EnumeratorConfig
    UDS_SecurityAccessServerEnumeratorConfig?: IUDS_SecurityAccessServerEnumeratorConfig
    UDS_RCEnumeratorConfig?: IUDS_RCEnumeratorConfig
    UDS_RCStartEnumeratorConfig?: IUDS_RCStartEnumeratorConfig
    UDS_RCSelectiveEnumeratorConfig?: IUDS_RCSelectiveEnumeratorConfig
    UDS_IOCBIEnumeratorConfig?: IUDS_IOCBIEnumeratorConfig
    UDS_RMBAEnumeratorConfig?: IUDS_RMBAEnumeratorConfig
    UDS_RMBARandomEnumeratorConfig?: IUDS_RMBARandomEnumeratorConfig
    UDS_RMBASequentialEnumeratorConfig?: IUDS_RMBASequentialEnumeratorConfig
    UDS_RDEnumeratorConfig?: IUDS_RDEnumeratorConfig
    UDS_TDEnumeratorConfig?: IUDS_TDEnumeratorConfig
}

export const UDSScanRun = (props: any) => {

    const scanRunId = props.scanRunId

    const udsScanRuns = useAppSelector(selectUDSScanRuns)
    const existingScanRun: IUDSScanRun | undefined = udsScanRuns.filter( udsScanRun => udsScanRun.id === scanRunId ).pop()
    const remoteJobTemplateQueryHWInterfaces = useAppSelector(selectRemoteJobTemplateQueryHWInterfaces())

    const isotpEndpoints = useAppSelector(selectISOTPEndpoints)
    const hwInterfaces = useAppSelector(selectHardwareInterfaces)
    const licenses = useAppSelector(selectValidLicenses)
    const activeRemoteRunners = useAppSelector(selectActiveRemoteRunners)
    const remoteRunners = useAppSelector(selectRemoteRunners)
    
    const scanRunLiveDataLines = useAppSelector(selectUDSScanRunLiveDataLines(scanRunId))

    const [smartScan, setSmartScan] = useState(existingScanRun?.smart_scan ?? false)
    // TODO: check what we want here - add a sane / useable default or leave the field empty
    //      (most likely we only want this field pointing to our internal server for convenience reasons)
    const defaultSecurityAccessKeyGenerationServerUrl = ''
    const [securityAccessKeyGenerationServerUrl, setSecurityAccessKeyGenerationServerUrl] = useState(existingScanRun?.security_access_key_generation_server_url ?? defaultSecurityAccessKeyGenerationServerUrl)
    const [hwInterface, setHwInterface] = useState(existingScanRun?.hw_interface ?? '')     // hw_interface points to the name and not the id
    const [isotpEndpointId, setISOTPEndpointId] = useState(existingScanRun?.isotp_endpoint.toString() ?? '')
    const [name, setName] = useState<string | undefined>(existingScanRun?.config.name ?? undefined)
    const [scanTimeout, setScanTimeout] = useState((existingScanRun?.config.uds_scan_arguments.scan_timeout ?? 120).toString())
    const [timeout, setTimeout] = useState((existingScanRun?.config.uds_scan_arguments.timeout ?? 1).toString())
    const [bootDelay, setBootDelay] = useState((existingScanRun?.config.uds_scan_arguments.reset_boot_delay ?? 0.5).toString())
    // Note: Enable/Disable Remote Runner here for release/development
    const [runner, setRunner] = useState(existingScanRun?.remote_runner ?? 0) // 0 for local
    // const runner = 0
    const [channel, setChannel] = useState<string>(existingScanRun?.config.remote_scan_selected_channel ?? "")

    useEffect(() => {
        // check if we have to update the suggested name
        if (scanRunId !== undefined) {
            // this is an existing scan run
            // (we are not on the "start a new scan run" page)
            return
        }
        const matchRegex = /UDS Scan\s+(\d+)/
        const existingMaxCounter = Math.max(...udsScanRuns.map(scanRun => scanRun.config.name.match(matchRegex)).map(match => match === null ? 0 : parseInt(match[1])).concat(0))
        const curNameMatch = (name ?? '').match(matchRegex)
        if (name === undefined || (curNameMatch !== null && parseInt(curNameMatch[1]) === existingMaxCounter)) {
            // the widget was either just openend or the current name is already in use
            setName(`UDS Scan ${existingMaxCounter + 1}`)
        }
    }, [udsScanRuns, scanRunId, name])

    const { t } = useTranslation()

    // do not "allow" smart scan runs on a remote runner
    useEffect(() => {
        if (runner !== 0 && smartScan === true) {
            setSmartScan(false)
        }
    }, [runner, smartScan])

    useEffect(() => {
        const endpointId = parseInt(isotpEndpointId)
        if (!(isNaN(endpointId) || isotpEndpoints.map(isotpEndpoints => isotpEndpoints.id).includes(endpointId))) {
            setISOTPEndpointId("")
        }
    }, [isotpEndpoints, isotpEndpointId])
    
    useEffect(() => {
        const hwInterfaceId = parseInt(hwInterface)
        if (!(isNaN(hwInterfaceId) || hwInterfaces.map(hwInterface => hwInterface.id).includes(hwInterfaceId))) {
            setHwInterface("")
        }
    }, [hwInterfaces, hwInterface])
    
    const getEnabledUDSEnumeratorsFrom = (scanRun: IUDSScanRun | undefined): [TUDSEnumerator] | [] => {
        return (scanRun?.config.uds_scan_arguments.test_cases ?? []).map( (testCase: TUDSEnumerator) => {
            return udsEnumerators.includes(testCase) ? testCase : undefined
        }).filter( (testCase: TUDSEnumerator | undefined) => testCase !== undefined )
    }
    const [enabledUDSEnumerators, setEnabledUDSEnumerators] = useState<TUDSEnumerator[]>(getEnabledUDSEnumeratorsFrom(existingScanRun))

    const getUDSEnumeratorsConfigFrom = (scanRun: IUDSScanRun | undefined): IUDS_EnumeratorsConfig => {
        return {
            UDS_DSCEnumeratorConfig: scanRun?.config.uds_scan_arguments.UDS_DSCEnumerator_kwargs,
            UDS_TPEnumeratorConfig: scanRun?.config.uds_scan_arguments.UDS_TPEnumerator_kwargs,
            UDS_EREnumeratorConfig: scanRun?.config.uds_scan_arguments.UDS_EREnumerator_kwargs,
            UDS_CCEnumeratorConfig: scanRun?.config.uds_scan_arguments.UDS_CCEnumerator_kwargs,
            UDS_RDBPIEnumeratorConfig: scanRun?.config.uds_scan_arguments.UDS_RDBPIEnumerator_kwargs,
            UDS_ServiceEnumeratorConfig: scanRun?.config.uds_scan_arguments.UDS_ServiceEnumerator_kwargs,
            UDS_RDBIEnumeratorConfig: scanRun?.config.uds_scan_arguments.UDS_RDBIEnumerator_kwargs,
            UDS_RDBISelectiveEnumeratorConfig: scanRun?.config.uds_scan_arguments.UDS_RDBISelectiveEnumerator_kwargs,
            UDS_WDBIEnumeratorConfig: scanRun?.config.uds_scan_arguments.UDS_WDBIEnumerator_kwargs,
            UDS_WDBISelectiveEnumeratorConfig: scanRun?.config.uds_scan_arguments.UDS_WDBISelectiveEnumerator_kwargs,
            UDS_SAEnumeratorConfig: scanRun?.config.uds_scan_arguments.UDS_SAEnumerator_kwargs,
            UDS_SA_XOR_EnumeratorConfig: scanRun?.config.uds_scan_arguments.UDS_SA_XOR_Enumerator_kwargs,
            UDS_SecurityAccessServerEnumeratorConfig: scanRun?.config.uds_scan_arguments.UdsSecurityAccessServerEnumerator_kwargs,
            UDS_RCEnumeratorConfig: scanRun?.config.uds_scan_arguments.UDS_RCEnumerator_kwargs,
            UDS_RCStartEnumeratorConfig: scanRun?.config.uds_scan_arguments.UDS_RCStartEnumerator_kwargs,
            UDS_RCSelectiveEnumeratorConfig: scanRun?.config.uds_scan_arguments.UDS_RCSelectiveEnumerator_kwargs,
            UDS_IOCBIEnumeratorConfig: scanRun?.config.uds_scan_arguments.UDS_IOCBIEnumerator_kwargs,
            UDS_RMBAEnumeratorConfig: scanRun?.config.uds_scan_arguments.UDS_RMBAEnumerator_kwargs,
            UDS_RMBARandomEnumeratorConfig: scanRun?.config.uds_scan_arguments.UDS_RMBARandomEnumerator_kwargs,
            UDS_RMBASequentialEnumeratorConfig: scanRun?.config.uds_scan_arguments.UDS_RMBASequentialEnumerator_kwargs,
            UDS_RDEnumeratorConfig: scanRun?.config.uds_scan_arguments.UDS_RDEnumerator_kwargs,
            UDS_TDEnumeratorConfig: scanRun?.config.uds_scan_arguments.UDS_TDEnumerator_kwargs
        }
    }
    const [udsEnumeratorsConfig, setUDSEnumeratorsConfig] = useState<IUDS_EnumeratorsConfig>(getUDSEnumeratorsConfigFrom(existingScanRun))

    const checkAndSetUDSScanTime = ((licenses: LicenseWrapper[], udsScanTime: string) => {
        let highestAllowedUDSScanTime: number = licenseFeatureDefaultValueMaxUDSScanTime
        for(let license of licenses) {
            const currentValue = (license.license.license_config.Features as any)[licenseFeatureKeyMaxUDSScanTime]
            if (currentValue === licenseFeatureUnlimitedCountMarker || parseInt(currentValue) === licenseFeatureUnlimitedCountMarker) {
                highestAllowedUDSScanTime = licenseFeatureUnlimitedCountMarker
                break
            }

            if (currentValue > highestAllowedUDSScanTime || parseInt(currentValue) > highestAllowedUDSScanTime) {
                highestAllowedUDSScanTime = parseInt((license.license.license_config.Features as any)[licenseFeatureKeyMaxUDSScanTime])
            }
        }
        
        if (highestAllowedUDSScanTime === licenseFeatureUnlimitedCountMarker || parseInt(udsScanTime) <= highestAllowedUDSScanTime) {
            setScanTimeout(udsScanTime)
        }
        else {
            showNeedsProfessionalVersionMessage(`${t("The value for the UDS scan duration will be set to the current max allowed value")} ${highestAllowedUDSScanTime}. ${t("Go to the licenses view to get an overview of the different options for increasing the scan duration.")}`)
            setScanTimeout(highestAllowedUDSScanTime.toString())
        } 
    }) 

    const dispatch = useAppDispatch()
    useObserveAndDeleteWidget("UDSSCANRUN", udsScanRuns)

    const hwInterfaceSelectionLabel = t("Hardware Interface")
    const hwInterfaceSelectionOptions = hwInterfaces.map( (hwInterface) => {
        return (
            <MenuItem
                key={hwInterface.id}
                value={hwInterface.name}
            >
                {hwInterface.name}
            </MenuItem>
        )
    })

    // Note: Enable/Disable Remote Runner here for release/development
    const runnerSelectionOptions = remoteRunners.map( (remoteRunner) => {
        const isActiveRunner = activeRemoteRunners.filter(activeRemoteRunner => activeRemoteRunner.id === remoteRunner.id).length > 0

        return (
            <MenuItem
                key={remoteRunner.id}
                value={remoteRunner.id}
            >
                {isActiveRunner ? 
                    remoteRunner.name
                :
                    <Typography color={"error"}>{remoteRunner.name}</Typography>
                }
            </MenuItem>
        )
    })

    const isotpEndpointSelectionLabel = "ISOTP Endpoint"
    const isotpEndpointSelectionOptions = isotpEndpoints.map( (isotpEndpoint) => {
        return (
            <MenuItem
                key={isotpEndpoint.id}
                value={isotpEndpoint.id}
            >
                {isotpEndpoint.name}
            </MenuItem>
        )
    })

    const hasAllNeededData = () => {
        let noOrValidSecurityAccessServerUrl = false
        try {
            if (securityAccessKeyGenerationServerUrl.length > 0) {
                new URL(securityAccessKeyGenerationServerUrl)
            }
            noOrValidSecurityAccessServerUrl = true
        } catch {}
        return (name ?? '').length > 0 &&
               hasNecessaryRunnerDataForScan(runner, hwInterface, channel, activeRemoteRunners) &&             
               isotpEndpointId.toString().length > 0 &&   // TODO: find out why "isotpEndpointId.length" is undefined without the ".toString()" (javascript fail?)
               (enabledUDSEnumerators.length > 0 || smartScan) &&
               scanTimeout.length > 0 &&
               timeout.length > 0 &&
               bootDelay >= 0 &&
               noOrValidSecurityAccessServerUrl
    }

    const makeScanConfigFromCurrentValues = () : IUDSScanRunConfig => {
        const config: IUDSScanRunConfig = {
            name: (name ?? ''),
            uds_scan_arguments: {
                scan_timeout: parseInt(scanTimeout),
                timeout: parseFloat(timeout),
                test_cases: enabledUDSEnumerators,
                reset_boot_delay: parseFloat(bootDelay),
                UDS_ServiceEnumerator_kwargs: enabledUDSEnumerators.includes('UDS_ServiceEnumerator') ? udsEnumeratorsConfig.UDS_ServiceEnumeratorConfig : undefined,
                UDS_TPEnumerator_kwargs: enabledUDSEnumerators.includes('UDS_TPEnumerator') ? udsEnumeratorsConfig.UDS_TPEnumeratorConfig : undefined,
                UDS_DSCEnumerator_kwargs: enabledUDSEnumerators.includes('UDS_DSCEnumerator') ? udsEnumeratorsConfig.UDS_DSCEnumeratorConfig : undefined,
                UDS_RDBIEnumerator_kwargs: enabledUDSEnumerators.includes('UDS_RDBIEnumerator') ? udsEnumeratorsConfig.UDS_RDBIEnumeratorConfig : undefined,
                UDS_RDBISelectiveEnumerator_kwargs: enabledUDSEnumerators.includes('UDS_RDBISelectiveEnumerator') ? udsEnumeratorsConfig.UDS_RDBISelectiveEnumeratorConfig : undefined,
                UDS_SAEnumerator_kwargs: enabledUDSEnumerators.includes('UDS_SAEnumerator') ? udsEnumeratorsConfig.UDS_SAEnumeratorConfig : undefined,
                UDS_SA_XOR_Enumerator_kwargs: enabledUDSEnumerators.includes('UDS_SA_XOR_Enumerator') ? udsEnumeratorsConfig.UDS_SA_XOR_EnumeratorConfig : undefined,
                UDS_RCStartEnumerator_kwargs: enabledUDSEnumerators.includes('UDS_RCStartEnumerator') ? udsEnumeratorsConfig.UDS_RCStartEnumeratorConfig : undefined,
                UDS_RCEnumerator_kwargs: enabledUDSEnumerators.includes('UDS_RCEnumerator') ? udsEnumeratorsConfig.UDS_RCEnumeratorConfig : undefined,
                UDS_RCSelectiveEnumerator_kwargs: enabledUDSEnumerators.includes('UDS_RCSelectiveEnumerator') ? udsEnumeratorsConfig.UDS_RCSelectiveEnumeratorConfig : undefined,
                UDS_RMBAEnumerator_kwargs: enabledUDSEnumerators.includes('UDS_RMBAEnumerator') ? udsEnumeratorsConfig.UDS_RMBAEnumeratorConfig : undefined,
                UDS_RMBARandomEnumerator_kwargs: enabledUDSEnumerators.includes('UDS_RMBARandomEnumerator') ? udsEnumeratorsConfig.UDS_RMBARandomEnumeratorConfig : undefined,
                UDS_RMBASequentialEnumerator_kwargs: enabledUDSEnumerators.includes('UDS_RMBASequentialEnumerator') ? udsEnumeratorsConfig.UDS_RMBASequentialEnumeratorConfig : undefined,
                UDS_WDBISelectiveEnumerator_kwargs: enabledUDSEnumerators.includes('UDS_WDBISelectiveEnumerator') ? udsEnumeratorsConfig.UDS_WDBISelectiveEnumeratorConfig : undefined,
                UDS_EREnumerator_kwargs: enabledUDSEnumerators.includes('UDS_EREnumerator') ? udsEnumeratorsConfig.UDS_EREnumeratorConfig : undefined,
                UDS_CCEnumerator_kwargs: enabledUDSEnumerators.includes('UDS_CCEnumerator') ? udsEnumeratorsConfig.UDS_CCEnumeratorConfig : undefined,
                UDS_RDBPIEnumerator_kwargs: enabledUDSEnumerators.includes('UDS_RDBPIEnumerator') ? udsEnumeratorsConfig.UDS_RDBPIEnumeratorConfig : undefined,
                UDS_IOCBIEnumerator_kwargs: enabledUDSEnumerators.includes('UDS_IOCBIEnumerator') ? udsEnumeratorsConfig.UDS_IOCBIEnumeratorConfig : undefined,
                UDS_RDEnumerator_kwargs: enabledUDSEnumerators.includes('UDS_RDEnumerator') ? udsEnumeratorsConfig.UDS_RDEnumeratorConfig : undefined,
                UDS_TDEnumerator_kwargs: enabledUDSEnumerators.includes('UDS_TDEnumerator') ? udsEnumeratorsConfig.UDS_TDEnumeratorConfig : undefined
            },
            remote_scan_selected_channel: runner !== 0 ? channel : undefined
        }
        return config
    }

    const startScanRun = () => {
        if (!hasAllNeededData()) {
            return
        }
        if (isPendingScanRun(existingScanRun)) {
            logger.debug('there is already an uds scan running for this hw interface and isotp endpoint')
            return
        }

        dispatch(deleteUDSScanRunLiveDataLines(scanRunId))

        logger.debug(`starting uds scan on hardware interface "${hwInterface}" and isotp endpoint "${isotpEndpointId}"`)

        const config = makeScanConfigFromCurrentValues()
        
        const getCreateUdsScanRunConfig = () => {
            if (runner === 0) {
                // local
                return {
                    hw_interface: hwInterface!,
                    remote_runner: null,
                    isotp_endpoint: parseInt(isotpEndpointId!),
                    config: config,
                    smart_scan: smartScan,
                    security_access_key_generation_server_url: securityAccessKeyGenerationServerUrl
                }
            } else {
                // remote
                const remoteRunnerId = activeRemoteRunners.filter((remoteRunner) => {
                    return remoteRunner.id === runner
                })[0].id
                return {
                    hw_interface: null,
                    remote_runner: remoteRunnerId,
                    isotp_endpoint: parseInt(isotpEndpointId!),
                    config: config,
                    smart_scan: smartScan,
                    security_access_key_generation_server_url: securityAccessKeyGenerationServerUrl
                }
            }
        }

        const getUpdateUdsScanRunConfig = () => {
            if (runner === 0) {
                // local
                return {
                    id: scanRunId,
                    data: {
                        hw_interface: hwInterface!,
                        remote_runner: null,
                        isotp_endpoint: parseInt(isotpEndpointId!),
                        error_description: '',
                        desired_state: 'RUNNING' as TUDSScanRunDesiredState,
                        state: 'CREATED' as TUDSScanRunState,
                        finished_at: null,
                        config: config,
                        smart_scan: smartScan,
                        security_access_key_generation_server_url: securityAccessKeyGenerationServerUrl
                    }
                }
            } else {
                // remote
                const remoteRunnerId = activeRemoteRunners.filter((remoteRunner) => {
                    return remoteRunner.id === runner
                })[0].id
                return {
                    id: scanRunId,
                    data: {
                        hw_interface: null,
                        remote_runner: remoteRunnerId,
                        isotp_endpoint: parseInt(isotpEndpointId!),
                        error_description: '',
                        desired_state: 'RUNNING' as TUDSScanRunDesiredState,
                        state: 'CREATED' as TUDSScanRunState,
                        finished_at: null,
                        config: config,
                        smart_scan: smartScan,
                        security_access_key_generation_server_url: securityAccessKeyGenerationServerUrl
                    }
                }
            }
        }
        if (existingScanRun === undefined) {
            // create a new scan run
            dispatch(createUDSScanRunAsync(getCreateUdsScanRunConfig())).then((resultPromise) => {
                // open the widget for the created scan run
                const createdScanRun: IUDSScanRun = resultPromise.payload
                dispatch(addOrActivateUDSScanRunWidget(createdScanRun))
            })
        } else {
            // just update the state of the existing scan run
            dispatch(updateUDSScanRunAsync(getUpdateUdsScanRunConfig()))
        }
    }

    const isPendingScanRun = (scanRun: IUDSScanRun | undefined) => {
        return ['CREATED', 'RUNNING', 'PAUSED'].includes((scanRun?.state ?? ''))
    }

    const hasScanRunFinishedDueToTimeout = () => {
        return scanRunLiveDataLines.some(line => {
            // Smart scan
            if (line.includes(scannerTimeoutLogLineSmartScanRun)) {
                return true
            }

            // Normal scan
            if (line.includes(scannerTimeoutLogLineNormalScanRun)) {
                return true
            }

            return false
        })
    }

    const getReadableState = (scanRun: IUDSScanRun | undefined): [AlertColor, string] => {
        switch (scanRun?.state) {
            case 'CREATED':
                return ['info', t('The scan is scheduled and about to be started')]
            case 'RUNNING':
                return ['info', t('The scan is currently running')]
            case 'PAUSED':
                return ['info', t('The scan is currently paused')]
            case 'FINISHED_ERROR':
                return ['error', `${t("The last scan generated an error")} - (${scanRun!.error_description})`]
            case 'FINISHED_SUCCESS':
                if (scanRun!.scan_was_aborted) {
                    return ['success', t('The last scan finished successfully but with a partial result because it was stopped by the user')]
                } else {
                    return ['success', t('The last scan finished successfully')]
                }
            default:
                return ['warning', t('The scan is in an unknown state')]
        }
    }

    const deleteScanRun = () => {
        if (existingScanRun === undefined) {
            return
        }
        dispatch(deleteUDSScanRunAsync(existingScanRun.id))
    }

    const updateScanRunDesiredStateWith = (desiredState: TUDSScanRunDesiredState) => {
        if (existingScanRun === undefined || !isPendingScanRun(existingScanRun)) {
            return
        }
        dispatch(updateUDSScanRunAsync({
                id: scanRunId,
                data: {
                    config: makeScanConfigFromCurrentValues(),     // FIXME: an empty config is currently rejected by the backend ...
                    desired_state: desiredState
                }
        }))
    }

    const stopScanRun = () => {
        return updateScanRunDesiredStateWith("FINISHED")
    }

    const pauseScanRun = () => {
        return updateScanRunDesiredStateWith("PAUSED")
    }

    const continueScanRun = () => {
        return updateScanRunDesiredStateWith("RUNNING")
    }

    const getReadableUDSEnumeratorDescriptionFrom = (udsEnumerator: TUDSEnumerator): string => {
        switch (udsEnumerator) {
            case "UDS_CCEnumerator":
                return t("The CommunicationControl enumerator tests if the Communication Control service is supported on the target system.")
            case "UDS_DSCEnumerator":
                return t("The DiagnosticSessionControl enumerator enables the capability to change diagnostic sessions on the target system, depending on the security level. This enumerator plays a vital role in accessing different diagnostic modes for comprehensive scanning and testing. This enumerator is not safe to use on a full vehicle scan.")
            case "UDS_EREnumerator":
                return t("The ECUReset enumerator verifies whether the ECU Reset service is supported on the target system.")
            case "UDS_IOCBIEnumerator":
                return t("The InputOutputControlByIdentifier enumerator gathers available Input Output Controls based on their identifiers and provides information about negative responses per state. This enumerator is not safe to use on a full vehicle scan.")
            case "UDS_RCEnumerator":
                return t("The RoutineControl enumerator enables users to identify control routines on the target system. Users can scan for start, stop, and retrieve results of routines based on the configuration. This enumerator is not safe to use on a full vehicle scan.")
            case "UDS_RCSelectiveEnumerator":
                return t("The Smart RoutineControl enumerator only scans for stop routines and retrieve results routines on the target system. Therefore it is safe to use on full vehicle scans.")
            case "UDS_RCStartEnumerator":
                return t("The RoutineControl enumerator, with the ‘startRoutine’ type, is specifically designed to start routines on the target system. This enumerator is not safe to use on a full vehicle scan.")
            case "UDS_RDBIEnumerator":
                return t("The ReadDataByIdentifier enumerator is designed to read data from the target system based on specific identifiers within a given scan range. It allows retrieving important data points that can provide insights into the system’s configuration and functionality.")
            case "UDS_RDBISelectiveEnumerator":
                return t("The Smart ReadDataByIdentifier enumerator is an intelligent variant of the ReadDataByIdentifier enumerator. It utilizes a more sophisticated approach to optimize data retrieval based on identifier information and scan range.")
            case "UDS_RDEnumerator":
                return t("The RequestDownload enumerator tests if the Request Download service is supported on the target system.")
            case "UDS_RMBAEnumerator":
                return t("The Smart ReadMemoryByAddress enumerator provides a sophisticated method to probe for the read memory addresses service on the target system by combining the Random ReadMemoryByAddress Enumeration followed by the Sequential ReadMemoryByAddress Enumeration.")
            case "UDS_RMBARandomEnumerator":
                return t("The Random ReadMemoryByAddress enumerator randomly reads memory addresses on the target system, contributing to a more diverse data collection.")
            case "UDS_RMBASequentialEnumerator":
                return t("The Smart ReadMemoryByAddress enumerator provides a sophisticated method to probe for the read memory addresses service on the target system by combining the Random ReadMemoryByAddress Enumeration followed by the Sequential ReadMemoryByAddress Enumeration.")
            case "UDS_RDBPIEnumerator":
                return t("The ReadDataByPeriodicIdentifier enumerator tests if the Read Data By Periodic Identifier service is supported on the target system.")
            case "UDS_SA_XOR_Enumerator":
                return t("The XOR-Key Probing variant of the SecurityAccess enumerator attempts to access available security levels by using an XOR-based method to generate the key from the seed.")
            case "UDS_SAEnumerator":
                return t("The SecurityAccess enumerator is used to discover available security seeds with access type and state on the target system. This information is essential for understanding the security mechanisms in place and gaining appropriate access to the system.")
            case "UdsSecurityAccessServerEnumerator":
                return t("The Query Server for Keys variant of the SecurityAccess enumerator attempts to access available security levels by using an external security access server. This method provides an alternative approach to handle security challenges.")
            case "UDS_ServiceEnumerator":
                return t("The Available Services Enumeration enumerator fetches information about the UDS services supported by the target system. It identifies both available services and negative responses for services that are not supported.")
            case "UDS_TDEnumerator":
                return t("The TransferData enumerator tests if the Transfer Data service is supported on the target system.")
            case "UDS_TPEnumerator":
                return t("The TesterPresent enumerator verifies whether the Tester Present service is supported on the target system.")
            case "UDS_WDBISelectiveEnumerator":
                return t("The Smart WriteDataByIdentifier enumerator is responsible for using the Write Data By Identifier services on the target system. It follows a two-step process, first reading the value of the identifier and then writing the same value to the identifier. This prevents undesired modification of the system.")
            default:
                return udsEnumerator
        }
    }
    
    const showEnumeratorDescriptionUserMessage = (enumeratorName: TUDSEnumerator | string) => {
        if (enumeratorName === "SmartScanRun"){
            dispatch(showUserMessage({
                title: "Smart Scan Run",
                message: t("The Smart Scan Run enumerator combines various other enumerators to gather the most general information possible about the target system. It leverages the information collected by other enumerators to provide a holistic view of the system. This enumerator is not safe to use on a full vehicle scan.")
            }))         
        } 
        else {
            dispatch(showUserMessage({
                title: getReadableUDSEnumeratorNameFrom(enumeratorName as TUDSEnumerator),
                message: getReadableUDSEnumeratorDescriptionFrom(enumeratorName as TUDSEnumerator)
            }))
        }
    }

    const enumeratorsConfig = udsEnumerators.map( (udsEnumerator) => {
        // TODO: think about a more generic handling of all the enumerators that just have a default config
        //       (this would trade a lot of copy&pasta text to, most likely, ugly and cryptic javascript code ...)
        let enumeratorConfig: JSX.Element
        switch (udsEnumerator) {
            case 'UDS_DSCEnumerator':
                enumeratorConfig = (
                    <UDSDSCEnumerator
                        config={udsEnumeratorsConfig.UDS_DSCEnumeratorConfig ?? {scan_range: "0x1-0xff", delay_state_change: 3, timeout: 2, overwrite_timeout: true}}
                        updateConfig={(enumConfig) => setUDSEnumeratorsConfig((allEnumsConfig) => { return {...allEnumsConfig, UDS_DSCEnumeratorConfig: enumConfig }} )}
                    />
                )
                break
            case 'UDS_TPEnumerator':
                enumeratorConfig = (
                    <UDSEnumerator
                        config={udsEnumeratorsConfig.UDS_TPEnumeratorConfig ?? {scan_range: "0x0,0x80", timeout: 1}}
                        updateConfig={(enumConfig) => setUDSEnumeratorsConfig((allEnumsConfig) => { return {...allEnumsConfig, UDS_TPEnumeratorConfig: enumConfig }} )}
                    />
                )
                break
            case 'UDS_EREnumerator':
                enumeratorConfig = (
                    <UDSEnumerator
                        config={udsEnumeratorsConfig.UDS_EREnumeratorConfig ?? {scan_range: "0x0-0x7f", timeout: 1}}
                        updateConfig={(enumConfig) => setUDSEnumeratorsConfig((allEnumsConfig) => { return {...allEnumsConfig, UDS_EREnumeratorConfig: enumConfig }} )}
                    />
                )
                break
            case 'UDS_CCEnumerator':
                enumeratorConfig = (
                    <UDSEnumerator
                        config={udsEnumeratorsConfig.UDS_CCEnumeratorConfig ?? {scan_range: "0x0-0x7f", timeout: 1}}
                        updateConfig={(enumConfig) => setUDSEnumeratorsConfig((allEnumsConfig) => { return {...allEnumsConfig, UDS_CCEnumeratorConfig: enumConfig }} )}
                    />
                )
                break
            case 'UDS_RDBPIEnumerator':
                enumeratorConfig = (
                    <UDSEnumerator
                        config={udsEnumeratorsConfig.UDS_RDBPIEnumeratorConfig ?? {scan_range: "0x0-0xff", timeout: 1}}
                        updateConfig={(enumConfig) => setUDSEnumeratorsConfig((allEnumsConfig) => { return {...allEnumsConfig, UDS_RDBPIEnumeratorConfig: enumConfig }} )}
                    />
                )
                break
            case 'UDS_ServiceEnumerator':
                enumeratorConfig = (
                    <UDSEmptyConfigEnumerator
                        config={{timeout: 1}}
                        updateConfig={(enumConfig) => setUDSEnumeratorsConfig((allEnumsConfig) => { return {...allEnumsConfig, UDS_ServiceEnumeratorConfig: enumConfig }} )}
                    />
                )
                break
            case 'UDS_RDBIEnumerator':
                enumeratorConfig = (
                    <UDSEnumerator
                        config={udsEnumeratorsConfig.UDS_RDBIEnumeratorConfig ?? {scan_range: "0x0-0xffff", timeout: 1}}
                        updateConfig={(enumConfig) => setUDSEnumeratorsConfig((allEnumsConfig) => { return {...allEnumsConfig, UDS_RDBIEnumeratorConfig: enumConfig }} )}
                    />
                )
                break
            case 'UDS_RDBISelectiveEnumerator':
                enumeratorConfig = (
                    <UDSEmptyConfigEnumerator
                        config={{timeout: 1}}
                        updateConfig={(enumConfig) => setUDSEnumeratorsConfig((allEnumsConfig) => { return {...allEnumsConfig, UDS_RDBISelectiveEnumeratorConfig: enumConfig }} )}
                    />
                )
                break
            case 'UDS_WDBISelectiveEnumerator':
                enumeratorConfig = (
                    <UDSEmptyConfigEnumerator
                        config={{timeout: 1}}
                        updateConfig={(enumConfig) => setUDSEnumeratorsConfig((allEnumsConfig) => { return {...allEnumsConfig, UDS_WDBISelectiveEnumeratorConfig: enumConfig }} )}
                    />
                )
                break
            case 'UDS_SAEnumerator':
                enumeratorConfig = (
                    <UDSEmptyConfigEnumerator
                        config={{timeout: 1}}
                        updateConfig={(enumConfig) => setUDSEnumeratorsConfig((allEnumsConfig) => { return {...allEnumsConfig, UDS_SAEnumeratorConfig: enumConfig }} )}
                    />
                )
                break
            case 'UDS_SA_XOR_Enumerator':
                enumeratorConfig = (
                    <UDSEmptyConfigEnumerator
                        config={{timeout: 1}}
                        updateConfig={(enumConfig) => setUDSEnumeratorsConfig((allEnumsConfig) => { return {...allEnumsConfig, UDS_SA_XOR_EnumeratorConfig: enumConfig }} )}
                    />
                )
                break
            case 'UdsSecurityAccessServerEnumerator':
                enumeratorConfig = (
                    <UDSEmptyConfigEnumerator
                        config={{timeout: 1}}
                        updateConfig={(enumConfig) => setUDSEnumeratorsConfig((allEnumsConfig) => { return {...allEnumsConfig, UdsSecurityAccessServerEnumeratorConfig: enumConfig }} )}
                    />
                )
                break 
            case 'UDS_RCEnumerator':
                enumeratorConfig = (
                    <UDSRCEnumerator
                        config={udsEnumeratorsConfig.UDS_RCEnumeratorConfig ?? {scan_range: "0x0-0xffff", type_list: [1, 2, 3], timeout: 1}}
                        updateConfig={(enumConfig) => setUDSEnumeratorsConfig((allEnumsConfig) => { return {...allEnumsConfig, UDS_RCEnumeratorConfig: enumConfig }} )}
                    />
                )
                break
            case 'UDS_RCStartEnumerator':
                enumeratorConfig = (
                    <UDSEnumerator
                        config={udsEnumeratorsConfig.UDS_RCStartEnumeratorConfig ?? {scan_range: "0x0-0xffff", timeout: 1}}
                        updateConfig={(enumConfig) => setUDSEnumeratorsConfig((allEnumsConfig) => { return {...allEnumsConfig, UDS_RCStartEnumeratorConfig: enumConfig }} )}
                    />
                )
                break
            case 'UDS_RCSelectiveEnumerator':
                enumeratorConfig = (
                    <UDSEmptyConfigEnumerator
                        config={{timeout: 1}}
                        updateConfig={(enumConfig) => setUDSEnumeratorsConfig((allEnumsConfig) => { return {...allEnumsConfig, UDS_RCSelectiveEnumeratorConfig: enumConfig }} )}
                    />
                )
                break
            case 'UDS_IOCBIEnumerator':
                enumeratorConfig = (
                    <UDSEnumerator
                        config={udsEnumeratorsConfig.UDS_IOCBIEnumeratorConfig ?? {scan_range: "0x0-0xffff", timeout: 1}}
                        updateConfig={(enumConfig) => setUDSEnumeratorsConfig((allEnumsConfig) => { return {...allEnumsConfig, UDS_IOCBIEnumeratorConfig: enumConfig }} )}
                    />
                )
                break
            case 'UDS_RMBAEnumerator':
                enumeratorConfig = (
                    <UDSEmptyConfigEnumerator
                        config={{timeout: 1}}
                        updateConfig={(enumConfig) => setUDSEnumeratorsConfig((allEnumsConfig) => { return {...allEnumsConfig, UDS_RMBAEnumeratorConfig: enumConfig }} )}
                    />
                )
                break
            case 'UDS_RMBARandomEnumerator':
                enumeratorConfig = (
                    <UDSEmptyConfigEnumerator
                        config={{timeout: 1}}
                        updateConfig={(enumConfig) => setUDSEnumeratorsConfig((allEnumsConfig) => { return {...allEnumsConfig, UDS_RMBARandomEnumeratorConfig: enumConfig }} )}
                    />
                )
                break
            case 'UDS_RMBASequentialEnumerator':
                enumeratorConfig = (
                    <UDSEmptyConfigEnumerator
                        config={{timeout: 1}}
                        updateConfig={(enumConfig) => setUDSEnumeratorsConfig((allEnumsConfig) => { return {...allEnumsConfig, UDS_RMBASequentialEnumeratorConfig: enumConfig }} )}
                    />
                )
                break
            case 'UDS_RDEnumerator':
                enumeratorConfig = (
                    <UDSEmptyConfigEnumerator
                        config={{timeout: 1}}
                        updateConfig={(enumConfig) => setUDSEnumeratorsConfig((allEnumsConfig) => { return {...allEnumsConfig, UDS_RDEnumeratorConfig: enumConfig }} )}
                    />
                )
                break
            case 'UDS_TDEnumerator':
                enumeratorConfig = (
                    <UDSEnumerator
                        config={udsEnumeratorsConfig.UDS_TDEnumeratorConfig ?? {scan_range: "0x0-0xff", timeout: 1}}
                        updateConfig={(enumConfig) => setUDSEnumeratorsConfig((allEnumsConfig) => { return {...allEnumsConfig, UDS_TDEnumeratorConfig: enumConfig }} )}
                    />
                )
                break
            default:
                enumeratorConfig = (
                    <div>
                        FIXME: no config handler for {udsEnumerator}
                    </div>
                )
                break
        }

        return (
            <Box key={udsEnumerator}>
                <FormGroup
                    sx={{ margin: 1 }}
                    row={true}
                >
                    <FormControlLabel
                        
                        control={
                            <Checkbox
                                id={`uds-enumerator-${udsEnumerator}`}
                                checked={ enabledUDSEnumerators.includes(udsEnumerator) }
                                disabled = { smartScan }
                                onChange={(e) => setEnabledUDSEnumerators( (curEnabledEnums) => {
                                    let updatedEnabledEnums = curEnabledEnums.filter( (udsEnumToFilter) => udsEnumToFilter !== udsEnumerator )
                                    return e.target.checked ? updatedEnabledEnums.concat([udsEnumerator]) : updatedEnabledEnums
                                })}
                            />
                        }
                        label={getReadableUDSEnumeratorNameFrom(udsEnumerator)}
                    />
                    <IconButton sx={{}} size="small" onClick={() => showEnumeratorDescriptionUserMessage(udsEnumerator)}>
                        <QuestionMarkIcon color="primary" />
                    </IconButton>
                </FormGroup>
                { enabledUDSEnumerators.includes(udsEnumerator) ? enumeratorConfig : <></> }
            </Box>
        )
    })

    const exampleSecurityAccessKeyRequestBody = { "license": "...", "seed": "113388CC", "access_level": 1, "rdbi_identifiers": [[123, "hex_value"]] }
    const exampleSecurityAccessKeyResponseBody = {'security_access_key': "0011AABB"}

    return (
        <Container sx={{ overflow: "hidden", overflowY: "auto" }}>

            <Tooltip title={t("Name / identifier of this scan run") as string}>
                <TextField
                    sx={{ margin: 1 }}
                    id="uds-scan-name"
                    label={t("Name")}
                    value={name ?? ''}
                    onChange={(e) => setName(e.target.value)}
                />
            </Tooltip>
            {/* // Note: Enable/Disable Remote Runner here for release/development */}
            {process.env.REACT_APP_PERSONALITY_NAME === "HydraVision" ?
                <Tooltip placement="right" title={t("The runner on which this scan should be run (disconnected runners are shown in red and cannot be used)") as string}>
                    <FormControl sx={{ margin: 1, minWidth: 200 }}>
                        <InputLabel id="isotp-endpoint-scan-runner-select-label">
                            {t("Runner")}
                        </InputLabel>
                        <Select
                            labelId="isotp-endpoint-scan-runner-select-label"
                            id="isotp-endpoint-scan-runner-select"
                            value={runner}
                            label="isotp-endpoint-scan-runner-select-label"
                            onChange={(e) => setRunner(typeof(e.target.value) === "string" ? parseInt(e.target.value) : e.target.value)}
                        >
                            <MenuItem value={0}>
                                {t("local")}
                            </MenuItem>
                            {runnerSelectionOptions}
                        </Select>
                    </FormControl>
                </Tooltip>
            :
                <></>
            }
            { runner === 0 ? 
                <FormControl sx={{ margin: 1, minWidth: 210 }}>
                    <InputLabel id="uds-scan-hwinterface-select-label">
                        {t("Hardware Interface")}
                    </InputLabel>
                    <Select
                        labelId="uds-scan-hwinterface-select-label"
                        id="uds-scan-hwinterface-select"
                        value={hwInterface}
                        label={hwInterfaceSelectionLabel}
                        onChange={(e) => setHwInterface(e.target.value)}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {hwInterfaceSelectionOptions}
                    </Select>
                </FormControl>
                :
                <Box component="span">
                    <Tooltip placement="right" title={t("Channel on which the scan should be run. If your channel does not appear in the list you can refresh it.") as string}>
                        <FormControl sx={{ margin: 1, minWidth: 200 }}>
                            <Autocomplete
                                freeSolo
                                id="uds-scan-channel-selection"
                                disableClearable
                                options={getAvailableChannels(activeRemoteRunners.filter(activeRemoteRunner => activeRemoteRunner.id === runner).pop()?.system_data)}
                                value={existingScanRun?.config.remote_scan_selected_channel ?? channel}
                                sx={{maxWidth: 200}}
                                onChange={(e, newValue) => setChannel(newValue)}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                    }}
                                    label={t("Channel")}
                                    value={channel}
                                    onChange={(e) => setChannel(e.target.value)}
                                />
                                )}
                            />
                        </FormControl>
                    </Tooltip>
                    <Tooltip placement="right" title={`${t("Refresh the available channels of the remote runner. Last refresh was at")}: ` +
                                                      `${getLastUpdatedDateTimeForHwInterfaceDescriptions(activeRemoteRunners.filter(activeRemoteRunner => activeRemoteRunner.id === runner).pop()?.system_data)}`}>
                        <FormControl sx={{ marginLeft: -1, marginTop: 2 }}>
                            <IconButton
                                color="primary"
                                onClick={() => {generateRemoteRunnerSystemData(remoteRunners.filter( (remoteRunner) => remoteRunner.id === runner).pop(), remoteJobTemplateQueryHWInterfaces!)}}
                            >
                                <RefreshIcon/>
                            </IconButton>
                        </FormControl>
                    </Tooltip>
                </Box>
            }

            <FormControl sx={{ margin: 1, minWidth: 210 }}>
                <InputLabel id="uds-scan-isotp-endpoint-select-label">
                    {t("ISOTP Endpoint")}
                </InputLabel>
                <Select
                    labelId="uds-scan-isotp-endpoint-select-label"
                    id="uds-scan-isotp-endpoint-select"
                    value={isotpEndpointId}
                    label={isotpEndpointSelectionLabel}
                    onChange={(e) => setISOTPEndpointId(e.target.value)}
                >
                    {isotpEndpointSelectionOptions}
                </Select>
            </FormControl>
            <br/>
            <Tooltip title={t("Maximum duration of a Scan in seconds") as string}>
                <TextField
                    sx={{ margin: 1, minWidth: 200 }}
                    id="uds-scan-scan-timeout"
                    label={t("Scan Duration")}
                    helperText={t("Seconds")}
                    value={scanTimeout}
                    type="number"
                    onChange={(e) => checkAndSetUDSScanTime(licenses,e.target.value)}
                />
            </Tooltip>

            <Tooltip title={t("Global timeout for all enumerators") as string}>
                <TextField
                    sx={{ margin: 1, minWidth: 200 }}
                    id="uds-scan-timeout"
                    label={t("Timeout")}
                    helperText={t("Seconds")}
                    value={timeout}
                    type="number"
                    onChange={(e) => setTimeout(e.target.value)}
                />
            </Tooltip>

            <Tooltip title={t("Delay for boot-up of ECU after reset") as string}>
                <TextField
                    sx={{ margin: 1 }}
                    id="uds-scan-bootDelay"
                    label={t("Boot Delay")}
                    helperText={t("Seconds")}
                    value={bootDelay}
                    type="number"
                    onChange={(e) => setBootDelay(e.target.value)}
                />
            </Tooltip>
            <br/>
            {(enabledUDSEnumerators.includes('UdsSecurityAccessServerEnumerator') || smartScan) ?
            <Tooltip title={
                <>
                    <Trans i18nKey="URL used by the scanner to retrieve security access keys.<1></1>The scanner sends a POST request with a JSON body that has the following structure:">
                        URL used by the scanner to retrieve security access keys.
                        <br/>
                        The scanner sends a POST request with a JSON body that has the following structure:
                    </Trans>
                    <br/>
                    <pre>
                        {JSON.stringify(exampleSecurityAccessKeyRequestBody, null, 2)}
                    </pre>
                    <br/>
                    <Trans i18nKey="The server then either returns an error or the calculated key wrapped in a JSON structure:">
                        The server then either returns an error or the calculated key wrapped in a JSON structure:
                    </Trans>
                    <br/>
                    <pre>
                        {JSON.stringify(exampleSecurityAccessKeyResponseBody, null, 2)}
                    </pre>
                </>
            }>
                <TextField
                    sx={{ margin: 1, minWidth: 350 }}
                    id="uds-scan-securityAccessKeyGenerationServerUrl"
                    label={t("Security access key generation server URL")}
                    value={securityAccessKeyGenerationServerUrl}
                    type="url"
                    onChange={(e) => setSecurityAccessKeyGenerationServerUrl(e.target.value)}
                />
            </Tooltip>
            : <></>}

            <br/>

            <div>
                <Container>
                    <FormControl sx={{ margin: 1, marginLeft: -3}}>
                        {isPendingScanRun(existingScanRun) ?
                            <ButtonWithDialog
                                title={t("Do you really want to stop this scan run?")}
                                message={t("Stopping a scan run will cancel it and any results up to this point are lost. You can of course always restart the scan run.")}
                                disabled={!isPendingScanRun(existingScanRun) || existingScanRun?.desired_state === "FINISHED"}
                                buttonColor="warning"
                                proceedButtonColor="warning"
                                cancelButtonColor="primary"
                                proceedCallback={stopScanRun}
                            >
                                {t("Stop Scan")}
                            </ButtonWithDialog>
                            :
                            <ButtonWithImplicitLicenseCheck
                                text={t("Start Scan")}
                                sx={{ margin: 1 }}
                                id="start-uds-scan-button"
                                color="primary"
                                variant="contained"
                                onClickCallback={startScanRun}
                                disabledCondition={isPendingScanRun(existingScanRun) || !hasAllNeededData()}
                            ></ButtonWithImplicitLicenseCheck>
                        }
                    </FormControl>
                    <FormControl sx={{ margin: 1, marginLeft: 0}}>
                        {!existingScanRun?.remote_runner &&
                            (existingScanRun?.state === "RUNNING" ?
                                <Button
                                    sx={{ margin: 1 }}
                                    color="secondary"
                                    variant="contained"
                                    onClick={pauseScanRun}
                                    disabled={["PAUSED", "FINISHED"].includes(existingScanRun?.desired_state)}
                                >
                                    {t("Pause Scan")}
                                </Button>
                                :
                                existingScanRun?.state === "PAUSED" ?
                                    <Button
                                        sx={{ margin: 1 }}
                                        color="secondary"
                                        variant="contained"
                                        onClick={continueScanRun}
                                        disabled={existingScanRun?.desired_state === "RUNNING"}
                                    >
                                        {t("Continue Scan")}
                                    </Button>
                                    :
                                    <></>
                            )
                        }
                    </FormControl>
                    <FormControl sx={{ margin: 1, marginLeft: -2}}>
                    {(existingScanRun !== undefined && !isPendingScanRun(existingScanRun)) ?
                        <DeleteElementButton
                            title={t("Do you really want to delete this scan run configuration?")}
                            message={t("Deleting a configuration is permanent and can not be undone. Of course you can always create a new one. Are you sure?")}
                            deleteCallback={deleteScanRun}
                            //disabled={isPendingScanRun(existingScanRun)}
                        />
                        :
                        <></>
                    }
                    </FormControl>
                </Container>
            </div>

            {existingScanRun === undefined ? <></> :
                <Grid container sx={{marginBottom: -2, marginTop: 3}} spacing={1}>
                    <Alert sx={{margin: 1}} severity={getReadableState(existingScanRun)[0]}>
                        {getReadableState(existingScanRun)[1]}
                    </Alert>
                    {getReadableState(existingScanRun)[0] === "error" && runner !== 0 ? 
                        <IconButton sx={{ }} onClick={showRemoteScanFailurePopup}>
                            <QuestionMarkIcon color="error"/>
                        </IconButton>
                    :
                        <></>
                    }
                    {hasScanRunFinishedDueToTimeout() ?
                        <Alert sx={{ margin: 1 }} severity="info">
                            {t("Scan run was stopped due to reaching the set scan duration.")}
                        </Alert>
                        :
                        <></>
                    }
                </Grid>
            }

            <br/>


            {existingScanRun === undefined ? <></> :
                <UDSScanRunLiveData scanRun={existingScanRun}/>
            }

            <br/>
            <Tooltip title={t("A 'Smart Scan Run' is currently only available on the local machine.") as string}>
                <FormGroup
                        sx={{ margin: 1 }}
                        row={true}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id={`smart-scan-run`}
                                checked={smartScan}
                                disabled={runner !== 0}
                                onChange={() => setSmartScan(!(smartScan))}
                            />
                        }
                        label={t("Smart Scan Run")}
                    />
                    <IconButton sx={{}} size="small" onClick={() => showEnumeratorDescriptionUserMessage("SmartScanRun")}>
                        <QuestionMarkIcon color="primary" />
                    </IconButton>
                </FormGroup>
            </Tooltip>
            {enumeratorsConfig}
        </Container>
    )
}