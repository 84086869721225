import { useState } from "react";
import { useAppDispatch } from "../../app/hooks";
import {
    createTargetECUAsync,
    ICreateTargetECU
} from "./TargetECUSlice";
import { TargetECUEdit } from "./TargetECUEdit";
import { ButtonWithImplicitLicenseCheck } from "../misc/Util";

import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import { addOrActivateTargetECUConfigurationWidget } from "../main_lumino_widget/MainLuminoWidgetSlice";

export const TargetECUCreate = () => {

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [remoteRunnerId, setRemoteRunnerId] = useState(-1)

    const { t } = useTranslation()

    const dispatch = useAppDispatch()

    const hasAllNeededData = () => {
        return name.length > 0 &&
               description.length > 0 &&
               remoteRunnerId !== -1
    }

    const createTargetECU = () => {
        if (!hasAllNeededData()) {
            return
        }

        const newTargetECU: ICreateTargetECU = {
            name: name,
            description: description,
            remote_runner: remoteRunnerId
        }
        dispatch(createTargetECUAsync(newTargetECU)).then((res) => {
            dispatch(addOrActivateTargetECUConfigurationWidget(res.payload))
        })
    }

    return (
        <Container disableGutters sx={{ overflow: "hidden", overflowY: "auto", marginLeft: -1 }}>
            <TargetECUEdit {...{
                stateName: [name, setName],
                stateDescription: [description, setDescription],
                stateRemoteRunnerId: [remoteRunnerId, setRemoteRunnerId],
                createView: true
            }}/>

            <ButtonWithImplicitLicenseCheck
                text={t("Create")}
                sx={{ margin: 1 }}
                id="create-target-ecu-button"
                variant="contained"
                onClickCallback={createTargetECU}
                disabledCondition={!hasAllNeededData()}
            />
        </Container>
    )
}