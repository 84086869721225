import { useAppSelector } from "../../app/hooks"
import { selectRemoteRunners } from "../remote_runner/RemoteRunnerSlice"
import { ConditionalFragment, utcToLocalDateTimeRepresentation } from "../misc/Util"
import { useTranslation } from "react-i18next"
import TextField from "@mui/material/TextField"
import Tooltip from "@mui/material/Tooltip"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import Container from "@mui/material/Container"

export const TargetECUEdit = (props: any) => {

    const [name, setName] = props.stateName
    const [description, setDescription] = props.stateDescription
    const [remoteRunnerId, setRemoteRunnerId] = props.stateRemoteRunnerId
    const createdAt = props.roStateCreatedAt

    const remoteRunners = useAppSelector(selectRemoteRunners)

    const { t } = useTranslation()

    return (
        <Container disableGutters>
            <Tooltip title={t("Name / identifier of this target ECU") as string}>
                <TextField
                    sx={{ margin: 1 }}
                    id={props.createView ? "create-target-ecu-edit-name" : "target-ecu-edit-name"}
                    label={t("Name")}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </Tooltip>

            <ConditionalFragment condition={createdAt !== undefined}>
                <Tooltip title={t("The date when the target ECU was created") as string}>
                    <TextField
                        sx={{ margin: 1 }}
                        id="target-ecu-created-at"
                        label={t("Created At")}
                        value={utcToLocalDateTimeRepresentation(createdAt)}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Tooltip>
            </ConditionalFragment>
            <Tooltip title={t("The Probes navigation tree entry is only visible with expert mode enabled")} placement="right">
                <FormControl sx={{ margin: 1, minWidth: 210 }}>
                    <InputLabel id="target-ecu-select-remote_runner-label">
                        {t("Probe")}
                    </InputLabel>
                    <Select
                        labelId="target-ecu-select-remote_runner-label"
                        id="target-ecu-select-remote_runner"
                        value={remoteRunnerId !== -1 ? remoteRunnerId : ''}
                        label={t("Probe")}
                        onChange={(e) => setRemoteRunnerId(parseInt(e.target.value))}
                    >
                        {remoteRunners.map((remoteRunner) => (
                            <MenuItem 
                                key={remoteRunner.id}
                                value={remoteRunner.id}
                            >
                                {remoteRunner.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Tooltip>
            <br/>

            <Tooltip title={t("Description for this target ECU") as string}>
                <TextField
                    sx={{ margin: 1, minWidth: 425 }}
                    multiline
                    id="target-ecu-edit-description"
                    label={t("Description")}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
            </Tooltip>
        </Container>
    )
}