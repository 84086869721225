import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useTranslation } from 'react-i18next'
import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { useState } from "react";
import { ButtonWithDialog, ConditionalFragment, utcToLocalDateTimeRepresentation } from "../misc/Util";
import { ICreateGroup, IGroup, createGroupAsync, deleteGroupAsync, selectGroups } from "./GroupSlice";
import { selectCurrentUser } from "../user_management/UserSlice";
import { defaultUserGroupName } from "../misc/Constants";


export const GroupManagement = (props: any) => {
    const currentUser = useAppSelector(selectCurrentUser)
    const groups = useAppSelector(selectGroups)
    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [addGroupDialogOpen, setAddGroupDialogOpen] = useState(false)


    const { t } = useTranslation()

    const dispatch = useAppDispatch()

    const deleteGroup = (group: IGroup) => {
        dispatch(deleteGroupAsync(group.id))
    }

    const handleAddGroupOpen = () => {
        setAddGroupDialogOpen(true)
    }

    const handleAddGroupClose = () => {
        setAddGroupDialogOpen(false)
        setName("")
        setDescription("")
    }

    const addGroup = () => {
        const newGroup: ICreateGroup = {
            name: name,
            description
        }
        dispatch(createGroupAsync(newGroup))
        handleAddGroupClose()
    }

    const hasAllNeededDataAddGroup = () => {
        return currentUser &&
            name !== "" &&
            description !== ""
    }

    const getGroupsAsRows = (users: IGroup[]) => {
        const rows: any = []

        users.forEach((group: IGroup) => {
            rows.push(
                <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    key={group.name}
                >
                    <TableCell component="th" scope="row">
                        {group.name}
                    </TableCell>
                    <TableCell align="right">
                        {group.description}
                    </TableCell>
                    <TableCell align="right">
                        {utcToLocalDateTimeRepresentation(group.creation_date)}
                    </TableCell>
                    <TableCell align="right">
                        <ConditionalFragment condition={currentUser?.is_staff ?? false}>
                            <p>
                                <ConditionalFragment condition={group.name !== defaultUserGroupName}>
                                    <ButtonWithDialog
                                        title={t("Do you really want to delete this group?")}
                                        message={t("Deleting a group is permanent and can not be undone. Of course you can always create a new one. Are you sure?")}
                                        buttonColor="error"
                                        proceedButtonColor="error"
                                        cancelButtonColor="primary"
                                        proceedCallback={() => deleteGroup(group)}
                                    >
                                        {t("Delete")}
                                    </ButtonWithDialog>
                                </ConditionalFragment>
                            </p>
                        </ConditionalFragment>
                    </TableCell>
                </TableRow>
            )
        })
        return rows
    }

    const getGroupsAsTable = (groups: IGroup[]) => {
        return (
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 300 }} aria-label="User Groups Table">
                    <TableHead>
                        <TableRow>
                            <TableCell><b>{t("Name")}</b></TableCell>
                            <TableCell align="right"><b>{t("Description")}</b></TableCell>
                            <TableCell align="right"><b>{t("Created at")}</b></TableCell>
                            <TableCell align="right">
                                <Button
                                    sx={{ margin: 1 }}
                                    variant="contained"
                                    onClick={handleAddGroupOpen}
                                >
                                    {t("Add Group")}
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getGroupsAsRows(groups)}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    return (
        <Container sx={{ marginLeft: -2, overflow: "hidden", overflowY: "auto", height: "90vh" }}>
            {getGroupsAsTable(groups)}
            <Dialog open={addGroupDialogOpen} onClose={handleAddGroupClose}>
                <DialogTitle>{t("Add Group")}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="add-group-name"
                        label={t("Name")}
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        fullWidth
                    />
                    <TextField
                        margin="dense"
                        id="add-group-description"
                        label={t("Description")}
                        type="text"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAddGroupClose}>{t("Cancel")}</Button>
                    <Button onClick={addGroup} disabled={!hasAllNeededDataAddGroup()}>{t("Add Group")}</Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}