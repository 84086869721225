import { useAppDispatch, useAppSelector } from '../../app/hooks';

import {
    ITargetECU,
    deleteTargetECUAsync,
    selectTargetECUs,
} from "./TargetECUSlice";

import { useTranslation } from "react-i18next";
import { useMemo, useState } from 'react';
import { THeadAlign, TSortOrder } from '../misc/GlobalTypes';
import { DeleteElementButton, TableToolbarWithFilterInput, getSortComparator, utcToLocalDateTimeRepresentation } from '../misc/Util';
import { addOrActivateTargetECUConfigurationWidget } from '../main_lumino_widget/MainLuminoWidgetSlice';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableBody from '@mui/material/TableBody';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { TargetECUCreate } from './TargetECUCreate';
import TablePagination from '@mui/material/TablePagination';

interface TableHeadCell {
    id: keyof ITargetECU
    label: string
    align: THeadAlign
}

const tableHeadCells: readonly TableHeadCell[] = [
    {
        id: "name",
        // t("Name")
        label: "Name",
        align: "left"
    },
    {
        id: "created_at",
        // t("Created at")
        label: "Created at",
        align: "right"
    },
]

export const TargetECUs = () => {

    const targetECUs = useAppSelector(selectTargetECUs)
    const [rowsPerPage, setRowsPerPage] = useState(25)
    const [page, setPage] = useState(0)
    const [order, setOrder] = useState<TSortOrder>("desc")
    const [orderBy, setOrderBy] = useState<keyof ITargetECU>("created_at")
    const [nameFilterPattern, setNameFilterPattern] = useState("")

    const dispatch = useAppDispatch()

    const { t } = useTranslation()

    const handleChangePage = (_: unknown, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const filteredAndSortedTargetECUs = useMemo(() =>
        targetECUs.filter((targetECU) => targetECU.name.toLowerCase().includes(nameFilterPattern.toLowerCase())).sort(getSortComparator(order, orderBy)), [nameFilterPattern, targetECUs, order, orderBy])

    const setSortHandler = (key: keyof ITargetECU) => (_: React.MouseEvent<unknown>) => {
        const isAsc = orderBy === key && order === "asc"
        setOrder(isAsc ? "desc" : "asc")
        setOrderBy(key)
    }

    const editTargetECU = (remoteRunner: ITargetECU) => {
        dispatch(addOrActivateTargetECUConfigurationWidget(remoteRunner))
    }

    const deleteTargetECU = (remoteRunner: ITargetECU) => {
        dispatch(deleteTargetECUAsync(remoteRunner.id))
    }

    const getTargetECURows = (targetECUs: ITargetECU[]): any[] => {
        return targetECUs.map((targetECU: ITargetECU) => (
            <TableRow
                 sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                 key={targetECU.id}
            >
                <TableCell id="target-ecu-name" key={`table-cell-target-ecu-${targetECU.id}-name`} component="th" scope="row">
                    {targetECU.name}
                </TableCell>
                <TableCell key={`table-cell-target-ecu-${targetECU.id}-created_at`} align="right">
                    {utcToLocalDateTimeRepresentation(targetECU.created_at)}
                </TableCell>
                <TableCell key={`table-cell-target-ecu-${targetECU.id}-edit-elements`} align="right">
                    <Button
                        id="target-ecu-edit-button"
                        sx={{ margin: 1 }}
                        variant="contained"
                        color="primary"
                        onClick={() => editTargetECU(targetECU)}
                    >
                        {t("Edit")}
                    </Button>
                    <DeleteElementButton
                        id="target-ecu-delete-button"
                        title={t("Do you really want to delete this target ECU?") as string}
                        message={t("Deleting a target ECU is permanent and can not be undone. Of course you can always create a new one. Are you sure?") as string}
                        deleteCallback={() => deleteTargetECU(targetECU)}
                    />
                </TableCell>
            </TableRow>
            )
        )
    }

    const getTargetECUsAsTable = (targetECUs: ITargetECU[]) => (
        <Paper sx={{ width: "100%", mb: 2 }}>
            <TableToolbarWithFilterInput
                hasValidFilter={true}
                toolbarTitle={t("Target ECUs")}
                filterTextBoxLabel={t("Filter for names")}
                filterPattern={nameFilterPattern}
                setFilterPattern={setNameFilterPattern}
                getSuggestedFilterItems={(currentFilterString: string) =>
                    targetECUs.filter((targetECU) => targetECU.name.toLowerCase().includes(currentFilterString.toLowerCase())).map((targetECU) => targetECU.name)
                }
            />
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 300 }} aria-label="Target ECU Table">
                    <TableHead>
                        <TableRow>
                            {tableHeadCells.map((tableHeadCell) => (
                                <TableCell
                                    key={`table-header-cell-target-ecu-${tableHeadCell.id}`}
                                    align={tableHeadCell.align}
                                    sortDirection={orderBy === tableHeadCell.id ? order : false}
                                >
                                    <TableSortLabel
                                        active={orderBy === tableHeadCell.id}
                                        direction={orderBy === tableHeadCell.id ? order : "asc"}
                                        onClick={setSortHandler(tableHeadCell.id)}
                                    >
                                        <b>
                                            {t(tableHeadCell.label) /* FIXME: this will generate a warning during the extraction of the translatable strings */}
                                        </b>
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                            <TableCell align="right"/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { getTargetECURows(targetECUs) }
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )

    return (
        <Container id="target-ecus-container" sx={{ overflow: "hidden", overflowY: "auto", height: "90vh", marginLeft: -1 }}>
            <Typography variant="h6">
                {t("Create a new target ECU")}
            </Typography>
            <TargetECUCreate/>
            <Typography variant="h6" marginTop={2}>
                {t("Edit an existing target ECU")}
            </Typography>
            { getTargetECUsAsTable(filteredAndSortedTargetECUs.slice(page * rowsPerPage, (page + 1) * rowsPerPage)) }
            <TablePagination
                rowsPerPageOptions={[25, 50]}
                component="div"
                count={filteredAndSortedTargetECUs.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Container>
    )
}