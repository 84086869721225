import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
    deleteRemoteRunnerAsync,
    IRemoteRunner,
    IUpdateRemoteRunner,
    selectRemoteRunner,
    selectRemoteRunners,
    updateRemoteRunnerAsync,
} from "./RemoteRunnerSlice";
import { RemoteRunnerEdit } from './RemoteRunnerEdit';
import { DeleteElementButton, generateRemoteRunnerSystemData, getLastUpdatedDateTimeForHwInterfaceDescriptions, useObserveAndDeleteWidget } from '../misc/Util';

import Button from "@mui/material/Button";
import Container from "@mui/material/Container";

import { useTranslation } from "react-i18next";
import { selectRemoteJobTemplateQueryHWInterfaces } from "../remote_job_template/RemoteJobTemplateSlice";
import Tooltip from "@mui/material/Tooltip";
import { ITargetECU, selectTargetECUs } from "../target_ecu/TargetECUSlice";

export const RemoteRunnerConfig = (props: any) => {

    const remoteRunner: IRemoteRunner | undefined = useAppSelector(selectRemoteRunner(props.remoteRunnerId))
    const remoteRunners: IRemoteRunner[] = useAppSelector(selectRemoteRunners)
    const targetECUs: ITargetECU[] = useAppSelector(selectTargetECUs)
    const remoteJobTemplateQueryHWInterfaces = useAppSelector(selectRemoteJobTemplateQueryHWInterfaces())

    const [name, setName] = useState(remoteRunner?.name ?? '')
    const token = remoteRunner?.token ?? ''
    const lastSeenClientIp = remoteRunner?.last_seen_ip ?? ''
    const lastSeen = remoteRunner?.last_seen ?? ''
    const createdAt = remoteRunner?.created_at ?? ''
    const associatedTargetECU = targetECUs.filter((targetECU) => targetECU.remote_runner === remoteRunner?.id).pop()

    const { t } = useTranslation()

    const dispatch = useAppDispatch()
    useObserveAndDeleteWidget("REMOTERUNNERCONFIG", remoteRunners)

    if (remoteRunner === undefined) {
        // should never be shown under normal circumstances
        return (
            <div>
                DELETED
            </div>
        )
    }

    const hasAllNeededData = () => {
        return name.length > 0
    }

    const saveRemoteRunner = () => {
        if (!hasAllNeededData()) {
            return
        }

        const updatedRemoteRunner: IUpdateRemoteRunner = {
            id: remoteRunner?.id ?? -1,
            data: {
                name: name,
            }
        }
        dispatch(updateRemoteRunnerAsync(updatedRemoteRunner))
    }

    const deleteRemoteRunner = () => {
        if (remoteRunner === undefined) {
            return
        }
        dispatch(deleteRemoteRunnerAsync(remoteRunner.id))
    }

    return (
        <Container id="remote-runner-container" sx={{ overflow: "hidden", overflowY: "auto", height: "90vh" }}>
            <RemoteRunnerEdit {...{
                stateName: [name, setName],
                roStateToken: token,
                roStateLastSeenIp: lastSeenClientIp,
                roStateLastSeen: lastSeen,
                roStateCreatedAt: createdAt,
                roStateAssociatedTargetECU: associatedTargetECU }
            }/>

            <br/>

            <Button
                id="remote-runner-save-button"
                sx={{ margin: 1 }}
                variant="contained"
                onClick={saveRemoteRunner}
                disabled={!hasAllNeededData()}
            >
                {t("Save")}
            </Button>

            <DeleteElementButton
                id="remote-runner-delete-button"
                title={t("Do you really want to delete this probe?") as string}
                message={t("Deleting a probe is permanent and can not be undone. Of course you can always create a new one. Are you sure?") as string}
                deleteCallback={deleteRemoteRunner}
            />

            <br/>
            <Tooltip placement="right" title={`${t("Refresh the available channels of the probe. Last refresh was at")}: ` +
                                              `${getLastUpdatedDateTimeForHwInterfaceDescriptions(remoteRunner.system_data)}`}>
                <Button
                    id="remote-runner-refresh-systemdata-button"
                    sx={{ margin: 1 }}
                    variant="contained"
                    onClick={() => generateRemoteRunnerSystemData(remoteRunner, remoteJobTemplateQueryHWInterfaces!)}
                >
                    {t("Refresh Probe System Data")}
                </Button>
            </Tooltip>
        </Container>
    )
}
