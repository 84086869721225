import { useState } from "react";
import { useAppDispatch } from "../../app/hooks";
import {
    createISOTPEndpointAsync,
    ICreateISOTPEndpoint
} from "./ISOTPEndpointsSlice";
import { ISOTPEndpointEdit } from "./ISOTPEndpointEdit";
import { ButtonWithImplicitLicenseCheck } from "../misc/Util";

import Container from "@mui/material/Container";

import "./ISOTPEndpointCreate.css";

export const ISOTPEndpointCreate = () => {

    const [name, setName] = useState('')
    const [hwInterface, setHwInterface] = useState('Unknown')
    const [txId, setTxId] = useState('')
    const [rxId, setRxId] = useState('')
    const [txExtAddr, setTxExtAddr] = useState('')
    const [rxExtAddr, setRxExtAddr] = useState('')
    const [padding, setPadding] = useState(false)

    const dispatch = useAppDispatch()

    const hasAllNeededData = () => {
        return name.length > 0 && 
               hwInterface.length > 0 &&
               txId.length > 0 &&
               rxId.length > 0
    }

    const createEndpoint = () => {
        if (!hasAllNeededData()) {
            return
        }

        const rx_ext_addr = rxExtAddr === '' ? undefined : parseInt(rxExtAddr)
        const tx_ext_addr = txExtAddr === '' ? undefined : parseInt(txExtAddr)
        const newEndpoint: ICreateISOTPEndpoint = {
            hw_interface: hwInterface,
            name: name,
            rx_id: parseInt(rxId),
            tx_id: parseInt(txId),
            rx_ext_address: rx_ext_addr,
            ext_address: tx_ext_addr,
            padding: padding
        }
        dispatch(createISOTPEndpointAsync(newEndpoint))
    }

    return (
        <Container sx={{ overflow: "hidden", overflowY: "auto", height: "90vh" }}>
            <ISOTPEndpointEdit {...{
                stateName: [name, setName],
                stateHwInterface: [hwInterface, setHwInterface],
                stateTxId: [txId, setTxId],
                stateRxId: [rxId, setRxId],
                stateExt: [txExtAddr, setTxExtAddr],
                stateRxExt: [rxExtAddr, setRxExtAddr],
                statePadding: [padding, setPadding] }
            }/>

            <ButtonWithImplicitLicenseCheck
                text="Create"
                sx={{ margin: 1 }}
                variant="contained"
                onClickCallback={createEndpoint}
                disabledCondition={!hasAllNeededData()}
            ></ButtonWithImplicitLicenseCheck>
        </Container>
    )
}