import TextField from "@mui/material/TextField"
import Tooltip from "@mui/material/Tooltip"
import { useTranslation } from "react-i18next"
import { ConditionalFragment, utcToLocalDateTimeRepresentation } from "../misc/Util"
import { ITargetECU } from "../target_ecu/TargetECUSlice"
import Container from "@mui/material/Container"

export const RemoteRunnerEdit = (props: any) => {

    const [name, setName] = props.stateName
    const associatedTargetECU: ITargetECU | undefined = props.roStateAssociatedTargetECU
    const token = props.roStateToken
    const lastSeenClientIp = props.roStateLastSeenIp
    const lastSeen = props.roStateLastSeen
    const createdAt = props.roStateCreatedAt

    const { t } = useTranslation()

    return (
        <Container disableGutters>
            <Tooltip title={t("Name / identifier of this probe") as string}>
                <TextField
                    sx={{ margin: 1 }}
                    id={props.createView ? "create-remote-runner-edit-name" : "remote-runner-edit-name"}
                    label={t("Name")}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </Tooltip>

            <ConditionalFragment condition={token !== undefined}>
                <Tooltip title={t("Token / unique probe configuration identifier") as string}>
                    <TextField
                        sx={{ margin: 1 }}
                        id="remote-runner-token"
                        label={t("Token")}
                        value={token}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Tooltip>
            </ConditionalFragment>

            <ConditionalFragment condition={!props.createView}>
                <Tooltip title={t("Name of the associated Target ECU") as string}>
                    <TextField
                        sx={{ margin: 1 }}
                        id="associated-target-ecu"
                        label={t("Associated Target ECU")}
                        value={associatedTargetECU?.name ?? t("None")}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Tooltip>
            </ConditionalFragment>

            <br />

            <ConditionalFragment condition={lastSeenClientIp !== undefined}>
                <Tooltip title={t("The IP that the probe token was last used at") as string}>
                    <TextField
                        sx={{ margin: 1 }}
                        id="remote-runner-last-seen-ip"
                        label={t("Last Seen IP")}
                        value={lastSeenClientIp}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Tooltip>
            </ConditionalFragment>

            <ConditionalFragment condition={lastSeen !== undefined}>
                <Tooltip title={t("The last contact with the probe") as string}>
                    <TextField
                        sx={{ margin: 1 }}
                        id="remote-runner-last-seen"
                        label={t("Last Seen")}
                        value={lastSeen ? utcToLocalDateTimeRepresentation(lastSeen) : t("Not connected yet")}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Tooltip>
            </ConditionalFragment>
            
            <ConditionalFragment condition={createdAt !== undefined}>
                <Tooltip title={t("The date when the probe was created") as string}>
                    <TextField
                        sx={{ margin: 1 }}
                        id="remote-runner-created-at"
                        label={t("Created At")}
                        value={utcToLocalDateTimeRepresentation(createdAt)}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Tooltip>
            </ConditionalFragment>
        </Container>
    )
}