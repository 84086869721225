import { dcrfClient } from "../../app/api";
import { logger } from "../../app/logging";
import {
    ITargetECU,
    ICreateTargetECU
} from "./TargetECUSlice";

const wsStream = 'targetecu'

export const fetchAllTargetECUsViaDCRF = () => {
    logger.debug('fetchAllTargetECUsViaDCRF')
    return dcrfClient.list(wsStream)
}

export const patchTargetECUViaDCRF = (id: number, data: any) => {
    logger.debug(`patchTargetECUViaDCRF with id: ${id} - data: ${data}`)
    return dcrfClient.patch(wsStream, id, data)
}

export const createTargetECUViaDCRF = (newTargetECU: ICreateTargetECU) => {
    logger.debug(`createTargetECUViaDCRF ${newTargetECU}`)
    return dcrfClient.create(wsStream, newTargetECU)
}

export const deleteTargetECUViaDCRF = (id: number) => {
    logger.debug(`deleteTargetECUViaDCRF with id: ${id}`)
    return dcrfClient.delete(wsStream, id)
}

export const subscribeToTargetECUChangesViaDCRF = (callback: (remoteJob: ITargetECU, action: string) => void) => {
    logger.debug("subscribeToTargetECUChangesViaDCRF")
    const subscription = dcrfClient.subscribe(wsStream,
        {},
        (remoteJob, action) => {
            callback(remoteJob as ITargetECU, action)
        },
        {
            includeCreateEvents: true,
            requestId: 'subscribeToTargetECUChangesViaDCRF',
            subscribeAction: 'subscribe_to_all_changes',
            unsubscribeAction: 'unsubscribe_from_all_changes',
        }
    )
    // a subscription can be canceled by calling "cancel" on the returned subscription object
    return subscription
}
