import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, store } from "../../app/store";
import {
    createUserViaDCRF, deleteUserViaDCRF,
    fetchAllUsersViaDCRF,
    patchUserViaDCRF,
    subscribeToUserChangesViaDCRF,
} from "./UserAPI";
import {
    showUserMessage,
} from '../user_message/UserMessageSlice';
import { LastRequestStatus } from "../misc/GlobalTypes";
import { authManager } from "../../app/auth";
import { t } from "i18next";
import { logger } from "../../app/logging";

export interface IUsers {
    users: IUser[]
    lastRequestStatus: LastRequestStatus
    subscribedToUpdates: boolean
}

export interface IUser {
    id: number,
    email: string,
    is_active: boolean,
    is_admin: boolean,
    is_staff: boolean,
    creation_date: string,
    name: string,
    company: string,
    password: string,
    last_login: string,
    groups: number[]
}

export const fetchAllUsersAsync = createAsyncThunk(
    'User/fetchAllUsersAsync',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            return await fetchAllUsersViaDCRF()
        } catch (rejectedValue) {
            // @ts-ignore
            const error_message = JSON.stringify(rejectedValue.errors)
            dispatch(showUserMessage({ title: 'fetchAllUsersAsync failed', message: error_message }))
            return rejectWithValue(rejectedValue)
        }
    }
)

export interface IUpdateUser {
    id: number
    data: {
        name?: string,
        email?: string,
        password?: string,
        authenticated_user_password?: string
        groups?: number[]
    }
}

export const updateUserAsync = createAsyncThunk(
    'User/updateUserAsync',
    async (updatedUser: IUpdateUser, { dispatch, rejectWithValue }) => {
        try {
            return await patchUserViaDCRF(updatedUser.id, updatedUser.data)
        } catch (rejectedValue) {
            // @ts-ignore
            const error_message = JSON.stringify(rejectedValue.errors)
            switch(true) {
                case error_message.includes("user with this email already exists"):
                    dispatch(showUserMessage({ title: t('EMail address already taken'), message: t('The supplied email address is already taken. Please try again.') }))
                    break
                case error_message.includes("Enter a valid email address"):
                    dispatch(showUserMessage({ title: t('Invalid email address'), message: t('The supplied email address is invalid. Please try again.') }))
                    break
                case error_message.includes("The authenticated_user_password is invalid"):
                    dispatch(showUserMessage({ title: t('Wrong password'), message: t('The supplied password of the authenticated user is incorrect. Please try again.') }))
                    break
                default:
                    dispatch(showUserMessage({ title: 'updateUserAsync failed', message: error_message }))
            }
            return rejectWithValue(rejectedValue)
        }
    }
)

export interface ICreateUser {
    email: string,
    name: string,
    company: string,
    password: string,
    is_staff?: boolean // User admins are is_staff=True, dissecto admins are is_admin=True
    groups?: number[]
}

export const createUserAsync = createAsyncThunk(
    'User/createUserAsync',
    async (newUser: ICreateUser, { dispatch, rejectWithValue }) => {
        try {
            return await createUserViaDCRF(newUser)
        } catch (rejectedValue) {
            // @ts-ignore
            const error_message = JSON.stringify(rejectedValue.errors)
            dispatch(showUserMessage({ title: 'createUserAsync failed', message: error_message }))
            return rejectWithValue(rejectedValue)
        }
    }
)

export const deleteUserAsync = createAsyncThunk(
    'User/deleteUserAsync',
    async (id: number, { dispatch, rejectWithValue }) => {
        try {
            return await deleteUserViaDCRF(id)
        } catch (rejectedValue) {
            // @ts-ignore
            const error_message = JSON.stringify(rejectedValue.errors)
            dispatch(showUserMessage({ title: 'deleteUserAsync failed', message: error_message }))
            return rejectWithValue(rejectedValue)
        }
    }
)

const getUpdatedUsers = (currentUsers: IUser[], updatedUser: IUser) => {
    return currentUsers.map((existingUser: IUser) => {
        if (existingUser.id === updatedUser.id) {
            return updatedUser
        } else {
            return existingUser
        }
    })
}


const initialState: IUsers = {
    users: [],
    subscribedToUpdates: false,
    lastRequestStatus: 'success',
}

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        addLocalUser: (state, action: PayloadAction<IUser>) => {
            if (!state.users.some(User => User.id === action.payload.id)) {
                state.users.push(action.payload)
            }
        },
        updateLocalUser: (state, action: PayloadAction<IUser>) => {
            state.users = getUpdatedUsers(state.users, action.payload)
        },
        deleteLocalUser: (state, action: PayloadAction<IUser>) => {
            state.users = state.users.filter((endpoint) => endpoint.id !== action.payload.id)
        },
        subscribeToUserChanges: (state) => {
            if (state.subscribedToUpdates) {
                return
            }
            const callback = (user: IUser, action: string) => {
                logger.debug(`user subscription callback (action: ${action})`)
                switch (action) {
                    case 'create':
                        store.dispatch(addLocalUser(user))
                        break
                    case 'update':
                        store.dispatch(updateLocalUser(user))
                        break
                    case 'delete':
                        // NOTE: We get the action.payload with a non null value here. That means,
                        // that this callback and the deleteLocalUser are responsible for
                        // keeping the state up to date when a remote job gets deleted.
                        // deleteUserAsync.fulfilled does only get a null value
                        store.dispatch(deleteLocalUser(user))
                        break
                    default:
                        logger.debug('FIXME: unexpected action')
                }
            }
            subscribeToUserChangesViaDCRF(callback)
            state.subscribedToUpdates = true
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllUsersAsync.pending, (state) => {
                state.lastRequestStatus = 'busy'
            })
            .addCase(fetchAllUsersAsync.fulfilled, (state, action: PayloadAction<IUser[]>) => {
                state.lastRequestStatus = 'success'
                state.users = action.payload
            })
            .addCase(fetchAllUsersAsync.rejected, (state) => {
                state.lastRequestStatus = 'failed'
            })
            .addCase(createUserAsync.pending, (state) => {
                state.lastRequestStatus = 'busy'
            })
            .addCase(createUserAsync.fulfilled, (state, action: PayloadAction<IUser>) => {
                state.lastRequestStatus = 'success'
                if (!state.users.some(endpoint => endpoint.id === action.payload.id)) {
                    state.users.push(action.payload)
                }
            })
            .addCase(createUserAsync.rejected, (state) => {
                state.lastRequestStatus = 'failed'
            })
            .addCase(updateUserAsync.pending, (state) => {
                state.lastRequestStatus = 'busy'
            })
            .addCase(updateUserAsync.fulfilled, (state, action: PayloadAction<IUser>) => {
                state.lastRequestStatus = 'success'
                state.users = getUpdatedUsers(state.users, action.payload)
            })
            .addCase(updateUserAsync.rejected, (state) => {
                state.lastRequestStatus = 'failed'
            })
            .addCase(deleteUserAsync.pending, (state) => {
                state.lastRequestStatus = 'busy'
            })
            .addCase(deleteUserAsync.fulfilled, (state, action: PayloadAction<IUser>) => {
                state.lastRequestStatus = 'success'
                // NOTE: action.payload.id is not available here (null value; update, fetch and create get a non null value) 
                // so just leave the state as it is (the subscription will trigger the deletion).
                // This means we are dependent on a functioning subscription callback
            })
            .addCase(deleteUserAsync.rejected, (state) => {
                state.lastRequestStatus = 'failed'
            })
    }
})

export const {
    addLocalUser,
    updateLocalUser,
    deleteLocalUser,
    subscribeToUserChanges } = usersSlice.actions

export const selectUsers = (state: RootState) => state.users.users
export const selectCurrentUser = (state: RootState) => state.users.users.filter(user => {
    return user.id === JSON.parse(Buffer.from(authManager?.email?.split(".")[1] ?? "", 'base64').toString('binary')).user_id
}).pop()

export const selectLastUserRequestStatus = (state: RootState) => state.users.lastRequestStatus
export const selectUserIsSubscribed = (state: RootState) => state.users.subscribedToUpdates

export default usersSlice.reducer
