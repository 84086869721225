import { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from '../../app/hooks';

import { useTranslation } from "react-i18next";
import Container from "@mui/material/Container";
import TreeItem from '@mui/lab/TreeItem';

import { selectRemoteJobTemplates, selectRemoteJobTemplatesByType } from "../remote_job_template/RemoteJobTemplateSlice";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { IRemoteRunner, selectActiveRemoteRunners, selectRemoteRunners } from "../remote_runner/RemoteRunnerSlice";
import { ButtonWithImplicitLicenseCheck, DeleteElementButton, distinct, generateId, IStringToNumberMapping, utcToLocalDateTimeRepresentation, useObserveAndDeleteWidget, authenticatedFetch, compactMap } from "../misc/Util";
import { createRemoteJobAsync, deleteRemoteJobAsync, ICreateRemoteJob, IRemoteJob, IUpdateRemoteJob, selectRemoteJobs, updateRemoteJobAsync } from "./RemoteJobSlice";
import Typography from "@mui/material/Typography";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import { addOrActivateGenericRemoteJobResultWidget, addOrActivateRemoteJobsWidget } from "../main_lumino_widget/MainLuminoWidgetSlice";
import { httpBackendURL } from "../../app/api";
import { showUserMessage } from "../user_message/UserMessageSlice";
import Chip from "@mui/material/Chip";
import { deleteGenericRemoteJobDataLines } from "../live_data/LiveDataSlice";
import { getRemoteJobStateMappedToRepresentation } from "../misc/Util";


export const RemoteJobs = () => {
    const remoteJobs = useAppSelector(selectRemoteJobs)
    const remoteJobTemplates = useAppSelector(selectRemoteJobTemplates)
    const remoteRunners = useAppSelector(selectRemoteRunners)

    const { t } = useTranslation()
    
    useObserveAndDeleteWidget("REMOTEJOBS", remoteJobs)

    const genericJobArtifactTreeItems = (templateId : number, tag: string) => {
        return remoteJobs.filter(remoteJob => remoteJob.job_template === templateId && remoteJob.tag === tag)
        .map(remoteJob => {
            const treeNodeId = `GENERICREMOTEJOBRESULT::${remoteJob.id}`
            const label = remoteRunners.filter(remoteRunner => remoteRunner.id === remoteJob.runner).pop()?.name
            return ( 
                <TreeItem
                    key={remoteJob.id}
                    nodeId={treeNodeId}
                    label={label}
                >
                </TreeItem>
            )
        })
    }

    const genericJobTreeItems = () => {
        const tagToTemplateIdMapping: IStringToNumberMapping = {} 

        return remoteJobs.filter(remoteJob => remoteJob.job_context === null)
        .map((remoteJob) => {
            if(remoteJob.tag){
                tagToTemplateIdMapping[remoteJob.tag] = remoteJob.job_template
            }
            return remoteJob.tag
        }).filter((tag): tag is string => !!tag) // We just remove the undefined values  
        .filter(distinct)
        .map(tag => {
            const templateId = tag ? tagToTemplateIdMapping[tag] : -1
            const treeNodeId = `REMOTEJOBS::${tag}`
            const label = remoteJobTemplates.filter(remoteJobTemplate => remoteJobTemplate.id === templateId).pop()?.name
            return (
                <TreeItem
                    key={tag}
                    nodeId={treeNodeId}
                    label={label}
                >
                    {genericJobArtifactTreeItems(templateId, tag)}
                </TreeItem>
            )
        })
    }

    return (
        <TreeItem nodeId="REMOTEJOBS" label={t("Start Generic Jobs")}>
            {genericJobTreeItems()}
        </TreeItem>
    )
}

export const RemoteJob = (props: any) => {
    const tag = props.tag
    const remoteJobTemplateId = props.remoteJobTemplateId
    const remoteJobs = useAppSelector(selectRemoteJobs)
    const remoteJobTemplates = useAppSelector(selectRemoteJobTemplatesByType(['GENERIC']))
    const activeRemoteRunners = useAppSelector(selectActiveRemoteRunners)
    const remoteRunners = useAppSelector(selectRemoteRunners)
    const [selectedRemoteJobTemplate, setSelectedRemoteJobTemplate] = useState("")
    const [selectedRemoteRunners, setSelectedRemoteRunners] = useState<number[]>([])

    const { t } = useTranslation()

    const dispatch = useAppDispatch()

    useEffect(() => {
        const templateId = parseInt(selectedRemoteJobTemplate)
        if (!(isNaN(templateId) || remoteJobTemplates.map(remoteJobTemplate => remoteJobTemplate.id).includes(templateId))) {
            setSelectedRemoteJobTemplate("")
        }
    }, [remoteJobTemplates, selectedRemoteJobTemplate])

    useEffect(() => {
        const availableRemoteRunnerIds = activeRemoteRunners.map(remoteRunner => remoteRunner.id)
        const deletedIds = selectedRemoteRunners.filter(selectedRemoteRunner => !availableRemoteRunnerIds.includes(selectedRemoteRunner))
        if (deletedIds.length > 0) {
            setSelectedRemoteRunners(selectedRemoteRunners => selectedRemoteRunners.filter(selectedRemoteRunner => !deletedIds.includes(selectedRemoteRunner)))
        }
    }, [activeRemoteRunners, selectedRemoteRunners])

    const isRunnerSelected = (runnerId: number, selectedRunners: number[]) => {
        return selectedRunners.indexOf(runnerId) === -1 ? false : true
    }

    const remoteTemplateSelectionOptions = remoteJobTemplates.map((remoteJobTemplate) => {
        return (
            <MenuItem
                key={remoteJobTemplate.id}
                value={remoteJobTemplate.id}
            >
                {remoteJobTemplate.name}
            </MenuItem>
        )
    })

    const getSelectedRemoteRunners = (remoteRunnerIds: number[]) => {
        return compactMap<number, IRemoteRunner>(remoteRunnerIds, ((remoteRunnerId) => {
            return activeRemoteRunners.filter(activeRemoteRunner => {
                return activeRemoteRunner.id === remoteRunnerId ? true : false
            }).pop()
        }))
    }

    const getSelectedRemoteRunnersAsStrings = (remoteRunnerIds: number[]) => {
        return getSelectedRemoteRunners(remoteRunnerIds).map(remoteRunner => {
            return remoteRunner.name
        })
    }

    const getSelectedRemoteRunnersAsIds = (remoteRunnerIds: number[]) => {
        return getSelectedRemoteRunners(remoteRunnerIds).map(remoteRunner => {
            return remoteRunner.id
        })
    }

    const handleChange = (event: SelectChangeEvent<typeof selectedRemoteRunners>) => {
        const {
          target: { value },
        } = event
        setSelectedRemoteRunners(
            // On autofill we get a stringified value.
            typeof value === 'string' ? getSelectedRemoteRunnersAsIds(value.split(',').map(value => parseInt(value))) : value,
        )
      }
      
    const hasAllNeededData = () => {
        return selectedRemoteJobTemplate !== "" &&
                selectedRemoteRunners.length > 0
    }

    const startRemoteJobs = () => {
        const tag = generateId(20)

        const createdJobsPromises = selectedRemoteRunners.map(remoteRunner => {
            const remoteJob: ICreateRemoteJob = {
                runner: remoteRunner,
                job_template: parseInt(selectedRemoteJobTemplate),
                tag: tag
            }
            return dispatch(createRemoteJobAsync(remoteJob))
        })
        Promise.all(createdJobsPromises).then((_resultPromise) => {
            dispatch(addOrActivateRemoteJobsWidget(tag, remoteJobTemplates.filter(remoteJobTemplate => remoteJobTemplate.id === parseInt(selectedRemoteJobTemplate)).pop()))
        })
    }

    const rerunAllRemoteJobs = (tag: string) => {
        const currentTag = tag

        remoteJobs.filter(remoteJob => remoteJob.tag === currentTag)
        .forEach(remoteJob => {
            const updatedRemoteJob: IUpdateRemoteJob = {
                id: remoteJob.id,
                data: {
                    state: 'CREATED',
                    desired_state: 'RUNNING',
                    error_description: '',
                    finished_at: null
                }
            }
            dispatch(deleteGenericRemoteJobDataLines(remoteJob.id))
            dispatch(updateRemoteJobAsync(updatedRemoteJob))
        })
    }

    const stopRemoteJob = (remoteJob: IRemoteJob) => {
        const updatedRemoteJob: IUpdateRemoteJob = {
            id: remoteJob.id,
            data: {
                desired_state: 'FINISHED',
            }
        }
        dispatch(updateRemoteJobAsync(updatedRemoteJob))
    }

    const deleteRemoteJobs = (tag: string) => {
        remoteJobs.filter(remoteJob => remoteJob.tag === tag)
        .forEach(remoteJob => {
            dispatch(deleteRemoteJobAsync(remoteJob.id))
        })
    }

    const handleOnClick = (remoteJob: IRemoteJob) => {
        const remoteRunner = remoteRunners.filter(remoteRunner => remoteRunner.id === remoteJob.runner).pop()
        dispatch(addOrActivateGenericRemoteJobResultWidget(remoteJob, remoteRunner!))
    }   
    
    const handleAllArtifactsDownloadForTag = () => {
        let headers = new Headers()
        headers.append('Content-Type', 'application/x-zip-compressed')
        headers.append('Accept', 'application/x-zip-compressed')

        authenticatedFetch(`${httpBackendURL}/api/remote_job_artifacts/${tag}/download_zipped_artifacts_of_tag/`, "GET", headers).then(response => {
            if(response.status !== 200){
                dispatch(showUserMessage({ title: t('Artifact Download failed'), message: t("The requested artifact does not seem to exist") }))
                return
            }
            response.blob().then(blob => {
                const fileURL = window.URL.createObjectURL(blob)
                let alink = document.createElement('a')
                alink.href = fileURL
                alink.download = `remote_jobs_${tag}_artifacts.zip`
                alink.click()
            })
        })
    }

    const getRemoteJobsAsRows = () => {
        return remoteJobs.filter((remoteJob) => remoteJob.tag === tag).map((remoteJob: IRemoteJob) => {
            return ( 
            <TableRow
                 sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                 key={remoteJob.id}
             >
                <TableCell id="generic-job-table-row-runner-name" component="th" scope="row">
                    {remoteRunners.filter(remoteRunner => remoteRunner.id === remoteJob.runner).pop()?.name}
                </TableCell>
                <TableCell align="right">{remoteJob.id}</TableCell>
                <TableCell align="right">{utcToLocalDateTimeRepresentation(remoteJob.created_at)}</TableCell>
                <TableCell align="right">{utcToLocalDateTimeRepresentation(remoteJob.finished_at)}</TableCell>
                <TableCell id="generic-job-table-row-job-state" align="right">{getRemoteJobStateMappedToRepresentation(remoteJob.state)}</TableCell>
                <TableCell align="right">{remoteJob.error_description}</TableCell>
                <TableCell align="right">
                    <Button
                        sx={{ margin: 1 }}
                        variant="contained"
                        color="error"
                        onClick={() => stopRemoteJob(remoteJob)}
                        disabled={remoteJob.state === 'FINISHED_SUCCESS' || remoteJob.state === 'FINISHED_ERROR'}
                    >
                        {t("Stop Job")}
                    </Button>
                    <Button
                        sx={{ margin: 1 }}
                        variant="contained"
                        color="primary"
                        onClick={() => handleOnClick(remoteJob)}
                    >
                        {t("View")}
                    </Button>
                </TableCell>
             </TableRow>
            )
        })
    }

    const getRemoteJobsAsTable = () => {
        return (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 300}} aria-label="Generic Jobs Table">
                <TableHead>
                  <TableRow>
                    <TableCell><b>{t("Probe")}</b></TableCell>
                    <TableCell align="right"><b>{t("Job ID")}</b></TableCell>
                    <TableCell align="right"><b>{t("Created at")}</b></TableCell>
                    <TableCell align="right"><b>{t("Finished at")}</b></TableCell>
                    <TableCell align="right"><b>{t("State")}</b></TableCell>
                    <TableCell align="right"><b>{t("Error description")}</b></TableCell>
                    <TableCell align="right">
                        <Button
                            sx={{ margin: 1 }}
                            variant="contained"
                            color="primary"
                            onClick={() => handleAllArtifactsDownloadForTag()}
                        >
                            {t("Download all artifacts")}
                        </Button>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getRemoteJobsAsRows()}
                </TableBody>
              </Table>
            </TableContainer>
          )
    }

    return (
        <Container sx={{ overflow: "hidden", overflowY: "auto", height: "90vh", marginLeft: -4 }}>
            {tag === undefined && remoteJobTemplateId === undefined ? 
                <Container id="start-generic-jobs-container">
                    <Typography variant="h6">{t("Start generic job(s)")}</Typography>
                    <Typography variant="body1">{t("Start one or more generic jobs by selecting the template and one or more probes on which the job should be run.")}</Typography>
                    <FormControl sx={{ margin: 1, marginTop:2, minWidth: 200 }}>
                        <InputLabel id="custom-job-remote-template-select-label">
                            {t("Template")}
                        </InputLabel>
                        <Select
                            labelId="custom-job-remote-template-select-label"
                            id="custom-job-remote-template-select"
                            value={selectedRemoteJobTemplate}
                            label={t("Template")}
                            onChange={(e) => setSelectedRemoteJobTemplate(e.target.value)}
                        >
                            {remoteTemplateSelectionOptions}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ margin: 1, marginTop: 2, width: 300 }}>
                        <InputLabel id="custom-job-remote-runners-select-label">
                            {t("Probes")}
                        </InputLabel>
                        <Select
                        labelId="custom-job-remote-runners-select-label"
                        id="custom-job-remote-runners-select"
                        multiple
                        label="custom-job-remote-runners-select-label"
                        value={selectedRemoteRunners}
                        onChange={handleChange}
                        renderValue={(selected) => getSelectedRemoteRunnersAsStrings(selected).join(', ')}
                        >
                        {activeRemoteRunners.map((remoteRunner) => (
                            <MenuItem 
                                key={remoteRunner.name} 
                                value={remoteRunner.id}
                            >
                                {isRunnerSelected(remoteRunner.id, selectedRemoteRunners) ?
                                    <b>{remoteRunner.name}</b>
                                :
                                    remoteRunner.name
                                } 
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                    <ButtonWithImplicitLicenseCheck
                        text="Start"
                        id="custom-job-remote-runners-start-button"
                        sx={{ margin: 1 }}
                        color="primary"
                        variant="contained"
                        onClickCallback={startRemoteJobs}
                        disabledCondition={!hasAllNeededData()}
                    ></ButtonWithImplicitLicenseCheck>
                </Container>
                :
                <Container id="generic-job-container">
                    <Typography variant="h6">{t("Overview of the started generic jobs")}</Typography>
                    <Container sx={{marginLeft: -2, marginTop: 1, marginBottom: 1}}>
                        <Chip color="primary" label={`ID: ${tag}`}/>
                        <Chip color="primary" sx={{marginLeft: 1}} label={`Template: ${remoteJobTemplates.filter(template => template.id === remoteJobTemplateId).pop()?.name}`}/>
                    </Container>
                    {getRemoteJobsAsTable()}
                    <Button
                        id={props.id}
                        color="primary"
                        variant="contained"
                        onClick={() => rerunAllRemoteJobs(tag)}
                    >
                        {t("Start all jobs again")}
                    </Button>
                    <DeleteElementButton
                        id="generic-job-delete-button"
                        title={t("Do you really want to delete the job(s)?")}
                        message={t("Deleting jobs is permanent and can not be undone. Of course you can always start them again. Are you sure?")}
                        deleteCallback={() =>  deleteRemoteJobs(tag)}
                    />
                </Container>
            }
        </Container>
    )
}
