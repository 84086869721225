import React, { useMemo, useState } from "react"
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    IRemoteRunner,
    deleteRemoteRunnerAsync,
    selectRemoteRunners,
} from "./RemoteRunnerSlice";
import { useTranslation } from "react-i18next";
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { DeleteElementButton, TableToolbarWithFilterInput, getSortComparator, utcToLocalDateTimeRepresentation } from '../misc/Util';
import Button from '@mui/material/Button';
import { addOrActivateRemoteRunnerConfigurationWidget } from '../main_lumino_widget/MainLuminoWidgetSlice';
import { RemoteRunnerCreate } from './RemoteRunnerCreate';
import { THeadAlign, TSortOrder } from '../misc/GlobalTypes';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';

interface TableHeadCell {
    id: keyof IRemoteRunner
    label: string
    align: THeadAlign
}

const tableHeadCells: readonly TableHeadCell[] = [
    {
        id: "name",
        label: "Name",
        align: "left"
    },
    {
        id: "created_at",
        label: "Created at",
        align: "right"
    },
    {
        id: "last_seen",
        label: "Last Seen",
        align: "right"
    },
]

export const RemoteRunners = () => {

    const remoteRunners = useAppSelector(selectRemoteRunners)
    const [rowsPerPage, setRowsPerPage] = useState(25)
    const [page, setPage] = useState(0)
    const [order, setOrder] = useState<TSortOrder>("desc")
    const [orderBy, setOrderBy] = useState<keyof IRemoteRunner>("created_at")
    const [nameFilterPattern, setNameFilterPattern] = useState("")

    const dispatch = useAppDispatch()

    const { t } = useTranslation()

    const handleChangePage = (_: unknown, newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const filteredAndSortedRemoteRunners = useMemo(() =>
        remoteRunners.filter((remoteRunner) => remoteRunner.name.toLowerCase().includes(nameFilterPattern.toLowerCase())).sort(getSortComparator(order, orderBy)), [nameFilterPattern, remoteRunners, order, orderBy])

    const setSortHandler = (key: keyof IRemoteRunner) => (_: React.MouseEvent<unknown>) => {
        const isAsc = orderBy === key && order === "asc"
        setOrder(isAsc ? "desc" : "asc")
        setOrderBy(key)
    }

    const editRemoteRunner = (remoteRunner: IRemoteRunner) => {
        dispatch(addOrActivateRemoteRunnerConfigurationWidget(remoteRunner))
    }

    const deleteRemoteRunner = (remoteRunner: IRemoteRunner) => {
        dispatch(deleteRemoteRunnerAsync(remoteRunner.id))
    }

    const getRemoteRunnerRows = (remoteRunners: IRemoteRunner[]): any[] => {
        return remoteRunners.map((remoteRunner: IRemoteRunner) => (
            <TableRow
                 sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                 key={remoteRunner.id}
             >
                <TableCell id="remote-runner-name" key={`table-cell-remote-runner-${remoteRunner.id}-name`} component="th" scope="row">
                    {remoteRunner.name}
                </TableCell>
                <TableCell key={`table-cell-remote-runner-${remoteRunner.id}-created_at`} align="right">
                    {utcToLocalDateTimeRepresentation(remoteRunner.created_at)}
                </TableCell>
                <TableCell key={`table-cell-remote-runner-${remoteRunner.id}-last_seen`} align="right">
                    {utcToLocalDateTimeRepresentation(remoteRunner.last_seen)}
                </TableCell>
                <TableCell key={`table-cell-remote-runner-${remoteRunner.id}-edit-elements`} align="right">
                    <Button
                        id="remote-runner-edit-button"
                        sx={{ margin: 1 }}
                        variant="contained"
                        color="primary"
                        onClick={() => editRemoteRunner(remoteRunner)}
                    >
                        {t("Edit")}
                    </Button>
                    <DeleteElementButton
                        id="remote-runner-delete-button"
                        title={t("Do you really want to delete this probe?") as string}
                        message={t("Deleting a probe is permanent and can not be undone. Of course you can always create a new one. Are you sure?") as string}
                        deleteCallback={() => deleteRemoteRunner(remoteRunner)}
                    />
                </TableCell>
             </TableRow>
            )
        )
    }

    const getRemoteRunnersAsTable = (remoteRunners: IRemoteRunner[]) => (
        <Paper sx={{ width: "100%", mb: 2 }}>
            <TableToolbarWithFilterInput
                hasValidFilter={true}
                toolbarTitle={t("Probes")}
                filterTextBoxLabel={t("Filter for names")}
                filterPattern={nameFilterPattern}
                setFilterPattern={setNameFilterPattern}
                getSuggestedFilterItems={(currentFilterString: string) =>
                    remoteRunners.filter((remoteRunner) => remoteRunner.name.toLowerCase().includes(currentFilterString.toLowerCase())).map((remoteRunner) => remoteRunner.name)
                }
            />
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 300 }} aria-label="Remote Runner Table">
                    <TableHead>
                        <TableRow>
                            {tableHeadCells.map((tableHeadCell) => (
                                <TableCell
                                    key={`table-header-cell-remote-runner-${tableHeadCell.id}`}
                                    align={tableHeadCell.align}
                                    sortDirection={orderBy === tableHeadCell.id ? order : false}
                                >
                                    <TableSortLabel
                                        active={orderBy === tableHeadCell.id}
                                        direction={orderBy === tableHeadCell.id ? order : "asc"}
                                        onClick={setSortHandler(tableHeadCell.id)}
                                    >
                                        <b>
                                            {t(tableHeadCell.label) /* FIXME: this will generate a warning during the extraction of the translatable strings */}
                                        </b>
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                            <TableCell align="right"/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { getRemoteRunnerRows(remoteRunners) }
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )

    return (
        <Container id="remote-runners-container" sx={{ overflow: "hidden", overflowY: "auto", height: "90vh", marginLeft: -1 }}>
            <Typography variant="h6">
                {t("Create a new probe")}
            </Typography>
            <RemoteRunnerCreate/>
            <Typography variant="h6" marginTop={2}>
                {t("Edit an existing probe")}
            </Typography>
            { getRemoteRunnersAsTable(filteredAndSortedRemoteRunners.slice(page * rowsPerPage, (page + 1) * rowsPerPage)) }
            <TablePagination
                rowsPerPageOptions={[25, 50]}
                component="div"
                count={filteredAndSortedRemoteRunners.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Container>
    )
}
