import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, store } from "../../app/store";
import {
    fetchAllRemoteJobArtifactsViaDCRF,
    subscribeToRemoteJobArtifactChangesViaDCRF,
} from "./RemoteJobArtifactAPI";
import {
    showUserMessage,
} from '../user_message/UserMessageSlice';
import { logger } from "../../app/logging";

type LastRequestStatus = 'success' | 'busy' | 'failed'

export const fetchAllRemoteJobArtifactsAsync = createAsyncThunk(
    'RemoteJobArtifact/fetchAllRemoteJobArtifactsAsync',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            return await fetchAllRemoteJobArtifactsViaDCRF()
        } catch (rejectedValue) {
            // @ts-ignore
            const error_message = JSON.stringify(rejectedValue.errors)
            dispatch(showUserMessage({ title: 'fetchAllRemoteJobArtifactsAsync failed', message: error_message }))
            return rejectWithValue(rejectedValue)
        }
    }
)

export interface IRemoteJobArtifact {
    id: number
    remote_job: number
    artifact_file: string
    artifact_type: string
    created_at: string
}

export interface IRemoteJobArtifacts {
    RemoteJobArtifacts: IRemoteJobArtifact[]
    lastRequestStatus: LastRequestStatus
    subscribedToUpdates: boolean
}

const initialState: IRemoteJobArtifacts = {
    RemoteJobArtifacts: [],
    subscribedToUpdates: false,
    lastRequestStatus: 'success',
}

const getUpdatedRemoteJobArtifacts = (currentRemoteJobArtifacts: IRemoteJobArtifact[], updatedRemoteJobArtifact: IRemoteJobArtifact) => {
    return currentRemoteJobArtifacts.map((existingRemoteJobArtifact: IRemoteJobArtifact) => {
        if (existingRemoteJobArtifact.id === updatedRemoteJobArtifact.id) {
            return updatedRemoteJobArtifact
        } else {
            return existingRemoteJobArtifact
        }
    })
}

export const RemoteJobArtifactsSlice = createSlice({
    name: 'remoteJobArtifacts',
    initialState,
    reducers: {
        addLocalRemoteJobArtifact: (state, action: PayloadAction<IRemoteJobArtifact>) => {
            if (!state.RemoteJobArtifacts.some(RemoteJobArtifact => RemoteJobArtifact.id === action.payload.id)) {
                state.RemoteJobArtifacts.push(action.payload)
            }
        },
        updateLocalRemoteJobArtifact: (state, action: PayloadAction<IRemoteJobArtifact>) => {
            state.RemoteJobArtifacts = getUpdatedRemoteJobArtifacts(state.RemoteJobArtifacts, action.payload)
        },
        deleteLocalRemoteJobArtifact: (state, action: PayloadAction<IRemoteJobArtifact>) => {
            state.RemoteJobArtifacts = state.RemoteJobArtifacts.filter((endpoint) => endpoint.id !== action.payload.id)
        },
        subscribeToRemoteJobArtifactChanges: (state) => {
            if (state.subscribedToUpdates) {
                return
            }
            const callback = (RemoteJobArtifact: IRemoteJobArtifact, action: string) => {
                logger.debug(`remote runner subscription callback (action: ${action})`)
                switch(action) {
                    case 'create':
                        store.dispatch(addLocalRemoteJobArtifact(RemoteJobArtifact))
                        break
                    case 'update':
                        store.dispatch(updateLocalRemoteJobArtifact(RemoteJobArtifact))
                        break
                    case 'delete':
                        store.dispatch(deleteLocalRemoteJobArtifact(RemoteJobArtifact))
                        break
                    default:
                        logger.debug('FIXME: unexpected action')
                }
            }
            subscribeToRemoteJobArtifactChangesViaDCRF(callback)
            state.subscribedToUpdates = true
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllRemoteJobArtifactsAsync.pending, (state) => {
                state.lastRequestStatus = 'busy'
            })
            .addCase(fetchAllRemoteJobArtifactsAsync.fulfilled, (state, action: PayloadAction<IRemoteJobArtifact[]>) => {
                state.lastRequestStatus = 'success'
                state.RemoteJobArtifacts = action.payload
            })
            .addCase(fetchAllRemoteJobArtifactsAsync.rejected, (state) => {
                state.lastRequestStatus = 'failed'
            })
    }
})

export const {
    addLocalRemoteJobArtifact,
    updateLocalRemoteJobArtifact,
    deleteLocalRemoteJobArtifact,
    subscribeToRemoteJobArtifactChanges } = RemoteJobArtifactsSlice.actions

export const selectRemoteJobArtifacts = (state: RootState) => state.remoteJobArtifacts.RemoteJobArtifacts
export const selectRemoteJobArtifact = (id: number) => (state: RootState) => {
    return state.remoteJobArtifacts.RemoteJobArtifacts.filter( RemoteJobArtifact => RemoteJobArtifact.id === id).pop()
}

export const selectLastRemoteJobArtifactRequestStatus = (state: RootState) => state.remoteJobArtifacts.lastRequestStatus
export const selectRemoteJobArtifactIsSubscribed = (state: RootState) => state.remoteJobArtifacts.subscribedToUpdates

export default RemoteJobArtifactsSlice.reducer
