import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, store } from "../../app/store";
import {
    createRemoteJobContextViaDCRF,
    deleteRemoteJobContextViaDCRF,
    fetchAllRemoteJobContextsViaDCRF,
    patchRemoteJobContextViaDCRF,
    subscribeToRemoteJobContextChangesViaDCRF,
} from "./RemoteJobContextAPI";
import {
    showUserMessage,
} from '../user_message/UserMessageSlice';
import { logger } from "../../app/logging";

export interface IRemoteJobContextDataEntryValue {
    cls: string     // TODO: add a type
    desc: string
    always_active: boolean
    value: {[key: string]: any}     // value specific "data" (value)
}

export interface IRemoteJobContextDataEntry {
    id?: number,
    key: string,
    value: IRemoteJobContextDataEntryValue,
    priority: number,
    created_at?: string,
    last_updated?: string
}

export interface IRemoteJobContext {
    id: number,
    namespace: string,
    created_at: string,
    last_updated: string
    data_entries: IRemoteJobContextDataEntry[]
}

type LastRequestStatus = 'success' | 'busy' | 'failed'

export interface IRemoteJobContexts {
    remoteJobContexts: IRemoteJobContext[]
    lastRequestStatus: LastRequestStatus
    subscribedToUpdates: boolean
}

export const fetchAllRemoteJobContextsAsync = createAsyncThunk(
    'RemoteJobContext/fetchAllRemoteJobContextsAsync',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            return await fetchAllRemoteJobContextsViaDCRF()
        } catch (rejectedValue) {
            // @ts-ignore
            const error_message = JSON.stringify(rejectedValue.errors)
            dispatch(showUserMessage({ title: 'fetchAllRemoteJobContextsAsync failed', message: error_message }))
            return rejectWithValue(rejectedValue)
        }
    }
)

export interface IUpdateRemoteJobContext {
    id: number
    data: {
        namespace: string,
        data_entries: IRemoteJobContextDataEntry[]
    }
}

export const updateRemoteJobContextAsync = createAsyncThunk(
    'RemoteJobContext/updateRemoteJobContextAsync',
    async (updatedRemoteJobContext: IUpdateRemoteJobContext, { dispatch, rejectWithValue }) => {
        try {
            return await patchRemoteJobContextViaDCRF(updatedRemoteJobContext.id, updatedRemoteJobContext.data)
        } catch (rejectedValue) {
            // @ts-ignore
            const error_message = JSON.stringify(rejectedValue.errors)
            dispatch(showUserMessage({ title: 'updateRemoteJobContextAsync failed', message: error_message }))
            return rejectWithValue(rejectedValue)
        }
    }
)

export interface ICreateRemoteJobContext {
    namespace: string,
    data_entries: IRemoteJobContextDataEntry[]
}

export const createRemoteJobContextAsync = createAsyncThunk(
    'RemoteJobContext/createRemoteJobContextAsync',
    async (newRemoteJobContext: ICreateRemoteJobContext, { dispatch, rejectWithValue }) => {
        try {
            return await createRemoteJobContextViaDCRF(newRemoteJobContext)
        } catch (rejectedValue) {
            // @ts-ignore
            const error_message = JSON.stringify(rejectedValue.errors)
            dispatch(showUserMessage({ title: 'createRemoteJobContextAsync failed', message: error_message }))
            return rejectWithValue(rejectedValue)
        }
    }
)

export const deleteRemoteJobContextAsync = createAsyncThunk(
    'RemoteJobContext/deleteRemoteJobContextAsync',
    async (id: number, { dispatch, rejectWithValue }) => {
        try {
            return await deleteRemoteJobContextViaDCRF(id)
        } catch (rejectedValue) {
            // @ts-ignore
            const error_message = JSON.stringify(rejectedValue.errors)
            dispatch(showUserMessage({ title: 'deleteRemoteJobContextAsync failed', message: error_message }))
            return rejectWithValue(rejectedValue)
        }
    }
)

const getUpdatedRemoteJobContexts = (currentRemoteJobContexts: IRemoteJobContext[], updatedRemoteJobContext: IRemoteJobContext) => {
    return currentRemoteJobContexts.map((existingRemoteJobContext: IRemoteJobContext) => {
        if (existingRemoteJobContext.id === updatedRemoteJobContext.id) {
            return updatedRemoteJobContext
        } else {
            return existingRemoteJobContext
        }
    })
}


const initialState: IRemoteJobContexts = {
    remoteJobContexts: [],
    subscribedToUpdates: false,
    lastRequestStatus: 'success',
}

export const remoteJobContextsSlice = createSlice({
    name: 'remoteJobContexts',
    initialState,
    reducers: {
        addLocalRemoteJobContext: (state, action: PayloadAction<IRemoteJobContext>) => {
            if (state.remoteJobContexts.every(remoteJobContext => remoteJobContext.id !== action.payload.id)) {
                state.remoteJobContexts.push(action.payload)
            }
        },
        updateLocalRemoteJobContext: (state, action: PayloadAction<IRemoteJobContext>) => {
            state.remoteJobContexts = getUpdatedRemoteJobContexts(state.remoteJobContexts, action.payload)
        },
        deleteLocalRemoteJobContext: (state, action: PayloadAction<IRemoteJobContext>) => {
            state.remoteJobContexts = state.remoteJobContexts.filter((remoteJobContext) => remoteJobContext.id !== action.payload.id)
        },
        subscribeToRemoteJobContextChanges: (state) => {
            if (state.subscribedToUpdates) {
                return
            }
            const callback = (remoteJobContext: IRemoteJobContext, action: string) => {
                logger.debug(`remote job context subscription callback (action: ${action})`)
                switch(action) {
                    case 'create':
                        store.dispatch(addLocalRemoteJobContext(remoteJobContext))
                        break
                    case 'update':
                        store.dispatch(updateLocalRemoteJobContext(remoteJobContext))
                        break
                    case 'delete':
                        // NOTE: We get the action.payload with a non null value here. That means,
                        // that this callback and the deleteLocalRemoteJob are responsible for
                        // keeping the state up to date when a remote job gets deleted.
                        // deleteRemoteJobAsync.fulfilled does only get a null value
                        store.dispatch(deleteLocalRemoteJobContext(remoteJobContext))
                        break
                    default:
                        logger.debug('FIXME: unexpected action')
                }
            }
            subscribeToRemoteJobContextChangesViaDCRF(callback)
            state.subscribedToUpdates = true
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllRemoteJobContextsAsync.pending, (state) => {
                state.lastRequestStatus = 'busy'
            })
            .addCase(fetchAllRemoteJobContextsAsync.fulfilled, (state, action: PayloadAction<IRemoteJobContext[]>) => {
                state.lastRequestStatus = 'success'
                state.remoteJobContexts = action.payload
            })
            .addCase(fetchAllRemoteJobContextsAsync.rejected, (state) => {
                state.lastRequestStatus = 'failed'
            })
            .addCase(createRemoteJobContextAsync.pending, (state) => {
                state.lastRequestStatus = 'busy'
            })
            .addCase(createRemoteJobContextAsync.fulfilled, (state, action: PayloadAction<IRemoteJobContext>) => {
                state.lastRequestStatus = 'success'
                if (state.remoteJobContexts.every(remoteJobContext => remoteJobContext.id !== action.payload.id)) {
                    state.remoteJobContexts.push(action.payload)
                }
            })
            .addCase(createRemoteJobContextAsync.rejected, (state) => {
                state.lastRequestStatus = 'failed'
            })
            .addCase(updateRemoteJobContextAsync.pending, (state) => {
                state.lastRequestStatus = 'busy'
            })
            .addCase(updateRemoteJobContextAsync.fulfilled, (state, action: PayloadAction<IRemoteJobContext>) => {
                state.lastRequestStatus = 'success'
                state.remoteJobContexts = getUpdatedRemoteJobContexts(state.remoteJobContexts, action.payload)
            })
            .addCase(updateRemoteJobContextAsync.rejected, (state) => {
                state.lastRequestStatus = 'failed'
            })
            .addCase(deleteRemoteJobContextAsync.pending, (state) => {
                state.lastRequestStatus = 'busy'
            })
            .addCase(deleteRemoteJobContextAsync.fulfilled, (state, action: PayloadAction<IRemoteJobContext>) => {
                state.lastRequestStatus = 'success'
                // NOTE: action.payload.id is not available here (null value; update, fetch and create get a non null value) 
                // so just leave the state as it is (the subscription will trigger the deletion).
                // This means we are dependent on a functioning subscription callback
            })
            .addCase(deleteRemoteJobContextAsync.rejected, (state) => {
                state.lastRequestStatus = 'failed'
            })
    }
})

export const {
    addLocalRemoteJobContext,
    updateLocalRemoteJobContext,
    deleteLocalRemoteJobContext,
    subscribeToRemoteJobContextChanges } = remoteJobContextsSlice.actions

export const selectRemoteJobContexts = (state: RootState) => state.remoteJobContexts.remoteJobContexts
export const selectRemoteJobContext = (id: number) => (state: RootState) =>
    state.remoteJobContexts.remoteJobContexts.filter(RemoteJobContext => RemoteJobContext.id === id).pop()
export const selectLastRemoteJobContextRequestStatus = (state: RootState) => state.remoteJobContexts.lastRequestStatus
export const selectRemoteJobContextIsSubscribed = (state: RootState) => state.remoteJobContexts.subscribedToUpdates

export default remoteJobContextsSlice.reducer
