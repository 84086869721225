import { dcrfClient } from "../../app/api";
import { logger } from "../../app/logging";
import { ICreateGroup, IGroup } from "./GroupSlice";

const wsStream = 'groups'

export const fetchAllGroupsViaDCRF = () => {
    logger.debug('fetchAllGroupsViaDCRF')
    return dcrfClient.list(wsStream)
}

export const patchGroupViaDCRF = (id: number, data: any) => {
    logger.debug(`patchGroupViaDCRF with id: ${id} - data: ${data}`)
    return dcrfClient.patch(wsStream, id, data)
}

export const createGroupViaDCRF = (newGroup: ICreateGroup) => {
    logger.debug(`createGroupViaDCRF ${newGroup}`)
    return dcrfClient.create(wsStream, newGroup)
}

export const deleteGroupViaDCRF = (id: number) => {
    logger.debug(`deleteGroupViaDCRF with id: ${id}`)
    return dcrfClient.delete(wsStream, id)
}

export const subscribeToGroupChangesViaDCRF = (callback: (Group: IGroup, action: string) => void) => {
    logger.debug("subscribeToGroupChangesViaDCRF")
    const subscription = dcrfClient.subscribe(wsStream,
        {},
        (Group, action) => {
            callback(Group as IGroup, action)
        },
        {
            includeCreateEvents: true,
            subscribeAction: 'subscribe_to_all_changes',
            unsubscribeAction: 'unsubscribe_from_all_changes',
        }
    )
    // a subscription can be canceled by calling "cancel" on the returned subscription object
    return subscription
}
