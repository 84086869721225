import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import TextField from "@mui/material/TextField"
import Checkbox from "@mui/material/Checkbox"
import Tooltip from "@mui/material/Tooltip"
import { useTranslation } from "react-i18next"
import { Dispatch, useEffect, useState } from "react"

export const ISOTPEndpointEdit = (props: any) => {

    const [name, setName] = props.stateName
    const [hwInterface, setHwInterface] = props.stateHwInterface
    const [txId, setTxId] = props.stateTxId
    const [rxId, setRxId] = props.stateRxId
    const [rxExtAddr, setRxExtAddr] = props.stateRxExt
    const [txExtAddr, setTxExtAddr] = props.stateExt
    const [padding, setPadding] = props.statePadding
    const [useHex, setUseHex] = useState(false)
    const [useHexHasChanged, setUseHexHasChanged] = useState(false)
    const [txIdDisplayed, setTxIdDisplayed] = useState(txId ? txId : '')
    const [rxIdDisplayed, setRxIdDisplayed] = useState(rxId ? rxId : '')
    const [txExtAddrDisplayed, setTxExtAddrDisplayed] = useState(txExtAddr ? txExtAddr : '')
    const [rxExtAddrDisplayed, setRxExtAddrDisplayed] = useState(rxExtAddr ? rxExtAddr : '')

    const { t } = useTranslation()

    const handleChange = (value: any, setter: Dispatch<any>, setterDisplayed: Dispatch<any>) => {
        if (useHex === true) {
            setter(`${parseInt(value.replace("0x", ""), 16)}`)
            setterDisplayed(value)
        } else {
            setter(value)
            setterDisplayed(value)
        }
    }

    useEffect(() => {
        const getterSetterDisplayedStates = [
            [txIdDisplayed, setTxIdDisplayed],
            [rxIdDisplayed, setRxIdDisplayed],
            [txExtAddrDisplayed, setTxExtAddrDisplayed],
            [rxExtAddrDisplayed, setRxExtAddrDisplayed]
        ]

        if (useHexHasChanged) {
            const oldUseHex = !useHex
            getterSetterDisplayedStates.forEach(([state, setState]) => {
                const value = parseInt(state, oldUseHex ? 16 : 10)
                isNaN(value) ? setState('') : (useHex ? setState(`0x${value.toString(16)}`) : setState(`${value}`))
            })
            setUseHexHasChanged(false)
        }
    // eslint-disable-next-line
    }, [useHexHasChanged])

    return (
        <div>
            <Tooltip title={t("Name / identifier of this endpoint") as string}>
                <TextField
                    sx={{ margin: 1 }}
                    id="isotp-endpoint-edit-name"
                    label={t("Name")}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </Tooltip>
            <Tooltip title={t("Name / identifier of the CAN interface this endpoint was found") as string}>
                <TextField
                    sx={{ margin: 1}}
                    id="isotp-endpoint-edit-hwinterface"
                    label={t("HW Interface")}
                    value={hwInterface}
                    onChange={(e) => setHwInterface(e.target.value)}
                />
            </Tooltip>

            <br/>

            <Tooltip title={t("Sender CAN ID") as string}>
                <TextField
                    sx={{ margin: 1 }}
                    id="isotp-endpoint-edit-txid"
                    label={t("TXID")}
                    //helperText="Sender CAN ID"
                    value={txIdDisplayed}
                    onChange={(e) => handleChange(e.target.value, setTxId, setTxIdDisplayed)}
                />
            </Tooltip>

            <Tooltip title={t("Extended sender address (opt.)") as string}>
                <TextField
                    sx={{ margin: 1 }}
                    id="isotp-endpoint-edit-txext"
                    label={t("TXEXT")}
                    //helperText="Extended Sender Address"
                    value={txExtAddrDisplayed}
                    onChange={(e) => handleChange(e.target.value, setTxExtAddr, setTxExtAddrDisplayed)}
                />
            </Tooltip>

            <br/>

            <Tooltip title={t("Receiver CAN ID") as string}>
                <TextField
                    sx={{ margin: 1 }}
                    id="isotp-endpoint-edit-rxid"
                    label={t("RXID")}
                    //helperText="Receiver CAN ID"
                    value={rxIdDisplayed}
                    onChange={(e) => handleChange(e.target.value, setRxId, setRxIdDisplayed)}
                />
            </Tooltip>

            <Tooltip title={t("Extended Receiver Address (opt.)") as string}>
                <TextField
                    sx={{ margin: 1 }}
                    id="isotp-endpoint-edit-rxext"
                    label={t("RXEXT")}
                    //helperText="Extended Receiver Address"
                    value={rxExtAddrDisplayed}
                    onChange={(e) => handleChange(e.target.value, setRxExtAddr, setRxExtAddrDisplayed)}
                />
            </Tooltip>

            <br/>

            <FormGroup sx={{ margin: 1 }}  row={true}>
                <Tooltip title={t("Use padding") as string}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="isotp-endpoint-edit-padding"
                                checked={padding}
                                onChange={(e) => setPadding(e.target.checked)}
                            />
                        }
                        label={t("Padding")}
                    />
                </Tooltip>
                <Tooltip title={t("Use and display hexadecimal numbers") as string}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="isotp-endpoint-edit-use-hex"
                                checked={useHex}
                                onChange={(e) => {
                                    setUseHex(e.target.checked)
                                    setUseHexHasChanged(true)
                                }}
                            />
                        }
                        label={t("Use hex")}
                    />
                </Tooltip>
            </FormGroup>
        </div>
    )
}