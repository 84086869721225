import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useTranslation } from 'react-i18next'
import { Button, Checkbox, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { useState } from "react";
import { IUpdateUser, selectCurrentUser, updateUserAsync } from "./UserSlice";
import { selectGroups } from "../group_management/GroupSlice";
import { selectUserDataAsObject } from "../settings/UserDataSlice";
import { getUserDataEntryFor, setUserDataEntryFor } from "../misc/Util";
import { userDataExpertModeEnabled } from "../misc/Constants";

export const User = (props: any) => {
    const currentUser = useAppSelector(selectCurrentUser)
    const groups = useAppSelector(selectGroups)
    const userGroups = groups.filter(group => currentUser?.groups.indexOf(group.id) !== -1)
    const [passwordChangeDialogOpen, setPasswordChangeDialogOpen] = useState(false)
    const [authenticatedUserPassword, setAuthenticatedUserPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("")
    const userDataObject = useAppSelector(selectUserDataAsObject)

    const { t } = useTranslation()

    const dispatch = useAppDispatch()

    const handlePasswordChangeOpen = () => {
        setPasswordChangeDialogOpen(true)
    }

    const handlePasswordChangeClose = () => {
        setPasswordChangeDialogOpen(false)
        setAuthenticatedUserPassword("")
        setNewPassword("")
        setNewPasswordConfirmation("")
    }

    const changePasword = () => {
        const updatedUser: IUpdateUser = {
            id: currentUser!.id,
            data: {
                password: newPassword,
                authenticated_user_password: authenticatedUserPassword
            }
        }
        dispatch(updateUserAsync(updatedUser))
        handlePasswordChangeClose()
    }

    const hasAllNeededDataPaswordChange = () => {
        return authenticatedUserPassword !== "" &&
            newPassword !== "" &&
            newPasswordConfirmation !== "" &&
            newPassword === newPasswordConfirmation
    }

    return (
        <Container id="user-container" sx={{ marginLeft: -2, overflow: "hidden", overflowY: "auto", height: "90vh" }}>
            <TextField
                sx={{ margin: 1 }}
                id="user-first-name"
                label={t("Name")}
                value={currentUser?.name ?? ("undefined")}
                disabled
            />
            <TextField
                sx={{ margin: 1 }}
                id="user-email"
                label={t("Email")}
                value={currentUser?.email ?? t("undefined")}
                disabled
            />
            <br />
            <Button
                sx={{ margin: 1 }}
                variant="contained"
                onClick={handlePasswordChangeOpen}
            >
                {t("Change Password")}
            </Button>
            <br />
            <TableContainer sx={{ margin: 1, width: 300 }} component={Paper}>
                <Table aria-label="user-groups-table">
                    <TableHead>
                        <TableRow>
                            <TableCell><b>{t("Assigned Groups")}</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {userGroups.map((row) => (
                            <TableRow
                                key={`group-${row}`}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <FormGroup sx={{ margin: 1 }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            id={"set-expert-mode"}
                            checked={getUserDataEntryFor(userDataExpertModeEnabled, userDataObject, false)}
                            onChange={(e) => setUserDataEntryFor(userDataExpertModeEnabled, e.target.checked, userDataObject)}
                        />
                    }
                    label={t("Expert Mode")}
                />
            </FormGroup>
            <Dialog open={passwordChangeDialogOpen} onClose={handlePasswordChangeClose}>
                <DialogTitle>{t("Change Password")}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="user-old-password"
                        label={t("Old Password")}
                        type="password"
                        value={authenticatedUserPassword}
                        onChange={(e) => setAuthenticatedUserPassword(e.target.value)}
                        fullWidth
                    />
                    <TextField
                        margin="dense"
                        id="user-new-password"
                        label={t("New Password")}
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        fullWidth
                    />
                    <TextField
                        margin="dense"
                        id="name"
                        label={t("Password confirmation")}
                        type="password"
                        value={newPasswordConfirmation}
                        onChange={(e) => setNewPasswordConfirmation(e.target.value)}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handlePasswordChangeClose}>{t("Cancel")}</Button>
                    <Button onClick={changePasword} disabled={!hasAllNeededDataPaswordChange()}>{t("Change")}</Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}