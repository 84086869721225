import { Container, TextField } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";

export const LicenseManagementEdit = (props: any) => {

    const [licenseText, setLicenseText] = props.stateLicenseText
    const { t } = useTranslation()

    return (
        <Container>
            <Tooltip title={t("Insert license here") as string}>
                <TextField
                    id="license-textarea-field"
                    placeholder={t("License")}
                    multiline
                    fullWidth={true}
                    value={licenseText}
                    onChange={(e) => setLicenseText(e.target.value)}
                />
            </Tooltip>
        </Container>
    )
}