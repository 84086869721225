import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Root } from 'react-dom/client';
import { RootState, store } from '../../app/store';
import { IHardwareInterface } from '../hardware_interface/HardwareInterfacesSlice'
import { IISOTPEndpoint } from "../isotp_endpoint/ISOTPEndpointsSlice";
import { IISOTPEndpointScanRun } from '../isotp_endpoint_scan_run/ISOTPEndpointScanRunsSlice';
import { ILicense } from '../license/LicenseSlice';
import { IRemoteJob } from '../remote_jobs/RemoteJobSlice';
import { IRemoteJobTemplate } from '../remote_job_template/RemoteJobTemplateSlice';
import { IRemoteRunner } from '../remote_runner/RemoteRunnerSlice';
import { IUDSScanRun, IUDSScanRunFinding } from '../uds_scan_run/UDSScanRunsSlice';
import { ITargetECU } from '../target_ecu/TargetECUSlice';
import i18n from 'i18next';

export type ReactWidgetComponentType = 'COUNTER' | 'HARDWAREWINTERFACECONFIG' | 'UDSSCANRUN' | 'UDSSCANREPORT' | 
                                       'ISOTPENDPOINTSCANRUN' | 'ISOTPENDPOINTSADDMANUALLY' | 'ISOTPENDPOINTCONFIG' |
                                       'GLOBALLIVEDATA' | 'MAINTREENAVIGATION' | 'TERMINAL' | 'LICENSES' | 'LICENSECONFIG' |
                                       'IMPRESSUM' | 'SETTINGS' | 'VERSIONS' | 'EXTERNALLICENSES' | 'REMOTERUNNERS' |
                                       'REMOTERUNNERCONFIG' | 'REMOTEJOBTEMPLATES' | 'REMOTEJOBTEMPLATE' | 'REMOTE' | 'REMOTEJOBS' |
                                       'GENERICREMOTEJOBRESULT' | 'USERMANAGEMENT' | 'USER' | 'TARGETECUS' | 'TARGETECUCONFIG' | 'TESTCASESEXECUTION' |
                                       'TESTCASERESULT' | 'GROUPMANAGEMENT' | 'RAWLIVEDATA' | 'HYDRACOREDOCU' | 'UDSSCANREPORTDATAINSPECTOR'

export interface IWidgetUidToCmpRootHashMap {
  [key: string]: Root
}

export interface IAppWidgetContent {
  componentType: ReactWidgetComponentType
  props: any                                // react properties for the component
}

export interface IAppWidget {
  uid: string
  name: string
  active: boolean
  content: IAppWidgetContent
}

export interface IMainLuminoWidgetState {
  widgets: IAppWidget[]
}

const initialState: IMainLuminoWidgetState = {
  widgets: []
}

export const mainLuminoWidgetSlice = createSlice({
  name: 'mainLuminoWidget',
  initialState,
  reducers: {
    activateWidget: (state: IMainLuminoWidgetState, action: PayloadAction<string>) => {
      state.widgets = state.widgets.map((widget) => {
        widget.active = (widget.uid === action.payload)
        return widget
      })
    },
    addWidget: (state: IMainLuminoWidgetState, action: PayloadAction<IAppWidget>) => {
      if (!action.payload.active) {
        // just add the new widget
        state.widgets.push(action.payload)
      } else {
        // add the new widget and set it active
        state.widgets = [...state.widgets, action.payload].map((widget) => {
          widget.active = (widget.uid === action.payload.uid)
          return widget
        })
      }
    },
    deleteWidget: (state: IMainLuminoWidgetState, action: PayloadAction<string>) => {
      state.widgets = state.widgets.filter((widget) => widget.uid !== action.payload)
    }
  },
})

export const { activateWidget, addWidget, deleteWidget } = mainLuminoWidgetSlice.actions;

export const makeWidgetUid = (componentType: ReactWidgetComponentType, componentId: number | undefined) => {
  if (componentId !== undefined) {
    return `${componentType}::${componentId}`
  } else {
    return `${componentType}`
  }
}

const addOrActivateWidget = (uid: string, name: string, componentType: ReactWidgetComponentType, properties: any = {}, addActive: boolean = true) => {
  const existingWidget = store.getState().mainLuminoWidget.widgets.filter(widget => widget.uid === uid).pop()
  if (existingWidget === undefined) {
    // create a new widget
    return addWidget({ uid: uid, name: name, content: { componentType: componentType, props: properties }, active: addActive})
  } else {
    // activate the existing widget
    return activateWidget(uid)
  }
}

export const addOrActivateTestTerminalWidget = () => {
  return addOrActivateWidget('TERMINAL', i18n.t('Test Terminal'), 'TERMINAL')
}

export const addOrActivateMainTreeNavigationWidget = () => {
  return addOrActivateWidget('MAINTREENAVIGATION', i18n.t('Main Menu'), 'MAINTREENAVIGATION')
}

export const addOrActivateISOTPEndpointAddManualWidget = () => {
  // there is only one "add an endpoint manually" widget
  return addOrActivateWidget('ISOTPENDPOINTSADDMANUALLY', i18n.t('Add an ISOTP Endpoint'), 'ISOTPENDPOINTSADDMANUALLY')
}

export const addOrActivateISOTPEndpointScanRunWidget = (scanRun: IISOTPEndpointScanRun | undefined = undefined) => {
  // an undefined scan run object will add / activate the "add a new scan run" widget
  const widgetUid = makeWidgetUid('ISOTPENDPOINTSCANRUN', scanRun?.id)
  const widgetName = i18n.t('ISOTP Endpoint Scan Run') + (scanRun?.config.name === undefined ? '' : ` - ${scanRun!.config.name}`)
  return addOrActivateWidget(widgetUid, widgetName, 'ISOTPENDPOINTSCANRUN', { scanRunId: scanRun?.id })
}

export const addOrActivateHardwareInterfaceConfigurationWidget = (hwInterface: IHardwareInterface) => {
  const widgetUid = makeWidgetUid('HARDWAREWINTERFACECONFIG', hwInterface.id)
  const name = `${hwInterface.category} / ${hwInterface.subtype}`
  return addOrActivateWidget(widgetUid, name, 'HARDWAREWINTERFACECONFIG', { hwInterfaceId: hwInterface.id })
}

export const addOrActivateISOTPEndpointConfigurationWidget = (isotpEndpoint: IISOTPEndpoint) => {
  const widgetUid = makeWidgetUid('ISOTPENDPOINTCONFIG', isotpEndpoint.id)
  const name = i18n.t('ISOTP Endpoint') + ` - ${isotpEndpoint.name}`
  return addOrActivateWidget(widgetUid, name, 'ISOTPENDPOINTCONFIG', { isotpEndpointId: isotpEndpoint.id })
}

export const addOrActivateUDSScanRunWidget = (scanRun: IUDSScanRun | undefined = undefined) => {
  // an undefined scan run object will add / activate the "add a new scan run" widget
  const widgetUid = makeWidgetUid('UDSSCANRUN', scanRun?.id)
  const widgetName = i18n.t('UDS Scan Run') + (scanRun?.config.name === undefined ? '' : ` - ${scanRun!.config.name}`)
  return addOrActivateWidget(widgetUid, widgetName, 'UDSSCANRUN', { scanRunId: scanRun?.id })
}

export const addOrActivateGlobalLiveDataWidget = () => {
  return addOrActivateWidget('GLOBALLIVEDATA', i18n.t('Global Live Data'), 'GLOBALLIVEDATA')
}

export const addOrActivateRawLiveData = (lines: string[], title: string) => {
  const widgetUid = title
  const widgetName = `${title} - ` + i18n.t('Raw Log Data')
  return addOrActivateWidget(widgetUid, widgetName, 'RAWLIVEDATA', {lines: lines})
}

export const addOrActivateUDSScanReportWidget = (scanReport: IUDSScanRunFinding | undefined = undefined) => {
  const widgetUid = makeWidgetUid('UDSSCANREPORT', scanReport?.id)
  const widgetName = i18n.t('UDS Scan Run Report') + (scanReport?.results_file === undefined ? '' : ` - ${scanReport!.results_file}`)
  return addOrActivateWidget(widgetUid, widgetName, 'UDSSCANREPORT', {
    scanReportId: scanReport?.id,
  })
}

export const addOrActivateFakeUDSScanReportWidget = (scanReport: IUDSScanRunFinding | undefined = undefined) => {
  // NOTE: We have this "add fake scan run" to be able to explicitly create a report page without an actual report
  //       (not having this would make it impossible to auto-close the page if the report gets deleted ...)
  const widgetUid = makeWidgetUid('UDSSCANREPORT', scanReport?.id)
  const widgetName = i18n.t('UDS Scan Run Report (Fake)') + (scanReport?.results_file === undefined ? '' : ` - ${scanReport!.results_file}`)
  return addOrActivateWidget(widgetUid, widgetName, 'UDSSCANREPORT', {
    scanReportId: scanReport?.id,
    staticInjectedScanReport: scanReport
  })
}

export const addOrActivateLicensesWidget = () => {
  return addOrActivateWidget('LICENSES', i18n.t('Add a license'), 'LICENSES')
}

export const addOrActivateLicenseConfigWidget = (license: ILicense) => {
  const widgetUid = makeWidgetUid('LICENSECONFIG', license.id)
  const name = i18n.t('License') + ` - ${license.license_config.Serial}`
  return addOrActivateWidget(widgetUid, name, 'LICENSECONFIG', {licenseId: license.id})
}

export const addOrActivateImpressumWidget = () => {
  return addOrActivateWidget('IMPRESSUM', i18n.t('Impressum'), 'IMPRESSUM')
}

export const addOrActivateSettingsWidget = () => {
  return addOrActivateWidget('SETTINGS', i18n.t('Settings'), 'SETTINGS')
}

export const addOrActivateVersionsWidget = () => {
  return addOrActivateWidget('VERSIONS', i18n.t('Versions'), 'VERSIONS')
}

export const addOrActivateExternalLicensesWidget = () => {
  return addOrActivateWidget('EXTERNALLICENSES', i18n.t('Open Source Licenses'), 'EXTERNALLICENSES')
}

export const addOrActivateRemoteRunnersWidget = () => {
  const widgetUid = makeWidgetUid('REMOTERUNNERS', undefined)
  return addOrActivateWidget(widgetUid, i18n.t('Probes'), 'REMOTERUNNERS')
}

export const addOrActivateRemoteRunnerConfigurationWidget = (remoteRunner: IRemoteRunner) => {
  const widgetUid = makeWidgetUid('REMOTERUNNERCONFIG', remoteRunner.id)
  const name = i18n.t('Probe') + ` - ${remoteRunner.name}`
  return addOrActivateWidget(widgetUid, name, 'REMOTERUNNERCONFIG', { remoteRunnerId: remoteRunner.id })
}

export const addOrActivateTargetECUsWidget = () => {
  const widgetUid = makeWidgetUid('TARGETECUS', undefined)
  return addOrActivateWidget(widgetUid, i18n.t('Target ECUs'), 'TARGETECUS')
}

export const addOrActivateTargetECUConfigurationWidget = (targetECU: ITargetECU) => {
  const widgetUid = makeWidgetUid('TARGETECUCONFIG', targetECU.id)
  const name = i18n.t('Target ECU') + ` - ${targetECU.name}`
  return addOrActivateWidget(widgetUid, name, 'TARGETECUCONFIG', { targetECUId: targetECU.id })
}

export const addOrActivateRemoteJobTemplatesWidget = (remoteJobTemplate: IRemoteJobTemplate | undefined = undefined) => {
  const widgetUid = makeWidgetUid('REMOTEJOBTEMPLATES', remoteJobTemplate?.id)
  let name: string
  if (remoteJobTemplate === undefined) {
    name = i18n.t('Testcase Manager')
  } else {
    name = i18n.t('Testcase Template') + ` - ${remoteJobTemplate!.name}`
  }
  return addOrActivateWidget(widgetUid, name, 'REMOTEJOBTEMPLATES', {remoteJobTemplateId: remoteJobTemplate?.id})
}

//
// Remote job - testcase
//

export const addOrActivateTestCasesExecutionWidget = (targetECU: ITargetECU | undefined = undefined) => {
  const widgetUid = targetECU ? `TESTCASESEXECUTION::${targetECU.id}` : 'TESTCASESEXECUTION'
  const widgetName = i18n.t('Testcase Execution') + (targetECU ? ` on ${targetECU.name}` : '')
  return addOrActivateWidget(widgetUid, widgetName, 'TESTCASESEXECUTION', {targetECU: targetECU})
}

export const addOrActivateTestCaseResultWidget = (remoteJob: IRemoteJob, targetECU: ITargetECU) => {
  // testcase results (artifacts) / live data
  const widgetUid = `TESTCASERESULT::${remoteJob.id}`
  // TODO: show the template name instead of just the job id
  const widgetName = `${targetECU.name} - Testcase ${remoteJob.id}`
  return addOrActivateWidget(widgetUid, widgetName, 'TESTCASERESULT', {remoteJob: remoteJob, targetECU: targetECU})
}

//
// Remote job - generic
//

export const addOrActivateRemoteJobsWidget = (tag: string | undefined = undefined, remoteJobTemplate: IRemoteJobTemplate | undefined = undefined) => {
  const widgetUid = tag ? `REMOTEJOBS::${tag}` : 'REMOTEJOBS'
  // FIXME: get rid of the s :)
  const widgetName = i18n.t('Generic Job') + (remoteJobTemplate?.name === undefined ? 's' : ` - ${remoteJobTemplate.name}`)
  return addOrActivateWidget(widgetUid, widgetName, 'REMOTEJOBS', {tag: tag, remoteJobTemplateId: remoteJobTemplate?.id})
}

export const addOrActivateGenericRemoteJobResultWidget = (remoteJob: IRemoteJob, remoteRunner: IRemoteRunner) => {
  // job artifacts / live data
  const widgetUid = `GENERICREMOTEJOBRESULT::${remoteJob.id}`
  const widgetName = `${remoteRunner.name} - ${i18n.t('Generic Job')} ${remoteJob.id}`
  return addOrActivateWidget(widgetUid, widgetName, 'GENERICREMOTEJOBRESULT', {remoteJob: remoteJob, remoteRunner: remoteRunner})
}

// User and groups

export const addOrActivateUserWidget = () => {
  return addOrActivateWidget('USER', i18n.t('User'), 'USER')
}

export const addOrActivateUserManagementWidget = () => {
  return addOrActivateWidget('USERMANAGEMENT', i18n.t('User Management'), 'USERMANAGEMENT')
}

export const addOrActivateGroupManagementWidget = () => {
  return addOrActivateWidget('GROUPMANAGEMENT', i18n.t('Group Management'), 'GROUPMANAGEMENT')
}

export const addOrActivateHydraCoreDocuWidget = () => {
  return addOrActivateWidget('HYDRACOREDOCU', i18n.t('HydraCore Documentation'), 'HYDRACOREDOCU')
}

export const addOrActivateUDSScanReportDataInspectorWidget = (scanReport: IUDSScanRunFinding | undefined = undefined) => {
  const widgetUid = makeWidgetUid('UDSSCANREPORTDATAINSPECTOR', scanReport?.id)
  const widgetName = i18n.t('Data Inspector') + (scanReport?.results_file === undefined ? '' : ` - ${scanReport!.results_file}`)
  return addOrActivateWidget(widgetUid, widgetName, 'UDSSCANREPORTDATAINSPECTOR', {
    scanReportId: scanReport?.id,
  })
}

export const selectAppWidgets = (state: RootState) => state.mainLuminoWidget.widgets

export default mainLuminoWidgetSlice.reducer;
