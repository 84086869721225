import React, { useEffect, useMemo, useState } from 'react';
import { store } from './app/store';
import { useAppDispatch, useAppSelector } from "./app/hooks";
import {
    IAppWidget,
    selectAppWidgets,
    addOrActivateHardwareInterfaceConfigurationWidget,
    addOrActivateISOTPEndpointScanRunWidget,
    addOrActivateISOTPEndpointAddManualWidget,
    addOrActivateISOTPEndpointConfigurationWidget,
    addOrActivateUDSScanRunWidget,
    addOrActivateUDSScanReportWidget,
    addOrActivateFakeUDSScanReportWidget,
    addOrActivateLicensesWidget,
    addOrActivateLicenseConfigWidget,
    addOrActivateImpressumWidget,
    addOrActivateExternalLicensesWidget,
    addOrActivateGlobalLiveDataWidget,
    addOrActivateRemoteRunnerConfigurationWidget,
    addOrActivateRemoteJobTemplatesWidget,
    addOrActivateRemoteJobsWidget,
    addOrActivateGenericRemoteJobResultWidget,
    addOrActivateUserWidget,
    addOrActivateUserManagementWidget,
    addOrActivateTargetECUConfigurationWidget,
    addOrActivateRemoteRunnersWidget,
    addOrActivateTargetECUsWidget,
    addOrActivateTestCasesExecutionWidget,
    addOrActivateGroupManagementWidget,
    addOrActivateUDSScanReportDataInspectorWidget
} from "./features/main_lumino_widget/MainLuminoWidgetSlice";
import { fetchAllHardwareInterfacesAsync, selectHardwareInterfaces, subscribeToHwInterfaceChanges } from "./features/hardware_interface/HardwareInterfacesSlice";
import { fetchAllISOTPEndpointsAsync, selectISOTPEndpoints, subscribeToISOTPEndpointChanges } from "./features/isotp_endpoint/ISOTPEndpointsSlice";
import { fetchAllISOTPEndpointScanRunsAsync, selectISOTPEndpointScanRuns, subscribeToISOTPEndpointScanRunChanges } from './features/isotp_endpoint_scan_run/ISOTPEndpointScanRunsSlice';
import { fetchAllUDSScanRunsAsync, selectUDSScanRuns, subscribeToUDSScanRunChanges } from './features/uds_scan_run/UDSScanRunsSlice';
import { fetchAllBackendEventsAsync, showBackendEvent, subscribeToBackendEventChanges } from "./features/backend_event/BackendEventSlice";
import { fetchAllLicensesAsync, selectLicenses, subscribeToLicenseChanges } from './features/license/LicenseSlice';
import { fetchAllRemoteRunnersAsync, selectRemoteRunners, subscribeToRemoteRunnerChanges } from './features/remote_runner/RemoteRunnerSlice';

import { MainLuminoWidget } from "./features/main_lumino_widget/MainLuminoWidget";
import { HardwareInterfaces } from "./features/hardware_interface/HardwareInterfaces";
import { ISOTPEndpointScanRuns } from "./features/isotp_endpoint_scan_run/ISOTPEndpointScanRun";
import { ISOTPEndpoints } from "./features/isotp_endpoint/ISOTPEndpoints";
import { UDSScanRuns } from './features/uds_scan_run/UDSScanRun';
import { UserMessage } from "./features/user_message/UserMessage";
import { BackendEvents } from "./features/backend_event/BackendEvents";
import { BackendEventDialog } from "./features/backend_event/BackendEventDialog";
import { UDSScanRunReports } from './features/uds_scan_report/UDSScanReport';
import { Impressum } from './features/impressum/Impressum';

import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from "@mui/lab/TreeItem";
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { selectIsLoggedIn, selectSettings } from './features/settings/SettingsSlice';
import CssBaseline from '@mui/material/CssBaseline';
import { amber, grey } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { fetchAllRemoteJobTemplatesAsync, selectRemoteJobTemplates, subscribeToRemoteJobTemplateChanges } from './features/remote_job_template/RemoteJobTemplateSlice';
import { fetchAllRemoteJobsAsync, selectRemoteJobs, subscribeToRemoteJobChanges } from './features/remote_jobs/RemoteJobSlice';
import { fetchAllRemoteJobContextsAsync, subscribeToRemoteJobContextChanges } from './features/remote_jobs/RemoteJobContextSlice';
import { fetchAllRemoteJobArtifactsAsync, subscribeToRemoteJobArtifactChanges } from './features/remote_job_artifacts/RemoteJobArtifactSlice';
import { fetchSystemDataAsync } from './features/system_data/SystemDataSlice';
import { fetchAllUserMessagesAsync, subscribeToUserMessageChanges } from './features/user_message/UserMessageSlice';
import { fetchAllUserDataAsync, selectUserDataAsObject, subscribeToUserDataChanges, updateUserDataObjectAsync } from './features/settings/UserDataSlice';
import { userDataEulaAccepted, userDataExpertModeEnabled } from './features/misc/Constants';
import { Licenses } from './features/license/License';
import { LoginPage } from './features/login/LoginPage';
import { fetchAllUsersAsync, selectCurrentUser, subscribeToUserChanges } from './features/user_management/UserSlice';
import { logger } from './app/logging';
import { DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import Dialog from "@mui/material/Dialog"

import { authManager } from './app/auth'

import "@fontsource/share-tech-mono";

import './App.css';
import { fetchAllTargetECUsAsync, selectTargetECUs, subscribeToTargetECUChanges } from './features/target_ecu/TargetECUSlice';
import { fetchAllGroupsAsync, subscribeToGroupChanges } from './features/group_management/GroupSlice';
import { ConditionalFragment, getUserDataEntryFor, isRunningAs } from './features/misc/Util';
// import { RemoteJobs } from './features/remote_jobs/RemoteJob';
import { TestCases } from './features/remote_jobs/RemoteJobTestcase';

// add "body3" type for Typography variant
// -> is used for "hacky" font (e.g. UDS Scan Report table data)
declare module "@mui/material/styles" {
    interface TypographyVariants {
        body3: React.CSSProperties;
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        body3?: React.CSSProperties;
    }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
    interface TypographyPropsVariantOverrides {
        body3: true;
    }
}

// add "body3" type for Typography variant
// -> is used for "hacky" font (e.g. UDS Scan Report table data)
declare module "@mui/material/styles" {
    interface TypographyVariants {
        body3: React.CSSProperties;
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        body3?: React.CSSProperties;
    }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
    interface TypographyPropsVariantOverrides {
        body3: true;
    }
}

// add "body3" type for Typography variant
// -> is used for "hacky" font (e.g. UDS Scan Report table data)
declare module "@mui/material/styles" {
    interface TypographyVariants {
        body3: React.CSSProperties;
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        body3?: React.CSSProperties;
    }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
    interface TypographyPropsVariantOverrides {
        body3: true;
    }
}

export const TreeNavigation = () => {

    const hwInterfaces = useAppSelector(selectHardwareInterfaces)
    const isotpEndpoints = useAppSelector(selectISOTPEndpoints)
    const isotpEndpointScanRuns = useAppSelector(selectISOTPEndpointScanRuns)
    const udsScanRuns = useAppSelector(selectUDSScanRuns)
    const remoteRunners = useAppSelector(selectRemoteRunners)
    const targetECUs = useAppSelector(selectTargetECUs)
    const licenses = useAppSelector(selectLicenses)
    const appWidgets: IAppWidget[] = useAppSelector(selectAppWidgets)
    const settings = useAppSelector(selectSettings)
    const remoteJobTemplates = useAppSelector(selectRemoteJobTemplates)
    const remoteJobs = useAppSelector(selectRemoteJobs)
    const userDataObject = useAppSelector(selectUserDataAsObject)
    const currentUser = useAppSelector(selectCurrentUser)
    const [eulaOpen, setEulaOpen] = useState<boolean>(false)
    const [isStaffUser, setIsStaffUser] = useState<boolean>(false)

    const [expandedTreeNodes, setExpandedTreeNodes] = useState<string[]>([])
    const [selectedTreeNodeItem, setSelectedTreeItem] = useState('')

    const expertMode = useMemo(() => getUserDataEntryFor(userDataExpertModeEnabled, userDataObject, false), [userDataObject])

    const { t } = useTranslation()

    const dispatch = useAppDispatch()

    const getRootTreeNodeIdsFor = (nodeId: string): string[] | [] => {
        const parts = nodeId.split("::")
        switch (parts[0]) {
            case 'ISOTPENDPOINTSCANRUN':
                return parts[1] === undefined ? [] : ['ADDISOTPENDPOINTS', 'ISOTPENDPOINTSCANRUN']
            case 'UDSSCANRUN':
                return parts[1] === undefined ? [] : ['UDSSCANRUN']
            case 'ISOTPENDPOINTCONFIG':
                return parts[1] === undefined ? [] : ['ISOTPENDPOINTS']
            case 'USER':
                return parts[1] === undefined ? [] : ['SETTINGS', 'USER']
            case 'USERMANAGEMENT':
                return parts[1] === undefined ? [] : ['SETTINGS', 'USERMANAGEMENT']
            case 'GROUPMANAGEMENT':
                return parts[1] === undefined ? [] : ['SETTINGS', 'GROUPMANAGEMENT']
            default:
                return []
        }
    }

    useEffect(() => {
        if (userDataObject !== undefined) {
            setEulaOpen(!userDataObject[userDataEulaAccepted])
        }
    }, [userDataObject])

    useEffect(() => {
        // TODO: check if setting staff user to false for "normal" users is ok here
        //       (changed the old semantics)
        setIsStaffUser(currentUser?.is_staff ?? false)
    }, [currentUser])

    useEffect(() => {
        // there should only be one active widget at any given time (TM)
        const activeAppWidget = appWidgets.filter((appWidget) => appWidget.active).pop()
        if (activeAppWidget === undefined) {
            return
        }
        if (selectedTreeNodeItem !== activeAppWidget!.uid) {
            // select the tree view node item
            setSelectedTreeItem(activeAppWidget!.uid)
            // expand the root nodes for that item as needed
            getRootTreeNodeIdsFor(activeAppWidget!.uid).forEach((rootTreeNodeId) => {
                if (!expandedTreeNodes.includes(rootTreeNodeId)) {
                    setExpandedTreeNodes(curExpandedTreeNodes => [...curExpandedTreeNodes, rootTreeNodeId])
                }
            })
        }
    }, [appWidgets, selectedTreeNodeItem, expandedTreeNodes])

    const handleTreeNodeToggle = (_: React.SyntheticEvent, nodeIds: string[]) => {
        setExpandedTreeNodes(nodeIds)
    }

    const handleTreeNodeSelect = (_: React.SyntheticEvent, nodeId: string) => {

        logger.debug(`selected tree item - ${nodeId}`)

        const parts = nodeId.split("::")

        // NOTE: the tree view item labels correspond to the lumino widget ids
        //       (that way its possible to easily select the tree view item for the currently active widget)

        switch (parts[0]) {
            case "HARDWAREWINTERFACECONFIG":
                const hwInterface = hwInterfaces.filter(hwInterface => hwInterface.id === parseInt(parts[1]))[0]
                dispatch(addOrActivateHardwareInterfaceConfigurationWidget(hwInterface))
                break
            case "UDSSCANRUN":
                const udsScanRun = udsScanRuns.filter(udsScanRun => udsScanRun.id === parseInt(parts[1]))[0]
                dispatch(addOrActivateUDSScanRunWidget(udsScanRun))
                break
            case "REPORTUDSSCANRUN":
                const reportUdsScanRun = udsScanRuns.filter(udsScanRun => udsScanRun.id === parseInt(parts[1]))[0]
                dispatch(addOrActivateUDSScanRunWidget(reportUdsScanRun))
                break
            case "ISOTPENDPOINTSCANRUN":
                const isotpEndpointScanRun = isotpEndpointScanRuns.filter(isotpEndpointScanRun => isotpEndpointScanRun.id === parseInt(parts[1]))[0]
                dispatch(addOrActivateISOTPEndpointScanRunWidget(isotpEndpointScanRun))
                break
            case "ISOTPENDPOINTSADDMANUALLY":
                dispatch(addOrActivateISOTPEndpointAddManualWidget())
                break
            case "ISOTPENDPOINTCONFIG":
                const isotpEndpoint = isotpEndpoints.filter(isotpEndpoint => isotpEndpoint.id === parseInt(parts[1]))[0]
                dispatch(addOrActivateISOTPEndpointConfigurationWidget(isotpEndpoint))
                break
            case "TESTCASESEXECUTION":
                const testcasesExecutionTargetECU = targetECUs.filter((targetECU) => targetECU.id === parseInt(parts[1])).pop()
                dispatch(addOrActivateTestCasesExecutionWidget(testcasesExecutionTargetECU))
                break
            case "REMOTEJOBS":
                const tag = parts[1]
                const usedRemoteJobTemplateId = remoteJobs.filter(remoteJob => remoteJob.tag === tag).pop()?.job_template
                const usedRemoteJobTemplate = remoteJobTemplates.filter(remoteJobTemplate => remoteJobTemplate.id === usedRemoteJobTemplateId)[0]
                dispatch(addOrActivateRemoteJobsWidget(tag, usedRemoteJobTemplate))
                break
            case "GENERICREMOTEJOBRESULT":
                const remoteJob = remoteJobs.filter(remoteJob => remoteJob.id === parseInt(parts[1]))[0]
                const jobRemoteRunner = remoteRunners.filter(remoteRunner => remoteRunner.id === remoteJob.runner)[0]
                dispatch(addOrActivateGenericRemoteJobResultWidget(remoteJob, jobRemoteRunner))
                break
            case "TARGETECUS":
                dispatch(addOrActivateTargetECUsWidget())
                break
            case "TARGETECUCONFIG":
                const targetECU = targetECUs.filter(targetECU => targetECU.id === parseInt(parts[1]))[0]
                dispatch(addOrActivateTargetECUConfigurationWidget(targetECU))
                break
            case "REMOTERUNNERCONFIG":
                const remoteRunner = remoteRunners.filter(remoteRunner => remoteRunner.id === parseInt(parts[1]))[0]
                dispatch(addOrActivateRemoteRunnerConfigurationWidget(remoteRunner))
                break
            case "REMOTERUNNERS":
                dispatch(addOrActivateRemoteRunnersWidget())
                break
            case "REMOTEJOBTEMPLATES":
                const remoteJobTemplate = remoteJobTemplates.filter(remoteJobTemplate => remoteJobTemplate.id === parseInt(parts[1]))[0]
                dispatch(addOrActivateRemoteJobTemplatesWidget(remoteJobTemplate))
                break
            case "BACKENDEVENT":
                dispatch(showBackendEvent(parseInt(parts[1])))
                break
            case "UDSSCANREPORT":
                const udsScanReport = udsScanRuns.flatMap(udsScanRun => udsScanRun.scan_run_findings.filter(udsScanRunFinding => udsScanRunFinding.id === parseInt(parts[1])))[0]
                if (udsScanReport !== undefined) {
                    dispatch(addOrActivateUDSScanReportWidget(udsScanReport))
                } else {
                    // show the "fake" scan report as a demo
                    dispatch(addOrActivateFakeUDSScanReportWidget({
                        id: -1,
                        created_at: '',
                        results_file: 'results/EXAMPLE.json.gz',
                        analyzer_results: [],
                        log_files: [{
                            id: -1,
                            created_at: '',
                            log_type: 'CAN',
                            log_file: 'logfiles/can-EXAMPLE.log.gz'
                        },
                        {
                            id: -1,
                            created_at: '',
                            log_type: 'UDS',
                            log_file: 'logfiles/uds-EXAMPLE.log.gz'
                        },
                        {
                            id: -1,
                            created_at: '',
                            log_type: 'SCANNER',
                            log_file: 'logfiles/scan_run-EXAMPLE.log.gz'
                        }]
                    }))
                }
                break
            case "GLOBALLIVEDATA":
                dispatch(addOrActivateGlobalLiveDataWidget())
                break
            case "LICENSES":
                dispatch(addOrActivateLicensesWidget())
                break
            case "LICENSECONFIG":
                const license = licenses.filter((license: { id: number }) => license.id === parseInt(parts[1]))[0]
                dispatch(addOrActivateLicenseConfigWidget(license))
                break
            case "IMPRESSUM":
                dispatch(addOrActivateImpressumWidget())
                break
            case "EXTERNALLICENSES":
                dispatch(addOrActivateExternalLicensesWidget())
                break
            case "USER":
                dispatch(addOrActivateUserWidget())
                break
            case "USERMANAGEMENT":
                dispatch(addOrActivateUserManagementWidget())
                break
            case "GROUPMANAGEMENT":
                dispatch(addOrActivateGroupManagementWidget())
                break
            case "UDSSCANREPORTDATAINSPECTOR":
                const udsScanReportData = udsScanRuns.flatMap(udsScanRun => udsScanRun.scan_run_findings.filter(udsScanRunFinding => udsScanRunFinding.id === parseInt(parts[1])))[0]
                if (udsScanReportData !== undefined) {
                    dispatch(addOrActivateUDSScanReportDataInspectorWidget(udsScanReportData))
                }
                break
            default:
                logger.debug(`no handler for selected tree view item - ${nodeId}`)
                break
        }
    }

    const onEULAAccept = () => {
        if (userDataObject !== undefined) {
            setEulaOpen(false)
            let updatedUserDataObject = Object.assign({}, userDataObject)
            updatedUserDataObject[userDataEulaAccepted] = true
            dispatch(updateUserDataObjectAsync(updatedUserDataObject))
        }
    }

    const EULADialog = () => {
        return (
            <Box>
                <Dialog open={eulaOpen}>
                    <DialogTitle>{t("EULA")}</DialogTitle>
                    <DialogContent>
                        <h2>End-User License Agreement (EULA) of <span>{process.env.REACT_APP_PERSONALITY_NAME}</span></h2>
                        <p>This End-User License Agreement ("EULA") is a legal agreement between you and <span>dissecto GmbH</span>. Our EULA was created by <a href="https://www.eulatemplate.com" rel="noreferrer" target="_blank">EULA Template</a> for <span>{process.env.REACT_APP_PERSONALITY_NAME}</span>.</p>
                        <p>This EULA agreement governs your acquisition and use of our <span>{process.env.REACT_APP_PERSONALITY_NAME}</span> software ("Software") directly from <span>dissecto GmbH</span> or indirectly through a <span>dissecto GmbH</span> authorized reseller or distributor (a "Reseller"). </p>
                        <p>Please read this EULA agreement carefully before completing the installation process and using the <span>{process.env.REACT_APP_PERSONALITY_NAME}</span> software. It provides a license to use the <span>{process.env.REACT_APP_PERSONALITY_NAME}</span> software and contains warranty information and liability disclaimers.</p>
                        <p>If you register for a free trial of the <span>{process.env.REACT_APP_PERSONALITY_NAME}</span> software, this EULA agreement will also govern that trial. By clicking "accept" or installing and/or using the <span>{process.env.REACT_APP_PERSONALITY_NAME}</span> software, you are confirming your acceptance of the Software and agreeing to become bound by the terms of this EULA agreement.</p>
                        <p>If you are entering into this EULA agreement on behalf of a company or other legal entity, you represent that you have the authority to bind such entity and its affiliates to these terms and conditions. If you do not have such authority or if you do not agree with the terms and conditions of this EULA agreement, do not install or use the Software, and you must not accept this EULA agreement.</p>
                        <p>This EULA agreement shall apply only to the Software supplied by <span>dissecto GmbH</span> herewith regardless of whether other software is referred to or described herein. The terms also apply to any <span>dissecto GmbH</span> updates, supplements, Internet-based services, and support services for the Software, unless other terms accompany those items on delivery. If so, those terms apply.</p>
                        <h3>License Grant</h3>
                        <p><span>dissecto GmbH</span> hereby grants you a personal, non-transferable, non-exclusive licence to use the <span>{process.env.REACT_APP_PERSONALITY_NAME}</span> software on your devices in accordance with the terms of this EULA agreement.</p>
                        <p>You are permitted to load the <span>{process.env.REACT_APP_PERSONALITY_NAME}</span> software (for example a PC, laptop, mobile or tablet) under your control. You are responsible for ensuring your device meets the minimum requirements of the <span>{process.env.REACT_APP_PERSONALITY_NAME}</span> software.</p>
                        <p>You are not permitted to:</p>
                        <ul><li>Edit, alter, modify, adapt, translate or otherwise change the whole or any part of the Software nor permit the whole or any part of the Software to be combined with or become incorporated in any other software, nor decompile, disassemble or reverse engineer the Software or attempt to do any such things</li><li>Reproduce, copy, distribute, resell or otherwise use the Software for any commercial purpose</li><li>Allow any third party to use the Software on behalf of or for the benefit of any third party</li><li>Use the Software in any way which breaches any applicable local, national or international law</li><li>use the Software for any purpose that <span>dissecto GmbH</span> considers is a breach of this EULA agreement</li></ul>
                        <h3>Intellectual Property and Ownership</h3>
                        <p><span>dissecto GmbH</span> shall at all times retain ownership of the Software as originally downloaded by you and all subsequent downloads of the Software by you. The Software (and the copyright, and other intellectual property rights of whatever nature in the Software, including any modifications made thereto) are and shall remain the property of <span>dissecto GmbH</span>.</p>
                        <p><span>dissecto GmbH</span> reserves the right to grant licences to use the Software to third parties.</p>
                        <h3>Termination</h3>
                        <p>This EULA agreement is effective from the date you first use the Software and shall continue until terminated. You may terminate it at any time upon written notice to <span>dissecto GmbH</span>.</p>
                        <p>It will also terminate immediately if you fail to comply with any term of this EULA agreement. Upon such termination, the licenses granted by this EULA agreement will immediately terminate and you agree to stop all access and use of the Software. The provisions that by their nature continue and survive will survive any termination of this EULA agreement.</p>
                        <h3>Governing Law</h3>
                        <p>This EULA agreement, and any dispute arising out of or in connection with this EULA agreement, shall be governed by and construed in accordance with the laws of <span>de</span>.</p>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => onEULAAccept()}>{t("Accept")}</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        )
    }

    return (
        </*navigation tree*/>
            <Box
                sx={{ width: "100%", height: "100%", overflowY: "auto" }}
            >
                <Box
                    sx={{
                        width: "100px",
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                        paddingBottom: "10px"
                    }}
                    component="img"
                    src={settings.isDarkTheme ? process.env.PUBLIC_URL + "/dissecto718x366_dark.png" : process.env.PUBLIC_URL + "/dissecto718x366.png"} />
                <TreeView
                    aria-label="widget-navigation"
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                    onNodeSelect={handleTreeNodeSelect}
                    selected={selectedTreeNodeItem}
                    onNodeToggle={handleTreeNodeToggle}
                    expanded={expandedTreeNodes}
                    className="TreeViewNavigation"
                >
                    <ConditionalFragment condition={isRunningAs("HydraScope")}>
                        {/*<HardwareInterfaces />
                        <ISOTPEndpointScanRuns />
                        <ISOTPEndpoints />
                        <UDSScanRuns />
                        <UDSScanRunReports />
                        <BackendEvents />*/}
                        <UDSScanRunReports />
                    </ConditionalFragment>
                    <ConditionalFragment condition={isRunningAs("HydraVision")}>
                        <ConditionalFragment condition={expertMode}>
                            <TreeItem nodeId="REMOTERUNNERS" label={t("Probes")}/>
                        </ConditionalFragment>
                        <TreeItem nodeId="TARGETECUS" label={t("Target ECUs")}/>
                        <TreeItem nodeId="REMOTEJOBTEMPLATES" label={t("Testcase Manager")}></TreeItem>
                        {/*<ConditionalFragment condition={expertMode}>
                            <RemoteJobs/>
                        </ConditionalFragment>*/}
                        <TestCases/>
                    </ConditionalFragment>
                    {/*
                    <ConditionalFragment condition={isRunningAs("HydraScope")}>
                        <TreeItem nodeId="GLOBALLIVEDATA" label={t('Global Live Data')} />
                    </ConditionalFragment>
                    */}
                    <ConditionalFragment condition={isRunningAs("HydraVision")}>
                        <TreeItem nodeId="SETTINGS" label={t('Settings')}>
                            <TreeItem nodeId="USER" label={t('User')} />
                            <ConditionalFragment condition={isStaffUser}>
                                <TreeItem nodeId="USERMANAGEMENT" label={t('User Management')} />
                                <TreeItem nodeId="GROUPMANAGEMENT" label={t('Group Management')} />
                            </ConditionalFragment>
                        </TreeItem>
                    </ConditionalFragment>
                    {/*<Licenses/>*/}
                    <TreeItem nodeId="LEGAL" label={t('Legal')}>
                        <Impressum />
                        <TreeItem nodeId="EXTERNALLICENSES" label={t('Open Source Licenses')} />
                    </TreeItem>
                </TreeView>
            </Box>
            {/*
            <ConditionalFragment condition={isRunningAs("HydraScope")}>
                <EULADialog/>
            </ConditionalFragment>
            */}
        </>
    )
}

export const fetchAndSubscribeAllData = () => {

    logger.debug('fetch and subscribe to user data changes')
    store.dispatch(fetchAllUserDataAsync())
    store.dispatch(subscribeToUserDataChanges())

    logger.debug('fetch system data')
    store.dispatch(fetchSystemDataAsync())

    logger.debug('fetch and subscribe to license changes')
    store.dispatch(fetchAllLicensesAsync())
    store.dispatch(subscribeToLicenseChanges())

    logger.debug('fetch and subscribe to user message changes')
    store.dispatch(fetchAllUserMessagesAsync())
    store.dispatch(subscribeToUserMessageChanges())

    logger.debug('fetch and subscribe to backend event changes')
    store.dispatch(fetchAllBackendEventsAsync())
    store.dispatch(subscribeToBackendEventChanges())

    if (process.env.REACT_APP_PERSONALITY_NAME === "HydraScope") {
        logger.debug('fetch and subscribe to hardware interface changes')
        store.dispatch(fetchAllHardwareInterfacesAsync())
        store.dispatch(subscribeToHwInterfaceChanges())

        logger.debug('fetch and subscribe to isotp endpoint changes')
        store.dispatch(fetchAllISOTPEndpointsAsync())
        store.dispatch(subscribeToISOTPEndpointChanges())

        logger.debug('fetch and subscribe to isotp endpoint scan run changes')
        store.dispatch(fetchAllISOTPEndpointScanRunsAsync())
        store.dispatch(subscribeToISOTPEndpointScanRunChanges())

        logger.debug('fetch and subscribe to uds scan run changes')
        store.dispatch(fetchAllUDSScanRunsAsync())
        store.dispatch(subscribeToUDSScanRunChanges())
    } else if (process.env.REACT_APP_PERSONALITY_NAME === "HydraVision") {
        logger.debug('fetch and subscribe to user changes')
        store.dispatch(fetchAllUsersAsync())
        store.dispatch(subscribeToUserChanges())

        logger.debug('fetch and subscribe to user group changes')
        store.dispatch(fetchAllGroupsAsync())
        store.dispatch(subscribeToGroupChanges())

        logger.debug('fetch and subscribe to remote job changes')
        store.dispatch(fetchAllRemoteJobsAsync())
        store.dispatch(subscribeToRemoteJobChanges())

        logger.debug('fetch and subscribe to remote job context changes')
        store.dispatch(fetchAllRemoteJobContextsAsync())
        store.dispatch(subscribeToRemoteJobContextChanges())

        logger.debug('fetch and subscribe to remote job artifact changes')
        store.dispatch(fetchAllRemoteJobArtifactsAsync())
        store.dispatch(subscribeToRemoteJobArtifactChanges())

        logger.debug('fetch and subscribe to remote job template changes')
        store.dispatch(fetchAllRemoteJobTemplatesAsync())
        store.dispatch(subscribeToRemoteJobTemplateChanges())

        logger.debug('fetch and subscribe to remote runner changes')
        store.dispatch(fetchAllRemoteRunnersAsync())
        store.dispatch(subscribeToRemoteRunnerChanges())

        logger.debug('fetch and subscribe to target ecu changes')
        store.dispatch(fetchAllTargetECUsAsync())
        store.dispatch(subscribeToTargetECUChanges())
    }
}

function App() {
    const settings = useAppSelector(selectSettings)
    const isLoggedIn = useAppSelector(selectIsLoggedIn)
    const userDataObject = useAppSelector(selectUserDataAsObject)
    const [gotUserDataFromBackend, setGotUserDataFromBackend] = useState(false)

    const [subscribed, setSubscribed] = useState(false)

    useEffect(() => {
        if (isLoggedIn && !subscribed) {
            fetchAndSubscribeAllData()
            setSubscribed(true)
        }
    }, [isLoggedIn, subscribed])

    useEffect(() => {
        if (!gotUserDataFromBackend && userDataObject !== undefined) {
            setGotUserDataFromBackend(true)
        }
    }, [userDataObject, gotUserDataFromBackend])

    useEffect(() => {
        logger.info(`Running as ${process.env.REACT_APP_PERSONALITY_NAME}`)
        // logger.info(`Frontend version ${process.env.REACT_APP_VERSION}`)
    }, [])

    const dissectoTheme = useMemo(
        () =>
            createTheme({
                palette: {
                    ...(!settings.isDarkTheme
                        ? {
                            // palette values for light mode
                            mode: "light",
                            primary: {
                                main: "#ffcd00"
                            },
                            divider: amber[200],
                            text: {
                                primary: grey[900],
                                secondary: grey[500],
                            },
                        }
                        : {
                            // palette values for dark mode
                            mode: "dark",
                            primary: {
                                main: "#ffcd00"
                            },
                            divider: amber[200],
                            background: {
                                default: grey[900],
                                paper: grey[900]
                            },
                            text: {
                                primary: grey[50],
                                secondary: grey[500],
                            },
                            action: {
                            }
                        }),
                },
                breakpoints: {
                    values: {
                        xs: 0,
                        sm: 600,
                        md: 900,
                        lg: 1400,
                        xl: 1536,
                    },
                },
                components: {
                },
                typography: {
                    fontFamily: [
                        '-apple-system',
                        'BlinkMacSystemFont',
                        '"Segoe UI"',
                        'Roboto',
                        'Oxygen',
                        'Ubuntu',
                        'Cantarell',
                        'Fira Sans',
                        'Droid Sans',
                        '"Helvetica Neue"',
                        'sans-serif',
                        "Share Tech Mono"
                    ].join(','),
                    body3: {
                        fontFamily: "Share Tech Mono",
                        fontWeight: 300
                    }
                },
                transitions: {
                    ...(process.env.REACT_APP_PUPPETEER_TEST === 'true' ?
                        {
                            create: () => 'none',
                        }
                        :
                        {}
                    )
                }

            }), [settings],
    )

    if (!authManager?.isLoggedIn()) {
        return (
            <ThemeProvider theme={dissectoTheme}>
                <LoginPage />
            </ThemeProvider>
        )
    } else {
        return gotUserDataFromBackend ? (
            <ThemeProvider theme={dissectoTheme}>
                <CssBaseline enableColorScheme={true} />
                <div className="App">
                    <UserMessage />
                    <BackendEventDialog />
                    <div className="Content">
                        <div className="DockingArea">
                            <MainLuminoWidget theme={dissectoTheme} />
                        </div>
                    </div>
                </div>
            </ThemeProvider>
        ) : (<div style={{ backgroundColor: 'grey', width: '100%', height: '100vh' }}></div>)
    }
}

export default App
