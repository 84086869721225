import { httpBackendURL } from "../../app/api"

export const HydraCoreDocu = () => {
    return (
        <iframe
            frameBorder={0}
            width="100%"
            height="100%"
            title="HydraCore Documentation"
            src={`${httpBackendURL}/hydracore_docs/index.html`}
        />
    )
}