import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, store } from "../../app/store";
import { LastRequestStatus } from "../misc/GlobalTypes";
import { logger } from "../../app/logging";
import { showUserMessage } from "../user_message/UserMessageSlice";
import { fetchAllGroupsViaDCRF, patchGroupViaDCRF, createGroupViaDCRF, deleteGroupViaDCRF, subscribeToGroupChangesViaDCRF } from "./GroupAPI";
import { IUser } from "../user_management/UserSlice";

export interface IGroups {
    groups: IGroup[]
    lastRequestStatus: LastRequestStatus
    subscribedToUpdates: boolean
}

export interface IGroup {
    id: number,
    name: string,
    description: string
    creation_date: string
    users: IUser[]
}

export const fetchAllGroupsAsync = createAsyncThunk(
    'Group/fetchAllgroupsAsync',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            return await fetchAllGroupsViaDCRF()
        } catch (rejectedValue) {
            // @ts-ignore
            const error_message = JSON.stringify(rejectedValue.errors)
            dispatch(showUserMessage({ title: 'fetchAllGroupsAsync failed', message: error_message }))
            return rejectWithValue(rejectedValue)
        }
    }
)

export interface IUpdateGroup {
    id: number
    data: {
        name?: string,
        description?: string
        users?: IUser[]
    }
}

export const updateGroupAsync = createAsyncThunk(
    'Group/updateGroupAsync',
    async (updatedGroup: IUpdateGroup, { dispatch, rejectWithValue }) => {
        try {
            return await patchGroupViaDCRF(updatedGroup.id, updatedGroup.data)
        } catch (rejectedValue) {
            // @ts-ignore
            const error_message = JSON.stringify(rejectedValue.errors)
            dispatch(showUserMessage({ title: 'updateGroupAsync failed', message: error_message }))
            return rejectWithValue(rejectedValue)
        }
    }
)

export interface ICreateGroup {
    name: string,
    description: string
}

export const createGroupAsync = createAsyncThunk(
    'Group/createGroupAsync',
    async (newGroup: ICreateGroup, { dispatch, rejectWithValue }) => {
        try {
            return await createGroupViaDCRF(newGroup)
        } catch (rejectedValue) {
            // @ts-ignore
            const error_message = JSON.stringify(rejectedValue.errors)
            dispatch(showUserMessage({ title: 'createGroupAsync failed', message: error_message }))
            return rejectWithValue(rejectedValue)
        }
    }
)

export const deleteGroupAsync = createAsyncThunk(
    'Group/deleteGroupAsync',
    async (id: number, { dispatch, rejectWithValue }) => {
        try {
            return await deleteGroupViaDCRF(id)
        } catch (rejectedValue) {
            // @ts-ignore
            const error_message = JSON.stringify(rejectedValue.errors)
            dispatch(showUserMessage({ title: 'deleteGroupAsync failed', message: error_message }))
            return rejectWithValue(rejectedValue)
        }
    }
)

const getUpdatedGroups = (currentGroups: IGroup[], updatedGroup: IGroup) => {
    return currentGroups.map((existingGroup: IGroup) => {
        if (existingGroup.id === updatedGroup.id) {
            return updatedGroup
        } else {
            return existingGroup
        }
    })
}


const initialState: IGroups = {
    groups: [],
    subscribedToUpdates: false,
    lastRequestStatus: 'success',
}

export const groupsSlice = createSlice({
    name: 'groups',
    initialState,
    reducers: {
        addLocalGroup: (state, action: PayloadAction<IGroup>) => {
            if (!state.groups.some(Group => Group.id === action.payload.id)) {
                state.groups.push(action.payload)
            }
        },
        updateLocalGroup: (state, action: PayloadAction<IGroup>) => {
            state.groups = getUpdatedGroups(state.groups, action.payload)
        },
        deleteLocalGroup: (state, action: PayloadAction<IGroup>) => {
            state.groups = state.groups.filter((endpoint) => endpoint.id !== action.payload.id)
        },
        subscribeToGroupChanges: (state) => {
            if (state.subscribedToUpdates) {
                return
            }
            const callback = (group: IGroup, action: string) => {
                logger.debug(`group subscription callback (action: ${action})`)
                switch (action) {
                    case 'create':
                        store.dispatch(addLocalGroup(group))
                        break
                    case 'update':
                        store.dispatch(updateLocalGroup(group))
                        break
                    case 'delete':
                        // NOTE: We get the action.payload with a non null value here. That means,
                        // that this callback and the deleteLocalGroup are responsible for
                        // keeping the state up to date when a remote job gets deleted.
                        // deleteGroupAsync.fulfilled does only get a null value
                        store.dispatch(deleteLocalGroup(group))
                        break
                    default:
                        logger.debug('FIXME: unexpected action')
                }
            }
            subscribeToGroupChangesViaDCRF(callback)
            state.subscribedToUpdates = true
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllGroupsAsync.pending, (state) => {
                state.lastRequestStatus = 'busy'
            })
            .addCase(fetchAllGroupsAsync.fulfilled, (state, action: PayloadAction<IGroup[]>) => {
                state.lastRequestStatus = 'success'
                state.groups = action.payload
            })
            .addCase(fetchAllGroupsAsync.rejected, (state) => {
                state.lastRequestStatus = 'failed'
            })
            .addCase(createGroupAsync.pending, (state) => {
                state.lastRequestStatus = 'busy'
            })
            .addCase(createGroupAsync.fulfilled, (state, action: PayloadAction<IGroup>) => {
                state.lastRequestStatus = 'success'
                if (!state.groups.some(endpoint => endpoint.id === action.payload.id)) {
                    state.groups.push(action.payload)
                }
            })
            .addCase(createGroupAsync.rejected, (state) => {
                state.lastRequestStatus = 'failed'
            })
            .addCase(updateGroupAsync.pending, (state) => {
                state.lastRequestStatus = 'busy'
            })
            .addCase(updateGroupAsync.fulfilled, (state, action: PayloadAction<IGroup>) => {
                state.lastRequestStatus = 'success'
                state.groups = getUpdatedGroups(state.groups, action.payload)
            })
            .addCase(updateGroupAsync.rejected, (state) => {
                state.lastRequestStatus = 'failed'
            })
            .addCase(deleteGroupAsync.pending, (state) => {
                state.lastRequestStatus = 'busy'
            })
            .addCase(deleteGroupAsync.fulfilled, (state, action: PayloadAction<IGroup>) => {
                state.lastRequestStatus = 'success'
                // NOTE: action.payload.id is not available here (null value; update, fetch and create get a non null value) 
                // so just leave the state as it is (the subscription will trigger the deletion).
                // This means we are dependent on a functioning subscription callback
            })
            .addCase(deleteGroupAsync.rejected, (state) => {
                state.lastRequestStatus = 'failed'
            })
    }
})

export const {
    addLocalGroup,
    updateLocalGroup,
    deleteLocalGroup,
    subscribeToGroupChanges } = groupsSlice.actions

export const selectGroups = (state: RootState) => state.groups.groups

export const selectLastGroupRequestStatus = (state: RootState) => state.groups.lastRequestStatus
export const selectGroupIsSubscribed = (state: RootState) => state.groups.subscribedToUpdates

export default groupsSlice.reducer
