import { SyntheticEvent, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
    deleteTargetECUAsync,
    ITargetECU,
    IUpdateTargetECU,
    selectTargetECU,
    selectTargetECUs,
    updateTargetECUAsync,
} from "./TargetECUSlice";
import { TargetECUEdit } from './TargetECUEdit';
import { ConditionalFragment, DeleteElementButton,
         compactMap,
         getUserDataEntryFor,
         isValidJSONString,
         useObserveAndDeleteWidget
} from '../misc/Util';
import { useTranslation } from "react-i18next";
import { IRemoteJobContextDataEntry, IRemoteJobContextDataEntryValue, selectRemoteJobContext, updateRemoteJobContextAsync } from "../remote_jobs/RemoteJobContextSlice"
import { logger } from "../../app/logging"
import Button from "@mui/material/Button"
import Container from "@mui/material/Container"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import Typography from "@mui/material/Typography"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import TextField from "@mui/material/TextField";
import { Checkbox, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Stack, Tooltip } from "@mui/material";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import i18n from 'i18next';
import { selectUserDataAsObject } from "../settings/UserDataSlice";
import { userDataExpertModeEnabled } from "../misc/Constants";

const defaultGUIContextDataEntryPriority = 20

const dataEntryClasses = [
    'UdsIsotpSocket',
    'IsotpSocket',
    'PowerSensor',
    'GpiodPowerSupply',
    'CanSocket',
    'CanBus',
    'Raw'
] as const

type TDataEntryClass = typeof dataEntryClasses[number]

type TEditComponentProperties = {
    parentStateValue: {value: IRemoteJobContextDataEntryValue, setValue: any}
    parentStateSetHasAllNeededData: any
    shadowDataEntry: IRemoteJobContextDataEntryValue | undefined
    allDataEntries: IRemoteJobContextDataEntry[]
}

const getEditDataEntryValueComponentFrom = (dataEntryClass: TDataEntryClass): any => {
    switch (dataEntryClass) {
        case "UdsIsotpSocket":
            return UdsIsotpSocketEditContextDataEntryValue
        case "IsotpSocket":
            return IsotpSocketEditContextDataEntryValue
        case "PowerSensor":
            return PowerSensorEditContextDataEntryValue
        case "GpiodPowerSupply":
            return GpiodPowerSupplyEditContextDataEntryValue
        case "CanSocket":
            return CanSocketEditContextDataEntryValue
        case "CanBus":
            return CanBusEditContextDataEntryValue
        default:
            return RawEditContextDataEntryValue
    }
}

const getReadableNameForDataEntryClass = (dataEntryClass: string): string => {
    switch (dataEntryClass) {
        case "UdsIsotpSocket":
            return i18n.t("UDS ISOTP Socket")
        case "IsotpSocket":
            return i18n.t("ISOTP Socket")
        case "PowerSensor":
            return i18n.t("Power Sensor")
        case "GpiodPowerSupply":
            return i18n.t("GPIO Power Supply")
        case "CanSocket":
            return i18n.t("Can Socket")
        case "CanBus":
            return i18n.t("Can Bus")
        default:
            return dataEntryClass
    }
}

const getContextKeyBasenameForDataEntryClass = (dataEntryClass: string): string => {
    switch (dataEntryClass) {
        case "GpiodPowerSupply":
            return "PowerSupply"
        default:
            return dataEntryClass
    }
}

const OverlayedToolTip = (props: {
    title: string | undefined
    children: any
}) => {

    const { t } = useTranslation()

    return (
        <>
            {props.title === undefined ?
                props.children
            :
                <Tooltip
                    title={t('Overlays internal value') + ' ' + props.title}
                >
                    {props.children}
                </Tooltip>
            }
        </>
    )
}

const getAllUniqueDataEntryKeysFor = (className: string, allDataEntries: IRemoteJobContextDataEntry[]): string[] => {
    let uniqueKeyEntries = new Set<string>()
    for (const dataEntry of allDataEntries) {
        if (dataEntry.value.cls === className) {
            uniqueKeyEntries.add(dataEntry.key)
        }
    }
    return Array.from(uniqueKeyEntries).sort()
}

//
// generic ("base") data entry configuration edit view
//

const genericEditElementTypes = [
    'textInput',
    'intInput',
    'floatInput',
    'checkbox',
    'selection',
    'staticInvisible',
] as const

type TGenericEditElement = typeof genericEditElementTypes[number]

// allowHexInput only works for integer input fields
type TBehaviourModifier = "setToNullIfEmpty" | "allowHexInput" | "onlyVisibleInExpertMode"

type TGenericEditElementDescription = {
    type: TGenericEditElement
    key: string
    name: string
    defaultValue: any
    typeSpecificData?: any
    behaviourModifiers?: TBehaviourModifier[]
}

type TGenericEditDescription = {
    type: string
    elements: TGenericEditElementDescription[]
}

const GenericEditContextDataEntryValue = (props: {
    parentProps: TEditComponentProperties,
    editDescription: TGenericEditDescription
    hasAllNeededData?: (() => boolean)
}) => {

    const { t } = useTranslation()

    const userDataObject = useAppSelector(selectUserDataAsObject)
    const [intInputHexMode, setIntInputHexMode] = useState(true)

    const expertMode = useMemo(() => getUserDataEntryFor(userDataExpertModeEnabled, userDataObject, false), [userDataObject])
    const hasAtLeastOneIntInputWithHexMode = useMemo(() => props.editDescription.elements.some((e) => e.behaviourModifiers?.includes("allowHexInput")), [props.editDescription])

    // TODO: there is a known issue in the way the current "input validation" works, it just prohibits a user to enter invalid values and does not allow it / mark the invalid value (e.g. showing a red frame)
    //       which can lead to "unexpected" behaviour

    useEffect(() => {
        // update "has all needed data"
        props.parentProps.parentStateSetHasAllNeededData(props.hasAllNeededData ? props.hasAllNeededData() : true)
    }, [props])

    useEffect(() => {
        // "mirror" the config back on first run (to set the default values)
        const updatedConfig: any = {}
        props.editDescription.elements.forEach((element) => {
            updatedConfig[element.key] = props.parentProps.parentStateValue.value.value[element.key] ?? element.defaultValue
        })
        props.parentProps.parentStateValue.setValue((valueObj: IRemoteJobContextDataEntryValue) => ({...valueObj, ...{value: {...valueObj.value, ...updatedConfig}}}))
    // eslint-disable-next-line
    }, [])

    const updateValue = (valueUpdate: any) => props.parentProps.parentStateValue.setValue((valueObj: IRemoteJobContextDataEntryValue) => ({...valueObj, ...{value: {...valueObj.value, ...valueUpdate}}}))

    const setEditValue = (key: string, value: any) => updateValue({[key]: value})

    const getInputValue = (element: TGenericEditElementDescription): any => {
        let rawValue = props.parentProps.parentStateValue.value.value[element.key] ?? element.defaultValue
        if (element.behaviourModifiers?.includes("setToNullIfEmpty") && rawValue === null) {
            // always assume that a null value on an element with "setToNullIfEmpty" goes both ways
            return ""
        }
        if (element.type === "intInput" && element.behaviourModifiers?.includes("allowHexInput") && intInputHexMode) {
            return `0x${rawValue.toString(16)}`
        } else {
            return rawValue
        }
    }

    return (
        <Container disableGutters>
            <CommonEditContextDataEntryValue {...props.parentProps}/>
            <ConditionalFragment condition={hasAtLeastOneIntInputWithHexMode}>
                <FormGroup sx={{ margin: 1 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                id={"set-integer-input-hex-mode"}
                                checked={intInputHexMode}
                                onChange={(e) => setIntInputHexMode(e.target.checked)}
                            />
                        }
                        label={t("Hexadecimal Mode")}
                    />
                </FormGroup>
            </ConditionalFragment>
            {compactMap(props.editDescription.elements, (element) => {
                if (!expertMode && element.behaviourModifiers?.includes('onlyVisibleInExpertMode')) {
                    return null
                }
                switch(element.type) {
                   case "selection":
                       return (
                           <OverlayedToolTip key={element.key} title={props.parentProps.shadowDataEntry?.value.value[element.key]}>
                               <FormControl sx={{ margin: 1, minWidth: 300 }}>
                                   <InputLabel id={`context-${props.editDescription.type}-data-value-select-${element.key}-label`}>
                                       {element.name}
                                   </InputLabel>
                                   <Select
                                        labelId={`context-${props.editDescription.type}-data-value-select-${element.key}-label`}
                                        id={`context-${props.editDescription.type}-data-value-select-${element.key}`}
                                        label={element.name}
                                        value={props.parentProps.parentStateValue.value.value[element.key] ?? element.defaultValue}
                                        onChange={(e) => setEditValue(element.key, e.target.value)}
                                   >
                                       {element.typeSpecificData.map((item: string) => (
                                           <MenuItem
                                               key={item}
                                               value={item}
                                           >
                                               {item}
                                           </MenuItem>
                                       ))}
                                   </Select>
                               </FormControl>
                           </OverlayedToolTip>
                       )
                   case "checkbox":
                       return (
                           <OverlayedToolTip key={element.key} title={props.parentProps.shadowDataEntry?.value.value[element.key]}>
                               <FormGroup sx={{ margin: 1 }}>
                                   <FormControlLabel
                                       control={
                                           <Checkbox
                                               id={`context-${props.editDescription.type}-data-value-${element.key}`}
                                               checked={props.parentProps.parentStateValue.value.value[element.key] ?? element.defaultValue}
                                               onChange={(e) => setEditValue(element.key, e.target.checked)}
                                           />
                                       }
                                       label={element.name}
                                   />
                               </FormGroup>
                           </OverlayedToolTip>
                       )
                   case "textInput":
                   case "intInput":
                   case "floatInput":
                       return (
                           <OverlayedToolTip key={element.key} title={props.parentProps.shadowDataEntry?.value.value[element.key]}>
                               <TextField
                                   sx={{ margin: 1 }}
                                   multiline={element.type === "textInput"}
                                   type={element.type !== "textInput" ? (element.behaviourModifiers?.includes('allowHexInput') ? "text" : "number") : "number"}
                                   inputProps={element.type === "floatInput" ? {step: 0.001} : {}}
                                   id={`context-${props.editDescription.type}-data-value-${element.key}`}
                                   label={element.name}
                                   value={getInputValue(element)}
                                   onChange={(e) => {
                                       switch (element.type) {
                                           case "textInput":
                                               if (e.target.value.length === 0 && element.behaviourModifiers?.includes('setToNullIfEmpty')) {
                                                   setEditValue(element.key, null)
                                               } else {
                                                   setEditValue(element.key, e.target.value)
                                               }
                                               break
                                           case "intInput":
                                               if (element.behaviourModifiers?.includes('setToNullIfEmpty') && (e.target.value === "0x" || e.target.value.length === 0))  {
                                                   setEditValue(element.key, null)
                                               } else {
                                                   let intValue = NaN
                                                   if (element.behaviourModifiers?.includes("allowHexInput") && intInputHexMode) {
                                                       // hex mode
                                                       intValue = parseInt(e.target.value, 16)
                                                   } else {
                                                       // int mode
                                                       intValue = parseInt(e.target.value)
                                                   }
                                                   if (!isNaN(intValue)) {
                                                       setEditValue(element.key, intValue)
                                                   }
                                               }
                                               break
                                           case "floatInput":
                                               if (e.target.value.length === 0 && element.behaviourModifiers?.includes('setToNullIfEmpty')) {
                                                   setEditValue(element.key, null)
                                               } else {
                                                   const floatValue = parseFloat(e.target.value)
                                                   if (!isNaN(floatValue)) {
                                                       setEditValue(element.key, floatValue)
                                                   }
                                               }
                                       }
                                   }}
                               />
                           </OverlayedToolTip>
                       )
                   case "staticInvisible":
                       return null
                   default:
                       return (<>FIXME</>)
                }
            })}
        </Container>
    )
}

//
// specific data entry configuration views
//

const RawEditContextDataEntryValue = (props: TEditComponentProperties) => {

    const [localRawValue, setLocalRawValue] = useState(JSON.stringify(props.parentStateValue.value))

    const { t } = useTranslation()

    useEffect(() => {
        props.parentStateSetHasAllNeededData(isValidJSONString(localRawValue))
    }, [props, localRawValue])

    return (
        <OverlayedToolTip title={JSON.stringify(props.shadowDataEntry?.value)}>
            <TextField
                sx={{ margin: 1, minWidth: 450, maxWidth: 450 }}
                multiline
                color={isValidJSONString(localRawValue) ? "primary" : "error"}
                id="context-data-value"
                label={t("Value")}
                value={localRawValue}
                minRows={3}
                onChange={(e) => {
                    setLocalRawValue(e.target.value)
                    if (isValidJSONString(e.target.value)) {
                        props.parentStateValue.setValue(JSON.parse(e.target.value))
                    }
                }}
            />
        </OverlayedToolTip>
    )
}

const CommonEditContextDataEntryValue = (props: {
    parentStateValue: {value: IRemoteJobContextDataEntryValue, setValue: any}
}) => {

    const { t } = useTranslation()

    const setAlwaysActive = (alwaysActive: boolean) => {
        props.parentStateValue.setValue((valueObj: IRemoteJobContextDataEntryValue) => ({...valueObj, ...{always_active: alwaysActive}}))
    }

    const setDescription = (description: string) => {
        props.parentStateValue.setValue((valueObj: IRemoteJobContextDataEntryValue) => ({...valueObj, ...{desc: description}}))
    }

    return (
        <Container disableGutters>
            <Stack direction={"row"} alignItems={"center"} sx={{ margin: 1, marginTop: 2, minWidth: 300 }}>
                <TextField
                    multiline
                    id="context-data-value-description"
                    label={t("Description")}
                    value={props.parentStateValue.value.desc}
                    onChange={(e) => setDescription(e.target.value)}
                />
                <FormGroup sx={{ marginLeft: 2 }}>
                    <Tooltip title={t("This element will always be initialized during a testcase execution.")}>
                        <FormControlLabel
                            control={<Checkbox
                                        id={"set-always-active"}
                                        checked={props.parentStateValue.value.always_active ?? false}
                                        onChange={(e) => setAlwaysActive(e.target.checked)}
                                     />
                            }
                            label={t("Always Active")}/>
                    </Tooltip>
                </FormGroup>
            </Stack>
        </Container>
    )
}

const UdsIsotpSocketEditContextDataEntryValue = (props: TEditComponentProperties) => {

    const { t } = useTranslation()

    const availableCanBusInterfaces = useMemo(() => getAllUniqueDataEntryKeysFor("CanBus", props.allDataEntries), [props])
    
    const hasAllNeededData = (): boolean => {
        const value = props.parentStateValue.value.value
        return ((value.iface ?? '') as string).length > 0
    }

    // {"cls":"UdsIsotpSocket","desc":"Created by test_case UdsProtocolTest with arguments {'ps': 'PowerSupply_1', 'isotp_endpoint': 'IsotpScan_00'}",
    //  "value":{"iface":"CanBus_0","tx_id":2016,"rx_id":2024,"ext_address":null,"rx_ext_address":null,"bs":0,"stmin":0,"padding":true,"listen_only":false,"frame_txtime":0,"fd":false,"basecls":"UDS"}}

    const editDescription: TGenericEditDescription = {type: "udsisotpsocket",
                                                      elements: [
                                                          {type: "selection", key: "iface", name: t("Interface"), defaultValue: "", typeSpecificData: availableCanBusInterfaces},
                                                          {type: "intInput", key: "tx_id", name: t("TX_ID"), defaultValue: 0, behaviourModifiers: ['allowHexInput']},
                                                          {type: "intInput", key: "rx_id", name: t("RX_ID"), defaultValue: 0, behaviourModifiers: ['allowHexInput']},
                                                          {type: "intInput", key: "ext_address", name: t("EXT_ADDRESS"), defaultValue: null, behaviourModifiers: ['setToNullIfEmpty', 'allowHexInput']},
                                                          {type: "intInput", key: "rx_ext_address", name: t("RX_EXT_ADDRESS"), defaultValue: null, behaviourModifiers: ['setToNullIfEmpty', 'allowHexInput']},
                                                          {type: "intInput", key: "bs", name: t("Blocksize in FC frame"), defaultValue: 0, behaviourModifiers: ['onlyVisibleInExpertMode']},
                                                          {type: "intInput", key: "stmin", name: t("Separation time in FC frame"), defaultValue: 0, behaviourModifiers: ['onlyVisibleInExpertMode']},
                                                          {type: "intInput", key: "frame_txtime", name: t("Frame transmission time"), defaultValue: 0, behaviourModifiers: ['onlyVisibleInExpertMode']},
                                                          {type: "checkbox", key: "listen_only", name: t("Listen only"), defaultValue: false},
                                                          {type: "checkbox", key: "padding", name: t("Padding"), defaultValue: false},
                                                          {type: "checkbox", key: "fd", name: t("CAN FD"), defaultValue: false},
                                                          {type: "staticInvisible", key: "basecls", name: "", defaultValue: "UDS"},
                                                      ]
                                                     }

    return (
        <GenericEditContextDataEntryValue parentProps={props} editDescription={editDescription} hasAllNeededData={hasAllNeededData}/>
    )
}

const IsotpSocketEditContextDataEntryValue = (props: TEditComponentProperties) => {

    const { t } = useTranslation()

    const availableCanBusInterfaces = useMemo(() => getAllUniqueDataEntryKeysFor("CanBus", props.allDataEntries), [props])
 
    const hasAllNeededData = (): boolean => {
        const value = props.parentStateValue.value.value
        return ((value.iface ?? '') as string).length > 0
    }

    // {"cls":"IsotpSocket","desc":"Created by test_case IsotpScan with arguments {'ps': 'PowerSupply_1', 'can_socket': 'CanSocket_0'}",
    //  "value":{"iface":"CanBus_0","tx_id":2016,"rx_id":2024,"ext_address":null,"rx_ext_address":null,"bs":0,"stmin":0,"padding":true,"listen_only":false,"frame_txtime":0,"fd":false,"basecls":"ISOTP"}}

    const editDescription: TGenericEditDescription = {type: "isotpsocket",
                                                      elements: [
                                                          {type: "selection", key: "iface", name: t("Interface"), defaultValue: "", typeSpecificData: availableCanBusInterfaces},
                                                          {type: "intInput", key: "tx_id", name: t("TX_ID"), defaultValue: 0, behaviourModifiers: ['allowHexInput']},
                                                          {type: "intInput", key: "rx_id", name: t("RX_ID"), defaultValue: 0, behaviourModifiers: ['allowHexInput']},
                                                          {type: "intInput", key: "ext_address", name: t("EXT_ADDRESS"), defaultValue: null, behaviourModifiers: ['setToNullIfEmpty', 'allowHexInput']},
                                                          {type: "intInput", key: "rx_ext_address", name: t("RX_EXT_ADDRESS"), defaultValue: null, behaviourModifiers: ['setToNullIfEmpty', 'allowHexInput']},
                                                          {type: "intInput", key: "bs", name: t("Blocksize in FC frame"), defaultValue: 0, behaviourModifiers: ['onlyVisibleInExpertMode']},
                                                          {type: "intInput", key: "stmin", name: t("Separation time in FC frame"), defaultValue: 0, behaviourModifiers: ['onlyVisibleInExpertMode']},
                                                          {type: "intInput", key: "frame_txtime", name: t("Frame transmission time"), defaultValue: 0, behaviourModifiers: ['onlyVisibleInExpertMode']},
                                                          {type: "checkbox", key: "listen_only", name: t("Listen only"), defaultValue: false},
                                                          {type: "checkbox", key: "padding", name: t("Padding"), defaultValue: false},
                                                          {type: "checkbox", key: "fd", name: t("CAN FD"), defaultValue: false},
                                                          {type: "staticInvisible", key: "basecls", name: "", defaultValue: "ISOTP"},
                                                      ]
                                                     }

    return (
        <GenericEditContextDataEntryValue parentProps={props} editDescription={editDescription} hasAllNeededData={hasAllNeededData}/>
    )
}

const PowerSensorEditContextDataEntryValue = (props: TEditComponentProperties) => {

    const { t } = useTranslation()

    // {"cls":"PowerSensor","desc":"PowerSensor on address 0x41","value":{"i2c_address_sensor":65}}

    const editDescription: TGenericEditDescription = {type: "powersensor",
                                                      elements: [
                                                          {type: "intInput", key: "i2c_address_sensor", name: t("I2C Sensor Address"), defaultValue: 65, behaviourModifiers: ['allowHexInput']},
                                                      ]
                                                     }

    return (
        <GenericEditContextDataEntryValue parentProps={props} editDescription={editDescription}/>
    )
}

const GpiodPowerSupplyEditContextDataEntryValue = (props: TEditComponentProperties) => {

    const { t } = useTranslation()

    // {"cls":"GpiodPowerSupply","desc":"Power Supply Pin","value":{"gpio":27,"delay_on":0.5,"delay_off":0.5,"inverted":false}}

    const editDescription: TGenericEditDescription = {type: "gpiodpowersupply",
                                                      elements: [
                                                          {type: "intInput", key: "gpio", name: t("GPIO"), defaultValue: 0},
                                                          {type: "floatInput", key: "delay_on", name: t("Delay on (seconds)"), defaultValue: 0.5},
                                                          {type: "floatInput", key: "delay_off", name: t("Delay off (seconds)"), defaultValue: 0.5},
                                                          {type: "checkbox", key: "inverted", name: t("Inverted"), defaultValue: false},
                                                      ]
                                                     }

    return (
        <GenericEditContextDataEntryValue parentProps={props} editDescription={editDescription}/>
    )
}

const CanBusEditContextDataEntryValue = (props: TEditComponentProperties) => {

    const { t } = useTranslation()

    const hasAllNeededData = (): boolean => {
        const value = props.parentStateValue.value.value
        return ((value.interface ?? '') as string).length > 0
    }

    // {"cls":"CanBus","desc":"Diagnostic CAN Bus","value":{"interface":"can1","bitrate":33333,"txqueuelen":4000}}

    const editDescription: TGenericEditDescription = {type: "canbus",
                                                      elements: [
                                                          {type: "textInput", key: "interface", name: t("Interface"), defaultValue: "can0"},
                                                          {type: "intInput", key: "bitrate", name: t("Bitrate"), defaultValue: 500000},
                                                          {type: "intInput", key: "txqueuelen", name: t("Transmit Queue Length"), defaultValue: 4000},
                                                      ]
                                                     }

    return (
        <GenericEditContextDataEntryValue parentProps={props} editDescription={editDescription} hasAllNeededData={hasAllNeededData}/>
    )
}

const CanSocketEditContextDataEntryValue = (props: TEditComponentProperties) => {

    const { t } = useTranslation()

    const hasAllNeededData = () => {
        const value = props.parentStateValue.value.value
        return ((value.iface ?? '') as string).length > 0
    }

    const availableCanBusInterfaces = useMemo(() => getAllUniqueDataEntryKeysFor("CanBus", props.allDataEntries), [props])

    // {"cls":"CanSocket","desc":"CanSocket on 'CanBus_0'","value":{"iface":"CanBus_1","receive_own_messages":false,"can_filters":null,"fd":false,"basecls":"CAN"}}

    const editDescription: TGenericEditDescription = {type: "cansocket",
                                                      elements: [
                                                          {type: "selection", key: "iface", name: t("Interface"), defaultValue: "", typeSpecificData: availableCanBusInterfaces},
                                                          {type: "checkbox", key: "receive_own_messages", name: t("Receive own messages"), defaultValue: false},
                                                          {type: "checkbox", key: "fd", name: t("CAN FD"), defaultValue: false},
                                                          {type: "textInput", key: "can_filters", name: t("CAN filters"), defaultValue: null, behaviourModifiers: ['setToNullIfEmpty']},
                                                          {type: "staticInvisible", key: "basecls", name: "", defaultValue: "CAN"}
                                                      ]
                                                     }

    return (
        <GenericEditContextDataEntryValue parentProps={props} editDescription={editDescription} hasAllNeededData={hasAllNeededData}/>
    )
}

//
// "generic" page handling
//

const ContextDataEntryAdd = (props: {
    addDataEntry: (newDataEntry: IRemoteJobContextDataEntry) => Promise<any>
    allDataEntries: IRemoteJobContextDataEntry[]
}) => {

    const makeEmptyContextDataEntryValue = (): IRemoteJobContextDataEntryValue => ({cls: "", desc: "", always_active: false, value: {}})

    const [key, setKey] = useState('')
    const [localDataEntryValue, setLocalDataEntryValue] = useState<IRemoteJobContextDataEntryValue>(makeEmptyContextDataEntryValue())
    const [selectedDataEntryClass, setSelectedDataEntryClass] = useState<TDataEntryClass>("Raw")
    const [editComponentHasAllNeededData, setEditComponentHasAllNeededData] = useState(false)

    const { t } = useTranslation()

    useEffect(() => {
        if (selectedDataEntryClass === "Raw") {
            setKey("")
            return
        }
        const selectedDataEntryBaseClass = getContextKeyBasenameForDataEntryClass(selectedDataEntryClass)
        const keyIndex = Math.max(-1, ...props.allDataEntries
                                         .map((e) => e.key.match(/((?:[A-Z][a-z]+)+)_(\d+)/))
                                         .filter((m) => m && m[1] === selectedDataEntryBaseClass)
                                         .map((m) => m ? parseInt(m[2]) : 0)) + 1
        setKey(`${selectedDataEntryBaseClass}_${keyIndex}`)
    }, [selectedDataEntryClass, props.allDataEntries])

    const addDataEntry = () => props.addDataEntry(makeLocalDataEntry())

    const hasAllNeededData = (): boolean => key.length > 0 && editComponentHasAllNeededData

    const keyIsUnique = (key: string): boolean => {
        for (const dataEntry of props.allDataEntries) {
            if (dataEntry.key === key) {
                return false
            }
        }
        return true
    }

    const keyMatchesNamingConvention = (key: string): boolean => {
        return /(?:[A-Z][a-z]+)+_\d+/.test(key)
    }

    const keyIsValid = (key: string): boolean => {
        return keyMatchesNamingConvention(key) && keyIsUnique(key)
    }

    const makeLocalDataEntry = (): IRemoteJobContextDataEntry => {
        return {key: key, value: {...localDataEntryValue, ...{cls: selectedDataEntryClass}}, priority: defaultGUIContextDataEntryPriority}
    }

    const clearAllInputFields = () => {
        setKey("")
        setLocalDataEntryValue(makeEmptyContextDataEntryValue())
    }

    const EditComponentForValueType = getEditDataEntryValueComponentFrom(selectedDataEntryClass)

    return (
        <Paper sx={{ width: "100%", marginBottom: 2 }}>
            <Typography variant="h6" sx={{ marginBottom: 1, marginLeft: 2 }}>
                {t("Create a new configuration entry")}
            </Typography>
            <FormControl sx={{ marginTop: 1, marginLeft: 1 }}>
                <InputLabel id="select-edit-cls-label">
                    {t("Class")}
                </InputLabel>
                <Select
                    labelId="select-edit-cls-label"
                    id="select-edit-cls"
                    value={selectedDataEntryClass}
                    label={t("Class")}
                    onChange={(e) => {
                        setLocalDataEntryValue(makeEmptyContextDataEntryValue())
                        setSelectedDataEntryClass(e.target.value as TDataEntryClass)
                    }}
                >
                    {dataEntryClasses.map((e) => <MenuItem key={e} value={e}>{getReadableNameForDataEntryClass(e)}</MenuItem>)}
                </Select>
            </FormControl>
            <ConditionalFragment condition={selectedDataEntryClass==="Raw"}>
                <Grid container alignItems="center">
                    <Grid item>
                        <TextField
                            sx={{ margin: 1 }}
                            id="context-data-key"
                            label={t("Key")}
                            value={key}
                            onChange={(e) => setKey(e.target.value)}
                        />
                    </Grid>
                    <Grid item>
                        <ConditionalFragment condition={key.length > 0 && !keyIsValid(key)}>
                            <Tooltip title={t("Either there is already an entry with that key or it does not match the naming convention - \"ExampleKey_0\"")}>
                                <ErrorOutlineIcon color="error" />
                            </Tooltip>
                        </ConditionalFragment>
                    </Grid>
                </Grid>
            </ConditionalFragment>

            <EditComponentForValueType {...{parentStateValue: {value: localDataEntryValue, setValue: setLocalDataEntryValue},
                                            parentStateSetHasAllNeededData: setEditComponentHasAllNeededData,
                                            allDataEntries: props.allDataEntries}}/>

            <br/>

            <Button
                sx={{ marginLeft: 1, marginBottom: 1 }}
                id="context-data-add"
                color="primary"
                variant="contained"
                disabled={!(hasAllNeededData() && keyIsValid(key))}
                onClick={() => {
                    addDataEntry()
                    clearAllInputFields()
                }}
            >
                {t("Add")}
            </Button>
        </Paper>
    )
}

const ContextDataEntryEdit = (props: {
    groupedByKeyDataEntries: IRemoteJobContextDataEntry[]
    allDataEntries: IRemoteJobContextDataEntry[]
    addDataEntry: (newDataEntry: IRemoteJobContextDataEntry) => Promise<any>
    saveDataEntry: (updatedDataEntry: IRemoteJobContextDataEntry) => Promise<any>
    deleteDataEntry: (dataEntry: IRemoteJobContextDataEntry) => Promise<any>
    isDataEntryExpanded: (key: string, priority: number) => boolean
    expandDataEntry: (key: string, priority: number) => void
    collapseDataEntry: (key: string, priority: number) => void
}) => {
    const dataEntry: IRemoteJobContextDataEntry = props.groupedByKeyDataEntries.at(0)!  // edit the entry with the highest priority
    const shadowDataEntry: IRemoteJobContextDataEntry | undefined = props.groupedByKeyDataEntries.at(1) // get any existing entry with the next lower priority to show what was overriden by "dataEntry"

    const [localDataEntryValue, setLocalDataEntryValue] = useState(dataEntry.value)
    const [editComponentHasAllNeededData, setEditComponentHasAllNeededData] = useState(false)

    const { t } = useTranslation()

    const createOverlayDataEntry = () => {
        const overlayDataEntry = makeLocalDataEntry(defaultGUIContextDataEntryPriority)
        props.expandDataEntry(overlayDataEntry.key, overlayDataEntry.priority)
        props.addDataEntry(overlayDataEntry)
    }
    const saveDataEntry = () => props.saveDataEntry(makeUpdatedDataEntry())
    const deleteDataEntry = () => props.deleteDataEntry(dataEntry)

    const hasAllNeededData = (): boolean => editComponentHasAllNeededData

    const makeLocalDataEntry = (prioritiy: (number | undefined) = undefined): IRemoteJobContextDataEntry => {
        return {key: dataEntry.key, value: localDataEntryValue, priority: prioritiy === undefined ? dataEntry.priority : prioritiy}
    }

    const makeUpdatedDataEntry = (): IRemoteJobContextDataEntry => {
        return {...dataEntry, ...makeLocalDataEntry()}
    }

    const makeConfigurationDescriptionText = (): string => {
        const createdInternally = (dataEntry.priority < defaultGUIContextDataEntryPriority)
        const overridesEntry = (shadowDataEntry !== undefined)
        return `${dataEntry.key} - ${getReadableNameForDataEntryClass(dataEntry.value.cls)}, ${createdInternally ? t('Created internally') : t('Created by user')}${overridesEntry ? ', ' + t('Overlays internal config') : ''}`
    }

    const EditComponentForValueType = getEditDataEntryValueComponentFrom(localDataEntryValue.cls as TDataEntryClass)

    return (
        <Container disableGutters sx={{ marginTop: 1 }}>
            <Accordion
                expanded={props.isDataEntryExpanded(dataEntry.key, dataEntry.priority)}
                onChange={(_: SyntheticEvent<Element, Event>, expanded: boolean) => {
                    if (expanded) {
                        props.expandDataEntry(dataEntry.key, dataEntry.priority)
                    } else {
                        props.collapseDataEntry(dataEntry.key, dataEntry.priority)
                    }
                }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    id={'context-data-entry-accordion-header'}
                >
                    <Typography variant="h6">
                        {makeConfigurationDescriptionText()}
                    </Typography>
                </AccordionSummary>

                <EditComponentForValueType {...{parentStateValue: {value: localDataEntryValue, setValue: setLocalDataEntryValue},
                                                parentStateSetHasAllNeededData: setEditComponentHasAllNeededData,
                                                shadowDataEntry: shadowDataEntry,
                                                allDataEntries: props.allDataEntries}}/>

                <br/>

                {dataEntry.priority < defaultGUIContextDataEntryPriority ?
                </* do not allow to change the entry but create a new one with "GUI created priority" */>
                    <Button
                        sx={{ margin: 1 }}
                        id="context-data-save"
                        color="primary"
                        variant="contained"
                        disabled={!hasAllNeededData()}
                        onClick={() => createOverlayDataEntry()}
                    >
                        {t("Create user overlay")}
                    </Button>

                    <DeleteElementButton
                        title={t("Do you really want to delete this auto generated configuration entry?") as string}
                        message={t("Deleting a configuration entry is permanent and can not be undone. Please note that this entry was auto generated and should not be deleted by a user. Are you sure?") as string}
                        deleteCallback={deleteDataEntry}
                    />
                </>
                :
                </* allow to change entries created via the GUI */>
                    <Button
                        sx={{ margin: 1 }}
                        id="context-data-save"
                        color="primary"
                        variant="contained"
                        disabled={!hasAllNeededData()}
                        onClick={() => saveDataEntry()}
                    >
                        {t("Save")}
                    </Button>

                    <DeleteElementButton
                        title={t("Do you really want to delete this configuration entry?") as string}
                        message={t("Deleting a configuration entry is permanent and can not be undone. Of course you can always create a new one. Are you sure?") as string}
                        deleteCallback={deleteDataEntry}
                    />
                </>
                }
            </Accordion>
        </Container>
    )
}

const TargetECUContextEdit = (props: any) => {

    // NOTE: this view expects that there exists at most one data entry for a given key / prio combination

    const contextId: number = props.roStateContextId
    const remoteJobContext = useAppSelector(selectRemoteJobContext(contextId))

    const allAvailableDataEntryClasses: string[] = useMemo(() => {
        const uniqueClasses = new Set<string>()
        remoteJobContext?.data_entries.forEach((dataEntry) => uniqueClasses.add(dataEntry.value.cls))
        return Array.from(uniqueClasses)
    }, [remoteJobContext])

    const [expandedDataEntries, setExpandedDataEntries] = useState<(number | string)[][]>([])     // [[key1, prio1], [key2, prio2], ...]
    const [filterVisibleClasses, setFilterVisibleClasses] = useState<string[]>(allAvailableDataEntryClasses)

    const dispatch = useAppDispatch()

    const { t } = useTranslation()

    const handleChangeFilterClassesSelection = (event: SelectChangeEvent<typeof filterVisibleClasses>) => {
        const { target: { value }, } = event
        setFilterVisibleClasses(typeof value === 'string' ? value.split(',') : value)
    }

    // [[key1, [dataEntry1, dataEntry2, ...]], [[key2, [dataEntry3, dataEntry4, ...]]], ...]
    const dataEntriesGroupedByKey: ((string | IRemoteJobContextDataEntry[])[])[] = useMemo(() => {
        let keyToPrioMap: {[key: string]: IRemoteJobContextDataEntry[]} = {}
        for (const dataEntry of remoteJobContext?.data_entries.slice() ?? []) {
            // sort by descending priority
            keyToPrioMap[dataEntry.key] = (keyToPrioMap[dataEntry.key] ?? []).concat(dataEntry).sort((a, b) => b.priority - a.priority)
        }
        let res: ((string | IRemoteJobContextDataEntry[])[])[] = []
        // sort by name for now
        for (const key of Object.keys(keyToPrioMap).sort()) {
            res.push([key, keyToPrioMap[key]])
        }
        return res
    }, [remoteJobContext])

    // There seems to be some kind of race condition for remoteJobContext
    // which gets added for each TargetECU. The context itself exists,
    // but the groups are only updated afterwards. Therefore we just return
    // nothing here and when the remoteJobContext is finally updated
    // after the group M2M relation we can show the TargetECUContextEdit 
    if (!remoteJobContext) {
        return (<></>)
    }

    const isDataEntryExpanded = (key: string, priority: number): boolean => {
        return expandedDataEntries.filter((expandedDataEntry) => (expandedDataEntry[0] as string) === key && (expandedDataEntry[1] as number) === priority).length === 1
    }

    const expandDataEntry = (key: string, priority: number) => {
        logger.debug(`expandDataEntry for ${key} ${priority}`)
        if (!isDataEntryExpanded(key, priority)) {
            setExpandedDataEntries((expandedDataEntries) => expandedDataEntries.concat([[key, priority]]))
        }
    }

    const collapseDataEntry = (key: string, priority: number) => {
        logger.debug(`collapseDataEntry for ${key} ${priority}`)
        setExpandedDataEntries((expandedDataEntries) => expandedDataEntries.filter((curExpandedDataEntry) => curExpandedDataEntry[0] !== key || curExpandedDataEntry[1] !== priority))
    }

    /*
    // FIXME: using this triggers race conditions when an already expanded user overlay is added
    useEffect(() => {
        // only keep elements in "expandedDataEntries" for existing data entries
        let keyToPrioMap: {[key: string]: number[]} = {}
        for (const dataEntry of remoteJobContext.data_entries.slice() ?? []) {
            keyToPrioMap[dataEntry.key] = (keyToPrioMap[dataEntry.key] ?? []).concat(dataEntry.priority)
        }
        setExpandedDataEntries((expandedDataEntries) => expandedDataEntries.filter((expandedDataEntry) => (keyToPrioMap[expandedDataEntry[0]] ?? []).includes(expandedDataEntry[1] as number)))
    // eslint-disable-next-line
    }, [remoteJobContext])
    */

    const saveDataEntry = (updatedDataEntry: IRemoteJobContextDataEntry): Promise<any> => {
        logger.debug(`saveDataEntry ${JSON.stringify(updatedDataEntry)}`)
        // NOTE: set "last_updated" to undefined so that the backend serializer will set it to "now"
        const updatedDataEntries = remoteJobContext.data_entries.map((curDataEntry) => {
            if (curDataEntry.key === updatedDataEntry.key && curDataEntry.priority === updatedDataEntry.priority) {
                return updatedDataEntry
            } else {
                return curDataEntry
            }
        })
        return dispatch(updateRemoteJobContextAsync({id: remoteJobContext.id, data: { namespace: remoteJobContext.namespace, data_entries: updatedDataEntries }}))
    }

    const deleteDataEntry = (dataEntry: IRemoteJobContextDataEntry): Promise<any> => {
        logger.debug(`deleteDataEntry ${JSON.stringify(dataEntry)}`)
        const updatedDataEntries = remoteJobContext.data_entries.filter((curDataEntry) => curDataEntry.key !== dataEntry.key || curDataEntry.priority !== dataEntry.priority)
        return dispatch(updateRemoteJobContextAsync({id: remoteJobContext.id, data: { namespace: remoteJobContext.namespace, data_entries: updatedDataEntries }})).then(() => {
            collapseDataEntry(dataEntry.key, dataEntry.priority)
        })
    }

    const addDataEntry = (newDataEntry: IRemoteJobContextDataEntry): Promise<any> => {
        logger.debug(`addDataEntry ${JSON.stringify(newDataEntry)}`)
        let updatedDataEntries = remoteJobContext.data_entries.slice().concat(newDataEntry)
        return dispatch(updateRemoteJobContextAsync({id: remoteJobContext.id, data: { namespace: remoteJobContext.namespace, data_entries: updatedDataEntries }}))
    }

    const makeEditComponentKey = (dataEntryKey: string): string => `${remoteJobContext.last_updated}::${dataEntryKey}`

    return (
        <Container disableGutters sx={{ margin: 1 }}>
            {/* add entries */}
            <ContextDataEntryAdd key={makeEditComponentKey('')} {...{allDataEntries: remoteJobContext.data_entries,
                                                                     addDataEntry: addDataEntry}}/>
            {/* filter entries */}
            <Paper sx={{ width: "100%", marginBottom: 2 }}>
                <Typography variant="h6" sx={{ marginBottom: 1, marginLeft: 2 }}>
                    {t("Filter")}
                </Typography>
                <FormControl sx={{ marginTop: 1, marginBottom: 2, marginLeft: 1 }}>
                    <InputLabel id="select-filter-cls-label">
                        {t("Class")}
                    </InputLabel>
                    <Select
                        sx={{ minWidth: 300, maxWidth: 450 }}
                        labelId="select-filter-cls-label"
                        id="select-filter-cls"
                        multiple
                        value={filterVisibleClasses}
                        label={t("Class")}
                        onChange={handleChangeFilterClassesSelection}
                    >
                        {allAvailableDataEntryClasses.map((e) => <MenuItem key={e} value={e}>{getReadableNameForDataEntryClass(e)}</MenuItem>)}
                    </Select>
                </FormControl>
            </Paper>
            {/* entries */}
            {compactMap(dataEntriesGroupedByKey, (groupedDataEntries) => {
                if (!filterVisibleClasses.includes((groupedDataEntries[1].at(0) as IRemoteJobContextDataEntry).value.cls)) {
                    return null
                }
                return (
                    <ContextDataEntryEdit key={makeEditComponentKey(groupedDataEntries[0] as string)} {...{groupedByKeyDataEntries: groupedDataEntries[1] as IRemoteJobContextDataEntry[],
                                                                                                           allDataEntries: remoteJobContext.data_entries,
                                                                                                           addDataEntry: addDataEntry,
                                                                                                           saveDataEntry: saveDataEntry,
                                                                                                           deleteDataEntry: deleteDataEntry,
                                                                                                           isDataEntryExpanded: isDataEntryExpanded,
                                                                                                           expandDataEntry: expandDataEntry,
                                                                                                           collapseDataEntry: collapseDataEntry}}
                    />
                )
            })}
        </Container>
    )
}

export const TargetECUConfig = (props: any) => {

    const targetECU: ITargetECU | undefined = useAppSelector(selectTargetECU(props.targetECUId))
    const targetECUs: ITargetECU[] = useAppSelector(selectTargetECUs)
    
    const [name, setName] = useState(targetECU?.name ?? '')
    const [description, setDescription] = useState(targetECU?.description ?? '')
    const [remoteRunnerId, setRemoteRunnerId] = useState(targetECU?.remote_runner ?? -1)
    const contextId = targetECU?.context
    const createdAt = targetECU?.created_at ?? ''

    const { t } = useTranslation()

    const dispatch = useAppDispatch()
    useObserveAndDeleteWidget("TARGETECUCONFIG", targetECUs)

    if (targetECU === undefined) {
        // should never be shown under normal circumstances
        return (
            <div>
                DELETED
            </div>
        )
    }

    const hasAllNeededData = () => {
        return name.length > 0 &&
               description.length > 0 &&
               remoteRunnerId !== -1
    }

    const saveTargetECU = () => {
        if (!hasAllNeededData()) {
            return
        }

        const updatedTargetECU: IUpdateTargetECU = {
            id: targetECU?.id ?? -1,
            data: {
                name: name,
                description: description,
                remote_runner: remoteRunnerId
            }
        }
        dispatch(updateTargetECUAsync(updatedTargetECU))
    }

    const deleteTargetECU = () => {
        if (targetECU === undefined) {
            return
        }
        dispatch(deleteTargetECUAsync(targetECU.id))
    }

    return (
        <Container id="target-ecu-container" disableGutters sx={{ overflow: "hidden", overflowY: "auto", marginLeft: -1 }}>
            <TargetECUEdit {...{
                stateName: [name, setName],
                stateDescription: [description, setDescription],
                stateRemoteRunnerId: [remoteRunnerId, setRemoteRunnerId],
                roStateCreatedAt: createdAt }
            }/>

            <Button
                id="target-ecu-save-button"
                sx={{ margin: 1 }}
                variant="contained"
                onClick={saveTargetECU}
                disabled={!hasAllNeededData()}
            >
                {t("Save")}
            </Button>

            <DeleteElementButton
               id="target-ecu-delete-button"
               title={t("Do you really want to delete this target ECU?") as string}
               message={t("Deleting a target ECU is permanent and can not be undone. Of course you can always create a new one. Are you sure?") as string}
               deleteCallback={deleteTargetECU}
            />

            {contextId === undefined ? <></> :
                <TargetECUContextEdit {...{roStateContextId: contextId}}/>
            }
        </Container>
    )
}
