import Chip from "@mui/material/Chip"
import Container from "@mui/material/Container"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"
import AutoSizer from 'react-virtualized-auto-sizer'
import { useTranslation } from "react-i18next"
import { useAppSelector } from "../../app/hooks"
import { RemoteJobLiveData } from "../live_data/LiveData"
import {
    useObserveAndDeleteWidget,
    AutoTooltip,
    unixTimestampToDateTimeString,
    keyStringToLabel,
    dateTimeStringToDateObject,
    dateTimeObjectToDateTimeString,
    makeRGBAColorList,
    ConditionalFragment,
    getDateTimeStringFromRFC3339TimestampWithMilliseconds,
    rfc3339TimestampToDateObject,
    getUserDataEntryFor,
    yellowColorDependingOnTheme,
    getRemoteJobStateMappedToRepresentation,
} from "../misc/Util"
import { IRemoteJob, selectRemoteJobs } from "../remote_jobs/RemoteJobSlice"
import { selectTestcaseRemoteJobTemplates } from "../remote_job_template/RemoteJobTemplateSlice"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { logger } from "../../app/logging"
import { FixedSizeList } from "react-window"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox"
import TableSortLabel from "@mui/material/TableSortLabel"
import ReactECharts from 'echarts-for-react'
import { selectSettings } from "../settings/SettingsSlice"
import EChartsReact from "echarts-for-react"
import { InfluxDB } from "@influxdata/influxdb-client"
import { selectSystemData } from "../system_data/SystemDataSlice"
import moment from "moment"
import Button from "@mui/material/Button"
import Stack from "@mui/material/Stack"
import Grid from "@mui/material/Grid"
import TableContainer from "@mui/material/TableContainer"
import Paper from "@mui/material/Paper"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import TreeView from "@mui/lab/TreeView"
import TreeItem from "@mui/lab/TreeItem"
import { remoteJobCSVResultsFileArtifactType, tablePageRowCountOptions, userDataDarkThemeEnabled, userDataExpertModeEnabled } from "../misc/Constants"
import { selectUserDataAsObject } from "../settings/UserDataSlice"
import CircularProgress from "@mui/material/CircularProgress"
import { TTablePageRowCount, TTestcaseResult } from "../misc/GlobalTypes"
import TablePagination from "@mui/material/TablePagination"
import { usePapaParse } from 'react-papaparse'
import { httpBackendURL } from "../../app/api"
import { selectRemoteJobArtifacts } from "./RemoteJobArtifactSlice"
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import RunCircleOutlinedIcon from '@mui/icons-material/RunCircleOutlined'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import Tooltip from "@mui/material/Tooltip"

// NOTE: RemoteJobTestcaseResult combines live data of a testcase remote job and the remote job artifacts
// -> that is the reason why it is called RemoteJobTestcaseResult instead of RemoteJobTestcaseArtifact

const pollIntervalMS = 1000

// const TestCaseArtifactType = ["LOG", "RESULT"] as const
// type TTestCaseArtifact = typeof TestCaseArtifactType[number]

// const TestCaseMeasurement = ["power_sensor"] as const
// type TTestCaseMeasurement = typeof TestCaseMeasurement[number]
type TTestCaseMeasurement = string

const TestCaseDataType = ["log_line", "chart_data_point", "result_line", "result_summary_line"] as const
type TTestCaseDataType = typeof TestCaseDataType[number]

interface ITestCaseLogRow {
    measurement: TTestCaseMeasurement
    dataType: TTestCaseDataType

    // rawTimestamp: number
    // the first column is replaced with a cooked version of the raw timestamp
    cols: string[]
}

interface ITestCaseLog {
    measurement: TTestCaseMeasurement
    dataType: TTestCaseDataType

    headerRow: ITestCaseLogRow
    logRows: ITestCaseLogRow[]
    
    lastUIUpdate: number
    tryToFetchNewData: boolean
}

const isDumpLogType = (logType: string): boolean => logType.includes(".dump")

const TestCaseReportChart = (props: {
    log: ITestCaseLog
    stateSelectedTimeframe: [[string | undefined, string | undefined], (tf: [string | undefined, string | undefined]) => void]
    stateSelectedTimestamp: [[string | undefined, boolean | undefined], (ts: [string | undefined, boolean | undefined]) => void]
    enableEdit: boolean
}) => {

    // start, end
    const [externalSelectedTimeframe, setExternalSelectedTimeframe] = props.stateSelectedTimeframe
    // timestamp, highlight true / false
    const setExternalSelectedTimestamp = props.stateSelectedTimestamp[1]

    const settings = useAppSelector(selectSettings)
    const echartsRef = useRef<EChartsReact | null>(null)
    const [legendSelection, setLegendSelection] = useState<{ [key: string]: boolean }>(props.log.headerRow.cols.slice(1).reduce((o, c) => ({ ...o, [keyStringToLabel(c)]: true }), {}))

    let zoomRegionChangedDebounceTimer: ReturnType<typeof setTimeout> | undefined = undefined
    const onDataZoom = () => {
        // what a shitshow, the event only gives percent values (which are pretty much useless)
        clearTimeout(zoomRegionChangedDebounceTimer)
        zoomRegionChangedDebounceTimer = setTimeout(() => {
            // @ts-ignore
            const { startValue, endValue } = echartsRef.current?.getEchartsInstance().getOption().dataZoom[0]      
            setExternalSelectedTimeframe([unixTimestampToDateTimeString(startValue / 1000), unixTimestampToDateTimeString(endValue / 1000)])
        }, 500)
    }

    const onLegendSelectChanged = (event: any) => {
        if (!props.enableEdit) {
            return
        }
        setLegendSelection(event.selected)
    }

    const onMouseOver = (event: any) => {
        if (!props.enableEdit) {
            return
        }
        const row = props.log.logRows[event.dataIndex]
        setExternalSelectedTimestamp([row.cols[0], false])
    }

    const onMouseClick = (event: any) => {
        if (!props.enableEdit) {
            return
        }
        const row = props.log.logRows[event.dataIndex]
        setExternalSelectedTimestamp([row.cols[0], true])
    }

    // https://echarts.apache.org/en/api.html#events
    const onChartEvents = {
        'datazoom': onDataZoom,
        'legendselectchanged': onLegendSelectChanged,
        'mouseover': onMouseOver,
        'click': onMouseClick
    }

    const dateTimeStringToLabelString = (dateTimeString: string) => {
        return dateTimeObjectToDateTimeString(new Date(dateTimeString))
    }

    const chartDatasetSource = useMemo(() => {
        logger.debug("update chart data")
        // @ts-ignore
        // return props.log.logRows.map((r) => [unixTimestampToDateObject(r.rawTimestamp)].concat(r.cols.slice(1).map((c) => parseFloat(c))))
        return props.log.logRows.map((r) => [rfc3339TimestampToDateObject(r.cols[0])].concat(r.cols.slice(1).map((c) => parseFloat(c))))
    }, [props.log])

    const chartOption = useMemo(() => {
        logger.debug("rebuild chart")
        if (props.log.logRows.length === 0) {
            return {}
        }
        return {
            title: {
                text: keyStringToLabel(props.log.measurement),
                textStyle: {
                    fontSize: 15
                },
                left: 'center',
                top: 'begin',
            },
            grid: {
                top: 30,
                left: 50,
                right: 30,
                bottom: 100
            },
            dataZoom: [
                {
                    type: 'slider',
                    xAxisIndex: [0],
                    filterMode: 'filter',
                    labelFormatter: dateTimeStringToLabelString,
                    startValue: dateTimeStringToDateObject(externalSelectedTimeframe[0] ?? props.log.logRows[0].cols[0]),
                    endValue: dateTimeStringToDateObject(externalSelectedTimeframe[1] ?? props.log.logRows[props.log.logRows.length - 1].cols[0]),
                    bottom: 45,
                    show: props.enableEdit
                },
                {
                    type: 'inside',
                    xAxisIndex: [0],
                    filterMode: 'filter',
                    show: props.enableEdit
                }
            ],
            legend: {
                selected: legendSelection,
                bottom: 10
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow',
                    label: {
                        formatter: (params: any) => {
                            return dateTimeStringToLabelString(params.value)
                        }
                    }
                },
            },
            dataset: {
                source: chartDatasetSource,
                dimensions: props.log.headerRow.cols
            },
            xAxis: {
                type: "time",
                boundaryGap: false,
            },
            yAxis: {},
            series: props.log.headerRow.cols.slice(1).map((c) => ({ type: 'line',
                                                                    name: keyStringToLabel(c),
                                                                    animation: false,
                                                                    showSymbol: false,
                                                                    encode: { x: props.log.headerRow.cols[0], y: c } }))
        }
    }, [chartDatasetSource, externalSelectedTimeframe, legendSelection, props.log, props.enableEdit])

    // logger.debug("re-render chart")

    return (
        <ReactECharts
            ref={(r) => {echartsRef.current = r}}
            option={chartOption}
            notMerge={true}
            lazyUpdate={true}
            theme={settings.isDarkTheme ? "dark" : "light"}     // NOTE: "light" does not exist, it just uses the default
            onEvents={onChartEvents}
            style={{height: '400px', width: '100%'}}
            opts={{renderer: 'canvas'}}
        />
    )
}

type TLogTypeSelectTree = {[cls: string]: {[desc: string]: string}}

const TestCaseReportLogsSelectionHeaderClassGroupingTree = (props: {
    logs: ITestCaseLog[]
    logTypeTree: TLogTypeSelectTree
    stateDisabledLogTypes: [string[], any]
    backgroundColor: string
}) => {

    const [disabledLogTypes, setDisabledLogTypes] = props.stateDisabledLogTypes
    const [logsStillLoading, setLogsStillLoading] = useState<string[]>([])
    
    const getAllLogTypesForClass = (cls: string): string[] => Object.values(props.logTypeTree[cls] ?? {})
    const allEntriesForClassAreEnabled = (cls: string): boolean => getAllLogTypesForClass(cls).every((logType) => !disabledLogTypes.includes(logType))
    const someEntriesForClassAreEnabled = (cls: string): boolean => getAllLogTypesForClass(cls).some((logType) => !disabledLogTypes.includes(logType))
    const setAllCassEntriesEnabled = (cls: string, enabled: boolean) => getAllLogTypesForClass(cls).forEach((logType) => setEntryEnabled(logType, enabled))
    const setEntryEnabled = (entryLogType: string, enabled: boolean) => {
        setDisabledLogTypes((disabledLogTypes: string[]) => {
            if (enabled) {
                return disabledLogTypes.filter((logType) => entryLogType !== logType)
            } else {
                return disabledLogTypes.concat(entryLogType)
            }
        })
    }
    const getEntryEnabled = (entryLogType: string): boolean => !disabledLogTypes.includes(entryLogType)
    
    const logStillLoadingThresholdMS = 3000

    useEffect(() => {
        const isLogStillLoading = (logType: string): boolean => {
            const now = Date.now()
            const logsStillLoading = props.logs.filter((log) => (now - log.lastUIUpdate) < logStillLoadingThresholdMS).map((log) => log.measurement)
            return logsStillLoading.includes(logType)
        }
        const updateLogsStillLoading = () => {
            setLogsStillLoading((logsStillLoading) => {
                const updatedLogsStillLoading = props.logs.slice().sort().filter((log) => isLogStillLoading(log.measurement)).map((log) => log.measurement)
                if (updatedLogsStillLoading.toString() === logsStillLoading.toString()) {
                    // logger.debug('updateLogsStillLoading -> do nothing')
                    return logsStillLoading
                } else {
                    // logger.debug('updateLogsStillLoading -> re-render')
                    return updatedLogsStillLoading
                }
            })            
        }
        updateLogsStillLoading()
        const timer = setInterval(updateLogsStillLoading, logStillLoadingThresholdMS)
        return () => clearInterval(timer)
    }, [props.logs])

    return (
        <Container disableGutters sx={{ backgroundColor: props.backgroundColor, borderRadius: "5px", overflowWrap: "anywhere" }}>
            <TreeView
                defaultCollapseIcon={<ExpandMoreIcon/>}
                defaultExpandIcon={<ChevronRightIcon/>}
            >
                {Object.keys(props.logTypeTree).sort().map((cls) => {
                    const subEntries = props.logTypeTree[cls]
                    const subKeys = Object.keys(subEntries)
                    const checkBoxColor = allEntriesForClassAreEnabled(cls) ? "primary" : "grey"
                    return (
                        <TreeItem
                            sx={[{ "&.MuiTreeItem-root > .MuiTreeItem-content.Mui-selected.Mui-focused": {
                                    background: "transparent"
                                   },
                                   "&.MuiTreeItem-root > .MuiTreeItem-content.Mui-selected": {
                                    background: "transparent"
                                   },
                                   "&.MuiTreeItem-root > .MuiTreeItem-content:hover": {
                                    background: "transparent"
                                   },
                                }]}
                            nodeId={cls}
                            label={
                                <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                >
                                    <Checkbox
                                        checked={someEntriesForClassAreEnabled(cls)}
                                        sx={{ [`&, &.${checkboxClasses.checked}`]: {
                                                color: checkBoxColor,
                                              },
                                            }}
                                        onChange={(e) => setAllCassEntriesEnabled(cls, e.target.checked)}
                                        onClick={(event) => {
                                            event.stopPropagation()
                                            // event.preventDefault()
                                        }}
                                    />
                                    <Typography
                                        variant="body2"
                                        style={{ fontFamily: "monospace" }}
                                        onClick={(event) => {
                                            event.stopPropagation()
                                            // event.preventDefault()
                                        }}
                                    >
                                        {keyStringToLabel(cls)}
                                    </Typography>
                                    <ConditionalFragment condition={getAllLogTypesForClass(cls).some((logEntry) => logsStillLoading.includes(logEntry))}>
                                        <Box sx={{ display: "flex" }}>
                                            <CircularProgress />
                                        </Box>
                                    </ConditionalFragment>
                                </Stack>
                            }
                        >
                            <ConditionalFragment condition={subKeys.length !== 1 || subKeys.at(0) !== ""}>
                                {subKeys.map((subKey) => {
                                    const entry = subEntries[subKey]
                                    return (
                                        <TreeItem
                                            sx={[{ "&.MuiTreeItem-root > .MuiTreeItem-content.Mui-selected.Mui-focused": {
                                                    background: "transparent"
                                                   },
                                                   "&.MuiTreeItem-root > .MuiTreeItem-content.Mui-selected": {
                                                       background: "transparent"
                                                   },
                                                   "&.MuiTreeItem-root > .MuiTreeItem-content:hover": {
                                                    background: "transparent"
                                                   },
                                            }]}
                                            nodeId={subKey}
                                            label={
                                                <Stack direction={"row"} alignItems={"center"}>
                                                    <Checkbox
                                                        checked={getEntryEnabled(entry)}
                                                        onChange={(e) => setEntryEnabled(entry, e.target.checked)}
                                                    />
                                                    <Typography
                                                        variant="body2"
                                                        style={{ fontFamily: "monospace" }}
                                                    >
                                                        {keyStringToLabel(subKey)}
                                                    </Typography>
                                                    <ConditionalFragment condition={logsStillLoading.includes(entry)}>
                                                        <Box sx={{ display: "flex" }}>
                                                            <CircularProgress />
                                                        </Box>
                                                    </ConditionalFragment>
                                                </Stack>
                                            }
                                        />
                                    )
                                })}
                            </ConditionalFragment>
                        </TreeItem>
                    )
                })}
            </TreeView>
        </Container>
    )
}

const TestCaseReportLogsAsText = (props: {
    logs: ITestCaseLog[]
    selectedTimeframe: [string | undefined, string | undefined]     // "YYYY-MM-DD hh:mm:ss,SSS"
    selectedTimestamp: [string | undefined, boolean | undefined]    // "YYYY-MM-DD hh:mm:ss,SSS"
    enableEdit: boolean
}) => {

    const [selectedTimeframeStart, selectedTimeframeEnd] = props.selectedTimeframe
    const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc")
    const [sortColumn, setSortColumn] = useState(0)
    const [disabledLogTypes, setDisabledLogTypes] = useState<TTestCaseMeasurement[]>(props.logs.map((log) => log.measurement).filter((logType) => isDumpLogType(logType)))
    const [colorTableStartIndexAndIncrement, setColorTableStartIndexAndIncrement] = useState<number[]>([479, 207])
    const fixedSizeListRef = useRef<FixedSizeList>(null)
    const contentTableRef = useRef<HTMLTableElement>(null)

    const { t } = useTranslation()
    
    useEffect(() => {
        props.logs.forEach((log) => {
            log.tryToFetchNewData = !disabledLogTypes.includes(log.measurement)
        })
    }, [disabledLogTypes, props.logs])

    const shuffleColors = () => {
        if (!props.enableEdit) {
            return
        }
        const newColorTableIndexAndIncrement = [Math.floor(Math.random() * 1000), Math.floor(Math.random() * 500)]
        logger.debug(newColorTableIndexAndIncrement)
        setColorTableStartIndexAndIncrement(newColorTableIndexAndIncrement)
    }

    const logTypeColors: string[] = useMemo(() => makeRGBAColorList(0.5, colorTableStartIndexAndIncrement[0], colorTableStartIndexAndIncrement[1]), [colorTableStartIndexAndIncrement])
    const logTypes: TTestCaseMeasurement[] = props.logs.map(log => log.measurement).sort()

    const getLogTypeClsFromLogType = (logType: string): string => {
        return logType.replace("probe.", "").split(".")[0]
    }

    const logTypeSelectTree: TLogTypeSelectTree = {}
    logTypes.forEach((logType) => {
        const [cls, ...rest] = logType.replace("probe.", "").split(".")
        const desc = rest.join(".")
        let clsTree = logTypeSelectTree[cls]
        if (clsTree === undefined) {
            clsTree = logTypeSelectTree[cls] = {}
        }
        clsTree[desc] ??= logType
    })
    const logTypeClasses = Object.keys(logTypeSelectTree).sort()

    const mergedLogLineRows: ITestCaseLogRow[] = useMemo(() => {
        return props.logs.filter(log => !disabledLogTypes.includes(log.measurement)).map(e => e.logRows).reduce((l, r) => l.concat(r), [])
    }, [disabledLogTypes, props.logs])
    const sortedLogLineRows: ITestCaseLogRow[] = useMemo(() => {
        const rowSortComparator = (a: ITestCaseLogRow, b: ITestCaseLogRow): number => {
            return (a.cols[sortColumn] < b.cols[sortColumn] ? -1 : 1) * (sortOrder === "asc" ? 1 : -1)
        }
        return mergedLogLineRows.sort(rowSortComparator)
    }, [mergedLogLineRows, sortColumn, sortOrder])

    const findLoglineIndexFromTimestamp = useCallback((timestamp: string): number | undefined => {
        const distantPast = "1970-01-01 00:00:00,000"
        let lastLogLineTime: string | undefined = undefined
        let pickedLogLineIndex: number = -1
        let index = 0
        for (const logLine of sortedLogLineRows) {
            const logLineTime = getDateTimeStringFromRFC3339TimestampWithMilliseconds(logLine.cols[0])
            if (lastLogLineTime !== undefined) {
                if ((lastLogLineTime <= (timestamp ?? distantPast) && logLineTime >= (timestamp ?? distantPast)) ||
                    (lastLogLineTime >= (timestamp ?? distantPast) && logLineTime <= (timestamp ?? distantPast))) {

                   pickedLogLineIndex = index 
                   break
                }
            }
            lastLogLineTime = logLineTime
            index += 1
        }
        return pickedLogLineIndex === -1 ? undefined : pickedLogLineIndex
    }, [sortedLogLineRows])

    useEffect(() => {
        const scrollToTime = props.selectedTimeframe[0]
        if (scrollToTime === undefined) {
            return
        }
        const pickedLogLineIndex = findLoglineIndexFromTimestamp(scrollToTime)
        if (pickedLogLineIndex !== undefined) {
            // logger.debug(`scrolling to entry with index ${pickedLogLineIndex} and date ${sortedLogLineRows[pickedLogLineIndex].cols[0]}`)
            fixedSizeListRef.current?.scrollToItem(pickedLogLineIndex, "start")
        }
    }, [props.selectedTimeframe, sortedLogLineRows, findLoglineIndexFromTimestamp])

    useEffect(() => {
        const [scrollToTime, setFocus] = props.selectedTimestamp
        if (scrollToTime === undefined) {
            return
        }
        const pickedLogLineIndex = findLoglineIndexFromTimestamp(scrollToTime)
        if (pickedLogLineIndex !== undefined) {
            // logger.debug(`scrolling to entry with index ${pickedLogLineIndex} and date ${sortedLogLineRows[pickedLogLineIndex].cols[0]}`)
            fixedSizeListRef.current?.scrollToItem(pickedLogLineIndex, "start")
            if (setFocus) {
                contentTableRef.current?.scrollIntoView()
            }
        }
    }, [props.selectedTimestamp, sortedLogLineRows, findLoglineIndexFromTimestamp])

    const handleToggleSortOrderFor = (column: number) => {
        setSortOrder(column === sortColumn ? (sortOrder === "asc" ? "desc" : "asc") : "asc")
        setSortColumn(column)
    }

    const LogTableRow = (props: { index: any, style: any, data: { autoWidth: number } }) => {
        const logRow = sortedLogLineRows[props.index]
        // const backgroundColor = logTypeColors[logTypes.indexOf(logRow.measurement) % logTypeColors.length]
        const backgroundColor = logTypeColors[logTypeClasses.indexOf(getLogTypeClsFromLogType(logRow.measurement)) % logTypeColors.length]

        const timeColWidth = (props.data.autoWidth / 4 * 1)
        const textColWidth = (props.data.autoWidth / 4 * 3)

        const rowDateTimeString = getDateTimeStringFromRFC3339TimestampWithMilliseconds(logRow.cols[0])

        return (
            <TableRow
                sx={{ width: (timeColWidth + textColWidth), backgroundColor: backgroundColor }}
                style={{ ...props.style, ...{ opacity: ((selectedTimeframeStart ?? rowDateTimeString) <= rowDateTimeString && rowDateTimeString <= (selectedTimeframeEnd ?? rowDateTimeString)) ? 1.0 : 0.2 } }}
                component="div"
            >
                <TableCell
                    align="left"
                    sx={{ width: timeColWidth, maxWidth: timeColWidth }}
                    component="div"
                >
                    <AutoTooltip>
                        <Typography sx={{ fontFamily: 'monospace' }}>
                            {rowDateTimeString}
                        </Typography>
                    </AutoTooltip>
                </TableCell>
                <TableCell
                    align="left"
                    sx={{ width: textColWidth, maxWidth: textColWidth }}
                    component="div"
                >
                    <AutoTooltip>
                        <Typography sx={{ fontFamily: 'monospace' }}>
                            {logRow.cols.slice(1).join(" - ")}
                        </Typography>
                    </AutoTooltip>
                </TableCell>
            </TableRow>
        )
    }

    return (
        <Box sx={{ width: "100%", height: "100%" }}>
            <Toolbar>
                <Typography variant="h6" id="tableTitle" onClick={shuffleColors}>
                    {t("Logs")}
                </Typography>
                <Grid container sx={{ margin: 1 }} columns={5} spacing={1} alignItems={"center"}>
                    {logTypeClasses.map((cls, index) => {
                        let singleClsLogTypeSelectTree: TLogTypeSelectTree = {}
                        singleClsLogTypeSelectTree[cls] = logTypeSelectTree[cls]
                        return (
                            <Grid item xs={5} sm={5/3} lg={1} key={cls}>
                                <TestCaseReportLogsSelectionHeaderClassGroupingTree
                                    logTypeTree={singleClsLogTypeSelectTree}
                                    stateDisabledLogTypes={[disabledLogTypes, setDisabledLogTypes]}
                                    backgroundColor={logTypeColors[index % logTypeColors.length]}
                                    logs={props.logs}
                                />
                            </Grid>
                        )
                    })}
                </Grid>
            </Toolbar>
            <Table
                ref={contentTableRef}
                sx={{ width: "100%", height: "100%" }}
                aria-labelledby="tableTitle"
                size="small"
                component="div"
            >
                <TableHead
                    component="div"
                    sx={{ width: "100%" }}
                >
                    <TableRow
                        component="div"
                        sx={{ width: "100%" }}
                    >
                        <TableCell
                            key="time"
                            align="left"
                            padding="normal"
                            sortDirection={sortColumn === 0 ? sortOrder : false}
                            component="div"
                        >
                            <TableSortLabel
                                active={sortColumn === 0}
                                direction={sortColumn === 0 ? sortOrder : "asc"}
                                onClick={() => handleToggleSortOrderFor(0)}
                            >
                                {t("Time")} 
                            </TableSortLabel>
                        </TableCell>
                        <TableCell
                            key="logline"
                            align="left"
                            padding="normal"
                            sortDirection={sortColumn === 1 ? sortOrder : false}
                            component="div"
                        >
                            <TableSortLabel
                                active={sortColumn === 1}
                                direction={sortColumn === 1 ? sortOrder : "asc"}
                                onClick={() => handleToggleSortOrderFor(1)}
                            >
                                {t("Logline")}
                            </TableSortLabel>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody
                    component="div"
                    sx={{ width: "100%" }}
                >
                    <AutoSizer>
                        {({height, width}) => (
                            <FixedSizeList
                                ref={fixedSizeListRef}
                                height={height}
                                width={width}
                                itemSize={36}   // row height
                                itemCount={sortedLogLineRows.length}
                                itemData={{ autoWidth: width }}
                            >
                                {LogTableRow}
                            </FixedSizeList>
                        )}
                    </AutoSizer>
                </TableBody>
            </Table>
        </Box>
    )
}

const updateLogEntryLocks: Set<string> = new Set()

export const RemoteJobTestcaseResult = (props: any) => {

    const { readString } = usePapaParse()

    const remoteJobId = props.remoteJob.id
    const remoteJobs = useAppSelector(selectRemoteJobs)
    const remoteJobTestcaseTemplates = useAppSelector(selectTestcaseRemoteJobTemplates)
    const remoteJob = remoteJobs.filter(remoteJob => remoteJob.id === remoteJobId).pop()
    const systemData = useAppSelector(selectSystemData)
    const userDataObject = useAppSelector(selectUserDataAsObject)

    const [selectedTimeframe, setSelectedTimeframe] = useState<[string | undefined, string | undefined]>([undefined, undefined])
    const [selectedTimestamp, setSelectedTimestamp] = useState<[string | undefined, boolean | undefined]>([undefined, undefined])

    const [testCaseLogs, setTestCaseLogs] = useState<ITestCaseLog[]>([])
    const [fetchLogsTicker, setFetchLogsTicker] = useState(0)
    const [enableEdit, setEnableEdit] = useState(true)
    
    const [summaryTablePage, setSummaryTablePage] = useState(0)
    const [summaryTablePageRowCount, setSummaryTablePageRowCount] = useState<TTablePageRowCount>(25)

    const remoteJobArtifacts = useAppSelector(selectRemoteJobArtifacts)
    const remoteJobResultArtifacts = useMemo(() => {
        return remoteJobArtifacts.filter((remoteJobArtifact) => remoteJobArtifact.artifact_type === remoteJobCSVResultsFileArtifactType &&
                                                                remoteJobId === remoteJobArtifact.remote_job)
    }, [remoteJobArtifacts, remoteJobId])
    const [remoteJobResultStateFromArtifact, setRemoteJobResultStateFromArtifact] = useState<{[remoteJobId: number]: TTestcaseResult}>({})

    const expertMode = useMemo(() => getUserDataEntryFor(userDataExpertModeEnabled, userDataObject, false), [userDataObject])
    const darkThemeEnabled = useMemo(() => getUserDataEntryFor(userDataDarkThemeEnabled, userDataObject, false), [userDataObject])

    useObserveAndDeleteWidget("TESTCASERESULT", remoteJobs)

    const { t } = useTranslation()

    useEffect(() => {
        remoteJobResultArtifacts.forEach((resultArtifact) => {
            fetch(`${httpBackendURL}${resultArtifact.artifact_file}`).then(response => {
                if(response.status !== 200) {
                    // dispatch(showUserMessage({ title: t("Artifact Download failed"), message: t("The requested artifact does not seem to exist") }))
                    return
                }
                response.text().then((text) => {
                    // a line in the CSV has the following format (we are interested in the "result" column)

                    // res_dict = {"index": i,
                    //             "test_case_framework": str(tc.test_case.__class__.__name__),
                    //             "arguments": str(tc.arguments),
                    //             "result": str(tc.test_case.result),
                    //             "return_value": str(tc.return_value)}

                    // possible values for result are (the string entry get formatted as "Results.EXECUTED_SUCCESSFUL")
                    // class Results(Enum):
                    //     NOT_EXECUTED = -1
                    //     EXECUTED_SUCCESSFUL = 0
                    //     EXECUTED_WITH_ERROR = 1
                    //     EXECUTED_WITH_WARNING = 2

                    readString(text, { worker: true,
                                       complete: (csvReaderResults) => {
                                           const csvRows: string[][] = csvReaderResults.data as string[][]
                                           const headerColumnToIndexMap: {[colKey: string]: number} = {}
                                           let results: string[] = []
                                           try {
                                                results = csvRows.map((csvRow, i) => {
                                                    if (i === 0) {
                                                        // header
                                                        csvRow.forEach((col, j) => headerColumnToIndexMap[col] = j)
                                                        return ""
                                                    } else if (csvRow.length <= 1) {
                                                        return ""
                                                    } else {
                                                        return csvRow[headerColumnToIndexMap["result"]].split(".")[1].trim()
                                                    }
                                                }).filter((e) => e.length > 0)
                                           } catch(error) {
                                                logger.error(error)
                                           }

                                           const atLeastOneSuccess = results.filter((r) => r === "EXECUTED_SUCCESSFUL").length > 0
                                           const atLeastOneError = results.filter((r) => r === "EXECUTED_WITH_ERROR").length > 0
                                           const atLeastOneWarning = results.filter((r) => r === "EXECUTED_WITH_WARNING").length > 0

                                           let overallResult: TTestcaseResult = "NOT_EXECUTED"
                                           if (atLeastOneError) {
                                               overallResult = "ERROR"
                                           } else if (atLeastOneWarning) {
                                               overallResult = "WARNING"
                                           } else if (atLeastOneSuccess) {
                                               overallResult = "SUCCESSFULL"
                                           }

                                           setRemoteJobResultStateFromArtifact((remoteJobResultStateFromArtifact) => {
                                               return {...remoteJobResultStateFromArtifact,
                                                       ...{[resultArtifact.remote_job]: overallResult}}
                                           })
                                       }
                                   })
                    
                })
            })
        })
    }, [remoteJobResultArtifacts, readString])

    const getRemoteTestcaseStateIcon = (remoteJob: IRemoteJob) => {
        const getIcon = (): any => {
            switch (remoteJob.state) {
                case "FINISHED_ERROR":
                    return <HighlightOffIcon sx={{ color: "red" }} />
                case "FINISHED_SUCCESS":
                    switch (remoteJobResultStateFromArtifact[remoteJob.id]) {
                        case "ERROR":
                            return <HighlightOffIcon sx={{ color: "red" }} />
                        case "WARNING":
                            return <ErrorOutlineIcon color="warning" />
                        case "SUCCESSFULL":
                            return <CheckCircleOutlineOutlinedIcon sx={{ color: "green" }} />
                        default:
                            // TODO: show something more "usefull" in this case
                            return <HighlightOffIcon sx={{ color: "red" }} />
                    }
                case "CREATED_WAITING":
                    return <RunCircleOutlinedIcon sx={{ color: "grey" }} />
                default:
                    // running
                    return <RunCircleOutlinedIcon sx={{ color: yellowColorDependingOnTheme(darkThemeEnabled) }} />
            }
        }
        const getTooltipTitle = (): string => {
            if (remoteJob.error_description.length > 0) {
                return remoteJob.error_description
            }
            if (remoteJob.state === "FINISHED_SUCCESS") {
                // use the high level csv result in this case
                switch (remoteJobResultStateFromArtifact[remoteJob.id]) {
                    case "ERROR":
                        return t("At least one test finished with an error")
                    case "WARNING":
                        return t("At least one test finished with a warning")
                    case "SUCCESSFULL":
                        return t("All tests finished successfully")
                    default:
                        return t("No test was executed")
                }
            }
            return getRemoteJobStateMappedToRepresentation(remoteJob.state)
        }
        return (
            <Tooltip
                title={getTooltipTitle()}
            >
                {getIcon()}
            </Tooltip>
        )
    }

    const handleChangeSummaryTablePage = (event: unknown, newPage: number) => {
        setSummaryTablePage(newPage)
    }

    const handleChangeRowsPerSummaryTablePage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const rowCount = parseInt(event.target.value, 10)
        setSummaryTablePageRowCount(rowCount as TTablePageRowCount)
        setSummaryTablePage(0)
    }

    const resetAllResults = () => {
        // remove all logs and re-set all selections
        // (should be called when the test case was re-started)
        setTestCaseLogs([])
        setSelectedTimestamp([undefined, undefined])
        setSelectedTimeframe([undefined, undefined])
    }

    useEffect(() => {
        setEnableEdit(false)
        const timeoutHandle = setTimeout(() => setEnableEdit(true), 1.5 * pollIntervalMS)
        return () => clearTimeout(timeoutHandle)
    }, [fetchLogsTicker])

    useEffect(() => {
        // Note: some companies might block "not common ports" (influxs 8086 for example)
        // -> we map the port to idb.$HydraVisionUrl, therefor no port is necessary
        const influxUrl = systemData.influxdb_env.port === undefined ? `${systemData.influxdb_env.url}` : `${systemData.influxdb_env.url}:${systemData.influxdb_env.port}` 
        const queryApi = new InfluxDB({url: `${systemData.influxdb_env.use_https ? 'https' : 'http'}://${influxUrl}`,
                                       token: systemData.influxdb_env.access_token}).getQueryApi(systemData.influxdb_env.org)

        // query all available measurements for a specific remote job
        let lastFetchedMeasusrements: string[][] = []
        const updateAvailableMeasurements = async () => {
            const fluxQuery = `from(bucket: "${systemData.influxdb_env.bucket}") \
                                |> range(start: time(v: ${remoteJob?.created_at})) \
                                |> filter(fn: (r) => r["remote_job_id"] == "${remoteJob?.id}") \
                                |> drop(columns: ["_field", "_value", "_time", "_start", "_stop"]) \
                                |> distinct(column: "data_type")`
            // logger.debug(fluxQuery)
            const fetchedMeasurements: string[][] = []
            for await (const {values, tableMeta} of queryApi.iterateRows(fluxQuery)) {
                const valueObject = tableMeta.toObject(values)
                fetchedMeasurements.push([valueObject["_measurement"], valueObject["data_type"]])
            }
            // logger.debug(JSON.stringify(fetchedMeasurements))

            lastFetchedMeasusrements = fetchedMeasurements
        }

        // fetch log data
        const updateLogEntry = async (logEntry: ITestCaseLog) => {
            if (!logEntry.tryToFetchNewData) {
                logger.debug(`skipping ${logEntry.measurement} (fetch disabled)`)
                return
            }
            const lockKey = `${logEntry.measurement}::${logEntry.dataType}`
            if (updateLogEntryLocks.has(lockKey)) {
                logger.debug(`skipping ${lockKey} (locked)`)
                return
            }
            updateLogEntryLocks.add(lockKey)
            try {
                // logger.debug(`fetching for ${logEntry.measurement} / ${logEntry.dataType} from ${logEntry.logRows.length > 0 ? (((logEntry.logRows.at(-1)?.rawTimestamp ?? 0) * 1e9) + 1e6) : remoteJob?.created_at}`)
                // const startTimestamp = logEntry.logRows.length > 0 ? (((logEntry.logRows.at(-1)?.rawTimestamp ?? 0) * 1e9) + 1e3) : remoteJob?.created_at
                const startTimestamp = logEntry.logRows.length > 0 ? ((((moment(logEntry.logRows.at(-1)?.cols[0] ?? "1970-01-01T00:00:00.000Z")).valueOf() / 1000) * 1e9) + 1e3) : remoteJob?.created_at
                const maxRowCount = 200000
                const fluxQuery = `from(bucket: "${systemData.influxdb_env.bucket}") \
                                    |> range(start: time(v: ${startTimestamp})) \
                                    |> filter(fn: (r) => r["_measurement"] == "${logEntry.measurement}" and r["data_type"] == "${logEntry.dataType}" and r["remote_job_id"] == "${remoteJob?.id}") \
                                    |> limit(n: ${maxRowCount}) \
                                    |> pivot(rowKey: ["_time"], columnKey: ["_field"], valueColumn: "_value") \
                                    |> drop(columns: ["remote_job_id", "data_type"]) \
                                    |> toFloat()`
                // logger.debug(fluxQuery)
                // letting the influxdb convert the RFC time to a nano second int will
                // lead to a "hanging" server with big logs (can dumps)
                // |> map(fn: (r) => ({ r with _time: int(v: r._time)}))
                const labelToIndexMap: {[label: string]: number} = {}
                let headerRow: string[] | undefined = undefined
                const logRows: ITestCaseLogRow[] = []
                for await (const {values, tableMeta} of queryApi.iterateRows(fluxQuery)) {
                    if (headerRow === undefined) {
                        const wantedColumns = tableMeta.columns.filter((c) => c.label === "_time" || !(c.label.startsWith("_") || ["result", "table"].includes(c.label)))
                        wantedColumns.forEach((e) => labelToIndexMap[e.label] = e.index)
                        headerRow = wantedColumns.map((e) => e.label)
                    }
                    // const rawTimestamp = (moment(values[labelToIndexMap["_time"]]).valueOf() / 1000)
                    logRows.push({ measurement: logEntry.measurement,
                                   dataType: logEntry.dataType,
                                   // rawTimestamp: rawTimestamp,
                                   cols: headerRow.map((h) => {
                                            let value = values[labelToIndexMap[h]]
                                            if (h === "_time") {
                                                // FIXME: improve this uber ugly javashit code
                                                // fix the "timestamp" and make it "string comparable" (if necessary) without breaking it (just removing the Z at the end would do that ...)
                                                // >>> "2023-07-26T08:34:36.98Z" < "2023-07-26T08:34:36.986Z" -> False
                                                if (value.at(-1) === "Z") {
                                                    const [time, fraction] = value.slice(0, value.length - 1).split(".", 2)
                                                    if ((fraction ?? "").length < 3) {
                                                        let fixedFraction = (fraction ?? "")
                                                        while (fixedFraction.length < 3) {
                                                            fixedFraction += "0"
                                                        }
                                                        value = [time, fixedFraction + "Z"].join(".")
                                                        // console.log(value)
                                                    }
                                                }
                                            }
                                            return value
                                         })
                                })
                    if (logRows.length > maxRowCount) {
                        // do not process more entries in this cycle
                        break
                    }
                }
                if (logRows.length > 0 && headerRow !== undefined) {
                    logger.debug(`adding ${logRows.length} enries for ${logEntry.measurement}`)
                    if (logEntry.headerRow.cols.length === 0) {
                        logEntry.headerRow = { measurement: logEntry.measurement,
                                               dataType: logEntry.dataType,
                                               cols: headerRow }
                    }
                    // let minRawTimestamp = logEntry.logRows.at(-1)?.rawTimestamp ?? 0
                    // const newLogRows = logRows.filter((e) => e.rawTimestamp > minRawTimestamp)
                    let minTimestamp = logEntry.logRows.at(-1)?.cols[0] ?? "1970-01-01T00:00:00.000Z"
                    const newLogRows = logRows.filter((e) => e.cols[0] > minTimestamp)
                    if (newLogRows.length > 0) {
                        logEntry.logRows = logEntry.logRows.concat(newLogRows.sort((a, b) => (a.cols[0] > b.cols[0]) ? 1 : ((a.cols[0] < b.cols[0]) ? -1 : 0)))
                        logEntry.lastUIUpdate = Date.now()
                        // trigger a re-render
                        setFetchLogsTicker((ticker) => ticker + 1)
                    }
                }
            } finally {
                updateLogEntryLocks.delete(lockKey)
            }
        }

        const updateAllLogs = () => {
            lastFetchedMeasusrements.forEach(([measurement, dataType]) => {
                setTestCaseLogs((testCaseLogs) => {
                    let newTestCaseLogs = testCaseLogs.slice()
                    const existingTestCaseLogEntry: ITestCaseLog | undefined = testCaseLogs.filter((testCaseLog) => testCaseLog.measurement === measurement && testCaseLog.dataType === dataType).pop()
                    if (existingTestCaseLogEntry === undefined) {
                        // no entry yet -> create a new one
                        const newLogEntry: ITestCaseLog = { measurement: measurement as TTestCaseMeasurement,
                                                            dataType: dataType as TTestCaseDataType,
                                                            headerRow: { measurement: measurement as TTestCaseMeasurement, dataType: dataType as TTestCaseDataType, cols: [] },
                                                            logRows: [],
                                                            lastUIUpdate: 0,
                                                            tryToFetchNewData: !isDumpLogType(measurement)}
                        logger.debug(`Adding new log entry for ${measurement} ${dataType}`)
                        newTestCaseLogs.push(newLogEntry)
                        setTimeout(() => updateLogEntry(newLogEntry))
                    } else {
                        // update the existing entry
                        logger.debug(`Updating log entry for ${measurement} ${dataType}`)
                        setTimeout(() => updateLogEntry(existingTestCaseLogEntry))
                    }
                    if (newTestCaseLogs.length > testCaseLogs.length) {
                        // can't avoid to trigger a re-render here
                        return newTestCaseLogs
                    } else {
                        // this wont trigger a re-render
                        return testCaseLogs
                    }
                })
            })
        }

        logger.debug("restart influxdb poll timer")

        resetAllResults()

        const timer = setInterval(() => {
            void (async () => {
                await updateAvailableMeasurements()
                updateAllLogs()
            })()
        }, pollIntervalMS)

        return () => clearInterval(timer)
    // eslint-disable-next-line
    }, [remoteJob?.created_at])

    const [logsForTextView, logsForChartViews, logsForResultsView, logsForResultSummary] = useMemo(() => {
        const logsForTextView: ITestCaseLog[] = []
        const logsForChartViews: ITestCaseLog[] = []
        let logsForResultsView: ITestCaseLog[] = []
        let logsForResultSummary: ITestCaseLog[] = []
        testCaseLogs.forEach((testCaseLog) => {
            switch (testCaseLog.dataType) {
                case "chart_data_point":
                    logsForChartViews.push(testCaseLog)
                    break
                case "log_line":
                    logsForTextView.push(testCaseLog)
                    break
                case "result_line":
                    logsForResultsView.push(testCaseLog)
                    break
                case "result_summary_line":
                    logsForResultSummary.push(testCaseLog)
                    break
                default:
                    logger.debug(`no idea how to handle this data type - ${testCaseLog.dataType}`)
                    break
            }
        })
        return [logsForTextView, logsForChartViews, logsForResultsView, logsForResultSummary]
    // eslint-disable-next-line
    }, [testCaseLogs, fetchLogsTicker])

    // combine results and summary per "measurement"
    interface ISummaryAndResults {
        key: string
        summary?: ITestCaseLog
        results?: ITestCaseLog
    }

    const combinedSummaryAndResults: ISummaryAndResults[] = useMemo(() => {
        const summaryAndResultsKeys: Set<string> = new Set()
        const summaryMap: {[key: string]: ITestCaseLog} = {}
        const resultsMap: {[key: string]: ITestCaseLog} = {}
        const getKeyFromMeasurement = (measurement: string): string => {
            // remove everything after "Results"
            return measurement.slice(0, measurement.indexOf("Results"))
        }
        logsForResultsView.forEach((log) => {
            const key = getKeyFromMeasurement(log.measurement)
            summaryAndResultsKeys.add(key)
            resultsMap[key] = log
        })
        logsForResultSummary.forEach((log) => {
            const key = getKeyFromMeasurement(log.measurement)
            summaryAndResultsKeys.add(key)
            summaryMap[key] = log
        })
        return Array.from(summaryAndResultsKeys).sort().map((key) => {
            return {
                key: key,
                summary: summaryMap[key],
                results: resultsMap[key]
            }
        })
    }, [logsForResultsView, logsForResultSummary])

    const makeSortedResultsTableRow = (key: string, headerRow: string[], sortRow: string[]): string[] => {
        const predefinedResultsHeaderOrder: {[name: string]: string[]} = {
            "PowerSensorBehaviourTest": ["boot_up", "boot_time_ecu", "avg_power_consumption", "goes_to_sleep", "time_to_sleep", "react_to_can_wakeup", "time_to_sleep_after_can_wakeup"],
            "ObdScan": ["OBD Service", "Service ID", "Data"],
            "UdsStateScan": ["msg", "cmd", "info"],
            "UdsServiceScan": ["ECU State", "UDS Service ID", "Data", "Info"],
            "IsotpValidation": ["Testcase", "Description Long", "Description Short", "CAN_DL: 0", "CAN_DL: 1", "CAN_DL: 2", "CAN_DL: 3", "CAN_DL: 4", "CAN_DL: 5", "CAN_DL: 6", "CAN_DL: 7", "CAN_DL: 8", "CAN_DL: 12", "CAN_DL: 16", "CAN_DL: 20", "CAN_DL: 24", "CAN_DL: 32", "CAN_DL: 48", "CAN_DL: 64"],
            // "IsotpScan": [... this result has a dynamic elements ...],
            // "UdsSecurityAccess": [... this result has a dynamic elements ...],
            // "ObdProtocolTest": [... this result has a dynamic elements ...],
            // "UdsProtocolTest": [... this result has a dynamic elements ...],
        }
        if (headerRow.length !== sortRow.length) {
            return []
        }
        let headerOrder = predefinedResultsHeaderOrder[key]
        const headerMapping: {[colKey: string]: string} = {}
        headerRow.forEach((k, i) => headerMapping[k] = sortRow[i])
        if (headerOrder?.length === headerRow.length) {
            return headerOrder.map((k) => keyStringToLabel(headerMapping[k]))
        } else {
            logger.debug(`no predefined header order defined or the count of the predefined and actual columns do not match for ${key}, sorting alphabetically`)
            headerOrder = headerRow.slice().sort()
            return headerOrder.map((k) => keyStringToLabel(headerMapping[k]))
        }
    }

    // let fakeTestLogsForTextView = logsForTextView[0] === undefined ? [] : Array(10).fill(logsForTextView[0]).map((e: ITestCaseLog, i: number) => ({...e, measurement: `hydra_probe.power_sensor.${i+1}${i%2===0?".dump":""}`, logRows: e.logRows.map(e => ({...e, measurement: `hydra_probe.power_sensor.${i+1}${i%2===0?".dump":""}`}))}))
    // fakeTestLogsForTextView = fakeTestLogsForTextView.concat(logsForTextView[0] === undefined ? [] : Array(5).fill(logsForTextView[0]).map((e: ITestCaseLog, i: number) => ({...e, measurement: `hydra_probe.power_foo.${i+1}${i%2===0?".dump":""}`, logRows: e.logRows.map(e => ({...e, measurement: `hydra_probe.power_foo.${i+1}${i%2===0?".dump":""}`}))})))

    return (
        <Container sx={{ overflow: "hidden", overflowY: "auto", height: "90vh", marginLeft: -1 }}>
            <Typography variant="h6">{t("Testcase for Target ECU") + " " + props.targetECU.name}</Typography>
            <Stack sx={{ marginTop: 1 }} direction="row" alignItems="center">
                <Chip sx={{ }} color="primary" label={`Remote Job ID: ${remoteJob?.id}`}/>
                <Chip color="primary" sx={{ marginLeft: 1 }} label={`Testcase Template: ${remoteJobTestcaseTemplates.filter(template => template.id === remoteJob?.job_template).pop()?.name}`}/>
                <Box display="flex" sx={{ marginLeft: 1 }}>
                    {getRemoteTestcaseStateIcon(remoteJob!)}
                </Box>
            </Stack>

            {combinedSummaryAndResults.map((summaryAndResults) => {
                return (
                    <Container sx={{ marginLeft: -3, marginTop: 3 }}>
                        <Accordion defaultExpanded={true}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                id={"summary-and-results-for-" + summaryAndResults.key}
                            >
                                <Typography
                                    sx={{ margin: 1 }}
                                    variant="h6"
                                >
                                    {summaryAndResults.key + " " + t("Results")}
                                </Typography>
                            </AccordionSummary>
                            <Container>
                                <ConditionalFragment condition={summaryAndResults.summary !== undefined}>
                                    <Typography sx={{ textAlign: "center", marginBottom: 3 }} variant="body1">
                                        {summaryAndResults.summary?.logRows[0]?.cols[1]}
                                    </Typography>
                                </ConditionalFragment>

                                <ConditionalFragment condition={summaryAndResults.results !== undefined}>
                                    <TableContainer sx={{ marginBottom: 5 }} component={Paper}>
                                        <Table
                                            sx={{ width: "100%", height: "100%" }}
                                            size="small"
                                            component="div"
                                        >
                                            <TableHead
                                                component="div"
                                                sx={{ width: "100%" }}
                                            >
                                                <TableRow
                                                    component="div"
                                                    sx={{ width: "100%" }}
                                                >
                                                    <TableCell
                                                        key="time"
                                                        align="left"
                                                        padding="normal"
                                                        component="div"
                                                    >
                                                        {t("Time")}
                                                    </TableCell>
                                                    {makeSortedResultsTableRow(summaryAndResults.key,
                                                                               summaryAndResults.results?.headerRow.cols.slice(1) ?? [],
                                                                               summaryAndResults.results?.headerRow.cols.slice(1) ?? []).map((col: string) => {
                                                        return (
                                                            <TableCell
                                                                key={col}
                                                                align="left"
                                                                padding="normal"
                                                                component="div"
                                                            >
                                                                {col}
                                                            </TableCell>
                                                        )
                                                    })}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody
                                                component="div"
                                                sx={{ width: "100%" }}
                                            >
                                                {(summaryAndResults.results?.logRows ?? []).slice(summaryTablePage * summaryTablePageRowCount,
                                                                                                  (summaryTablePage + 1) * summaryTablePageRowCount).map((row: ITestCaseLogRow, rowIndex: number) => {
                                                    return (
                                                        <TableRow
                                                            component="div"
                                                            sx={{ width: "100%" }}
                                                        >
                                                            <TableCell
                                                                key="time"
                                                                align="left"
                                                                padding="normal"
                                                                component="div"
                                                            >
                                                                <Typography
                                                                    variant="body2"
                                                                    style={{ fontFamily: 'monospace'  }}
                                                                >
                                                                    {getDateTimeStringFromRFC3339TimestampWithMilliseconds(row.cols[0])}
                                                                </Typography>
                                                            </TableCell>
                                                            {makeSortedResultsTableRow(summaryAndResults.key,
                                                                                       summaryAndResults.results?.headerRow.cols.slice(1) ?? [],
                                                                                       row.cols.slice(1)).map((col: string, colIndex: number) => {
                                                                return (
                                                                    <TableCell
                                                                        sx={{ maxWidth: 200 }}
                                                                        key={`${rowIndex}::${colIndex}`}
                                                                        align="left"
                                                                        padding="normal"
                                                                        component="div"
                                                                    >
                                                                        <AutoTooltip>
                                                                            <Typography
                                                                                variant="body2"
                                                                                style={{ fontFamily: 'monospace' }}
                                                                            >
                                                                                    {col}
                                                                            </Typography>
                                                                        </AutoTooltip>
                                                                    </TableCell>
                                                                )
                                                            })}
                                                        </TableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={tablePageRowCountOptions.slice()}
                                        component="div"
                                        count={(summaryAndResults.results?.logRows ?? []).length}
                                        rowsPerPage={summaryTablePageRowCount}
                                        page={summaryTablePage}
                                        onPageChange={handleChangeSummaryTablePage}
                                        onRowsPerPageChange={handleChangeRowsPerSummaryTablePage}
                                    />
                                </ConditionalFragment>
                            </Container>
                        </Accordion>
                    </Container>
                )
            })}

            <ConditionalFragment condition={remoteJob !== undefined && expertMode}>
                <Container sx={{ marginLeft: -3, marginTop: 3 }}>
                    <Stack direction="column">
                        <Typography variant="h6">
                            {t("Console Output")}
                        </Typography>
                        <RemoteJobLiveData remoteJob={remoteJob!}/>
                    </Stack>
                </Container>
            </ConditionalFragment>

            <ConditionalFragment condition={logsForTextView.length > 0 || logsForChartViews.length > 0}>
                <Stack sx={{ marginTop: 2 }} direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="h6">{t("Generated Data")}</Typography>
                    <ConditionalFragment condition={enableEdit}>
                        <Button
                            sx={{ marginRight: 7 }}
                            variant="contained"
                            onClick={() => setSelectedTimeframe([undefined, undefined])}
                            disabled={selectedTimeframe[0] === undefined && selectedTimeframe[1] === undefined}
                        >
                            {t("Reset Selected Timeframe")}
                        </Button>
                    </ConditionalFragment>
                </Stack>

                <Container sx={{ marginLeft: -3, marginTop: 2, marginBottom: 2 }}>
                    {logsForChartViews.map((chartLog) => <TestCaseReportChart
                                                            key={`${chartLog.measurement}:${chartLog.dataType}:${chartLog.logRows.at(-1)?.cols[0]}`}
                                                            log={chartLog}
                                                            stateSelectedTimeframe={[selectedTimeframe, setSelectedTimeframe]}
                                                            stateSelectedTimestamp={[selectedTimestamp, setSelectedTimestamp]}
                                                            enableEdit={enableEdit}
                                                         />)}
                </Container>

                <Container sx={{ marginLeft: -3 }}>
                    <Box sx={{ height: 25 * 36 }}>
                        <TestCaseReportLogsAsText
                            key={logsForChartViews.slice().sort().map((e) => e.logRows.at(-1)?.cols[0]).join(":")}
                            // logs={fakeTestLogsForTextView}
                            logs={logsForTextView}
                            selectedTimeframe={selectedTimeframe}
                            selectedTimestamp={selectedTimestamp}
                            enableEdit={enableEdit}
                        />
                    </Box>
                </Container>
            </ConditionalFragment>
        </Container>
    )
}
