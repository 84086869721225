import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { ICreateLicense, createLicenseAsync, ILicense, ILicenseInfo, ILicenseFeaturesToExplanationMapping, LicenseType, PaidLicenseType, selectValidLicenses, TLicenseFeature } from "./LicenseSlice"
import { LicenseManagementEdit } from "./LicenseEdit"
import { addOrActivateLicenseConfigWidget } from "../main_lumino_widget/MainLuminoWidgetSlice"
import { ConditionalFragment, distinct, getLicenseDetailsAPIURL, getLicenseServerAPIBaseURL, isRunningAs, isValidEmail } from "../misc/Util"
import { selectSystemData } from "../system_data/SystemDataSlice"
import { useTranslation } from 'react-i18next'

import FormControl from "@mui/material/FormControl"
import MenuItem from "@mui/material/MenuItem"
import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import Container from "@mui/material/Container"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import { Accordion, AccordionSummary, FormLabel, IconButton, Paper, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Checkbox, Typography, Radio, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from "@mui/material"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { showUserMessage } from "../user_message/UserMessageSlice"
import RefreshIcon from '@mui/icons-material/Refresh';
import { selectSettings } from "../settings/SettingsSlice"
import { logger } from "../../app/logging"
import { licenseFeatureKeyFreeLicense } from "../misc/Constants"

export const LicenseManagementCreate = () => {

    const SalesContactTimeFrameArray = ["NEVER", "ONEMONTH", "THREEMONTH", "SIXMONTH"] as const
    type SalesContactTimeFrame = typeof SalesContactTimeFrameArray[number]

    const { t } = useTranslation()

    const [licenseText, setLicenseText] = useState('')
    const [userEmail, setUserEmail] = useState('')
    const [userTitle, setUserTitle] = useState('')
    const [userAddressStreet, setUserAddressStreet] = useState('')
    const [userAddressCity, setUserAddressCity] = useState('')
    const [userAddressState, setUserAddressState] = useState('')
    const [userAddressPostal, setUserAddressPostal] = useState('')
    const [userAddressCountry, setUserAddressCountry] = useState('')
    const [licenseCouponCode, setLicenseCouponCode] = useState('')
    const [firstName, setFirstUserName] = useState('')
    const [lastName, setLastUserName] = useState('')
    const [userCompany, setUserCompany] = useState('')
    const [selectedPaidLicense, setSelectedPaidLicense] = useState<string>(PaidLicenseType.PERSONAL)
    const [licenseFeaturesMatrix, setLicenseFeaturesMatrix] = useState<ILicenseInfo>({})
    const [featureDescriptions, setFeatureDescriptions] = useState<ILicenseFeaturesToExplanationMapping>({})
    const [featureMatrixFetchFailed, setFeatureMatrixFetchFailed] = useState<boolean>(false)
    const [userWantsRegularUpdates, setUserWantsRegularUpdates] = useState(true)
    const [userWantsSalesContactTimeframe, setUserWantsSalesContactTimeframe] = useState<SalesContactTimeFrame>("ONEMONTH")
    const [userFeedbackText, setUserFeedbackText] = useState('')
    const [userFeedbackTextColor, setUserFeedbackTextColor] = useState('grey')
    const validLicenses = useAppSelector(selectValidLicenses)
    const [freeLicenseAccordionExpanded, setFreeLicenseAccordionExpanded] = useState<boolean>(validLicenses.length > 0 ? false : true)
    const [licenseRequestMailDialogOpen, setLicenseRequestMailDialogOpen] = useState(false)

    const systemData = useAppSelector(selectSystemData)
    const settings = useAppSelector(selectSettings)
    const locale = settings.locale
    const app_environment = systemData.app_environment

    const dispatch = useAppDispatch()

    const encodeLicenseRequestMailValue = (rawStr: string) => {
        return encodeURIComponent(rawStr)
    }

    const createLicenseRequestMail = (): string => {
        // https://www.eso.org/~ndelmott/url_encode.html
        const subject = `${t("Request for a license")}`
        const body = encodeLicenseRequestMailValue(`${t("Dear Sir or Madam")},

        ${t("I would like to request a license for HydraScope.")}
        ${t("The following data should be used for generating the license")}:
        ${t("Title")}: ${userTitle}
        ${t("First name")}: ${firstName}
        ${t("Last name")}: ${lastName}
        ${t("Company")}: ${userCompany}
        ${t("Street Address")}: ${userAddressStreet}
        ${t("City")}: ${userAddressCity}
        ${t("Postcode")}: ${userAddressPostal}
        ${t("State/Province")}: ${userAddressState}
        ${t("Country")}: ${userAddressCountry}
        ${t("License type")}: ${selectedPaidLicense}
        ${t("Inform me about updates")}: ${userWantsRegularUpdates ? t("Yes") : t("No")}
        ${t("Hardware-ID")}: ${systemData.hardware_id}
        
        ${t("Kind regards")}
        ${firstName} ${lastName}`)
        return `mailto:license@dissec.to?subject=${subject}&body=${body}`
    }

    const createLicenseRequestMailPopupContent = () => {
        return (
            <div>
                <span>{t("Dear Sir or Madam")},<br/></span>
                <span>{t("I would like to request a license for HydraScope.")}<br/></span>
                <span>{t("The following data should be used for generating the license")}:<br/></span>
                <span>{t("Title")}: {userTitle}<br/></span>
                <span>{t("First name")}: {firstName}<br/></span>
                <span>{t("Last name")}: {lastName}<br/></span>
                <span>{t("Company")}: {userCompany}<br/></span>
                <span>{t("Street Address")}: {userAddressStreet}<br/></span>
                <span>{t("City")}: {userAddressCity}<br/></span>
                <span>{t("Postcode")}: {userAddressPostal}<br/></span>
                <span>{t("State/Province")}: {userAddressState}<br/></span>
                <span>{t("Country")}: {userAddressCountry}<br/></span>
                <span>{t("License type")}: {selectedPaidLicense}<br/></span>
                <span>{t("Inform me about updates")}: {userWantsRegularUpdates ? t("Yes") : t("No")}<br/></span>
                <span>{t("Hardware-ID")}: {systemData.hardware_id}<br/></span>
                <span>{t("Kind regards")}<br/></span>
                <span>{firstName} {lastName}</span>
            </div>
        )
    }

    const makeLicenseRequest = (): string => {
        const licenseRequestObj = {
            "HWID": systemData.hardware_id,
            "EMAIL": userEmail,
            "NAME": `${firstName} ${lastName}`,
            "COMPANY": userCompany,
            "WANT_REGULAR_UPDATES": userWantsRegularUpdates,
            "WANT_SALES_CONTACT_AFTER": userWantsSalesContactTimeframe,
            "COUPON_CODE": licenseCouponCode === '' ? null : licenseCouponCode
        }
        //logger.debug(licenseRequestObj)
        return Buffer.from(JSON.stringify(licenseRequestObj)).toString('base64')
    }

    const getLicenseTypesAsHeaderRows = () => {
        const rows: any = []

        Object.values(LicenseType).forEach((licenseType: string) => {
            rows.push(<TableCell key={`${licenseType}-header-row`} width="20%" ><b>{licenseType}</b></TableCell>
            )
        })
        return rows
    }

    const fetchLicenseFeaturesMatrixFromLicenseServer = () => {
        fetch(`${getLicenseDetailsAPIURL(app_environment)}`)
        .then((response) => {
            if (response.status === 200) {
                response.json().then((jsonResponse) => {
                    setFeatureMatrixFetchFailed(false)
                    setLicenseFeaturesMatrix(jsonResponse.license_details as ILicenseInfo)
                    setFeatureDescriptions(jsonResponse.feature_descriptions as ILicenseFeaturesToExplanationMapping)
                })
            }
            else {
                setFeatureMatrixFetchFailed(true)
                logger.debug("Unable to fetch license features matrix.")
            }
        })
        .catch((error) => {
            logger.debug(error)
            setFeatureMatrixFetchFailed(true)
        })
    }
    const handleFeatureTableCellClick = (e: any) => {
        const descr = featureDescriptions[e.target.textContent]
        dispatch(showUserMessage({ title: e.target.textContent, message: descr[locale] }))
    }

    const getLicenseFeaturesAsRows = () => {
        let availableFeatures: string[] = []
        const rows: any = []

        for (let value of Object.values(licenseFeaturesMatrix)) {
            for (let key of Object.keys(value.features)) {
                availableFeatures.push(key)
            }
        }

        availableFeatures = availableFeatures.filter(distinct)

        for (let availableFeature of availableFeatures.sort()) {
            const currentRowValues: any[] = []
            for (let license of Object.values(LicenseType)) {
                if (licenseFeaturesMatrix[license] !== undefined) {
                    if (Object.keys(licenseFeaturesMatrix[license].features).includes(availableFeature)) {
                        currentRowValues.push(licenseFeaturesMatrix[license].features[availableFeature as TLicenseFeature])
                    } else {
                        currentRowValues.push("")
                    }
                } else {
                    currentRowValues.push("")
                }
            }
            rows.push(
                <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    key={availableFeature}
                >
                    <Tooltip title={t("Click to find out more about this feature.") as string}>
                        <TableCell width="20%" onClick={handleFeatureTableCellClick} component="th" scope="row">{availableFeature}</TableCell>
                    </Tooltip>
                    {currentRowValues.map((value) => <TableCell key={`${value}-${Math.random().toString(36).substring(2)}`} width="20%" >{value}</TableCell>)}
                </TableRow>)
        }

        return rows
    }

    const getLicensePricesAsRows = () => {
        return (
            <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                key="license-price-row"
            >
                <TableCell width="20%" component="th" scope="row">{t("Price (per year)")}</TableCell>
                {Object.values(LicenseType).map((license) => {
                    if (licenseFeaturesMatrix[license] !== undefined) {
                        if (Object.keys(licenseFeaturesMatrix[license].price)) {
                            return <TableCell key={`${licenseFeaturesMatrix[license].price}-${Math.random().toString(36).substring(2)}`} width="20%" component="th" scope="row">{typeof licenseFeaturesMatrix[license].price === "number" ? `${licenseFeaturesMatrix[license].price}€` : licenseFeaturesMatrix[license].price}</TableCell>
                        } else {
                            return <TableCell key={`Contact us-${Math.random().toString(36).substring(2)}`} width="20%" component="th" scope="row">{t("Contact us for more information")}</TableCell>
                        }
                    } else {
                        return <TableCell key={`Contact us-${Math.random().toString(36).substring(2)}`} width="20%" component="th" scope="row">{t("Contact us for more information")}</TableCell>
                    }
                })}
            </TableRow>
        )
    }

    const getLicenseFeaturesMatrixAsTable = () => {
        return (
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 300 }} aria-label="License Features Table" id="license-feature-matrix">
                    <TableHead>
                        <TableRow key="License-Details-Header-Row">
                            <TableCell width="20%" ><b>{t("Feature")}</b></TableCell>
                            {getLicenseTypesAsHeaderRows()}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getLicenseFeaturesAsRows()}
                        {getLicensePricesAsRows()}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    const tryToRequestFreeLicense = () => {
        if (!hasAllNeededDataToRequestLicense() || !isValidEmail(userEmail)) {
            setUserFeedbackText(t("You need to provide a valid email address.") as string)
            setUserFeedbackTextColor('orange')
            return
        }
        if (alreadyGotAFreeLicense()) {
            setUserFeedbackText(t("A free license was already added.") as string)
            setUserFeedbackTextColor('orange')
            return
        }
        fetch(`${getLicenseServerAPIBaseURL(app_environment)}${makeLicenseRequest()}/`)
            .then((response) => {
                switch (response.status) {
                    case 400:
                        logger.debug('bad request')
                        setUserFeedbackText(t("There was an error. Please make sure that you have the latest version installed / try again later. Please report this as a bug if the error perists longer than a day.") as string)
                        setUserFeedbackTextColor('error')
                        break
                    case 402:
                        logger.debug('there are no more free licenses available for this domain or coupon code')
                        setUserFeedbackText(t("There are no more free licenses available for this domain or coupon code. Feel free to contact us for a paid license.") as string)
                        setUserFeedbackTextColor('orange')
                        break
                    case 404:
                        logger.debug('the provided coupon code is invalid')
                        setUserFeedbackText(t("The provided coupon code seems to be invalid.") as string)
                        setUserFeedbackTextColor('orange')
                        break
                    case 202:
                    case 409:
                        logger.debug('pending user verification - retry in 10 seconds')
                        setUserFeedbackText(t("Please check your email inbox and click on the provided link to finish the verification. Check your spam folder if the email does not appear in the inbox.") as string)
                        setUserFeedbackTextColor('orange')
                        setTimeout(tryToRequestFreeLicense, 10000)
                        break
                    case 200:
                        setUserFeedbackText(t("Your free license was downloaded successfully and will be added now.") as string)
                        setUserFeedbackTextColor('green')
                        response.json().then((jsonResponse) => {
                            addLicense(jsonResponse['License'])
                            setUserFeedbackText(t("Your free license was successfully added.") as string)
                            setUserFeedbackTextColor('green')
                        })
                        break
                    default:
                        logger.debug(`unexpected response (code - ${response.status})`)
                        setUserFeedbackText(t("Unexpected backend error. Please try again later or report this as bug if the error perists for a longer period.") as string)
                        setUserFeedbackTextColor('error')
                }
            })
            .catch((error) => {
                logger.debug(error)
                let feedbackText = t("Unexpected backend error. Please try again later or report this as bug if the error perists for a longer period.") as string
                feedbackText += ` (${error})`
                setUserFeedbackText(feedbackText)
                setUserFeedbackTextColor('error')
            })
    }

    const getReadableStringFromSalesContactTimeFrame = (timeFrame: SalesContactTimeFrame): string => {
        switch (timeFrame) {
            case "NEVER":
                return t("Never") as string
            case "ONEMONTH":
                return t("One Month") as string
            case "THREEMONTH":
                return t("Three Month") as string
            case "SIXMONTH":
                return t("Six Month") as string
        }
    }

    const alreadyGotAFreeLicense = (): boolean => {
        return validLicenses.filter(l => Object.keys(l.license.license_config.Features).includes(licenseFeatureKeyFreeLicense)).length > 0
    }

    const hasAllNeededDataToRequestLicense = (): boolean => {
        return userEmail.length > 0
    }

    const hasAllNeededDataToAddLicense = () => {
        return licenseText.length > 0
    }

    const addLicense = (licenseBlob: string) => {
        const newLicense: ICreateLicense = {
            license_text: licenseBlob
        }
        dispatch(createLicenseAsync(newLicense)).then((resultPromise) => {
            if (resultPromise.type !== "license/createLicenseAsync/rejected") {
                const createdLicense: ILicense = resultPromise.payload
                dispatch(addOrActivateLicenseConfigWidget(createdLicense))
            }
        })
    }

    const addLicenseClicked = () => {
        if (!hasAllNeededDataToAddLicense()) {
            return
        }
        addLicense(licenseText)
    }

    useEffect(() => {
        // Execute this only once. If it fails the user can try to manually trigger it again
        // by clicking a button after fixing his internet connection/other problems
        if (Object.keys(licenseFeaturesMatrix).length !== 0) {
            return
        }
        fetchLicenseFeaturesMatrixFromLicenseServer()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleLicenseRequestMailPopupClose = () => {
        setLicenseRequestMailDialogOpen(false)
    }

    return (
        <Container id="licenses-container" sx={{ overflow: "hidden", overflowY: "auto", height: "90vh", marginLeft: -2 }}>
            <ConditionalFragment condition={isRunningAs("HydraScope")}>
                <Container sx={{ marginTop: 2 }}>
                    <Accordion
                        expanded={freeLicenseAccordionExpanded}
                        onChange={() => {setFreeLicenseAccordionExpanded((expanded) => !expanded)}}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            id="request-a-free-license-header"
                            sx={{ margin: 1, marginLeft: 1 }}
                        >
                            <Typography
                                sx={{ margin: 1 }}
                                variant="h6"
                            >
                                {t("Request a free license")}</Typography>
                        </AccordionSummary>
                        <Typography
                            sx={{ marginLeft: 4 }}
                            variant="body1"
                        >
                            {t('Click the following button to request a free license. The form data you inserted will be used to generate the license which gets added automatically in the licenses tab. The free license requires you to type in your email address. You can also specify if and when our sales team may contact you about paid license options.')}
                        </Typography>
                        <Container sx={{ marginLeft: 1, display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                            <TextField
                                error={!isValidEmail(userEmail)}
                                sx={{ marginTop: 2, flex: "1 0 30%" }}
                                id="add-license-email"
                                label={t("Email address")}
                                value={userEmail}
                                onChange={(e) => setUserEmail(e.target.value)}
                            />
                            <FormControl sx={{ marginLeft: 1, marginTop: 2, flex: "1 1 30%" }}>
                                <TextField
                                    label={t("When do you want to be contacted by us")}
                                    id="add-license-sales-contact-timeframe-select"
                                    value={userWantsSalesContactTimeframe}
                                    select
                                    onChange={(e) => setUserWantsSalesContactTimeframe(e.target.value as SalesContactTimeFrame)}
                                >
                                    {SalesContactTimeFrameArray.map((timeFrame) =>
                                        <MenuItem key={timeFrame} value={timeFrame}>
                                            {getReadableStringFromSalesContactTimeFrame(timeFrame)}
                                        </MenuItem>
                                    )}
                                </TextField>
                            </FormControl>
                            <Tooltip title={t("You can add a coupon code here that was handed to you by us. It is however not necessary and if omitted you will still be able to get one free license per company domain (that is yourname@companydomain.com).") as string}>
                                <TextField
                                    sx={{ marginLeft: 1, marginTop: 2, flex: "1 0 30%" }}
                                    id="add-license-coupon"
                                    label={t("Coupon code")}
                                    value={licenseCouponCode}
                                    onChange={(e) => setLicenseCouponCode(e.target.value)}
                                />
                            </Tooltip>
                        </Container>
                        <Container sx={{ marginTop: 2, marginLeft: 1 }}>
                            <Typography color={userFeedbackTextColor}>
                                {userFeedbackText}
                            </Typography>
                        </Container>
                        <Button
                            id="request-free-license-button"
                            variant="contained"
                            sx={{ marginLeft: 4, marginTop: 2, marginBottom: 2 }}
                            onClick={tryToRequestFreeLicense}
                            disabled={alreadyGotAFreeLicense()}
                        >
                            {t('Try to request a free license')}
                        </Button>
                    </Accordion>
                </Container>
                <Container sx={{ marginTop: 2 }}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            id="feature-overview-for-the-licenses-header"
                            sx={{ margin: 1, marginLeft: 1, marginTop: 2 }}

                        >
                            <Typography
                                sx={{ margin: 1 }}
                                variant="h6"
                            >
                                {t('Licence feature overview')}</Typography>
                        </AccordionSummary>
                        {featureMatrixFetchFailed ?
                            <Container>
                                <Typography
                                    color="warning"
                                    variant="body1"
                                >
                                    {t("Unable to fetch the features overview. Please check your internet connection and manually retry the fetch or visit our website www.dissec.to.")}
                                </Typography>
                                <IconButton
                                    color="primary"
                                    onClick={fetchLicenseFeaturesMatrixFromLicenseServer}
                                >
                                    <RefreshIcon />
                                </IconButton>
                            </Container>

                            :
                            Object.keys(licenseFeaturesMatrix).length !== 0 ?
                                getLicenseFeaturesMatrixAsTable()
                                :
                                <Typography
                                    color="info"
                                    variant="body1"
                                >
                                    {t("Fetching feature overview data from server.")}
                                </Typography>
                        }
                    </Accordion>
                </Container>
                <Container sx={{ marginTop: 2 }}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            id="request-a-commerical-license-header"
                            sx={{ margin: 1, marginLeft: 1, marginTop: 2 }}

                        >
                            <Typography
                                sx={{ margin: 1 }}
                                variant="h6"
                            >
                                {t('Request a commerical license')}</Typography>
                        </AccordionSummary>
                        <Typography
                            sx={{ marginLeft: 3 }}
                        >
                            <br />
                            {t('Please fill in the following form. Afterwards you can either try to obtain a free license (only one per domain) or request a paid license.')}
                        </Typography>
                        <Container>
                            <FormControl sx={{ marginTop: 2, marginRight: 1, minWidth: 210, flex: "1 0 30%" }}>
                                <TextField
                                    label={t("Title")}
                                    id="add-license-title"
                                    value={userTitle}
                                    select
                                    onChange={(e) => setUserTitle(e.target.value as SalesContactTimeFrame)}
                                >
                                    <MenuItem key={"add-license-title-mr"} value={t("Mr")}>
                                        {t("Mr")}
                                    </MenuItem>
                                    <MenuItem key={"add-license-title-mrs"} value={t("Mrs")}>
                                        {t("Mrs")}
                                    </MenuItem>
                                </TextField>
                            </FormControl>
                            <TextField
                                sx={{ marginTop: 2, marginRight: 1, flex: "1 0 30%" }}
                                id="add-license-first-name"
                                label={t("First Name")}
                                value={firstName}
                                onChange={(e) => setFirstUserName(e.target.value)}
                            />
                            <TextField
                                sx={{ marginTop: 2, marginRight: 1, flex: "1 0 30%" }}
                                id="add-license-last-name"
                                label={t("Last Name")}
                                value={lastName}
                                onChange={(e) => setLastUserName(e.target.value)}
                            />
                            <TextField
                                sx={{ marginTop: 2, marginRight: 1, flex: "1 0 20%" }}
                                id="add-license-company"
                                label={t("Company")}
                                value={userCompany}
                                onChange={(e) => setUserCompany(e.target.value)}
                            />
                            <br />
                            <TextField
                                sx={{ marginTop: 2, marginRight: 1, flex: "1 0 30%" }}
                                id="add-license-address-street"
                                label={t("Street Address")}
                                value={userAddressStreet}
                                onChange={(e) => setUserAddressStreet(e.target.value)}
                            />
                            <TextField
                                sx={{ marginTop: 2, marginRight: 1, flex: "1 0 30%" }}
                                id="add-license-address-city"
                                label={t("City")}
                                value={userAddressCity}
                                onChange={(e) => setUserAddressCity(e.target.value)}
                            />
                            <TextField
                                sx={{ marginTop: 2, marginRight: 1, flex: "1 0 20%" }}
                                id="add-license-address-postal"
                                label={t("Postcode")}
                                value={userAddressPostal}
                                onChange={(e) => setUserAddressPostal(e.target.value)}
                            />
                            <TextField
                                sx={{ marginTop: 2, marginRight: 1, flex: "1 0 20%" }}
                                id="add-license-address-state"
                                label={t("State/Province")}
                                value={userAddressState}
                                onChange={(e) => setUserAddressState(e.target.value)}
                            />
                            <TextField
                                sx={{ marginTop: 2, marginRight: 1, flex: "1 0 20%" }}
                                id="add-license-address-country"
                                label={t("Country")}
                                value={userAddressCountry}
                                onChange={(e) => setUserAddressCountry(e.target.value)}
                            />
                            <br />
                            <FormGroup sx={{ marginTop: 2, marginLeft: 1, flex: "1 0 10%" }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="add-license-wants-update"
                                            checked={userWantsRegularUpdates}
                                            onChange={(e) => setUserWantsRegularUpdates(e.target.checked)}
                                        />
                                    }
                                    label={t("Regular updates")}
                                />
                            </FormGroup>
                        </Container>
                        <Container sx={{}}>
                            <Container sx={{ marginTop: 2, marginLeft: -3 }}>
                                <Typography
                                    sx={{ marginBottom: 1 }}
                                    variant="h6"
                                >
                                    {t('Contact us for a paid license')}
                                </Typography>
                                <FormControl>
                                    <FormLabel id="radio-buttons-group-license-selection-label">{t("Select license")}:</FormLabel>
                                    <RadioGroup
                                        aria-labelledby="radio-buttons-group-license-selection-label"
                                        name="radio-buttons-group-license-selection"
                                        value={selectedPaidLicense}
                                        onChange={(e) => setSelectedPaidLicense(e.target.value)}
                                    >
                                        {Object.values(PaidLicenseType).map((licenseType) => {
                                            return <FormControlLabel key={licenseType} value={licenseType} control={<Radio />} label={licenseType} />
                                        })}
                                    </RadioGroup>
                                </FormControl>
                                <br />
                                <Typography
                                    sx={{ marginTop: 1 }}
                                    variant="body1"
                                >
                                    {t("Click the following button to contact us for a paid license. The form data you inserted will be used to populate an automatically generated email which you can send to us. If your email client doesn't open you can also click on the \"Show Mail Content\" button, copy the displayed text and send it to license@dissec.to.")}
                                </Typography>
                                <br />
                                <Button
                                    sx={{ marginTop: -1, marginBottom: 2 }}
                                    id="request-paid-license-button"
                                    variant="contained"
                                    rel="noopener noreferrer"
                                    href={createLicenseRequestMail()}
                                >
                                    {t("Contact us for a paid license")}
                                </Button>
                                <Button
                                    sx={{ marginTop: -1, marginBottom: 2, marginLeft: 1 }}
                                    id="request-paid-license-button"
                                    variant="contained"
                                    onClick={() => setLicenseRequestMailDialogOpen(true)}
                                >
                                    {t("Show Mail Content")}
                                </Button>
                                <Dialog  open={licenseRequestMailDialogOpen} onClose={handleLicenseRequestMailPopupClose}>
                                    <DialogTitle>{t("Request for a license")}</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText>
                                            {createLicenseRequestMailPopupContent()}
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleLicenseRequestMailPopupClose}>{t("OK")}</Button>
                                    </DialogActions>
                                </Dialog>
                            </Container>
                        </Container>
                    </Accordion>
                </Container>
            </ConditionalFragment>
            <Container>
                <Accordion defaultExpanded={isRunningAs("HydraVision")}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        id="add-a-license-header"
                        sx={{ margin: 1, marginLeft: 1, marginTop: 2 }}
                    >
                        <Typography
                            sx={{ margin: 1 }}
                            variant="h6"
                        >
                            {t('Add a license')}</Typography>
                    </AccordionSummary>
                    <Typography
                        sx={{ marginLeft: 3, marginBottom: 1 }}
                        variant="h5"
                    >
                        {t('Paste in the received license')}
                    </Typography>
                    <LicenseManagementEdit {...{
                        stateLicenseText: [licenseText, setLicenseText]
                    }
                    } />
                    <Button
                        sx={{ marginLeft: 3, marginTop: 2, marginBottom: 2 }}
                        id="add-license-button"
                        variant="contained"
                        onClick={addLicenseClicked}
                        disabled={!hasAllNeededDataToAddLicense()}
                    >
                        {t('Add')}
                    </Button>
                </Accordion>
            </Container>

        </Container >
    )
}
