import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, store } from '../../app/store';
import {
    fetchAllHwInterfacesViaDCRF,
    subscribeToHwInterfaceChangesViaDCRF,
    patchHwInterfaceViaDCRF,
} from './HardwareInterfacesAPI';
import { logger } from '../../app/logging';

type HardwareInterfaceState = 'AVAILABLE' | 'UNAVAILABLE'

export interface IHardwareInterface {
    id: number
    name: string
    category: string
    subtype: string
    state: HardwareInterfaceState
    config: any
    current_device_path: any
    extended_state: any
}

type LastRequestStatus = 'success' | 'busy' | 'failed'

export interface IHardwareInterfaces {
    hwInterfaces: IHardwareInterface[]
    lastRequestStatus: LastRequestStatus
    subscribedToUpdates: boolean
}

export const fetchAllHardwareInterfacesAsync = createAsyncThunk(
    'hardwareInterface/fetchAllHardwareInterfacesAsync',
    async () => {
        const response = await fetchAllHwInterfacesViaDCRF()
        return response
    }
)

export interface IHwInterfaceSetConfig {
    id: number
    config: any
}

export const setHwInterfaceConfigAsync = createAsyncThunk(
    'hardwareInterface/setHwInterfaceConfigAsync',
    async (hwInterfaceConfig: IHwInterfaceSetConfig) => {
        const response = await patchHwInterfaceViaDCRF(hwInterfaceConfig.id, { config: hwInterfaceConfig.config })
        return response
    }
)

const getUpdatedHwInterfaces = (currentHwInterface: IHardwareInterface[], updatedHwInterface: IHardwareInterface) => {
    return currentHwInterface.map((existingHwInterface: IHardwareInterface) => {
        if (existingHwInterface.id === updatedHwInterface.id) {
            return updatedHwInterface
        } else {
            return existingHwInterface
        }
    })
}

const initialState: IHardwareInterfaces = {
    hwInterfaces: [],
    subscribedToUpdates: false,
    lastRequestStatus: 'success',
}

export const hardwareInterfacesSlice = createSlice({
    name: 'hardwareInterfaces',
    initialState,
    reducers: {
        addLocalHwInterface: (state, action: PayloadAction<IHardwareInterface>) => {
            state.hwInterfaces = [...state.hwInterfaces, action.payload];
        },
        updateLocalHwInterface: (state, action: PayloadAction<IHardwareInterface>) => {
            state.hwInterfaces = getUpdatedHwInterfaces(state.hwInterfaces, action.payload)
        },
        deleteLocalHwInterface: (state, action: PayloadAction<IHardwareInterface>) => {
            state.hwInterfaces = state.hwInterfaces.filter((hw) => hw.id !== action.payload.id);
        },
        subscribeToHwInterfaceChanges: (state) => {
            if (state.subscribedToUpdates) {
                return
            }
            const callback = (hwInterface: IHardwareInterface, action: string) => {
                logger.debug(`hardware interface subscription callback (action: ${action})`)
                switch (action) {
                    case 'create':
                        store.dispatch(addLocalHwInterface(hwInterface))
                        break
                    case 'update':
                        store.dispatch(updateLocalHwInterface(hwInterface))
                        break
                    case 'delete':
                        // NOTE: We get the action.payload with a non null value here. That means,
                        // that this callback and the deleteLocalRemoteJob are responsible for
                        // keeping the state up to date when a remote job gets deleted
                        store.dispatch(deleteLocalHwInterface(hwInterface))
                        break
                    default:
                        logger.debug('FIXME: unexpected action')
                }
            }
            subscribeToHwInterfaceChangesViaDCRF(callback)
            state.subscribedToUpdates = true
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllHardwareInterfacesAsync.pending, (state) => {
                state.lastRequestStatus = 'busy'
            })
            .addCase(fetchAllHardwareInterfacesAsync.fulfilled, (state, action) => {
                state.lastRequestStatus = 'success'
                state.hwInterfaces = action.payload
            })
            .addCase(fetchAllHardwareInterfacesAsync.rejected, (state) => {
                state.lastRequestStatus = 'failed'
            })
            .addCase(setHwInterfaceConfigAsync.fulfilled, (state, action) => {
                state.lastRequestStatus = 'success'
                state.hwInterfaces = getUpdatedHwInterfaces(state.hwInterfaces, action.payload)
            })
    },
})

export const { addLocalHwInterface, deleteLocalHwInterface, updateLocalHwInterface, subscribeToHwInterfaceChanges } = hardwareInterfacesSlice.actions;

export const selectHardwareInterfaces = (state: RootState) => state.hardwareInterfaces.hwInterfaces
export const selectHardwareInterface = (id: number) => (state: RootState) => {
    // FIXME: this should really close the widget (return undefined here and handle it in the view ...)
    // FIXME: interface could be not found in case the backend deleted it
    return state.hardwareInterfaces.hwInterfaces.filter( hwInterface => hwInterface.id === id).pop() ?? {
        id, name: "NotFound", config: {}, current_device_path: {}, extended_state: {},
        state: 'UNAVAILABLE', subtype: 'NotFound', category: 'NotFound'
    }
}
export const selectLastHwInterfacesRequestStatus = (state: RootState) => state.hardwareInterfaces.lastRequestStatus
export const selectHwInterfaceIsSubscribed = (state: RootState) => state.hardwareInterfaces.subscribedToUpdates

export default hardwareInterfacesSlice.reducer