import axios from 'axios'
import dcrf from 'dcrf-client'
import { authManager } from './auth'

export const baseBackendURL = `${window.location.hostname}`
export const httpBackendURL = `https://${baseBackendURL}`

export const restClient = axios.create({
    baseURL: `${httpBackendURL}/api`,
    timeout: 2000,
    headers: {
        'Content-Type': 'application/json'
    }
})

// see store.ts for the other websocket connections
export const dcrfClient = dcrf.connect(`wss://${baseBackendURL}/ws/dcrf/`)

// register our own "on reconnect" handler
dcrfClient.transport.on("reconnect", () => {
    // NOTE: just always reload the GUI here
    //       (dont worry about a potential inconsistent GUI vs. backend "state")
        authManager?.logout()
})

// TODO: move to "constants"
export const jobArtifactsPath = "job_artifacts"
