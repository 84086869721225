import { useEffect, useMemo, useState } from "react";
import { useAppSelector, useAppDispatch } from '../../app/hooks';

import { useTranslation } from "react-i18next";
import Container from "@mui/material/Container";
import TreeItem from '@mui/lab/TreeItem';

import { selectTestcaseRemoteJobTemplates } from "../remote_job_template/RemoteJobTemplateSlice";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { ButtonWithImplicitLicenseCheck, DeleteElementButton, utcToLocalDateTimeRepresentation, useObserveAndDeleteWidget,  compactMap, isRemoteJobFinished, isRemoteJobRunning, utcToLocalDateTime, useTargetECUSelectedRemoteRunnerExists, ConditionalFragment, getUserDataEntryFor, yellowColorDependingOnTheme } from "../misc/Util";
import { createRemoteJobAsync, deleteRemoteJobAsync, ICreateRemoteJob, IRemoteJob, IUpdateRemoteJob, selectRemoteJobs, updateRemoteJobAsync } from "./RemoteJobSlice";
import Typography from "@mui/material/Typography";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import { addOrActivateTestCaseResultWidget, addOrActivateTestCasesExecutionWidget, deleteWidget } from "../main_lumino_widget/MainLuminoWidgetSlice";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import RunCircleOutlinedIcon from '@mui/icons-material/RunCircleOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { deleteGenericRemoteJobDataLines } from "../live_data/LiveDataSlice";
import { getRemoteJobStateMappedToRepresentation } from "../misc/Util";
import { ITargetECU, selectTargetECUs } from "../target_ecu/TargetECUSlice";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { showUserMessage } from "../user_message/UserMessageSlice";
import Grid from "@mui/material/Grid";
import { selectUserDataAsObject } from "../settings/UserDataSlice";
import { remoteJobCSVResultsFileArtifactType, userDataDarkThemeEnabled } from "../misc/Constants";
import { selectRemoteJobArtifacts } from "../remote_job_artifacts/RemoteJobArtifactSlice";
import { httpBackendURL } from "../../app/api";
import { usePapaParse } from 'react-papaparse';
import { logger } from "../../app/logging";
import { TTestcaseResult } from "../misc/GlobalTypes";

export const TestCases = () => {
    const remoteJobs = useAppSelector(selectRemoteJobs)
    const remoteJobTestcaseTemplates = useAppSelector(selectTestcaseRemoteJobTemplates)
    const targetECUs = useAppSelector(selectTargetECUs)

    const { t } = useTranslation()
    
    useObserveAndDeleteWidget("TESTCASESEXECUTION", targetECUs)

    const remoteJobTestcaseTemplateIds = remoteJobTestcaseTemplates.map((remoteJobTemplate) => remoteJobTemplate.id)
    const targetECUTreeItems = compactMap(targetECUs, (targetECU) => { 
        const remoteTestcaseJobsForTargetECU = remoteJobs.filter((remoteJob) => remoteJob.runner === targetECU.remote_runner && remoteJob.job_context === targetECU.context && remoteJobTestcaseTemplateIds.includes(remoteJob.job_template))
        if (remoteTestcaseJobsForTargetECU.length > 0) {
            const targetEcuId = targetECU.id
            const treeNodeId = `TESTCASESEXECUTION::${targetEcuId}`
            const label = targetECU.name
            return (
                <TreeItem
                    key={treeNodeId}
                    nodeId={treeNodeId}
                    label={label}
                />
            )
        }
    })

    return (
        <TreeItem nodeId="TESTCASESEXECUTION" label={t("Start Tests")}>
            {targetECUTreeItems}
        </TreeItem>
    )
}

export const TestCasesExecution = (props: any) => {

    const targetECU: ITargetECU | undefined = props.targetECU
    const { readString } = usePapaParse()
    const remoteJobTemplates = useAppSelector(selectTestcaseRemoteJobTemplates)
    const remoteJobTemplateIds = useMemo(() => remoteJobTemplates.map((remoteJobTemplate) => remoteJobTemplate.id), [remoteJobTemplates])
    const remoteJobArtifacts = useAppSelector(selectRemoteJobArtifacts)
    const remoteJobs = useAppSelector(selectRemoteJobs)
    // FIXME: unfortunately this view implements both the "start tests" and "manage tests" pages which should be separate components,
    //        targetECU is only defined if the view is used for test management and so remoteJobs can't be used on the "start tests" page
    const remoteJobsForECU = useMemo(() => {
        return remoteJobs.filter((remoteJob) => remoteJobTemplateIds.includes(remoteJob.job_template) &&
                                                remoteJob.runner === targetECU?.remote_runner &&
                                                remoteJob.job_context === targetECU.context)
    }, [remoteJobTemplateIds, remoteJobs, targetECU])
    const remoteJobIDsForECU = useMemo(() => remoteJobsForECU.map((remoteJob) => remoteJob.id), [remoteJobsForECU])
    const remoteJobResultArtifactsForECU = useMemo(() => { 
        return remoteJobArtifacts.filter((remoteJobArtifact) => remoteJobArtifact.artifact_type === remoteJobCSVResultsFileArtifactType &&
                                                                remoteJobIDsForECU.includes(remoteJobArtifact.remote_job)) 
    }, [remoteJobArtifacts, remoteJobIDsForECU])
    const remoteTargetECUs = useAppSelector(selectTargetECUs)
    const userDataObject = useAppSelector(selectUserDataAsObject)
    const [remoteJobResultStateFromArtifact, setRemoteJobResultStateFromArtifact] = useState<{[remoteJobId: number]: TTestcaseResult}>({})
    const [selectedRemoteJobTemplateIds, setSelectedRemoteJobTemplateIds] = useState<number[]>([])
    const [selectedRemoteTargetECUId, setSelectedRemoteTargetECUId] = useState("")

    const selectedTargetECU = remoteTargetECUs.filter((remoteTargetECU) => remoteTargetECU.id === parseInt(selectedRemoteTargetECUId)).pop()
    const selectedTargetECURemoteRunnerExists = useTargetECUSelectedRemoteRunnerExists(selectedTargetECU)

    const darkThemeEnabled = useMemo(() => getUserDataEntryFor(userDataDarkThemeEnabled, userDataObject, false), [userDataObject])
    
    const { t } = useTranslation()

    const dispatch = useAppDispatch()

    // FIXME: dispatching the deleteWidget action without the "setTimeout" will lead to a hanging browser (endless loop)
    useEffect(() => {
        if (targetECU !== undefined && remoteJobsForECU.length === 0) {
            // close the widget
            setTimeout(() => dispatch(deleteWidget(`TESTCASESEXECUTION::${targetECU.id}`)))
        }
    }, [targetECU, remoteJobsForECU, dispatch])
    
    useEffect(() => {
        remoteJobResultArtifactsForECU.forEach((resultArtifact) => {
            fetch(`${httpBackendURL}${resultArtifact.artifact_file}`).then(response => {
                if(response.status !== 200) {
                    // dispatch(showUserMessage({ title: t("Artifact Download failed"), message: t("The requested artifact does not seem to exist") }))
                    return
                }
                response.text().then((text) => {
                    // a line in the CSV has the following format (we are interested in the "result" column)

                    // res_dict = {"index": i,
                    //             "test_case_framework": str(tc.test_case.__class__.__name__),
                    //             "arguments": str(tc.arguments),
                    //             "result": str(tc.test_case.result),
                    //             "return_value": str(tc.return_value)}

                    // possible values for result are (the string entry get formatted as "Results.EXECUTED_SUCCESSFUL")
                    // class Results(Enum):
                    //     NOT_EXECUTED = -1
                    //     EXECUTED_SUCCESSFUL = 0
                    //     EXECUTED_WITH_ERROR = 1
                    //     EXECUTED_WITH_WARNING = 2

                    readString(text, { worker: true,
                                       complete: (csvReaderResults) => {
                                           const csvRows: string[][] = csvReaderResults.data as string[][]
                                           const headerColumnToIndexMap: {[colKey: string]: number} = {}
                                           let results: string[] = []
                                           try {
                                                results = csvRows.map((csvRow, i) => {
                                                    if (i === 0) {
                                                        // header
                                                        csvRow.forEach((col, j) => headerColumnToIndexMap[col] = j)
                                                        return ""
                                                    } else if (csvRow.length <= 1) {
                                                        return ""
                                                    } else {
                                                        return csvRow[headerColumnToIndexMap["result"]].split(".")[1].trim()
                                                    }
                                                }).filter((e) => e.length > 0)
                                           } catch(error) {
                                                logger.error(error)
                                           }

                                           const atLeastOneSuccess = results.filter((r) => r === "EXECUTED_SUCCESSFUL").length > 0
                                           const atLeastOneError = results.filter((r) => r === "EXECUTED_WITH_ERROR").length > 0
                                           const atLeastOneWarning = results.filter((r) => r === "EXECUTED_WITH_WARNING").length > 0

                                           let overallResult: TTestcaseResult = "NOT_EXECUTED"
                                           if (atLeastOneError) {
                                               overallResult = "ERROR"
                                           } else if (atLeastOneWarning) {
                                               overallResult = "WARNING"
                                           } else if (atLeastOneSuccess) {
                                               overallResult = "SUCCESSFULL"
                                           }

                                           setRemoteJobResultStateFromArtifact((remoteJobResultStateFromArtifact) => {
                                               return {...remoteJobResultStateFromArtifact,
                                                       ...{[resultArtifact.remote_job]: overallResult}}
                                           })
                                       }
                                   })
                    
                })
            })
        })
    }, [remoteJobResultArtifactsForECU, readString])

    const filterAndSetSelectedRemoteJobTemplateIds = (updatedSelectedRemoteJobTemplateIds: number[]) => {
        const remoteJobTemplateIds = remoteJobTemplates.map((remoteJobTemplate) => remoteJobTemplate.id)
        updatedSelectedRemoteJobTemplateIds = updatedSelectedRemoteJobTemplateIds.filter((updatedRemoteJobTemplateId) => remoteJobTemplateIds.includes(updatedRemoteJobTemplateId))
        if (selectedRemoteJobTemplateIds.slice().sort().toString() !== updatedSelectedRemoteJobTemplateIds.slice().sort().toString()) {
            setSelectedRemoteJobTemplateIds(updatedSelectedRemoteJobTemplateIds)
        }
    }

    useEffect(() => {
        filterAndSetSelectedRemoteJobTemplateIds(selectedRemoteJobTemplateIds)
    // eslint-disable-next-line
    }, [remoteJobTemplates])

    const remoteTargetECUSelectionOptions = remoteTargetECUs.map((remoteTargetECU) => {
        return (
            <MenuItem
                key={remoteTargetECU.id}
                value={remoteTargetECU.id}
            >
                {remoteTargetECU.name}
            </MenuItem>
        )
    })

    const hasAllNeededData = () => {
        return selectedRemoteJobTemplateIds.length > 0 &&
               selectedRemoteTargetECUId !== "" &&
               selectedTargetECURemoteRunnerExists
    }

    const startRemoteJobs = () => {
        if (!hasAllNeededData()) {
            return
        }
        const remoteRunner = selectedTargetECU?.remote_runner!
        const allExistingAndRunningJobs: IRemoteJob[] = []
        const startedJobsPromises = compactMap(selectedRemoteJobTemplateIds, (selectedRemoteJobTemplateId) => {
            const existingRemoteJobs = remoteJobs.filter((remoteJob) => remoteJob.job_template === selectedRemoteJobTemplateId &&
                                                                        remoteJob.job_context === selectedTargetECU!.context &&
                                                                        remoteJob.runner === remoteRunner)
            const existingAndRunningJob = existingRemoteJobs.filter((remoteJob) => isRemoteJobRunning(remoteJob)).at(0)
            if (existingAndRunningJob) {
                allExistingAndRunningJobs.push(existingAndRunningJob)
                return null
            }
            if (existingRemoteJobs.length > 0) {
                // assume that the one created last is the "active" one
                const activeRemoteJob = existingRemoteJobs.sort((a: IRemoteJob, b: IRemoteJob): number => (utcToLocalDateTime(a.created_at)?.getTime() ?? 0) - (utcToLocalDateTime(b.created_at)?.getTime() ?? 0)).at(-1)
                const updatedRemoteJob: IUpdateRemoteJob = {
                    id: activeRemoteJob!.id,
                    data: {
                        state: 'CREATED_WAITING',   // the backend will set the state to "should run" (it ensures that there is never more than one testcase executed at the same time)
                        desired_state: 'RUNNING',
                        error_description: '',
                        finished_at: null
                    }
                }
                dispatch(deleteGenericRemoteJobDataLines(activeRemoteJob!.id))
                return dispatch(updateRemoteJobAsync(updatedRemoteJob))
            } else {
                const remoteJob: ICreateRemoteJob = {
                    state: 'CREATED_WAITING',   // the backend will set the state to "should run" (it ensures that there is never more than one testcase executed at the same time)
                    runner: remoteRunner,
                    job_template: selectedRemoteJobTemplateId,
                    job_context: selectedTargetECU!.context,
                }
                return dispatch(createRemoteJobAsync(remoteJob))
            }
        })
        Promise.all(startedJobsPromises).then(() => {
            // TODO: the "user message view" should have the ability to interact with the user
            //       (ask if the test should be restarted in this case)
            if (allExistingAndRunningJobs.length > 0) {
                dispatch(showUserMessage({
                    title: t("Testcases already running"),
                    message: t("Some testcases are already running on the selected target ecu.") +
                               ` (Job IDs: ${allExistingAndRunningJobs.map((remoteJob) => remoteJob.id).join(", ")})`
                }))
            }
            dispatch(addOrActivateTestCasesExecutionWidget(selectedTargetECU))
        })
    }

    const remoteJobIsStopped = (remoteJob: IRemoteJob) => isRemoteJobFinished(remoteJob)
    const allRemoteJobsAreStopped = () => remoteJobsForECU.every((remoteJob) => remoteJobIsStopped(remoteJob))

    const rerunRemoteJob = (remoteJob: IRemoteJob) => {
        if (!remoteJobIsStopped(remoteJob)) {
            return
        }
        const updatedRemoteJob: IUpdateRemoteJob = {
            id: remoteJob.id,
            data: {
                state: 'CREATED_WAITING',   // the backend will set the state to "should run" (it ensures that there is never more than one testcase executed at the same time)
                desired_state: 'RUNNING',
                error_description: '',
                finished_at: null
            }
        }
        dispatch(deleteGenericRemoteJobDataLines(remoteJob.id))     // TODO: check if we need to distinguish the testcase jobs from the generic ones ...
        dispatch(updateRemoteJobAsync(updatedRemoteJob))
    }

    const rerunAllRemoteJobs = () => remoteJobsForECU.forEach((remoteJob) => rerunRemoteJob(remoteJob))

    const stopRemoteJob = (remoteJob: IRemoteJob) => {
        const updatedRemoteJob: IUpdateRemoteJob = {
            id: remoteJob.id,
            data: {
                desired_state: 'FINISHED',
            }
        }
        dispatch(updateRemoteJobAsync(updatedRemoteJob))
    }
    
    const deleteRemoteJob = (remoteJob: IRemoteJob) => {
        dispatch(deleteRemoteJobAsync(remoteJob.id)) 
    }

    const deleteRemoteJobs = () => {
        remoteJobsForECU.map((remoteJob) => deleteRemoteJob(remoteJob))
    }

    const handleOnClickViewRemoteJob = (remoteJob: IRemoteJob) => {
        // FIXME: remove the targetECU from the arguments ...
        const remoteTargetECU = remoteTargetECUs.filter(targetECU => targetECU.remote_runner === remoteJob.runner).pop()
        dispatch(addOrActivateTestCaseResultWidget(remoteJob, remoteTargetECU!))
    }

    const getRemoteTestcaseStateIcon = (remoteJob: IRemoteJob) => {
        const getIcon = (): any => {
            switch (remoteJob.state) {
                case "FINISHED_ERROR":
                    return <HighlightOffIcon sx={{ color: "red" }} />
                case "FINISHED_SUCCESS":
                    switch (remoteJobResultStateFromArtifact[remoteJob.id]) {
                        case "ERROR":
                            return <HighlightOffIcon sx={{ color: "red" }} />
                        case "WARNING":
                            return <ErrorOutlineIcon color="warning" />
                        case "SUCCESSFULL":
                            return <CheckCircleOutlineOutlinedIcon sx={{ color: "green" }} />
                        default:
                            // TODO: show something more "usefull" in this case
                            return <HighlightOffIcon sx={{ color: "red" }} />
                    }
                case "CREATED_WAITING":
                    return <RunCircleOutlinedIcon sx={{ color: "grey" }} />
                default:
                    // running
                    return <RunCircleOutlinedIcon sx={{ color: yellowColorDependingOnTheme(darkThemeEnabled) }} />
            }
        }
        const getTooltipTitle = (): string => {
            if (remoteJob.error_description.length > 0) {
                return remoteJob.error_description
            }
            if (remoteJob.state === "FINISHED_SUCCESS") {
                // use the high level csv result in this case
                switch (remoteJobResultStateFromArtifact[remoteJob.id]) {
                    case "ERROR":
                        return t("At least one test finished with an error")
                    case "WARNING":
                        return t("At least one test finished with a warning")
                    case "SUCCESSFULL":
                        return t("All tests finished successfully")
                    default:
                        return t("No test was executed")
                }
            }
            return getRemoteJobStateMappedToRepresentation(remoteJob.state)
        }
        return (
            <Tooltip
                title={getTooltipTitle()}
            >
                {getIcon()}
            </Tooltip>
        )
    }

    const getTestcaseTableRows = () => {
        return remoteJobsForECU.map((remoteJob) => {
            return (
                <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    key={remoteJob.id}
                >
                    <TableCell component="th" scope="row">
                        {remoteJobTemplates.filter((remoteJobTemplate) => remoteJobTemplate.id === remoteJob.job_template).pop()?.name}
                    </TableCell>
                    <TableCell align="right">
                        {remoteJob.id}
                    </TableCell>
                    <TableCell align="right">
                        {utcToLocalDateTimeRepresentation(remoteJob.created_at)}
                    </TableCell>
                    <TableCell align="right">
                        {utcToLocalDateTimeRepresentation(remoteJob.finished_at)}
                    </TableCell>
                    <TableCell align="right">
                        {getRemoteTestcaseStateIcon(remoteJob)}
                    </TableCell>
                    <TableCell align="right">
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <ButtonWithImplicitLicenseCheck
                                text={t("Restart")}
                                id={props.id}
                                sx={{ marginTop: 1 }}
                                color="primary"
                                variant="contained"
                                onClickCallback={() => rerunRemoteJob(remoteJob)}
                                disabledCondition={!remoteJobIsStopped(remoteJob)}
                            />
                            <Button
                                sx={{ marginLeft: 1, marginTop: 1, marginBottom: 1 }}
                                variant="contained"
                                color="error"
                                onClick={() => stopRemoteJob(remoteJob)}
                                disabled={remoteJob.state === 'FINISHED_SUCCESS' || remoteJob.state === 'FINISHED_ERROR'}
                            >
                                {t("Stop")}
                            </Button>
                            <DeleteElementButton
                                title={t("Do you really want to delete this testcase run?")}
                                message={t("Deleting a testcase run is permanent and can not be undone. Of course you can always start a new one. Are you sure?")}
                                deleteCallback={() => deleteRemoteJob(remoteJob)}
                            />
                            <Button
                                sx={{ margin: 1 }}
                                variant="contained"
                                color="primary"
                                onClick={() => handleOnClickViewRemoteJob(remoteJob)}
                            >
                                {t("Details")}
                            </Button>
                        </Box>
                    </TableCell>
                </TableRow>
            )
        })
    }

    const getTestcasesAsTable = () => {
        return (
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 300 }} aria-label="Testcases Table">
                    <TableHead>
                        <TableRow>
                            <TableCell><b>{t("Testcase")}</b></TableCell>
                            <TableCell align="right"><b>{t("Job ID")}</b></TableCell>
                            <TableCell align="right"><b>{t("Created at")}</b></TableCell>
                            <TableCell align="right"><b>{t("Finished at")}</b></TableCell>
                            <TableCell align="right"><b>{t("State")}</b></TableCell>
                            <TableCell align="right"/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getTestcaseTableRows()}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    return (
        <Container sx={{ overflow: "hidden", overflowY: "auto", height: "90vh", marginLeft: -4 }}>
            {targetECU === undefined ?
                <Container>
                    <Typography variant="h6">
                        {t("Start Tests")}
                    </Typography>
                    <Typography variant="body1">
                        {t("Start one or more tests by selecting the test case, and the target ECU on which the job should be run.")}
                    </Typography>
                    <Grid container alignItems="center">
                        <FormControl sx={{ margin: 1, marginTop: 2, width: 300 }}>
                            <InputLabel id="custom-job-remote-template-select-label">
                                {t("Test Case")}
                            </InputLabel>
                            <Select
                                labelId="custom-job-remote-template-select-label"
                                id="custom-job-remote-template-select"
                                multiple
                                label="Test Case"
                                value={selectedRemoteJobTemplateIds}
                                onChange={(e) => {
                                    let updatedSelectedRemoteJobTemplateIds: number[]
                                    if (typeof e.target.value === 'string') {
                                        // On autofill we get a stringified value.
                                        updatedSelectedRemoteJobTemplateIds = compactMap(e.target.value.split(','), (v) => parseInt(v))
                                    } else {
                                        updatedSelectedRemoteJobTemplateIds = e.target.value
                                    }
                                    filterAndSetSelectedRemoteJobTemplateIds(updatedSelectedRemoteJobTemplateIds)
                                }}
                                renderValue={(selectedIds) => compactMap(remoteJobTemplates, (remoteJobTemplate) => selectedIds.includes(remoteJobTemplate.id) ? remoteJobTemplate.name : undefined).join(', ')}
                            >
                                {remoteJobTemplates.map((remoteJobTemplate) => (
                                    <MenuItem
                                        key={remoteJobTemplate.id}
                                        value={remoteJobTemplate.id}
                                    >
                                        {selectedRemoteJobTemplateIds.includes(remoteJobTemplate.id) ?
                                            <b>{remoteJobTemplate.name}</b>
                                        :
                                            remoteJobTemplate.name
                                        }
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl sx={{ margin: 1, marginTop: 2, minWidth: 200 }}>
                            <InputLabel id="custom-job-target-ecu-select-label">
                                {t("Target ECU")}
                            </InputLabel>
                            <Select
                                labelId="custom-job-target-ecu-select-label"
                                id="custom-job-target-ecu-select"
                                value={selectedRemoteTargetECUId}
                                label="Target ECU"
                                onChange={(e) => setSelectedRemoteTargetECUId(e.target.value)}
                            >
                                {remoteTargetECUSelectionOptions}
                            </Select>
                        </FormControl>
                        <ConditionalFragment condition={selectedTargetECU !== undefined && !selectedTargetECURemoteRunnerExists}>
                            <Tooltip title={t("The Probe associated with this Target ECU is either gone or currently not available")}>
                                <ErrorOutlineIcon color="error" />
                            </Tooltip>
                        </ConditionalFragment>
                    </Grid>
                    <ButtonWithImplicitLicenseCheck
                        text="Start"
                        id="testcase-job-remote-runners-start-button"
                        sx={{ margin: 1 }}
                        color="primary"
                        variant="contained"
                        onClickCallback={startRemoteJobs}
                        disabledCondition={!hasAllNeededData()}
                    />
                </Container>
                :
                <Container>
                    <Typography variant="h6">{t("Testcases on Target ECU") + ` - ${targetECU.name}`}</Typography>
                    {getTestcasesAsTable()}
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <ButtonWithImplicitLicenseCheck
                            text={t("Restart all testcases")}
                            id={props.id}
                            sx={{ marginTop: 1 }}
                            color="primary"
                            variant="contained"
                            onClickCallback={() => rerunAllRemoteJobs()}
                            disabledCondition={!allRemoteJobsAreStopped()}
                        />
                        <DeleteElementButton
                            title={t("Do you really want to delete all testcases?")}
                            message={t("Deleting the testcases is permanent and can not be undone. Of course you can always start them again. Are you sure?")}
                            deleteCallback={() => deleteRemoteJobs()}
                        />
                    </Box>
                </Container>
                }
        </Container>
    )
}