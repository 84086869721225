import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"

export const WEBSOCKET_LIVEDATA_REDUX_ACTION_PREFIX = "WEBSOCKET_LIVEDATA"

export interface ILiveData {
    // FIXME: the id needs a type description
    // valid ids - "GLOBAL" / "UDSSCAN::{scan_db_id}" / "ISOTPEPSCAN::{scan_db_id}"
    log: { [id: string] : { lines: string[] } }
}

export const initialState: ILiveData = {
    log: {}
}

interface IRawWebsocketMessage  {
    message: string,
    origin: string,
}

const websocketOnMessageAction = createAction<IRawWebsocketMessage>(`${WEBSOCKET_LIVEDATA_REDUX_ACTION_PREFIX}::MESSAGE`)

export const liveDataSlice = createSlice({
    name: "liveData",
    initialState,
    reducers: {
        deleteUDSScanRunLiveDataLines: (state, action: PayloadAction<any>) => {
            delete state.log[`UDSSCAN::${action.payload}`]
        },
        deleteISOTPEndpointScanRunLiveDataLines: (state, action: PayloadAction<any>) => {
            delete state.log[`ISOTPEPSCAN::${action.payload}`]
        },
        deleteGenericRemoteJobDataLines: (state, action: PayloadAction<any>) => {
            delete state.log[`GENERICREMOTEJOB::${action.payload}`]
        }
    },
    extraReducers: (builder) => {
        builder.addCase(websocketOnMessageAction, (state: ILiveData, action: PayloadAction<IRawWebsocketMessage>) => {
            // FIXME: UI feels very laggy if live messages get constantly updated.
            // We use live data only in production for now so tests/development can run smoothly (tests may fail otherwise).
            // Live data actions are blocked by a middleware (store.ts).
            // For the future: throw live data out of the store (history is lost then) or find a better way to make this
            // work with history still available
            const websocketMessage: { id: string, loglines: string[] } = JSON.parse(action.payload.message)
            if (!['GLOBAL', 'UDSSCAN', 'ISOTPEPSCAN', 'GENERICREMOTEJOB'].includes(websocketMessage.id.split("::")[0])) {
                return
            }
            state.log[websocketMessage.id] = state.log[websocketMessage.id] ?? { lines: [] }
            state.log[websocketMessage.id].lines = state.log[websocketMessage.id].lines.concat(websocketMessage.loglines)
            const maxAllowedLines: number = 1000
            if (state.log[websocketMessage.id].lines.length > maxAllowedLines) {
                const logLines = state.log[websocketMessage.id].lines
                state.log[websocketMessage.id].lines = logLines.slice((logLines.length - maxAllowedLines), logLines.length)
            }
            // TODO: expire old log entries
            //       (it seems that this is not as easy as expected because we do not have access to the global state here ...)
        })
    }
})

export const {
    deleteUDSScanRunLiveDataLines,
    deleteISOTPEndpointScanRunLiveDataLines,
    deleteGenericRemoteJobDataLines } = liveDataSlice.actions


export const selectGlobalLiveDataLines = (state: RootState) => state.liveData.log["GLOBAL"]?.lines ?? []
export const selectUDSScanRunLiveDataLines = (scanRunId: number) => (state: RootState) => state.liveData.log[`UDSSCAN::${scanRunId}`]?.lines ?? []
export const selectISOTPEndpointScanRunLiveDataLines = (scanRunId: number) => (state: RootState) => state.liveData.log[`ISOTPEPSCAN::${scanRunId}`]?.lines ?? []
export const selectGenericRemoteJobLiveDataLines = (remoteJobId: number) => (state: RootState) => state.liveData.log[`GENERICREMOTEJOB::${remoteJobId}`]?.lines ?? []

export default liveDataSlice.reducer