import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
    IISOTPEndpointScanRun,
    IISOTPEndpointScanRunFinding,
    IISOTPEndpointScanRunConfig,
    createISOTPEndpointScanRunAsync,
    selectISOTPEndpointScanRuns,
    updateISOTPEndpointScanRunAsync,
    deleteISOTPEndpointScanRunAsync,
    ISOTPEndpointScanRunState,
    ISOTPEndpointScanRunDesiredState,
    IUpdateISOTPEndpointScanRun,
} from "./ISOTPEndpointScanRunsSlice";
import {
    selectHardwareInterfaces
} from "../hardware_interface/HardwareInterfacesSlice";
import {
    ICreateISOTPEndpoint,
    createISOTPEndpointAsync,
} from "../isotp_endpoint/ISOTPEndpointsSlice";
import { addOrActivateISOTPEndpointScanRunWidget } from "../main_lumino_widget/MainLuminoWidgetSlice";
import {
    ButtonWithDialog,
    ButtonWithImplicitLicenseCheck,
    DeleteElementButton,
    authenticatedFetch,
    generateRemoteRunnerSystemData,
    getAvailableChannels,
    getLastUpdatedDateTimeForHwInterfaceDescriptions,
    hasNecessaryRunnerDataForScan,
    showRemoteScanFailurePopup,
    useObserveAndDeleteWidget
} from "../misc/Util";
import { ISOTPEndpointScanRunLiveData } from '../live_data/LiveData';
import { ILogRow, IParsedScanRunFindingLogFile, ScanReportLogs } from '../misc/ScanReportLogs';

import FormControl from '@mui/material/FormControl';
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Alert, { AlertColor } from "@mui/material/Alert";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import TreeItem from '@mui/lab/TreeItem';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { selectActiveRemoteRunners, selectRemoteRunners } from '../remote_runner/RemoteRunnerSlice';
import { Autocomplete } from '@mui/material';
import { deleteISOTPEndpointScanRunLiveDataLines } from '../live_data/LiveDataSlice';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import RefreshIcon from '@mui/icons-material/Refresh';
import { selectRemoteJobTemplateQueryHWInterfaces } from '../remote_job_template/RemoteJobTemplateSlice';
import { logger } from '../../app/logging';
import { showUserMessage } from '../user_message/UserMessageSlice';
import { httpBackendURL } from '../../app/api';

import './ISOTPEndpointScanRun.css';


export const ISOTPEndpointScanRuns = () => {

    const isotpEndpointScanRuns = useAppSelector(selectISOTPEndpointScanRuns)

    const { t } = useTranslation()

    const isotpEndpointScanRunTreeItems = isotpEndpointScanRuns.map((scanRun) => {
        const treeNodeId = `ISOTPENDPOINTSCANRUN::${scanRun.id}`
        return (
            <TreeItem
                key={scanRun.id}
                nodeId={treeNodeId}
                label={scanRun.config.name}
            />
        )
    })

    return (
        <TreeItem nodeId="ADDISOTPENDPOINTS" label={t("Add an ISOTP Endpoint")}>
            <TreeItem nodeId="ISOTPENDPOINTSCANRUN" label={t("Scan for ISOTP Endpoints")}>
              {isotpEndpointScanRunTreeItems}
            </TreeItem>
            <TreeItem nodeId="ISOTPENDPOINTSADDMANUALLY" label={t("Manually add an ISOTP Endpoint")}/>
        </TreeItem>
    )
}

// a single endpoint scan run config (this is used for creating a new scan run if "scanRunId" is undefined / update an existing one)
export const ISOTPEndpointScanRun = (props: any) => {

    const scanRunId = props.scanRunId

    const endpointScanRuns = useAppSelector(selectISOTPEndpointScanRuns)
    const existingScanRun: IISOTPEndpointScanRun | undefined = endpointScanRuns.filter( endpointScanRun => endpointScanRun.id === scanRunId ).pop()
    const remoteJobTemplateQueryHWInterfaces = useAppSelector(selectRemoteJobTemplateQueryHWInterfaces())
    const hwInterfaces = useAppSelector(selectHardwareInterfaces)
    const activeRemoteRunners = useAppSelector(selectActiveRemoteRunners)
    const remoteRunners = useAppSelector(selectRemoteRunners)

    const [hwInterface, setHwInterface] = useState(existingScanRun?.hw_interface ?? '')     // hw_interface points to the name and not the id
    const [name, setName] = useState<string | undefined>(existingScanRun?.config.name ?? undefined)
    const [scanRange, setScanRange] = useState(existingScanRun?.config.scan_range ?? '0x0-0x7FF')
    const [extAddr, setExtAddr] = useState(existingScanRun?.config.extended_addressing ?? false)
    const [extScanRange, setExtScanRange] = useState(existingScanRun?.config.extended_scan_range ?? '')
    const [noiseListenTime, setNoiseListenTime] = useState((existingScanRun?.config.noise_listen_time ?? 2).toString())
    const [sniffTime, setSniffTime] = useState((existingScanRun?.config.sniff_time ?? 0.4).toString())
    const [extCanId, setExtCanId] = useState(existingScanRun?.config.extended_can_id ?? false)
    const [verifyResults, setVerifyResults] = useState(existingScanRun?.config.verify_results ?? true)
    // Note: Enable/Disable Remote Runner here for release/development 
    const [runner, setRunner] = useState(existingScanRun?.remote_runner ?? 0) // 0 for local
    // const runner = 0
    const [channel, setChannel] = useState<string>(existingScanRun?.config.remote_scan_selected_channel ?? "")
    const [localLogFiles, setLocalLogFiles] = useState<IParsedScanRunFindingLogFile[]>([])
    const [lastRequestedLogsFinishedAtTimestamp, setLastRequestedLogsFinishedAtTimestamp] = useState<string>("")
    const [selectedIsotpEndpoints, setSelectedIsotpEndpoints] = useState<readonly number[]>([])

    useEffect(() => {
        // check if we have to update the suggested name
        if (scanRunId !== undefined) {
            // this is an existing scan run
            // (we are not on the "start a new scan run" page)
            return
        }
        const matchRegex = /Isotp Scan\s+(\d+)/
        const existingMaxCounter = Math.max(...endpointScanRuns.map(scanRun => scanRun.config.name.match(matchRegex)).map(match => match === null ? 0 : parseInt(match[1])).concat(0))
        const curNameMatch = (name ?? '').match(matchRegex)
        if (name === undefined || (curNameMatch !== null && parseInt(curNameMatch[1]) === existingMaxCounter)) {
            // the widget was either just openend or the current name is already in use
            setName(`Isotp Scan ${existingMaxCounter + 1}`)
        }
    }, [endpointScanRuns, scanRunId, name])

    useEffect(() => {
        if(extAddr === true) {
            setExtScanRange(existingScanRun?.config.extended_scan_range ?? '0x00-0xff')
        } else {
            setExtScanRange('')
        }
    }, [extAddr, existingScanRun?.config.extended_scan_range])

    const { t } = useTranslation()
    
    const dispatch = useAppDispatch()
    useObserveAndDeleteWidget("ISOTPENDPOINTSCANRUN", endpointScanRuns)

    const hwInterfaceSelectionLabel = t("Hardware Interface")
    const hwInterfaceSelectionOptions = hwInterfaces.map( (hwInterface) => {
        return (
            <MenuItem
                key={hwInterface.id}
                value={hwInterface.name}
            >
                {hwInterface.name}
            </MenuItem>
        )
    })

    // Note: Enable/Disable Remote Runner here for release/development
    const runnerSelectionOptions = remoteRunners.map( (remoteRunner) => {
        const isActiveRunner = activeRemoteRunners.filter(activeRemoteRunner => activeRemoteRunner.id === remoteRunner.id).length > 0

        return (
                <MenuItem
                    key={remoteRunner.id}
                    value={remoteRunner.id}
                >
                    {isActiveRunner ? 
                        remoteRunner.name
                    :
                        <Typography color={"error"}>{remoteRunner.name}</Typography>
                    }
                </MenuItem>

        )
    })

    useEffect(() => {
        if (existingScanRun === undefined || existingScanRun.log_files.length === 0 || isPendingScanRun(existingScanRun)) {
            return
        }
        if (existingScanRun.finished_at !== lastRequestedLogsFinishedAtTimestamp) {
            
            logger.debug("reloading logs")

            const defaultHeader = new Headers()
            // const noCacheHeader = new Headers({ "pragma": "no-cache", "cache-control": "no-cache" })

            // logs
            Promise.all(existingScanRun.log_files.map(logFileDescription => {
                return fetch(logFileDescription.log_file, { method: "GET", headers: defaultHeader })
                    .then(response => response.text())
                    .then(text => {
                        const logRows = text.split("\n").reduce((cookedRows: ILogRow[], rawLogLine: string): ILogRow[] => {
                            if (rawLogLine.length > 0) {
                                const cols = rawLogLine.split(" - ")
                                cookedRows.push({ // date: moment.utc(cols[0], "YYYY-MM-DD hh:mm:ss,SSS").toDate(), // 2022-08-03 10:42:23,696
                                                type: logFileDescription.log_type,
                                                cols: cols })
                            }
                            return cookedRows
                        }, [])
                        return {...logFileDescription, logRows: logRows}
                    })
            }))
            .then((newLocalLogFiles) => {
                setLocalLogFiles(newLocalLogFiles)
            })
            setLastRequestedLogsFinishedAtTimestamp(existingScanRun.finished_at)
        }
    }, [existingScanRun, lastRequestedLogsFinishedAtTimestamp])

    useEffect(() => {

        const hwInterfaceId = parseInt(hwInterface)
        if (!(isNaN(hwInterfaceId) || hwInterfaces.map(hwInterface => hwInterface.id).includes(hwInterfaceId))) {
            setHwInterface("")
        }
    }, [hwInterfaces, hwInterface])

    const hasAllNeededData = () => {
        return (name ?? '').length > 0 &&
               hasNecessaryRunnerDataForScan(runner, hwInterface, channel, activeRemoteRunners) &&             
               scanRange.length > 0 &&
               noiseListenTime.length > 0 &&
               sniffTime.length > 0
    }

    const makeScanConfigFromCurrentValues = () : IISOTPEndpointScanRunConfig => {
        // FIXME: the config should be part of the top level object (no benefit to have a separate object for a 1-1 relation ... just adds unnecessary complexity)
        const config: IISOTPEndpointScanRunConfig = {
            name: (name ?? ''),
            scan_range: scanRange,
            extended_addressing: extAddr,
            extended_scan_range: extScanRange === '' ? undefined : extScanRange,    // FIXME: this needs a better solution (sending an empty string to the backend gives an error, leaving it out will force the backend to use the default)
            noise_listen_time: parseInt(noiseListenTime),
            sniff_time: parseFloat(sniffTime),
            extended_can_id: extCanId,
            verify_results: verifyResults,
            remote_scan_selected_channel: runner !== 0 ? channel : undefined
        }
        return config
    }

    const startScanRun = () => {
        if (!hasAllNeededData()) {
            return
        }
        if (isPendingScanRun(existingScanRun)) {
            logger.debug('there is already an isotp endpoint scan running for this hw interface')
            return
        }

        dispatch(deleteISOTPEndpointScanRunLiveDataLines(scanRunId))

        logger.debug(`starting isotp endpoint scan on runner ${runner === 0 ? 'local' : runner} with hardware interface "${hwInterface}"`)

        const config = makeScanConfigFromCurrentValues()

        const getCreateIsotpEndpointScanRunConfig = () => {
            if (runner === 0) {
                return {
                    hw_interface: hwInterface!,
                    config: config,
                }
            } else {
                const remoteRunnerId = activeRemoteRunners.filter((remoteRunner) => {
                    return remoteRunner.id === runner
                })[0].id
                return {
                    remote_runner: remoteRunnerId,
                    config: config,
                }
            }
        }

        const getUpdateIsotpEndpointScanRunConfig = () => {
            if (runner === 0) {
                // local
                return {
                    id: scanRunId,
                    data: {
                        hw_interface: hwInterface!,
                        state: 'CREATED' as ISOTPEndpointScanRunState,
                        desired_state: 'RUNNING' as ISOTPEndpointScanRunDesiredState,
                        error_description: '',
                        finished_at: null,
                        config: config,
                        remote_runner: null
                    }
                } as IUpdateISOTPEndpointScanRun
            } else {
                // remote
                const remoteRunnerId = activeRemoteRunners.filter((remoteRunner) => {
                    return remoteRunner.id === runner
                })[0].id
                return {
                    id: scanRunId,
                    data: {
                        state: 'CREATED' as ISOTPEndpointScanRunState,
                        desired_state: 'RUNNING' as ISOTPEndpointScanRunDesiredState,
                        error_description: '',
                        finished_at: null,
                        remote_runner: remoteRunnerId,
                        hwInterface: null,
                        config: config,
                    }
                } as IUpdateISOTPEndpointScanRun
            }
        }

        if (existingScanRun === undefined) {
            // create a new scan run
            dispatch(createISOTPEndpointScanRunAsync(getCreateIsotpEndpointScanRunConfig())).then((resultPromise) => {
                // open the widget for the created scan run
                const createdScanRun: IISOTPEndpointScanRun = resultPromise.payload
                dispatch(addOrActivateISOTPEndpointScanRunWidget(createdScanRun))
            })
        } else {
            // just update the state of the existing scan run
            dispatch(updateISOTPEndpointScanRunAsync(getUpdateIsotpEndpointScanRunConfig()))
        }
    }

    const isPendingScanRun = (scanRun: IISOTPEndpointScanRun | undefined) => {
        return ['CREATED', 'RUNNING'].includes((scanRun?.state ?? ''))
    }

    const getReadableState = (scanRun: IISOTPEndpointScanRun | undefined): [AlertColor, string] => {
        switch (scanRun?.state) {
            case 'CREATED':
                return ['info', t('The scan is scheduled and about to be started')]
            case 'RUNNING':
                return ['info', t('The scan is currently running')]
            case 'FINISHED_ERROR':
                return ['error', `${t('The last scan generated an error')} - (${scanRun!.error_description})`]
            case 'FINISHED_SUCCESS':
                if (scanRun!.scan_was_aborted) {
                    return ['success', t('The last scan finished successfully but with a partial result because it was stopped by the user')]
                } else {
                    return ['success', t('The last scan finished successfully')]
                }
            default:
                return ['warning', t('The scan is in an unknown state')]
        }
    }

    const createEndpointFromFinding = (scanRunName: string, scanRunFinding: IISOTPEndpointScanRunFinding) => {
        const newEndpoint: ICreateISOTPEndpoint = {
            name: `${scanRunName} Finding 0x${scanRunFinding.rx_id.toString(16)}:0x${scanRunFinding.tx_id.toString(16)}`,
            hw_interface: runner === 0 ? hwInterface : `Remote: ${activeRemoteRunners.filter((remoteRunner) => remoteRunner.id === runner)[0].name}`,
            rx_id: scanRunFinding.rx_id,
            tx_id: scanRunFinding.tx_id,
            ext_address: scanRunFinding.ext_address,
            rx_ext_address: scanRunFinding.rx_ext_address,
            padding: scanRunFinding.padding,
        }
        dispatch(createISOTPEndpointAsync(newEndpoint))
    }

    const createMultipleEndpointsFromFinding = () => {
        selectedIsotpEndpoints.forEach(isotpEndpointID => {
            const finding = existingScanRun!.scan_run_findings.filter(scanRunFinding => scanRunFinding.id === isotpEndpointID).pop()
            createEndpointFromFinding(existingScanRun!.config.name, finding!)
        })
    }

    const deleteScanRun = () => {
        if (existingScanRun === undefined) {
            return
        }
        dispatch(deleteISOTPEndpointScanRunAsync(existingScanRun.id))
    }

    const stopScanRun = () => {
        if (existingScanRun === undefined || !isPendingScanRun(existingScanRun)) {
            return
        }
        dispatch(updateISOTPEndpointScanRunAsync({
                id: scanRunId,
                data: {
                    config: makeScanConfigFromCurrentValues(),     // FIXME: an empty config is currently rejected by the backend ...
                    desired_state: "FINISHED"
                }
        }))
    }

    // TODO: make the findings table a separate component
    const [preferHex, setPreferHex] = useState(true)
    const makeReadableFieldValue = (value: string | number | undefined, preferHex: boolean = true): string => {
        if (value === undefined || value === null) {
            return "-"
        }
        if (preferHex === false) {
            return value.toString()
        }
        return `0x${value.toString(16)}`
    }

    const handleIsotpEndpointCSVReportDownload = () => {
        let headers = new Headers()
        headers.append('Content-Type', 'application/x-zip-compressed')
        headers.append('Accept', 'application/x-zip-compressed')

        authenticatedFetch(`${httpBackendURL}/api/isotp_endpoint_scan_runs/${scanRunId}/download_zipped_csv_report/`, "GET", headers).then(response => {
            if(response.status !== 200){
                dispatch(showUserMessage({ title: t('CSV report download failed'), message: t("The requested CSV report does not seem to exist") }))
                return
            }
            response.blob().then(blob => {
                const fileURL = window.URL.createObjectURL(blob)
                let alink = document.createElement('a')
                alink.href = fileURL
                alink.download = `isotp_scan_run_${scanRunId}_csv_reports.zip`
                alink.click()
            })
        })
    }

    const handleSelectAllIsotpEndpointsClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = existingScanRun!.scan_run_findings.map((finding) => finding.id)
            setSelectedIsotpEndpoints(newSelected)
            return
        }
        setSelectedIsotpEndpoints([])
    }

    const isSelectedIsotpEndpoint = (id: number) => selectedIsotpEndpoints.indexOf(id) !== -1

    const handleClickIsotpEndpointCheckbox = (event: React.MouseEvent<unknown>, id: number) => {
        const selectedIndex = selectedIsotpEndpoints.indexOf(id)
        let newSelected: number[] = []

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedIsotpEndpoints, id)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedIsotpEndpoints.slice(1))
        } else if (selectedIndex === selectedIsotpEndpoints.length - 1) {
            newSelected = newSelected.concat(selectedIsotpEndpoints.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedIsotpEndpoints.slice(0, selectedIndex),
                selectedIsotpEndpoints.slice(selectedIndex + 1)
            )
        }

        setSelectedIsotpEndpoints(newSelected)
    }

    return (
        <Container sx={{ overflow: "hidden", overflowY: "auto" }}>
            <Tooltip title={t("Name / identifier of this scan run") as string}>
                <TextField
                    sx={{ margin: 1 }}
                    id="isotp-endpoint-scan-name"
                    label={t("Name")}
                    //helperText="Name"
                    value={name ?? ''}
                    onChange={(e) => setName(e.target.value)}
                />
            </Tooltip>
            {/* // Note: Enable/Disable Remote Runner here for release/development */}
            {process.env.REACT_APP_PERSONALITY_NAME === "HydraVision" ?
                <Tooltip placement="right" title={t("The runner on which this scan should be run (disconnected runners are shown in red and cannot be used)") as string}>
                    <FormControl sx={{ margin: 1, minWidth: 200 }}>
                        <InputLabel id="isotp-endpoint-scan-runner-select-label">
                            {t("Runner")}
                        </InputLabel>
                        <Select
                            labelId="isotp-endpoint-scan-runner-select-label"
                            id="isotp-endpoint-scan-runner-select"
                            value={runner}
                            label="isotp-endpoint-scan-runner-select-label"
                            onChange={(e) => setRunner(typeof(e.target.value) === "string" ? parseInt(e.target.value) : e.target.value)}
                        >
                            <MenuItem value={0}>
                                {t("local")}
                            </MenuItem>
                            {runnerSelectionOptions}
                        </Select>
                    </FormControl>
                </Tooltip>
            :
                <></>
            }
            { runner === 0 ? 
                <FormControl sx={{ margin: 1, minWidth: 210 }}>
                    <InputLabel id="isotp-endpoint-scan-hwinterface-select-label">
                        {t("Hardware Interface")}
                    </InputLabel>
                    <Select
                        labelId="isotp-endpoint-scan-hwinterface-select-label"
                        id="isotp-endpoint-scan-hwinterface-select"
                        value={hwInterface}
                        label={hwInterfaceSelectionLabel}
                        onChange={(e) => setHwInterface(e.target.value)}
                    >
                        <MenuItem value="">
                            <em>{t("None")}</em>
                        </MenuItem>
                        {hwInterfaceSelectionOptions}
                    </Select>
                </FormControl>
                :
                <Box component="span">
                    <Tooltip placement="right" title={t("Channel on which the scan should be run. If your channel does not appear in the list you can refresh it.") as string}>
                        <FormControl sx={{ margin: 1, minWidth: 200 }}>
                            <Autocomplete
                                freeSolo
                                id="isotp-endpoint-scan-channel-selection"
                                disableClearable
                                options={getAvailableChannels(activeRemoteRunners.filter(activeRemoteRunner => activeRemoteRunner.id === runner).pop()?.system_data)}
                                value={existingScanRun?.config.remote_scan_selected_channel ?? channel}
                                sx={{maxWidth: 200}}
                                onChange={(e, newValue) => setChannel(newValue)}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                    }}
                                    label={t("Channel")}
                                    value={channel}
                                    onChange={(e) => setChannel(e.target.value)}
                                />
                                )}
                            />
                        </FormControl>
                    </Tooltip>
                    <Tooltip placement="right" title={`${t("Refresh the available channels of the remote runner. Last refresh was at")}: ` +
                                                      `${getLastUpdatedDateTimeForHwInterfaceDescriptions(activeRemoteRunners.filter(activeRemoteRunner => activeRemoteRunner.id === runner).pop()?.system_data)}`}>
                        <FormControl sx={{ marginLeft: -1, marginTop: 2 }}>
                            <IconButton
                                color="primary"
                                onClick={() => {generateRemoteRunnerSystemData(remoteRunners.filter( (remoteRunner) => remoteRunner.id === runner).pop(), remoteJobTemplateQueryHWInterfaces!)}}
                            >
                                <RefreshIcon/>
                            </IconButton>
                        </FormControl>
                    </Tooltip>
                </Box>
            }
            <br/>
            <Tooltip title={t("Range of CAN-Identifiers to scan (the default is 0x000 - 0x7FF)") as string}>
                <TextField
                    sx={{ margin: 1 }}
                    id="isotp-endpoint-scan-range"
                    label={t("Scan Range")}
                    //helperText="Range of CAN-Identifiers to scan (the default is 0x000 - 0x7ff)"
                    value={scanRange}
                    onChange={(e) => setScanRange(e.target.value)}
                />
            </Tooltip>

            <Tooltip title={t("Range of extended ISOTP addressing values (opt.)") as string}>
                <TextField
                    sx={{ margin: 1 }}
                    id="isotp-endpoint-scan-extended-scan-range"
                    label={t("Extended Scan Range")}
                    //helperText="Range of extended ISOTP addressing values"
                    value={extScanRange}
                    onChange={(e) => setExtScanRange(e.target.value)}
                />
            </Tooltip>

            <br/>

            <Tooltip title={t("Time in seconds to listen for default communication on the bus") as string}>
                <TextField
                    sx={{ margin: 1 }}
                    id="isotp-endpoint-scan-noise-listen-time"
                    label={t("Noise Listen Time")}
                    //helperText="Time in seconds to listen for default communication on the bus"
                    value={noiseListenTime}
                    type="number"
                    onChange={(e) => setNoiseListenTime(e.target.value)}
                />
            </Tooltip>

            <Tooltip title={t("Time in seconds to wait for isotp flow control responses after sending a first frame") as string}>
                <TextField
                    sx={{ margin: 1 }}
                    id="isotp-endpoint-scan-sniff-time"
                    label={t("Sniff Time")}
                    //helperText="Time in seconds to wait for isotp flow control responses after sending a first frame"
                    value={sniffTime}
                    type="number"
                    onChange={(e) => setSniffTime(e.target.value)}
                />
            </Tooltip>

            <br/>

            <FormGroup
                sx={{ margin: 1 }}
                row={true}
            >
                <Tooltip title={t("Use extended addressing") as string}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="isotp-endpoint-scan-extended-addressing"
                                checked={extAddr}
                                onChange={(e) => setExtAddr(e.target.checked)}
                            />
                        }
                        label={t("Extended Addressing")}
                    />
                </Tooltip>

                <Tooltip title={t("Verify results") as string}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="isotp-endpoint-scan-verify-results"
                                checked={verifyResults}
                                onChange={(e) => setVerifyResults(e.target.checked)}
                            />
                        }
                        label={t("Verify Results")}
                    />
                </Tooltip>

                <Tooltip title={t("Extended CAN ID") as string}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="isotp-endpoint-scan-extended-can-id"
                                checked={extCanId}
                                onChange={(e) => setExtCanId(e.target.checked)}
                            />
                        }
                        label={t("Extended CAN ID")}
                    />
                </Tooltip>
            </FormGroup>

            <div>
                <Container>
                    <FormControl sx={{ margin: 1, marginLeft: -3}}>
                        {isPendingScanRun(existingScanRun) ?
                            <ButtonWithDialog
                                title={t("Do you really want to stop this scan run?") as string}
                                message={t("Stopping a scan run will cancel it and any results up to this point are lost. You can of course always restart the scan run.") as string}
                                disabled={!isPendingScanRun(existingScanRun) || existingScanRun?.desired_state === "FINISHED"}
                                buttonColor="warning"
                                proceedButtonColor="warning"
                                cancelButtonColor="primary"
                                proceedCallback={stopScanRun}
                            >
                                {t("Stop Scan")}
                            </ButtonWithDialog>
                            :
                            <ButtonWithImplicitLicenseCheck
                                text="Start Scan"
                                sx={{ margin: 1 }}
                                color="primary"
                                variant="contained"
                                onClickCallback={startScanRun}
                                disabledCondition={isPendingScanRun(existingScanRun) || !hasAllNeededData()}
                            ></ButtonWithImplicitLicenseCheck>
                        }
                    </FormControl>    
                    <FormControl sx={{ margin: 1, marginLeft: -1 }}>    
                        {(existingScanRun !== undefined && !isPendingScanRun(existingScanRun)) ?
                            <DeleteElementButton
                                title={t("Do you really want to delete this scan run configuration?") as string}
                                message={t("Deleting a configuration is permanent and can not be undone. Of course you can always create a new one. Are you sure?") as string}
                                deleteCallback={deleteScanRun}
                                //disabled={isPendingScanRun(existingScanRun)}
                            />
                            :
                            <></>
                        }
                    </FormControl>
                </Container>
            </div>

            {existingScanRun === undefined ? <></> :
                <Grid container sx={{marginBottom: -2, marginTop: 3}} spacing={1}>
                    <Alert sx={{margin: 1}} severity={getReadableState(existingScanRun)[0]}>
                        {getReadableState(existingScanRun)[1]}
                    </Alert>
                    {getReadableState(existingScanRun)[0] === "error" && runner !== 0 ? 
                        <IconButton sx={{ }} onClick={showRemoteScanFailurePopup}>
                            <QuestionMarkIcon color="error"/>
                        </IconButton>
                    :
                        <></>
                    }
                </Grid>
            }
            <br/>

            {existingScanRun === undefined ? <></> :
                <ISOTPEndpointScanRunLiveData scanRun={existingScanRun}/>
            }

            <br/>

            {existingScanRun === undefined ? <></> :
                <Box>
                    <Box sx={{ width: "100%" }}>
                        <Paper sx={{ width: "100%", mb: 2 }}>
                            <Toolbar
                                sx={{
                                    pl: { sm: 2 },
                                    pr: { xs: 1, sm: 1 },
                                }}
                            >
                                <Typography
                                    sx={{ flex: "1 1 100%" }}
                                    variant="h6"
                                    id="tableTitle"
                                >
                                    {t("Findings")}
                                </Typography>
                            </Toolbar>
                            <TableContainer sx={{ width: "100%" }}>
                                <Table 
                                    aria-label="isotp-endpoint-scan-findings-table"
                                    size="small"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    indeterminate={selectedIsotpEndpoints.length > 0 && selectedIsotpEndpoints.length < existingScanRun!.scan_run_findings.length}
                                                    checked={existingScanRun!.scan_run_findings.length > 0 && selectedIsotpEndpoints.length === existingScanRun!.scan_run_findings.length}
                                                    onChange={handleSelectAllIsotpEndpointsClick}
                                                    inputProps={{}}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                {t("RX_ID")}
                                            </TableCell>
                                            <TableCell align="left">
                                                {t("TX_ID")}
                                            </TableCell>
                                            <TableCell align="left">
                                                {t("EXT_ADDRESS")}
                                            </TableCell>
                                            <TableCell align="left">
                                                {t("RX_EXT_ADDRESS")}
                                            </TableCell>
                                            <TableCell align="left">
                                                {t("Padding")}
                                            </TableCell>
                                            <TableCell align="left">
                                                {t("Protocol")}
                                            </TableCell>
                                            <TableCell align="right">
                                                {["FINISHED_SUCCESS", "FINISHED_ERROR"].indexOf(existingScanRun.state) !== -1 ?
                                                    <Grid container justifyContent="flex-end">
                                                        <Grid item>
                                                            <ButtonWithImplicitLicenseCheck
                                                                id="isotp-endpoint-scan-csv-report-button"
                                                                sx={{ "marginRight": 1 }}
                                                                key="isotp-endpoint-scan-csv-report-button"
                                                                text={t("Generate CSV Report")}
                                                                variant="contained"
                                                                disabledCondition={false}
                                                                onClickCallback={() => handleIsotpEndpointCSVReportDownload()}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <ButtonWithImplicitLicenseCheck
                                                                id="isotp-endpoint-add-multiple-endpoints"
                                                                sx={{ "color": "primary" }}
                                                                key="isotp-endpoint-add-multiple-endpoints-button"
                                                                text={t("Add selected endpoints")}
                                                                variant="contained"
                                                                disabledCondition={!(selectedIsotpEndpoints.length > 0)}
                                                                onClickCallback={() => createMultipleEndpointsFromFinding()}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    :
                                                    <></>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {existingScanRun!.scan_run_findings.map((scanRunFinding: IISOTPEndpointScanRunFinding) => (
                                            <TableRow key={scanRunFinding.id}>
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        color="primary"
                                                        onClick={(event) => handleClickIsotpEndpointCheckbox(event, scanRunFinding.id)}
                                                        checked={isSelectedIsotpEndpoint(scanRunFinding.id)}
                                                        inputProps={{}}
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    onClick={() => setPreferHex((preferHex) => !preferHex)}
                                                >
                                                    {makeReadableFieldValue(scanRunFinding.rx_id, preferHex)}
                                                </TableCell>
                                                <TableCell
                                                    align="left"
                                                    onClick={() => setPreferHex((preferHex) => !preferHex)}
                                                >
                                                    {makeReadableFieldValue(scanRunFinding.tx_id, preferHex)}
                                                </TableCell>
                                                <TableCell
                                                    align="left"
                                                    onClick={() => setPreferHex((preferHex) => !preferHex)}
                                                >
                                                    {makeReadableFieldValue(scanRunFinding.ext_address, preferHex)}
                                                </TableCell>
                                                <TableCell
                                                    align="left"
                                                    onClick={() => setPreferHex((preferHex) => !preferHex)}
                                                >
                                                    {makeReadableFieldValue(scanRunFinding.rx_ext_address, preferHex)}
                                                </TableCell>
                                                <TableCell
                                                    align="left"
                                                >
                                                    {scanRunFinding.padding === true ? 'Yes' : 'No'}
                                                </TableCell>
                                                <TableCell
                                                    align="left"
                                                >
                                                    {scanRunFinding.basecls}
                                                </TableCell>
                                                <TableCell
                                                    align="right"
                                                >
                                                    <Button
                                                        //sx={{ margin: 1 }}
                                                        variant="contained"
                                                        onClick={() => createEndpointFromFinding(existingScanRun?.config.name, scanRunFinding)}
                                                    >
                                                        {t("Add Endpoint")}
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Box>

                    <Box
                        sx={{ height: 25 * 33 }}
                    >
                        <ScanReportLogs
                            logs={localLogFiles}
                            scrollToTime={undefined}
                            key="logFiles"
                        />
                    </Box>
                </Box>
            }
        </Container>
    )
}
