import jwt_decode from "jwt-decode"
import { send } from "@giantmachines/redux-websocket"
import { dcrfClient, restClient } from './api'
import { WEBSOCKET_REMOTE_TEMPLATE_EDITOR_REDUX_ACTION_PREFIX } from '../features/remote_job_template/RemoteJobTemplateSlice'
import { store } from './store'
import { fetchAndSubscribeAllData } from "../App"
import { setIsLoggedIn } from "../features/settings/SettingsSlice"
import { logger } from "./logging"

export const jwtLoginViaREST = (email: string, password: string) => {
    logger.debug('jwtLoginViaREST')
    return restClient.post('/token/login/', { 'email': email, 'password': password })
}

export const basicLoginViaREST = () => {
    logger.debug('basicLoginViaREST')
    return restClient.get('/licenses/')
}

export const jwtRefreshViaREST = (refresh: string) => {
    logger.debug('jwtRefreshViaREST')
    return restClient.post('/token/refresh/', { 'refresh': refresh })
}

export const jwtBlacklistViaREST = (token: string) => {
    logger.debug('jwtBlacklistViaREST')
    return restClient.post('/token/blacklist/', { 'refresh': token }).then(() => {
        logger.debug('jwtBlacklistViaREST - blacklisted refresh token')
        return true
    }).catch((err) => {
        logger.debug(`jwtBlacklistViaREST - failed to blacklist refresh token (${err})`)
        return false
    })
}

/*
export const jwtVerifyViaREST = (token: string) => {
    logger.debug('jwtVerifyViaREST')
    return restClient.post('/token/verify/', { 'token': token }).then(res => {
        logger.debug('jwtVerifyViaREST - token is valid')
        return true
    }).catch(err => {
        logger.debug('jwtVerifyViaREST - token is invalid')
        return false
    })
}
*/

export const setJWTAccessTokenForDCRF = () => {
    logger.debug(`setJWTAccessTokenForDCRF with for user ${authManager?.email}`)
    return dcrfClient.update('set_jwt_access_token', 0, { 'user': authManager?.email, 'password': authManager?.password })
}

export const setJWTAccessTokenForRemoteTemplateEditor = (token: string) => {
    logger.debug(`setJWTAccessTokenForRemoteTemplateEditor with token ${token}`)
    store.dispatch(send({
        stream: 'set_jwt_access_token',
        payload: {
            data: { access: token }
        }
    }, WEBSOCKET_REMOTE_TEMPLATE_EDITOR_REDUX_ACTION_PREFIX))
}

class AuthManager {

    email: string | undefined
    password: string | undefined

    constructor() {
        this.email = undefined
        this.password = undefined
    }

    isLoggedIn() {
        return this.email !== undefined
    }

    login(email: string, password: string) {
        restClient.defaults.headers.common['Authorization'] = 'Basic ' + Buffer.from(`${email}:${password}`).toString('base64')

        return basicLoginViaREST().then(res => {
            logger.debug(`login successfull`)
            this.email = email
            this.password = password
            setJWTAccessTokenForDCRF()
            store.dispatch(setIsLoggedIn(true))
            return true
        }).catch((err => {
            logger.debug(`login failed - ${err}`)
            this.email = undefined
            this.password = undefined
            store.dispatch(setIsLoggedIn(false))
            return false
        }))
    }

    logout() {
        store.dispatch(setIsLoggedIn(false))

        this.email = undefined
        this.password = undefined

        // just reload the page for now
        // TODO: should disconnect all websockets / clear the redux store / ...
        window.location.reload()
    }

}

export let authManager: AuthManager | undefined = undefined
authManager = new AuthManager()