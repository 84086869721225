import { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import {
    IHardwareInterface,
    setHwInterfaceConfigAsync,
    selectHardwareInterface,
    selectHardwareInterfaces
} from "./HardwareInterfacesSlice";
import Button from '@mui/material/Button';
import { useObserveAndDeleteWidget } from '../misc/Util';
import { Trans, useTranslation } from 'react-i18next';
import i18n from 'i18next';
import Container from '@mui/material/Container';
import { FormControl, InputLabel, MenuItem, Select, TextField, Tooltip, Checkbox, FormControlLabel } from "@mui/material";

import "./HardwareInterfaceConfig.css"
import { logger } from '../../app/logging';


export function renderHardwareIntefaceError(extras: any): JSX.Element {
    const errorType = extras.error;
    switch (errorType) {
        case 'PermissionsError':
            return (
                <div>
                    {i18n.t("You don't have the correct permissions to use this device.")}<br />
                    {i18n.t("Please add the following 2 lines to /etc/udev/rules.d/99-custom.rules")}:<br />
                    <pre style={{ backgroundColor: '#ddd' }}>
                        {i18n.t("# GS USB to CAN interface")}<br />
                        'SUBSYSTEM=="usb", ATTRS&#123;idVendor&#125;=="1d50", ATTRS&#123;idProduct&#125;=="606f", MODE="0666"
                    </pre>
                    {i18n.t("After adding the lines, please reload the udev rules with this command")}:
                    <pre style={{ backgroundColor: '#ddd' }}>
                        # udevadm control --reload-rules
                    </pre>
                    {i18n.t("then replug the device.")}
                </div>
            )
        case 'DriverNotLibUSB':
            return (
                <div>
                    {i18n.t("The device you selected needs a libusb driver.")}<br />
                    {i18n.t("Please use")} <a target={'_blank'} rel={'noreferrer'} href={'https://zadig.akeo.ie'}>zadig (https://zadig.akeo.ie)</a> {i18n.t("to change the driver to")}
                    <strong>libusb-win32</strong> {i18n.t("for the USB device")} <strong>1d50:606f</strong>.
                </div>
            )
        case 'PCANDriverNotInstalled':
            return (
                <div>
                    {i18n.t("A PCAN device was detected, but the PCAN-Basic driver is not installed.")}
                </div>
            )
        default:
            return (
                <div>
                    {i18n.t("Unexpected error")} {errorType}.
                </div>
            );
    }
}


export const HardwareInterfaceConfig = (props: any) => {
    const hwInterfaces: IHardwareInterface[] = useAppSelector(selectHardwareInterfaces)
    const hwInterface: IHardwareInterface = useAppSelector(selectHardwareInterface(props.hwInterfaceId))
    const [localHwInterfaceConfig, setLocalHwInterfaceConfig] = useState<any>(hwInterface.config.user_data ?? hwInterface.config.usbcatherder_data)

    const { t } = useTranslation()

    const dispatch = useAppDispatch()
    useObserveAndDeleteWidget("HARDWAREWINTERFACECONFIG", hwInterfaces)
    
    useEffect( () => {
        if (!('user_data' in hwInterface.config)) {
            logger.debug("hardware interface config user_data has been reset -> updating local version")
            setLocalHwInterfaceConfig(hwInterface.config.usbcatherder_data)
            return
        }

        if (Object.entries(localHwInterfaceConfig).sort().toString() === Object.entries(hwInterface.config.user_data ?? {}).sort().toString()) {
            return
        }
        logger.debug("hardware interface config user_data has been changed -> updating local version")
        setLocalHwInterfaceConfig(hwInterface.config.user_data)
    // Note: we compare a string, because object would always trigger useEffect
    }, [JSON.stringify(hwInterface.config.user_data)]) // eslint-disable-line react-hooks/exhaustive-deps

    const saveConfig = () => {
        const current_device_path = hwInterface.current_device_path
        const config = { usbcatherder_data: hwInterface.config.usbcatherder_data, user_data: localHwInterfaceConfig }
        dispatch(setHwInterfaceConfigAsync({ id: hwInterface.id, config: { ...config, ...current_device_path } }))
    }

    const resetConfig = () => {
        const current_device_path = hwInterface.current_device_path
        const config = { usbcatherder_data: hwInterface.config.usbcatherder_data }
        setLocalHwInterfaceConfig(hwInterface.config.usbcatherder_data)
        dispatch(setHwInterfaceConfigAsync({ id: hwInterface.id, config: { ...config, ...current_device_path } }))
    }

    const configOptions = (() => {
        switch (hwInterface.subtype) {
            case 'GS':
                if (!('bitrate' in localHwInterfaceConfig))
                    setLocalHwInterfaceConfig({ ...localHwInterfaceConfig, bitrate: 500000 });
                return (
                    <FormControl sx={{ margin: 1, minWidth: 210 }}>
                        <InputLabel id="can-bitrate">CAN bitrate</InputLabel>
                        <Select
                            labelId="can-bitrate"
                            id="can-bitrate-select"
                            value={localHwInterfaceConfig.bitrate}
                            label="CAN bitrate"
                            onChange={(event) => setLocalHwInterfaceConfig((localHwInterfaceConfig: any) => { return { ...localHwInterfaceConfig, bitrate: event.target.value } })}
                        >
                            <MenuItem value={10000}>10000</MenuItem>
                            <MenuItem value={20000}>20000</MenuItem>
                            <MenuItem value={50000}>50000</MenuItem>
                            <MenuItem value={83333}>83333</MenuItem>
                            <MenuItem value={100000}>100000</MenuItem>
                            <MenuItem value={125000}>125000</MenuItem>
                            <MenuItem value={250000}>250000</MenuItem>
                            <MenuItem value={500000}>500000</MenuItem>
                            <MenuItem value={1000000}>1000000</MenuItem>
                        </Select>
                    </FormControl>
                )
            case 'PCAN':
                if (!('bitrate' in localHwInterfaceConfig))
                    setLocalHwInterfaceConfig({ ...localHwInterfaceConfig, bitrate: 500000 });
                if (!('state' in localHwInterfaceConfig))
                    setLocalHwInterfaceConfig({ ...localHwInterfaceConfig, state: "ACTIVE" });
                return (
                    <Container>
                        <FormControl sx={{ margin: 1, minWidth: 210, marginLeft: -2 }}>
                            <InputLabel id="can-bitrate">CAN bitrate</InputLabel>
                            <Select
                                labelId="can-bitrate"
                                id="can-bitrate-select"
                                value={localHwInterfaceConfig.bitrate}
                                label="CAN bitrate"
                                onChange={(event) => setLocalHwInterfaceConfig((localHwInterfaceConfig: any) => { return { ...localHwInterfaceConfig, bitrate: event.target.value } })}
                            >
                                <MenuItem value={10000}>10000</MenuItem>
                                <MenuItem value={20000}>20000</MenuItem>
                                <MenuItem value={33300}>33300</MenuItem>
                                <MenuItem value={50000}>50000</MenuItem>
                                <MenuItem value={83333}>83333</MenuItem>
                                <MenuItem value={100000}>100000</MenuItem>
                                <MenuItem value={125000}>125000</MenuItem>
                                <MenuItem value={250000}>250000</MenuItem>
                                <MenuItem value={500000}>500000</MenuItem>
                                <MenuItem value={1000000}>1000000</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{ margin: 1, minWidth: 210 }}>
                            <InputLabel id="can-state">Interface state</InputLabel>
                            <Select
                                labelId="can-state"
                                id="can-state-select"
                                value={localHwInterfaceConfig.state}
                                label="CAN State"
                                onChange={(event) => setLocalHwInterfaceConfig((localHwInterfaceConfig: any) => { return { ...localHwInterfaceConfig, state: event.target.value } })}
                            >
                                <MenuItem value={"ACTIVE"}>ACTIVE</MenuItem>
                                <MenuItem value={"PASSIVE"}>PASSIVE</MenuItem>
                            </Select>
                        </FormControl>

                    </Container>
                )
            case 'Vector':
                if (!('bitrate' in localHwInterfaceConfig))
                    setLocalHwInterfaceConfig({ ...localHwInterfaceConfig, bitrate: 500000 });
                return (
                    <FormControl sx={{ margin: 1, minWidth: 210 }}>
                        <InputLabel id="can-bitrate">CAN bitrate</InputLabel>
                        <Select
                            labelId="can-bitrate"
                            id="can-bitrate-select"
                            value={localHwInterfaceConfig.bitrate}
                            label="CAN bitrate"
                            onChange={(event) => setLocalHwInterfaceConfig((localHwInterfaceConfig: any) => { return { ...localHwInterfaceConfig, bitrate: event.target.value } })}
                        >
                            <MenuItem value={10000}>10000</MenuItem>
                            <MenuItem value={20000}>20000</MenuItem>
                            <MenuItem value={33300}>33300</MenuItem>
                            <MenuItem value={50000}>50000</MenuItem>
                            <MenuItem value={83333}>83333</MenuItem>
                            <MenuItem value={100000}>100000</MenuItem>
                            <MenuItem value={125000}>125000</MenuItem>
                            <MenuItem value={250000}>250000</MenuItem>
                            <MenuItem value={500000}>500000</MenuItem>
                            <MenuItem value={1000000}>1000000</MenuItem>
                        </Select>
                    </FormControl>
                )
            case 'SLCAN':
                if (!('bitrate' in localHwInterfaceConfig) || localHwInterfaceConfig.bitrate === null)
                    setLocalHwInterfaceConfig({ ...localHwInterfaceConfig, bitrate: 500000 });
                if (!('ttyBaudrate' in localHwInterfaceConfig))
                    setLocalHwInterfaceConfig({ ...localHwInterfaceConfig, ttyBaudrate: 115200 });
                if (!('btr' in localHwInterfaceConfig))
                    setLocalHwInterfaceConfig({ ...localHwInterfaceConfig, btr: '' });
                if (!('sleep_after_open' in localHwInterfaceConfig))
                    setLocalHwInterfaceConfig({ ...localHwInterfaceConfig, sleep_after_open: 2 });
                if (!('rtscts' in localHwInterfaceConfig))
                    setLocalHwInterfaceConfig({ ...localHwInterfaceConfig, rtscts: false });
                return (
                    <Container>
                        <FormControl sx={{ margin: 1, minWidth: 210, marginLeft: -2 }}>
                            <InputLabel id="can-bitrate">CAN bitrate</InputLabel>
                            <Select
                                labelId="can-bitrate"
                                id="can-bitrate-select"
                                value={localHwInterfaceConfig.bitrate}
                                label="CAN bitrate"
                                onChange={(event) => setLocalHwInterfaceConfig((localHwInterfaceConfig: any) => { return { ...localHwInterfaceConfig, bitrate: event.target.value } })}
                            >
                                <MenuItem value={10000}>10000</MenuItem>
                                <MenuItem value={20000}>20000</MenuItem>
                                <MenuItem value={33300}>33300</MenuItem>
                                <MenuItem value={50000}>50000</MenuItem>
                                <MenuItem value={83333}>83333</MenuItem>
                                <MenuItem value={100000}>100000</MenuItem>
                                <MenuItem value={125000}>125000</MenuItem>
                                <MenuItem value={250000}>250000</MenuItem>
                                <MenuItem value={500000}>500000</MenuItem>
                                <MenuItem value={1000000}>1000000</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{ margin: 1, minWidth: 210 }}>
                            <InputLabel id="tty-baudrate">TTY bitrate</InputLabel>
                            <Select
                                labelId="tty-baudrate"
                                id="tty-baudrate-select"
                                value={localHwInterfaceConfig.ttyBaudrate}
                                label="TTY baudrate"
                                onChange={(event) => setLocalHwInterfaceConfig((localHwInterfaceConfig: any) => { return { ...localHwInterfaceConfig, ttyBaudrate: event.target.value } })}
                            >
                                <MenuItem value={9600}>9600</MenuItem>
                                <MenuItem value={57200}>57200</MenuItem>
                                <MenuItem value={115200}>115200</MenuItem>
                                <MenuItem value={230400}>230400</MenuItem>
                                <MenuItem value={460800}>460800</MenuItem>
                                <MenuItem value={921600}>921600</MenuItem>
                                <MenuItem value={1000000}>1000000</MenuItem>
                                <MenuItem value={2000000}>2000000</MenuItem>
                            </Select>
                        </FormControl>
                        <br />
                        <TextField
                            sx={{ margin: 1, minWidth: 200, marginLeft: -2 }}
                            id="slcan-btr"
                            value={localHwInterfaceConfig.btr ?? ""}
                            label="BTR register (optional)"
                            onChange={(event) => setLocalHwInterfaceConfig((localHwInterfaceConfig: any) => { return { ...localHwInterfaceConfig, btr: event.target.value } })}
                        />
                        <TextField
                            sx={{ margin: 1, minWidth: 200 }}
                            id="slcan-sleep"
                            value={localHwInterfaceConfig.sleep_after_open}
                            label="Time to wait in seconds after opening serial connection"
                            onChange={(event) => setLocalHwInterfaceConfig((localHwInterfaceConfig: any) => { return { ...localHwInterfaceConfig, sleep_after_open: parseFloat(event.target.value) } })}
                        />
                        <br />
                        <FormControlLabel
                            sx={{ margin: 1, minWidth: 200, marginLeft: -3 }}
                            control={
                                <Checkbox
                                    id="tty-rtscts1"
                                    checked={localHwInterfaceConfig.rtscts}
                                    onChange={(event) => setLocalHwInterfaceConfig((localHwInterfaceConfig: any) => { return { ...localHwInterfaceConfig, rtscts: event.target.checked } })}
                                />
                            }
                            label={t("hardware handshake (RTS/CTS)")}
                        />
                    </Container>
                )
            case 'SocketCAN':
                return (
                    <div>
                        <p>{t("SocketCAN devices can not be configured by non-root users.")}</p>
                        <p><Trans i18nKey="Please configure the SocketCAN interface using the <1>ip</1> command." t={t}>Please configure the SocketCAN interface using the <strong>ip</strong> command.</Trans></p>
                    </div>
                )
            default:
                return (
                    <p>{t("There are no configurable parameters for the selected interface")}</p>
                )
        }
    })()

    // TODO: figure out why current_device_path still comes as a string instead of an object
    let path = hwInterface.current_device_path
    let info = hwInterface.extended_state
    if (typeof (path) !== 'object') {
        logger.debug("HwInterfaceConfig: path should be an object!")
        return
    }
    if (typeof (info) !== 'object') {
        logger.debug("HwInterfaceConfig: info should be an object!")
        return
    }
    const config = JSON.stringify({ ...path, ...localHwInterfaceConfig })

    return (
        <Container sx={{ overflow: "hidden", overflowY: "auto", marginTop: 0 }}>
            <br />
            <Tooltip title={t("Hardware interface name") as string}>
                <TextField
                    sx={{ margin: 1, minWidth: 200 }}
                    id="hardware-interface-name-textfield"
                    label={t("Hardware Interface Name")}
                    value={hwInterface.name}

                />
            </Tooltip>
            <br />
            {configOptions}
            <br />
            <br />
            <Trans i18nKey='Generated configuration for {hwInterface.name} follows, this will be passed directly to the python-can <3>can.Bus(...)</3> constructor.' values={{ hwInterface }}>
                Generated configuration for {hwInterface.name} follows, this will be passed
                directly to the python-can <span style={{ fontFamily: 'monospace' }}>can.Bus(...)</span> "constructor".
            </Trans>
            <br />
            <TextField
                id="hardware-config-text-area"
                multiline
                minRows={4}
                value={config}
                aria-label="empty textarea"
                placeholder="Empty"
                sx={{ marginBottom: 2, width: "75%" }}
            />
            <br />
            <Button
                id="hardware-config-save-button"
                variant="contained"
                onClick={saveConfig}
            >
                {t("Save")}
            </Button>
            <Button
                id="hardware-config-reset-button"
                variant="contained"
                onClick={resetConfig}
                sx={{ marginLeft: 1 }}
                color="warning"
            >
                {t("Reset")}
            </Button>
        </Container>
    )
}
