import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
    deleteISOTPEndpointAsync,
    IISOTPEndpoint,
    IUpdateISOTPEndpoint,
    selectISOTPEndpoint,
    selectISOTPEndpoints,
    updateISOTPEndpointAsync,
} from "./ISOTPEndpointsSlice";
import { ISOTPEndpointEdit } from './ISOTPEndpointEdit';
import { DeleteElementButton, useObserveAndDeleteWidget } from '../misc/Util';

import Button from "@mui/material/Button";
import Container from "@mui/material/Container";

import "./ISOTPEndpointConfig.css";
import { useTranslation } from "react-i18next";
import FormControl from "@mui/material/FormControl";

export const ISOTPEndpointConfig = (props: any) => {

    const isotpEndpoint: IISOTPEndpoint | undefined = useAppSelector(selectISOTPEndpoint(props.isotpEndpointId))
    const isotpEndpoints: IISOTPEndpoint[] = useAppSelector(selectISOTPEndpoints)

    const [name, setName] = useState(isotpEndpoint?.name ?? '')
    const [hwInterface, setHwInterface] = useState(isotpEndpoint?.hw_interface ?? '')
    const [txId, setTxId] = useState(`${isotpEndpoint?.tx_id ?? ''}`)
    const [rxId, setRxId] = useState(`${isotpEndpoint?.rx_id ?? ''}`)
    const [txExtAddr, setTxExtAddr] = useState(`${isotpEndpoint?.ext_address ?? ''}`)
    const [rxExtAddr, setRxExtAddr] = useState(`${isotpEndpoint?.rx_ext_address ?? ''}`)
    const [padding, setPadding] = useState(isotpEndpoint?.padding ?? false)

    const { t } = useTranslation()

    const dispatch = useAppDispatch()
    useObserveAndDeleteWidget("ISOTPENDPOINTCONFIG", isotpEndpoints)

    if (isotpEndpoint === undefined) {
        // should never be shown under normal circumstances
        return (
            <div>
                DELETED
            </div>
        )
    }

    const hasAllNeededData = () => {
        return name.length > 0 &&
               hwInterface.length > 0 &&
               txId.length > 0 &&
               rxId.length > 0
    }

    const saveEndpoint = () => {
        if (!hasAllNeededData()) {
            return
        }

        const rx_ext_addr = rxExtAddr === '' ? undefined : parseInt(rxExtAddr)
        const tx_ext_addr = txExtAddr === '' ? undefined : parseInt(txExtAddr)
        const updatedEndpoint: IUpdateISOTPEndpoint = {
            id: isotpEndpoint?.id ?? -1,
            data: {
                hw_interface_id: hwInterface,
                name: name,
                rx_id: parseInt(rxId),
                tx_id: parseInt(txId),
                rx_ext_address: rx_ext_addr,
                ext_address: tx_ext_addr,
                padding: padding
            }
        }
        dispatch(updateISOTPEndpointAsync(updatedEndpoint))
    }

    const deleteEndpoint = () => {
        if (isotpEndpoint === undefined) {
            return
        }
        dispatch(deleteISOTPEndpointAsync(isotpEndpoint.id))
    }

    return (
        <Container sx={{ overflow: "hidden", overflowY: "auto", height: "90vh" }}>
            <ISOTPEndpointEdit {...{
                stateName: [name, setName],
                stateHwInterface: [hwInterface, setHwInterface],
                stateTxId: [txId, setTxId],
                stateRxId: [rxId, setRxId],
                stateExt: [txExtAddr, setTxExtAddr],
                stateRxExt: [rxExtAddr, setRxExtAddr],
                statePadding: [padding, setPadding] }
            }/>

            <br/>
            <FormControl sx={{ margin: 1, marginLeft: 0, marginTop: -3}}>
                <Button
                    sx={{ margin: 1 }}
                    variant="contained"
                    onClick={saveEndpoint}
                    disabled={!hasAllNeededData()}
                >
                    {t("Save")}
                </Button>
            </FormControl>
            <FormControl sx={{ margin: 1, marginLeft: -1, marginTop: -3}}>
                <DeleteElementButton
                title={t("Do you really want to delete this endpoint?") as string}
                message={t("Deleting an endpoint is permanent and can not be undone. Of course you can always create a new one. Are you sure?") as string}
                deleteCallback={deleteEndpoint}
                />
            </FormControl>
        </Container>
    )
}
