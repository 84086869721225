import { dcrfClient } from "../../app/api";
import { logger } from "../../app/logging";
import {
    IRemoteJobContext,
    ICreateRemoteJobContext
} from "./RemoteJobContextSlice";

const wsStream = 'remotejobcontext'

export const fetchAllRemoteJobContextsViaDCRF = () => {
    logger.debug('fetchAllRemoteJobContextsViaDCRF')
    return dcrfClient.list(wsStream)
}

export const patchRemoteJobContextViaDCRF = (id: number, data: any) => {
    logger.debug(`patchRemoteJobContextViaDCRF with id: ${id} - data: ${data}`)
    return dcrfClient.patch(wsStream, id, data)
}

export const createRemoteJobContextViaDCRF = (newRemoteJobContext: ICreateRemoteJobContext) => {
    logger.debug(`createRemoteJobContextViaDCRF ${newRemoteJobContext}`)
    return dcrfClient.create(wsStream, newRemoteJobContext)
}

export const deleteRemoteJobContextViaDCRF = (id: number) => {
    logger.debug(`deleteRemoteJobContextViaDCRF with id: ${id}`)
    return dcrfClient.delete(wsStream, id)
}

export const subscribeToRemoteJobContextChangesViaDCRF = (callback: (remoteJobContext: IRemoteJobContext, action: string) => void) => {
    logger.debug("subscribeToRemoteJobContextChangesViaDCRF")
    const subscription = dcrfClient.subscribe(wsStream,
        {},
        (remoteJobContext, action) => {
            callback(remoteJobContext as IRemoteJobContext, action)
        },
        {
            includeCreateEvents: true,
            requestId: 'subscribeToRemoteJobContextChangesViaDCRF',
            subscribeAction: 'subscribe_to_all_changes',
            unsubscribeAction: 'unsubscribe_from_all_changes',
        }
    )
    // a subscription can be canceled by calling "cancel" on the returned subscription object
    return subscription
}
